import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import gtm from '../../lib/gtm';
import { Helmet } from 'react-helmet-async';
import ChevronRightIcon from '../../icons/ChevronRight';
import { Link as RouterLink } from 'react-router-dom';
import UploadIcon from '../../icons/Upload';
import DownloadIcon from '../../icons/Download';
import PlusIcon from '../../icons/Plus';
import CustomersListTable from './CustomersListTable';
import Can, { permissions, subjects } from '../../utils/Can';
import GenericBreadcrumb from '../../components/GenericBreadcrumb';

const CustomersList = () => {
  const { settings } = useSettings();

  return (
    <>
      <Helmet>
        <title>Dashboard: Customer List </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Customer List
              </Typography>
              <GenericBreadcrumb />
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Can I={permissions.CREATE} a={subjects.CUSTOMERS}>
                  <Button
                    color="primary"
                    startIcon={<PlusIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    variant="contained"
                  >
                    Add Customer
                  </Button>
                </Can>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <CustomersListTable />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CustomersList;
