import type { FC, ChangeEvent } from 'react';
import { RootState, useDispatch, useSelector } from '../../store';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  CircularProgress,
} from '@mui/material';
import useSettings from '../../hooks/useSettings';
import AnalyticsChart from './AnalyticsChart';
import { Helmet } from 'react-helmet-async';
import GenericBreadcrumb from '../../components/GenericBreadcrumb';
import AnalyticsFilters from './AnalyticsFilters';
import { useEffect, useRef, useState } from 'react';
import {
  getReportsCustomers,
  getReportsInvoices,
  getReportsOrganizations,
  getReportsTechnicians,
  getReportsVendors,
  getReportsVenue,
} from '../../slices/analytics';
import { RequestStatus } from '../../utils/RequestStatus';
import { useSearchParams } from 'react-router-dom';
import { convertSelectedToArrays } from '../../utils/dropdownHelpers';
import styled from 'styled-components';
import AnalyticsDatepicker from './AnalyticsDatepicker';
import { getOrganizations } from '../../slices/organizations';
import { addMinutes, intervalToDuration, parseISO } from 'date-fns';

const DataValueContainer = styled.div`
  display: block;
  min-width: 50px;
  text-align: center;
`;

const Analytics: FC<any> = (props) => {
  const dispatch = useDispatch();
  const { settings } = useSettings();
  let [searchParams, setSearchParams] = useSearchParams({});
  const [appliedFilters, setAppliedFilters] = useState<any>({});
  const [appliedDates, setAppliedDates] = useState<any>(
    searchParams.get('start-date') && searchParams.get('end-date')
      ? {
          startDate: parseISO(searchParams.get('start-date')),
          endDate: parseISO(searchParams.get('end-date')),
        }
      : {}
  );
  const {
    chart: chartData,
    table: tableData,
    dropdownChoices,
  } = useSelector((state: RootState) => state.analytics);

  const { count: userOrganizationsCount, fetchStatus: userOrganizationsFetchStatus } = useSelector(
    (state: RootState) => state.organizations.list
  );

  const { fetchStatus: chartFetchStatus } = chartData;

  const {
    customers: customersChoices,
    technicians: techniciansChoices,
    organizations: organizationsChoices,
    vendors: vendorsChoices,
    invoices: invoicesChoices,
  } = dropdownChoices;

  const otherSearchParams = Object.fromEntries(searchParams.entries());

  const handleDropdownsChange = (values, wasOrganizationChanged?: boolean) => {
    const convertedValues: any = convertSelectedToArrays(values, false);
    if (wasOrganizationChanged) {
      dispatch(
        getReportsTechnicians({ search: '', organizations: convertedValues?.organizations })
      );
    }
    setAppliedFilters(convertedValues);
  };

  const handleDatesChange = (dates) => {
    setAppliedDates(dates);
  };

  const convertToReadableTimePeriod = (min) => {
    const startDate = new Date();
    const endDate = addMinutes(startDate, min);
    const duration = intervalToDuration({ start: startDate, end: endDate });

    return `${duration.months ? duration.months + ' mo  ' : ''} ${
      duration.days ? duration.days + ' d  ' : ''
    } ${duration.hours ? duration.hours + ' h  ' : ''} ${
      duration.minutes ? duration.minutes + ' min ' : ''
    } `;
  };

  useEffect(() => {
    dispatch(getReportsOrganizations({ search: '' }));
  }, []);

  // everything depends on organization? and it will be a deeper cascade
  useEffect(() => {
    if (RequestStatus.isDone(organizationsChoices.fetchStatus)) {
      dispatch(getReportsTechnicians({ search: '' }));
    }
  }, [organizationsChoices.fetchStatus]);

  useEffect(() => {
    const datesToRequest: any = {};
    if (appliedDates?.startDate) datesToRequest.start_date = appliedDates?.startDate;
    if (appliedDates?.endDate) datesToRequest.end_date = appliedDates?.endDate;
    dispatch(getReportsVenue({ ...appliedFilters, ...datesToRequest }));
  }, [appliedFilters, appliedDates]);

  useEffect(() => {
    if (RequestStatus.isNull(userOrganizationsFetchStatus))
      dispatch(getOrganizations({ limit: 10, offset: 0, search: null }));
  }, []);

  return (
    <>
      <Helmet>
        <title>Analytics</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          display: 'flex',
          py: 4,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Analytics
              </Typography>
              <GenericBreadcrumb />
              <Box
                sx={{
                  mb: -1,
                  mx: -1,
                  mt: 1,
                }}
              ></Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              mt: 3,
              mb: -5,
              pb: 3,
              flex: 1,
              position: 'relative',
              height: `calc(100% - 84px)`,
              minHeight: '200px',
            }}
          >
            <Card sx={{}}>
              <Box
                sx={{ mt: 0, flex: 1, minHeight: '100%', display: 'flex', flexDirection: 'column' }}
              >
                {/*<Table>*/}
                {/*  <TableBody>*/}
                {/*    // <TableRow>*/}
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    Filters
                  </Typography>
                  <AnalyticsFilters
                    customersChoices={customersChoices}
                    organizationsChoices={organizationsChoices}
                    techniciansChoices={techniciansChoices}
                    vendorsChoices={vendorsChoices}
                    invoicesChoices={invoicesChoices}
                    onDropdownsChange={handleDropdownsChange}
                    userOrganizationsCount={userOrganizationsCount}
                  />
                </TableCell>
                {/*// </TableRow>*/}
                {/*// <TableRow>*/}
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    Dates
                  </Typography>
                  <AnalyticsDatepicker onDatesChange={handleDatesChange} />
                </TableCell>
                {/*// </TableRow>*/}

                {/*<TableRow>*/}
                <TableCell sx={{ display: 'flex' }}>
                  <Typography color="textPrimary" variant="subtitle2">
                    Monthly Revenue
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{ paddingLeft: '30px', display: 'flex' }}
                  >
                    $
                    {RequestStatus.isFetching(chartFetchStatus) ? (
                      <DataValueContainer>
                        <CircularProgress
                          size={20}
                          sx={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}
                        />
                      </DataValueContainer>
                    ) : (
                      <DataValueContainer>{tableData?.data.revenue}</DataValueContainer>
                    )}
                  </Typography>
                </TableCell>
                {/*</TableRow>*/}
                {/*<TableRow>*/}
                <TableCell sx={{ display: 'flex' }}>
                  <Typography color="textPrimary" variant="subtitle2">
                    Monthly Tips
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{ paddingLeft: '30px', display: 'flex' }}
                  >
                    $
                    {RequestStatus.isFetching(chartFetchStatus) ? (
                      <DataValueContainer>
                        <CircularProgress
                          size={20}
                          sx={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}
                        />
                      </DataValueContainer>
                    ) : (
                      <DataValueContainer>{tableData?.data.tips}</DataValueContainer>
                    )}
                  </Typography>
                </TableCell>
                {/*</TableRow>*/}
                {/*<TableRow>*/}
                <TableCell sx={{ display: 'flex' }}>
                  <Typography color="textPrimary" variant="subtitle2">
                    Billable Chats
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{ paddingLeft: '30px', display: 'flex' }}
                  >
                    $
                    {RequestStatus.isFetching(chartFetchStatus) ? (
                      <DataValueContainer>
                        <CircularProgress
                          size={20}
                          sx={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}
                        />
                      </DataValueContainer>
                    ) : (
                      <DataValueContainer>{tableData?.data?.billableChats}</DataValueContainer>
                    )}
                  </Typography>
                </TableCell>
                {/*</TableRow>*/}
                {/*<TableRow>*/}
                <TableCell sx={{ display: 'flex' }}>
                  <Typography color="textPrimary" variant="subtitle2">
                    Monthly Minutes
                  </Typography>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{ paddingLeft: '30px', display: 'flex' }}
                  >
                    {RequestStatus.isFetching(chartFetchStatus) ? (
                      <DataValueContainer>
                        <CircularProgress
                          size={20}
                          sx={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}
                        />
                      </DataValueContainer>
                    ) : (
                      <DataValueContainer>
                        {convertToReadableTimePeriod(tableData?.data.minutes)}
                      </DataValueContainer>
                    )}
                  </Typography>
                </TableCell>
                {/*</TableRow>*/}

                {/*<TableRow>*/}
                {chartData?.data && (
                  <AnalyticsChart
                    chartData={chartData.data}
                    isLoading={RequestStatus.isFetching(chartFetchStatus)}
                  />
                )}
                {/*</TableRow>*/}
                {/*</TableBody>*/}
                {/*</Table>*/}
              </Box>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Analytics;
