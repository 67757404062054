import { Box, Divider, LinearProgress, ListItemText } from '@mui/material';
import { FC, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { ItemType, LocationType } from '../../../slices/locations';
import { RequestStatus } from '../../../utils/RequestStatus';
import LocationsItemsRow from './LocationsItemsRow';
import { AutoSizer, List as VirtualizedList } from 'react-virtualized';

interface PlacesMachinesListProps {
  items: Array<ItemType>;
  itemsFetchStatus: string | null;
  handleSetQrCodesUrlsToPrint: (item: LocationType | ItemType) => void;
  qrCodesToPrint: Array<{
    qr_code: string;
    name: string;
    ancestors_names: Array<string>;
    printAmount: number;
  }>;
  isPrintStateActive: boolean;
  isAnyLoading: boolean;
}

const LocationsItemsList: FC<PlacesMachinesListProps> = (props) => {
  const {
    items,
    itemsFetchStatus,
    handleSetQrCodesUrlsToPrint,
    qrCodesToPrint,
    isPrintStateActive,
    isAnyLoading,
  } = props;

  const heights = {
    locationHeader: '101px',
    loadingBar: '4px',
    searchBox: '60px',
    printHelpText: '43px',
    selectAllItemsCheckbox: '59px',
    cancelButtonHeight: '33px',
  };

  const params = useParams();

  const rootRef = useRef<any>(null);

  useEffect(() => {
    if (rootRef?.current?._container && RequestStatus.isDone(itemsFetchStatus)) {
      rootRef.current._container.scrollTop = 0;
    }
  }, [itemsFetchStatus, rootRef?.current]);

  const rowRenderer = ({ key, index, style }) => {
    const item = items[index];
    return (
      <Box key={key} style={style}>
        <LocationsItemsRow
          item={item}
          handleSetQrCodesUrlsToPrint={handleSetQrCodesUrlsToPrint}
          qrCodesToPrint={qrCodesToPrint}
          isPrintStateActive={isPrintStateActive}
          isAnyLoading={isAnyLoading}
        />
        {index < items?.length - 1 && <Divider />}
      </Box>
    );
  };

  const noRowsRenderer = () =>
    !RequestStatus.isFetching(itemsFetchStatus) && (
      <ListItemText
        primary={
          Object.values(params)?.length > 0 ? 'No items for this place found' : 'No items found'
        }
        primaryTypographyProps={{
          noWrap: true,
          color: 'textSecondary',
          variant: 'h6',
          align: 'center',
        }}
      />
    );

  return (
    <>
      <Box sx={{ height: '4px' }}>
        {RequestStatus.isFetching(itemsFetchStatus) && <LinearProgress />}
      </Box>
      {/*<Scrollbar*/}
      {/*  ref={rootRef}*/}
      {/*  style={{*/}
      {/*    //4px -> loading bar height; 60px -> search box height*/}
      {/*    height: `calc(100% - ${heights.loadingBar} - ${heights.searchBox} - ${*/}
      {/*      heights.locationHeader*/}
      {/*    }${*/}
      {/*      isPrintStateActive*/}
      {/*        ? ` - ${heights.printHelpText} - ${heights.selectAllItemsCheckbox}`*/}
      {/*        : ''*/}
      {/*    })`,*/}
      {/*  }}*/}
      {/*>*/}

      {/*{items.length > 0 ? (*/}
      {/*  <Box*/}
      {/*    sx={{*/}
      {/*      height: `calc(100% - ${heights.loadingBar} - ${heights.searchBox} - ${*/}
      {/*        heights.locationHeader*/}
      {/*      }${*/}
      {/*        isPrintStateActive*/}
      {/*          ? ` - ${heights.printHelpText} - ${heights.selectAllItemsCheckbox}`*/}
      {/*          : ''*/}
      {/*      })`,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <AutoSizer>*/}
      {/*      {({ height, width }) => (*/}
      {/*        <List*/}
      {/*          // sx={{*/}
      {/*          //   p: 0,*/}
      {/*          // }}*/}
      {/*          height={height}*/}
      {/*          width={width}*/}
      {/*          rowRenderer={rowRenderer}*/}
      {/*          rowCount={items.length}*/}
      {/*          rowHeight={116}*/}
      {/*          overscanRowCount={25}*/}
      {/*          noRowsRenderer={noRowsRenderer}*/}
      {/*        />*/}
      {/*      )}*/}
      {/*    </AutoSizer>*/}
      {/*  </Box>*/}
      {/*) : (*/}
      {/*  <>*/}
      {/*    {!RequestStatus.isFetching(itemsFetchStatus) && (*/}
      {/*      <ListItemText*/}
      {/*        primary={*/}
      {/*          Object.values(params)?.length > 0*/}
      {/*            ? 'No items for this place found'*/}
      {/*            : 'No items found'*/}
      {/*        }*/}
      {/*        primaryTypographyProps={{*/}
      {/*          noWrap: true,*/}
      {/*          color: 'textSecondary',*/}
      {/*          variant: 'h6',*/}
      {/*          align: 'center',*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  </>*/}
      {/*)}*/}

      <Box
        sx={{
          height: `calc(100% - ${heights.loadingBar} - ${heights.searchBox} - ${
            heights.locationHeader
          }${
            isPrintStateActive
              ? ` - ${heights.printHelpText} - ${heights.selectAllItemsCheckbox} - ${heights.cancelButtonHeight}`
              : ''
          })`,
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <VirtualizedList
              // sx={{
              //   p: 0,
              // }}
              height={height}
              width={width}
              rowRenderer={rowRenderer}
              rowCount={items.length}
              rowHeight={116}
              overscanRowCount={25}
              noRowsRenderer={noRowsRenderer}
            />
          )}
        </AutoSizer>
      </Box>

      {/*</Scrollbar>*/}
    </>
  );
};

export default LocationsItemsList;
