import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
// import InputDropdown from './InputDropdown';
import { GenericInput } from './GenericInput';
// import InputSearch from './InputSearch';
// import TextMaskPercent, { StyledInputAdornment } from './PercentMask';
// import InputPin from './InputPin';
// import { SmallSpinner } from '../LoadingIndicators';
import { Controller } from 'react-hook-form';
// import MaskedInput from 'react-text-mask';
// import { stringToZipCode } from '../../helpers/formatters';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// import datePickerStyle from './datePicker.style';
import plLocale from 'date-fns/locale/pl';
// import { KeyboardDatePicker } from '@material-ui/pickers';
import { MobileDatePicker } from '@mui/x-date-pickers';
import format from 'date-fns/format';
import InputPhone from './InputPhone';
import { RequestStatus } from '../../../utils/RequestStatus';
import { Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { convertPhoneToInput, convertPhoneToInputReadOnly } from '../../../utils/formsHelper';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { experimentalStyled, Theme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import EventIcon from '@mui/icons-material/Event';
import { DropdownInput } from './InputDropdown';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// const PostCodeMask = (props) => {
//   const { inputRef, ...other } = props;
//
//   return (
//     <MaskedInput
//       {...other}
//       ref={inputRef}
//       mask={[/[0-9]/, /\d/, '-', /\d/, /\d/, /\d/]}
//       placeholderChar={'\u2000'}
//     />
//   );
// };

export const StyledInputAdornment = experimentalStyled(InputAdornment)({
  marginRight: '10px',
});

const ValidationMessage = experimentalStyled('div')(({ theme }) => ({
  fontSize: '11px',
  color: 'red',

  marginTop: '3px',
}));

const DatePickerInput = (props) => <GenericInput {...props} />;

class PLLocaleUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'LLLL', { locale: this.locale });
  }

  getDatePickerHeaderText(date) {
    return format(date, 'dd MMMM', { locale: this.locale });
  }
}

const InputParent = styled.div`
  display: block;
`;

const DisplayOnly = styled(Typography)`
  min-height: 50px;
`;

export const GetDisplayOnlyValueForPhoneNumberInput = (props) => {
  const { defaultValue } = props;
  const [value, setValue] = useState<string>('');
  const inputRef = useRef(null);

  useEffect(() => {
    const inputValue = inputRef.current?.value;
    setValue(inputValue);
  }, [defaultValue]);

  return (
    <>
      <PhoneInput
        containerStyle={{ display: 'none' }}
        value={defaultValue}
        key={defaultValue}
        inputStyle={{ display: 'none' }}
        inputClass="hiddenPhoneNumberInput"
        inputProps={{
          ref: inputRef,
        }}
      />
      {value}
    </>
  );
};

const getDropdownValue = ({ value, choices, label }) => {
  return value === null || value === 'null' || !choices?.length ? label || '' : value;
};

interface InputProps {
  isEditMode?: boolean;
  getValues?: any;
  defaultValue?: any;
  label?: any;
  type?: any;
  choices?: any;
  name?: any;
  fetchStatus?: any;
  inputProps?: any;
  control?: any;
  mask?: any;
  validationMessage?: any;
  initialFocusedDate?: any;
  disabled?: any;
  register?: any;
  onlyNumbers?: any;
  shrinkLabel?: boolean;
  sx?: any;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
  requiredSign?: boolean;
  fullWidth?: boolean;
  noValueMessage?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
}

export const Input: FC<InputProps> = (props) => {
  const {
    isEditMode,
    getValues,
    defaultValue = '',
    label,
    type,
    choices,
    name,
    fetchStatus,
    inputProps,
    control,
    mask,
    validationMessage,
    disabled,
    shrinkLabel = false,
    sx = {},
    multiline = false,
    rows = 1,
    placeholder = null,
    requiredSign = false,
    fullWidth = true,
    noValueMessage = 'No options found',
    minDate = null,
    maxDate = null,
  } = props;

  const getDisplayOnlyValue = () => {
    let value = defaultValue || (getValues && getValues(name));
    value = String(value || '');

    // if (mask === 'postcode' && value.length === 5 && !value.includes('-')) {
    //   return stringToZipCode(value);
    // } else {
    return value && value !== 'null' ? value : '-';
    // }
  };

  const getInputValue = (value) => {
    return value === null || value === 'null' ? '' : value;
  };

  const getValueOnChange = (value) => {
    return value === 'null' ? null : value;
  };

  const getDisplayOnlyValueForDropdown = () => {
    const value = getDisplayOnlyValue();
    let display = value;
    if (value && choices) {
      display = choices.find((choice) => choice.value === value)?.label;
    }
    return display;
  };

  const handlePhoneInputCountChange = useCallback((formattedValue, onChange) => {
    const newValue = formattedValue
      .replace(/\)/g, '')
      .replace(/\(/g, '')
      .replace(/\-/g, '')
      .replace(/\s/g, '');
    onChange(newValue);
  }, []);

  return (
    <>
      {isEditMode ? (
        type === 'dropdown' ? (
          <>
            <Controller
              control={control}
              name={name}
              defaultValue={defaultValue}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
                <DropdownInput
                  {...props}
                  label={
                    props.label
                      ? `${props.label || props.placeholder} ${requiredSign ? '*' : ''}`
                      : null
                  }
                  noValueMessage={noValueMessage}
                  onChange={onChange}
                  value={value}
                  helperText={validationMessage}
                  error={Boolean(validationMessage)}
                  inputRef={props.register}
                  variant="outlined"
                  id={`outlined-amount-${name}`}
                  InputLabelProps={{
                    shrink: (defaultValue !== '' && value !== '') || isTouched,
                  }}
                  InputProps={{
                    ...(mask === 'postcode'
                      ? {
                          /*inputComponent: PostCodeMask*/
                        }
                      : {}),
                    value: getDropdownValue({ value, choices, label }),
                    onChange: (e) => onChange(getValueOnChange(e.target.value)),
                    onKeyPress: (e) => {
                      if (props.onlyNumbers) {
                        const re = /[0-9]/;
                        if (!re.test(e.key)) e.preventDefault();
                      }
                    },
                    ...inputProps,
                  }}
                />
              )}
            />
          </>
        ) : // <InputDropdown {...props} error={validationMessage} helperText={validationMessage} />
        type === 'phone' ? (
          <>
            <Controller
              control={control}
              name={name}
              defaultValue={defaultValue}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
                <PhoneInput
                  onChange={(value, data, event, formattedValue) => {
                    handlePhoneInputCountChange(formattedValue, onChange);
                  }}
                  copyNumbersOnly={false}
                  value={value}
                  defaultErrorMessage={''}
                  country={'us'}
                  isValid={() => !validationMessage}
                  disableCountryGuess={false}
                  onlyCountries={['ca', 'us', 'pl', 'nz', 'au', 'cr', 'gb']}
                  specialLabel=""
                  placeholder={'+1 (222) 222-2222'}
                  containerStyle={{
                    borderRadius: '15px',
                  }}
                  inputProps={{}}
                  inputStyle={{
                    background: 'transparent',
                    borderRadius: '15px',
                    width: '100%',
                    maxWidth: '500px',
                    maxHeight: '80px',
                    height: '50px',
                    color: 'rgb(23, 43, 77)',
                  }}
                  disabled={disabled}
                />
              )}
            />

            <ValidationMessage>{validationMessage}</ValidationMessage>
          </>
        ) : type === 'hidden' ? (
          <input {...props} type={'text'} style={{ display: 'none' }} />
        ) : type === 'pin' ? (
          <></>
        ) : // <InputPin {...props} />
        type === 'ignored' ? (
          <input {...props} type={'hidden'} />
        ) : type === 'search' ? (
          <></>
        ) : type === 'amount' ? (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
              <GenericInput
                {...props}
                onChange={onChange}
                value={value}
                helperText={validationMessage}
                error={Boolean(validationMessage)}
                inputRef={props.register}
                variant="outlined"
                id={`outlined-amount-${name}`}
                InputLabelProps={{
                  shrink: (defaultValue !== '' && value !== '') || isTouched,
                }}
                InputProps={{
                  ...(mask === 'postcode'
                    ? {
                        /*inputComponent: PostCodeMask*/
                      }
                    : {}),
                  value: getInputValue(value),
                  onChange: (e) => onChange(getValueOnChange(e.target.value)),
                  onKeyPress: (e) => {
                    if (props.onlyNumbers) {
                      const re = /[0-9]/;
                      if (!re.test(e.key)) e.preventDefault();
                    }
                  },
                  ...inputProps,
                  // @ts-ignore
                  startAdornment: <StyledInputAdornment>$</StyledInputAdornment>,
                }}
              />
            )}
          />
        ) : // <InputSearch {...props} />
        type === 'date' ? (
          <Controller
            control={control}
            name={name}
            // defaultValue={defaultValue}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
              <>
                <MobileDatePicker
                  label={label ? `${label || placeholder} ${requiredSign ? '*' : ''}` : null}
                  disabled={disabled}
                  onChange={onChange}
                  value={value ? new Date(value) : null}
                  inputRef={ref}
                  minDate={minDate}
                  maxDate={maxDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        ...params.sx,
                        input: {
                          cursor: 'pointer',
                        },
                        ...sx,
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <EventIcon sx={{ cursor: 'pointer' }} />,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth={fullWidth}
                    />
                  )}
                />
                {validationMessage && <ValidationMessage>{validationMessage}</ValidationMessage>}
              </>
            )}
          />
        ) : type === 'datetime' ? (
          <Controller
            control={control}
            name={name}
            // defaultValue={defaultValue}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
              <>
                <MobileDateTimePicker
                  label={label ? `${label || placeholder} ${requiredSign ? '*' : ''}` : null}
                  disabled={disabled}
                  onChange={onChange}
                  value={value ? new Date(value) : null}
                  inputRef={ref}
                  minDate={minDate}
                  maxDate={maxDate}
                  DialogProps={{
                    sx: {
                      '& .MuiClock-amButton': {
                        color: 'black',
                        border: 'solid 1px #919EAB3D',
                        '& .MuiTypography-root': {
                          fontWeight: 'bold',
                        },
                      },
                      '& .MuiClock-pmButton': {
                        color: 'black',
                        border: 'solid 1px #919EAB3D',
                        '& .MuiTypography-root': {
                          fontWeight: 'bold',
                        },
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        ...params.sx,
                        input: {
                          cursor: 'pointer',
                        },
                        ...sx,
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <EventIcon sx={{ cursor: 'pointer' }} />,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth={fullWidth}
                    />
                  )}
                />
                {validationMessage && <ValidationMessage>{validationMessage}</ValidationMessage>}
              </>
            )}
          />
        ) : // <MuiPickersUtilsProvider utils={PLLocaleUtils} locale={plLocale}>
        //   <Controller
        //     name={name}
        //     control={control}
        //     defaultValue={defaultValue}
        //     initialFocusedDate={defaultValue || initialFocusedDate}
        //     render={({ value, onChange, isTouched }) => (
        //       <KeyboardDatePicker
        //         margin="normal"
        //         inputVariant="outlined"
        //         id="date-picker-dialog"
        //         label={label}
        //         format="yyyy-MM-dd"
        //         placeholder="YYYY-MM-DD"
        //         cancelLabel="zamknij"
        //         okLabel="ok"
        //         clearLabel="wyczyść"
        //         helperText={validationMessage}
        //         error={validationMessage}
        //         clearable
        //         KeyboardButtonProps={{
        //           'aria-label': 'change date',
        //         }}
        //         minDate={minDate}
        //         maxDate={maxDate}
        //         TextFieldComponent={DatePickerInput}
        //         InputLabelProps={{
        //           shrink: (defaultValue !== '' && value !== '') || isTouched,
        //         }}
        //         // inputProps={{ readOnly: true }}
        //         value={value}
        //         onChange={(e) => {
        //           if (e instanceof Date && !isNaN(e)) {
        //             onChange(new Date(e).toISOString().split('T')[0]);
        //           } else {
        //             onChange(e);
        //           }
        //         }}
        //       />
        //     )}
        //   />
        // </MuiPickersUtilsProvider>
        type === 'percent' ? (
          <></>
        ) : // <Controller
        //   control={control}
        //   name={name}
        //   defaultValue={defaultValue}
        //   render={({ onChange, value, isTouched }) => (
        //     <GenericInput
        //       {...props}
        //       onChange={onChange}
        //       value={value}
        //       helperText={validationMessage}
        //       error={validationMessage}
        //       inputRef={props.register}
        //       variant="outlined"
        //       id={`outlined-basic-${name}`}
        //       InputProps={{
        //         ...inputProps,
        //         endAdornment: <StyledInputAdornment>%</StyledInputAdornment>,
        //       }}
        //       InputLabelProps={{
        //         shrink: (defaultValue !== '' && value !== '') || isTouched,
        //       }}
        //     />
        //   )}
        // />
        type === 'postcode' ? (
          <></>
        ) : // <Controller
        //   control={control}
        //   name={name}
        //   defaultValue={defaultValue}
        //   render={({ onChange, value, isTouched }) => (
        //     <GenericInput
        //       {...props}
        //       onChange={onChange}
        //       value={value}
        //       helperText={validationMessage}
        //       error={validationMessage}
        //       inputRef={props.register}
        //       variant="outlined"
        //       id={`outlined-basic-${name}`}
        //       InputLabelProps={{
        //         shrink: (defaultValue !== '' && value !== '') || isTouched,
        //       }}
        //       InputProps={{
        //         ...(mask === 'postcode' ? { inputComponent: PostCodeMask } : {}),
        //         value: value,
        //         onChange: (e) => onChange(e.target.value.replace('-', '')),
        //         ...inputProps,
        //         endAdornment: RequestStatus.isFetching(fetchStatus) ? (
        //           <StyledInputAdornment>
        //             <SmallSpinner />
        //           </StyledInputAdornment>
        //         ) : (
        //           <></>
        //         ),
        //       }}
        //     />
        //   )}
        // />
        type === 'file' ? (
          <></>
        ) : type === 'radio-group' ? (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
              <>
                <RadioGroup
                  {...props}
                  sx={{ width: '100%' }}
                  defaultValue={defaultValue}
                  defaultChecked={value === defaultValue}
                >
                  {choices.map((choice) => (
                    // <Card sx={{ px: 1, my: 0.5 }} variant={'outlined'}>
                    <FormControlLabel
                      onChange={onChange}
                      onBlur={onBlur}
                      value={choice.value}
                      key={`radio-${choice.value}`}
                      id={`radio-${choice.value}`}
                      name={`radio-${choice.value}`}
                      label={choice.label}
                      labelPlacement="end"
                      control={<Radio checked={choice.value === value} />}
                      disabled={disabled}
                      // sx={{ my: 0.5 }}
                    />
                  ))}
                </RadioGroup>
                <ValidationMessage>{validationMessage}</ValidationMessage>
              </>
            )}
          />
        ) : type === 'checkbox' ? (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
              <>
                <FormControlLabel
                  value={value}
                  label={label}
                  labelPlacement="end"
                  onChange={onChange}
                  control={
                    <Checkbox
                      {...props}
                      onChange={onChange}
                      onBlur={onBlur}
                      // value={value}
                      checked={value}
                      inputRef={props.register}
                      id={`checkbox-${name}`}
                      name={`${name}`}
                    />
                  }
                  disabled={disabled}
                  // sx={{Boolean(validationMessage) ? '' : ''}}
                />
              </>
            )}
          />
        ) : (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
              <GenericInput
                {...props}
                onChange={onChange}
                value={value}
                label={label ?? null}
                placeholder={placeholder ?? null}
                helperText={validationMessage}
                error={Boolean(validationMessage)}
                inputRef={props.register}
                variant="outlined"
                id={`outlined-basic-${name}`}
                InputLabelProps={{
                  shrink: (defaultValue !== '' && value !== '') || isTouched || shrinkLabel,
                }}
                multiline={multiline}
                rows={rows}
                sx={sx}
                InputProps={{
                  ...(mask === 'postcode'
                    ? {
                        /*inputComponent: PostCodeMask*/
                      }
                    : {}),
                  value: getInputValue(value),
                  onChange: (e) => onChange(getValueOnChange(e.target.value)),
                  onKeyPress: (e) => {
                    if (props.onlyNumbers) {
                      const re = /[0-9]/;
                      if (!re.test(e.key)) e.preventDefault();
                    }
                  },
                  ...inputProps,
                  endAdornment: RequestStatus.isFetching(fetchStatus) ? (
                    <></>
                  ) : (
                    // <StyledInputAdornment>
                    //   <SmallSpinner />
                    // </StyledInputAdornment>
                    <></>
                  ),
                }}
              />
            )}
          />
        )
      ) : (
        <>
          <Typography color="textSecondary" variant="body2" minHeight={'50px'} lineHeight="50px">
            {/*{type === 'phone' && getDisplayOnlyValue() !== '-' && '+'}*/}
            {type === 'dropdown' && choices && choices.length ? (
              getDisplayOnlyValueForDropdown()
            ) : type === 'phone' ? (
              <GetDisplayOnlyValueForPhoneNumberInput defaultValue={defaultValue} />
            ) : (
              getDisplayOnlyValue()
            )}
            {type === 'percent' ? '%' : ''}
          </Typography>
        </>
      )}
    </>
  );
};
