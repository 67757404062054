import styled from 'styled-components';
import { experimentalStyled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

const sizeBase = 16;

const QRCodeImg = styled.img`
  height: ${sizeBase}rem;
  width: ${sizeBase}rem;
`;

const QRCodePlaceholder = experimentalStyled('div')(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.secondary,
  position: 'absolute',
  top: `${sizeBase / 2}rem`,
  textAlign: 'center',
  height: `${sizeBase}rem`,
  width: `${sizeBase}rem`,
}));

const QRCodeImage = (props: any) => {
  const { qrCode, qrCodeImageStyle, QRCodePlaceholderStyle } = props;

  const [imgLoaded, setImgLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!qrCode) setImgLoaded(true);
    else setImgLoaded(false);
  }, [qrCode]);

  return (
    <>
      <QRCodeImg
        src={qrCode}
        style={{
          ...qrCodeImageStyle,
          ...(!imgLoaded && {
            filter: 'blur(2px)',
          }),
        }}
        onLoad={(e) => {
          setImgLoaded(true);
        }}
      />
      {!qrCode && (
        <QRCodePlaceholder style={{ ...QRCodePlaceholderStyle }}>No code to show</QRCodePlaceholder>
      )}
      {!imgLoaded && (
        <QRCodePlaceholder style={{ ...QRCodePlaceholderStyle }}>
          <Box
            sx={{
              position: 'relative',
              top: `20px`,
            }}
          >
            <CircularProgress
              sx={{
                color: 'primary.dark',
              }}
            />
          </Box>
        </QRCodePlaceholder>
      )}
    </>
  );
};

export default QRCodeImage;
