import { useCallback, useState, useEffect } from 'react';
import type { FC, ChangeEvent } from 'react';
import { Link as RouterLink, useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import useMounted from '../../hooks/useMounted';
import gtm from '../../lib/gtm';
import useSettings from '../../hooks/useSettings';
import {
  getOrganizationDetails,
  clearOrganizationDetails,
  patchOrganizationDetails,
  setPatchFetchStatus,
  setPatchValidationErrors,
} from '../../slices/organizations';
import { RootState, useDispatch, useSelector } from '../../store';
import getInitials from '../../utils/getInitials';
import OrganizationsDetailsRevenue from './OrganizationsDetailsRevenue';
import { useForm } from 'react-hook-form';
import { Input } from '../../components/widgets/inputs/Input';
import { clearThreadsList } from '../../slices/messaging';
import EditButton from '../../components/widgets/buttons/EditButton';
import { RequestStatus } from '../../utils/RequestStatus';
import FileUploadInput from '../FileUpload/FileUploadInput';
import {
  convertPhoneToSend,
  getErrorMessageFor,
  isObjectAsString,
  setFormValuesFromDetailsObject,
} from '../../utils/formsHelper';
import { getOrganizationInvoicesPath } from '../../routesPaths';
import useEditMode from '../../hooks/useEditMode';
import AvatarUploadInput from '../AvatarUploadInput/AvatarUploadInput';

const formId = 'edit-organization-form';

const OrganizationsDetails: FC = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const dispatch = useDispatch();
  const { uuid: id } = useParams();
  const {
    organization: organizationDetails,
    patchFetchStatus,
    patchValidationErrors,
  } = useSelector((state: RootState) => state.organizations.details);
  const { handleSubmit, setValue, getValues, control, register } = useForm();
  const navigate = useNavigate();
  const { triggerEditMode, isEditMode } = useEditMode();

  const onCancelClick = () => {
    triggerEditMode();
    setFormValuesFromDetailsObject({ setValue, detailsObject: organizationDetails });
  };

  const handleFormSubmit = handleSubmit((data) => {
    if (isEditMode) {
      if ((data.logo && isObjectAsString(data.logo)) || data.logo === 'null') delete data.logo;
      dispatch(patchOrganizationDetails({ ...data, uuid: id }));
    }
  });

  const onInvoiceClick = () => {
    navigate(getOrganizationInvoicesPath({ id }));
  };

  useEffect(() => {
    if (RequestStatus.isDone(patchFetchStatus)) {
      dispatch(setPatchValidationErrors(null));
      dispatch(setPatchFetchStatus(RequestStatus.status.NULL));
      triggerEditMode();
    }
  }, [patchFetchStatus]);

  useEffect(() => {
    if (RequestStatus.isDone(patchFetchStatus)) {
    } else if (RequestStatus.isError(patchFetchStatus) && patchValidationErrors) {
    }
  }, [patchFetchStatus]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(() => {
    if (mounted) dispatch(getOrganizationDetails({ id }));
  }, [mounted]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearOrganizationDetails());
  //   };
  // }, []);

  useEffect(() => {
    setFormValuesFromDetailsObject({ setValue, detailsObject: organizationDetails });
  }, [organizationDetails]);

  const areValidationMessages =
    patchValidationErrors && Object.keys(patchValidationErrors).length > 0;

  const isPatchFetching = RequestStatus.isFetching(patchFetchStatus);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Box sx={{ height: 50 }}></Box>
            </Grid>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {organizationDetails.name}
              </Typography>
            </Grid>

            <Grid item>
              <Box sx={{ m: -1 }}>
                <EditButton
                  isEditMode={isEditMode}
                  form={formId}
                  type="submit"
                  onClick={triggerEditMode}
                  onCancelClick={onCancelClick}
                  isLoading={isPatchFetching}
                />
              </Box>
            </Grid>
          </Grid>

          <Divider />

          <form onSubmit={handleFormSubmit} id={formId}>
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={3}>
                <Grid
                  item
                  lg={settings.compact ? 6 : 4}
                  md={6}
                  xl={settings.compact ? 6 : 3}
                  xs={12}
                >
                  {/* <FileUploadInput
                    control={control}
                    isEditMode={isEditMode}
                    setValue={setValue}
                    name="logo"
                    defaultValue={organizationDetails.logo}
                    storeKey={'logoKey'}
                    validationMessage={
                      areValidationMessages &&
                      getErrorMessageFor({
                        key: 'logo',
                        errorMessages: patchValidationErrors,
                      })
                    }
                    disabled={isPatchFetching}
                  /> */}
                  <AvatarUploadInput
                    control={control}
                    isEditMode={isEditMode}
                    setValue={setValue}
                    name="logo"
                    defaultValue={organizationDetails.logo}
                    storeKey={'logoKey'}
                    validationMessage={
                      areValidationMessages &&
                      getErrorMessageFor({
                        key: 'logo',
                        errorMessages: patchValidationErrors,
                      })
                    }
                    disabled={isPatchFetching}
                  />
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography color="textPrimary" variant="subtitle2">
                            Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Input
                            control={control}
                            isEditMode={isEditMode}
                            defaultValue={organizationDetails.name}
                            type="text"
                            name="name"
                            validationMessage={
                              areValidationMessages &&
                              getErrorMessageFor({
                                key: 'name',
                                errorMessages: patchValidationErrors,
                              })
                            }
                            disabled={isPatchFetching}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography color="textPrimary" variant="subtitle2">
                            Description
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Input
                            control={control}
                            isEditMode={isEditMode}
                            defaultValue={organizationDetails.description}
                            type="text"
                            name="description"
                            validationMessage={
                              areValidationMessages &&
                              getErrorMessageFor({
                                key: 'description',
                                errorMessages: patchValidationErrors,
                              })
                            }
                            disabled={isPatchFetching}
                          />
                        </TableCell>
                      </TableRow>
                      {/* data for this on Stripe, we are not doing it now */}
                      {/*<TableRow>*/}
                      {/*  <TableCell>*/}
                      {/*    <Typography color="textPrimary" variant="subtitle2">*/}
                      {/*      Billing Information*/}
                      {/*    </Typography>*/}
                      {/*  </TableCell>*/}
                      {/*  <TableCell>*/}
                      {/*    <Input*/}
                      {/*      control={control}*/}
                      {/*      isEditMode={isEditMode}*/}
                      {/*      defaultValue={organizationDetails.billing_information}*/}
                      {/*      type="text"*/}
                      {/*      name="billing_information"*/}
                      {/*      validationMessage={*/}
                      {/*        areValidationMessages &&*/}
                      {/*        getErrorMessageFor({*/}
                      {/*          key: 'billing_information',*/}
                      {/*          errorMessages: patchValidationErrors,*/}
                      {/*        })*/}
                      {/*      }*/}
                      {/*    />*/}
                      {/*  </TableCell>*/}
                      {/*</TableRow>*/}

                      <TableRow>
                        <TableCell>
                          <Typography color="textPrimary" variant="subtitle2">
                            Address
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Input
                            control={control}
                            isEditMode={isEditMode}
                            defaultValue={organizationDetails.address}
                            type="text"
                            name="address"
                            validationMessage={
                              areValidationMessages &&
                              getErrorMessageFor({
                                key: 'address',
                                errorMessages: patchValidationErrors,
                              })
                            }
                            disabled={isPatchFetching}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography color="textPrimary" variant="subtitle2">
                            Address 2
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Input
                            control={control}
                            isEditMode={isEditMode}
                            defaultValue={organizationDetails.address2}
                            type="text"
                            name="address2"
                            validationMessage={
                              areValidationMessages &&
                              getErrorMessageFor({
                                key: 'address2',
                                errorMessages: patchValidationErrors,
                              })
                            }
                            disabled={isPatchFetching}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography color="textPrimary" variant="subtitle2">
                            City
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Input
                            control={control}
                            isEditMode={isEditMode}
                            defaultValue={organizationDetails.city}
                            type="text"
                            name="city"
                            validationMessage={
                              areValidationMessages &&
                              getErrorMessageFor({
                                key: 'city',
                                errorMessages: patchValidationErrors,
                              })
                            }
                            disabled={isPatchFetching}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography color="textPrimary" variant="subtitle2">
                            State
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Input
                            control={control}
                            isEditMode={isEditMode}
                            defaultValue={organizationDetails.state}
                            type="text"
                            name="state"
                            validationMessage={
                              areValidationMessages &&
                              getErrorMessageFor({
                                key: 'state',
                                errorMessages: patchValidationErrors,
                              })
                            }
                            disabled={isPatchFetching}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography color="textPrimary" variant="subtitle2">
                            Zip code
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Input
                            control={control}
                            isEditMode={isEditMode}
                            defaultValue={organizationDetails.zip_code}
                            type="text"
                            name="zip_code"
                            validationMessage={
                              areValidationMessages &&
                              getErrorMessageFor({
                                key: 'zip_code',
                                errorMessages: patchValidationErrors,
                              })
                            }
                            disabled={isPatchFetching}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography color="textPrimary" variant="subtitle2">
                            Main Contact
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Input
                            control={control}
                            isEditMode={isEditMode}
                            defaultValue={organizationDetails.main_contact}
                            type="text"
                            name="main_contact"
                            validationMessage={
                              areValidationMessages &&
                              getErrorMessageFor({
                                key: 'main_contact',
                                errorMessages: patchValidationErrors,
                              })
                            }
                            disabled={isPatchFetching}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography color="textPrimary" variant="subtitle2">
                            Phone
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Input
                            control={control}
                            isEditMode={isEditMode}
                            defaultValue={organizationDetails.phone}
                            type="phone"
                            name="phone"
                            validationMessage={
                              areValidationMessages &&
                              getErrorMessageFor({
                                key: 'phone',
                                errorMessages: patchValidationErrors,
                              })
                            }
                            disabled={isPatchFetching}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography color="textPrimary" variant="subtitle2">
                            Invoices
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={onInvoiceClick}
                            disabled={isPatchFetching}
                          >
                            See invoices
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid
                  item
                  lg={settings.compact ? 6 : 4}
                  md={6}
                  xl={settings.compact ? 6 : 3}
                  xs={12}
                >
                  <OrganizationsDetailsRevenue {...organizationDetails} />
                </Grid>
              </Grid>
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default OrganizationsDetails;
