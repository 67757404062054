import React, { Dispatch, FC, MutableRefObject, SetStateAction } from 'react';
import { Box, Button, Collapse, Grid, IconButton, Typography } from '@mui/material';
import { PrintRounded } from '@mui/icons-material';
import { LocationType } from '../../../slices/locations';
import QRCodeImage from '../../../components/QRCodeImage';
import { useNavigate } from 'react-router-dom';
import { getMessagingDetailsPath } from '../../../routesPaths';
import ClearIcon from '@mui/icons-material/Clear';
import useModal from '../../../hooks/useModal';
import LocationsPrintAmountRow from './LocationsPrint/LocationsPrintAmountRow';
import { useTheme } from '@mui/material/styles';
import LocationsPrintLayoutSetupModal from './LocationsPrint/LocationsPrintLayoutSetupModal';
import { AutoSizer, List as VirtualizedList } from 'react-virtualized';
import ChatIcon from '@mui/icons-material/Chat';
import LocationsActionModal from '../LocationsModals/LocationsItemsActionsModal';
import LocationsItemsActionsModal from '../LocationsModals/LocationsItemsActionsModal';

interface LocationsToolbarProps {
  location: LocationType;
  handleSetPrintCodesState: () => void;
  isPrintStateActive: boolean;
  handlePrint: any;
  qrCodesToPrint: Array<{
    qr_code: string;
    name: string;
    ancestors_names: Array<string>;
    printAmount: number;
  }>;
  handleChangePrintAmount: (printAmount: number, qr_code: string) => void;
  setPagesToPrintRef: Dispatch<SetStateAction<MutableRefObject<HTMLDivElement>>>;
  setQrCodesToPrintLayout: Dispatch<SetStateAction<Array<Array<Array<string>>>>>;
  isAnyLoading: boolean;
  handleGetOrganizations: (data?: any) => void;
}

const LocationsMainWindowToolbar: FC<LocationsToolbarProps> = (props) => {
  const {
    location,
    handleSetPrintCodesState,
    isPrintStateActive,
    handlePrint,
    qrCodesToPrint,
    handleChangePrintAmount,
    setPagesToPrintRef,
    setQrCodesToPrintLayout,
    isAnyLoading,
    handleGetOrganizations,
  } = props;

  const navigate = useNavigate();
  const theme = useTheme();

  const { Component: PrintAmountModal, ...printAmountModal } = useModal();
  const { Component: PrintModal, ...printModal } = useModal();

  const rowRenderer = ({ key, index, style }) => (
    <Box key={key} style={style}>
      <LocationsPrintAmountRow
        key={key}
        qrCode={qrCodesToPrint[index]}
        handleChangePrintAmount={handleChangePrintAmount}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        // p: 2,
        // py: 1,
        borderBottom: 1,
        borderColor: 'divider',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            // ...(location?.thread_uuid && {
            //   cursor: 'pointer',
            //   borderTopRightRadius: '8px',
            //   borderBottomRightRadius: '8px',
            //   '&:hover': {
            //     backgroundColor: 'action.hover',
            //   },
            // }),
          }}
        >
          <PrintAmountModal
            {...printAmountModal.props}
            onCancel={() => {
              printAmountModal.close();
            }}
            sx={{
              width: 'fit-content',
              height: 'fit-content',
            }}
          >
            <Typography color="textPrimary" variant="h5" sx={{ marginBottom: '20px' }}>
              Select amount of each QR code you want to print
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Typography variant={'subtitle2'} sx={{ mr: 1 }}>
                Current total amount of QR codes (each page can contain 20 QR codes):
              </Typography>
              <Typography variant={'subtitle2'} color={'textSecondary'}>
                {qrCodesToPrint.reduce((acc, cur) => (acc = acc + cur.printAmount), 0)}
              </Typography>
            </Box>
            <Box
              style={{
                height: `calc(50vh)`,
                minWidth: '750px',
              }}
            >
              {/*<List>*/}
              {/*  {qrCodesToPrint.map((qrCode) => (*/}
              {/*    <LocationsPrintAmountRow*/}
              {/*      key={qrCode.qr_code}*/}
              {/*      qrCode={qrCode}*/}
              {/*      handleChangePrintAmount={handleChangePrintAmount}*/}
              {/*    />*/}
              {/*  ))}*/}
              {/*</List>*/}
              <AutoSizer>
                {({ height, width }) => (
                  <VirtualizedList
                    height={height}
                    width={width}
                    rowRenderer={rowRenderer}
                    rowCount={qrCodesToPrint.length}
                    rowHeight={91}
                    overscanRowCount={25}
                    // noRowsRenderer={noRowsRenderer}
                  />
                )}
              </AutoSizer>
            </Box>
            <Grid container spacing={2} justifyContent={'space-between'} marginTop={2}>
              <Grid item xs={12} md={3}>
                <Button
                  fullWidth
                  variant={'outlined'}
                  sx={{ py: 1, fontSize: '16px' }}
                  onClick={() => {
                    printAmountModal.close();
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={3}>
                <Button
                  fullWidth
                  variant={'contained'}
                  sx={{ py: 1, fontSize: '16px' }}
                  onClick={() => {
                    printAmountModal.close();
                    setTimeout(() => {
                      printModal.open();
                    }, 150);
                  }}
                >
                  Print setup
                </Button>
              </Grid>
            </Grid>
          </PrintAmountModal>
          <PrintModal
            {...printModal.props}
            onCancel={() => {
              printModal.close();
            }}
            sx={{
              width: 'fit-content',
              height: 'fit-content',
              maxHeight: '100vh',
            }}
          >
            <Typography color="textPrimary" variant="h5" sx={{ marginBottom: '20px' }}>
              QR code page structure
            </Typography>
            <LocationsPrintLayoutSetupModal
              qrCodesToPrint={qrCodesToPrint}
              setPagesToPrintRef={setPagesToPrintRef}
              setQrCodesToPrintLayout={setQrCodesToPrintLayout}
            />
            <Grid container spacing={2} justifyContent={'space-between'} marginTop={2}>
              <Grid item xs={12} md={3}>
                <Button
                  fullWidth
                  variant={'outlined'}
                  sx={{ py: 1, fontSize: '16px' }}
                  onClick={() => {
                    printModal.close();
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  fullWidth
                  variant={'outlined'}
                  sx={{ py: 1, fontSize: '16px' }}
                  onClick={() => {
                    printModal.close();
                    setTimeout(() => {
                      printAmountModal.open();
                    }, 150);
                  }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={12} md={3}></Grid>
              <Grid item xs={12} md={3}>
                <Button
                  fullWidth
                  variant={'contained'}
                  sx={{ py: 1, fontSize: '16px' }}
                  onClick={() => {
                    printModal.close();
                    handlePrint();
                  }}
                >
                  Print
                </Button>
              </Grid>
            </Grid>
          </PrintModal>
          {location?.qr_code ? (
            <QRCodeImage
              QRCodePlaceholderStyle={{ width: '100px', height: '100px', top: '16px' }}
              qrCode={location?.qr_code}
              qrCodeImageStyle={{
                width: '100px',
                height: '100px',
              }}
            />
          ) : (
            <Box sx={{ width: '1px', height: '100px' }}></Box>
          )}
          <Box sx={{ width: '100%', py: 1 }}>
            <Typography
              variant={'h6'}
              sx={{ ml: 2, display: 'flex', gap: '8px', alignItems: 'center' }}
              {...(!!location?.name && !location?.is_active && { color: 'textSecondary' })}
            >
              {location?.name ?? 'All buildings'}
              {!!location?.name && !location?.is_active && (
                <Typography color={'error'} variant={'subtitle2'}>
                  (inactive)
                </Typography>
              )}
            </Typography>
            {location?.ancestors_names?.length > 1 && (
              <Typography variant={'subtitle2'} color={'textSecondary'} sx={{ ml: 2 }}>
                {location.ancestors_names.join(' / ')}
              </Typography>
            )}
            {/*{location?.thread_uuid && !isPrintStateActive && (*/}
            {/*  <Typography variant={'subtitle2'} color={'textSecondary'} sx={{ ml: 2, mt: 1 }}>*/}
            {/*    {`(Click to go to chat)`}*/}
            {/*  </Typography>*/}
            {/*)}*/}
            {location?.name && (
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  ml: 2,
                  mt: 1,
                }}
              >
                <LocationsItemsActionsModal
                  type={'location_edit'}
                  location={location}
                  buttonSx={{
                    border: `solid 1px ${theme.palette.divider}`,
                  }}
                  disabled={isAnyLoading}
                  isRootLocation={location.ancestors_uuids.length === 1}
                  handleGetOrganizations={handleGetOrganizations}
                />
                <LocationsItemsActionsModal
                  type={'location_delete'}
                  location={location}
                  buttonSx={{
                    border: `solid 1px ${theme.palette.divider}`,
                  }}
                  disabled={isAnyLoading}
                />
                {/*{location.thread_uuid && (*/}
                {/*  <Button*/}
                {/*    endIcon={<ChatIcon />}*/}
                {/*    variant={'outlined'}*/}
                {/*    onClick={() => {*/}
                {/*      if (!location?.thread_uuid || isPrintStateActive) return;*/}
                {/*      navigate(getMessagingDetailsPath({ id: location.thread_uuid }));*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    Go to chat*/}
                {/*  </Button>*/}
                {/*)}*/}
                {location.thread_uuid && (
                  <IconButton
                    color={'primary'}
                    onClick={() => {
                      if (!location?.thread_uuid || isPrintStateActive) return;
                      navigate(getMessagingDetailsPath({ id: location.thread_uuid }));
                    }}
                    sx={{
                      padding: 0.5,
                      borderRadius: '8px',
                      border: `solid 1px ${theme.palette.divider}`,
                    }}
                    disabled={!location.is_active}
                  >
                    <ChatIcon fontSize={'small'} />
                  </IconButton>
                )}
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: '160px',
            mx: 2,
            mt: 1,
          }}
        >
          <LocationsActionModal
            type={'item_add'}
            location={location}
            buttonType={'regular'}
            buttonSx={{ mb: 1 }}
            disabled={!location?.name}
          />
          <Button
            variant={'outlined'}
            endIcon={<PrintRounded />}
            onClick={
              isPrintStateActive
                ? () => {
                    printAmountModal.open();
                  }
                : handleSetPrintCodesState
            }
            sx={{
              minWidth: '160px',
            }}
            disabled={!qrCodesToPrint.length && isPrintStateActive}
          >
            {isPrintStateActive ? 'Print' : 'Print QR codes'}
          </Button>
          <Collapse in={isPrintStateActive}>
            <Button
              variant={'outlined'}
              endIcon={<ClearIcon />}
              onClick={handleSetPrintCodesState}
              sx={{
                minWidth: '160px',
                mt: 1,
              }}
            >
              Cancel
            </Button>
          </Collapse>
        </Box>
      </Box>
      <Collapse in={isPrintStateActive}>
        <Typography
          variant={'subtitle2'}
          color={'textSecondary'}
          align={'center'}
          sx={{ pt: 1, pb: 2 }}
        >
          Select items you want to print QR codes of
        </Typography>
      </Collapse>
    </Box>
  );
};

export default LocationsMainWindowToolbar;
