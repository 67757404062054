import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import ArrowRightIcon from '../../icons/ArrowRight';
import PencilAltIcon from '../../icons/PencilAlt';
import SearchIcon from '../../icons/Search';
import type { Customer } from '../../types/customer_new';
import getInitials from '../../utils/getInitials';
import Scrollbar from '../../components/Scrollbar';
import { RootState, useDispatch, useSelector } from '../../store';
import { getCustomers, resetState } from '../../slices/customers';
import { useNavigate } from 'react-router-dom';
import { getCustomerDetailsPath } from 'src/routesPaths';
import { getCustomerDetails } from '../../slices/customers';
import { RequestStatus } from '../../utils/RequestStatus';
import EmptyList from 'src/components/EmptyList';
import useDebouncedSearchAndPagination from '../../hooks/useDebouncedSearchAndPagination';
import { pagination } from '../../theme';

interface CustomerListTableProps {
  customers: Customer[];
}

const CustomerListTable: FC = (props) => {
  const { ...other } = props;
  const dispatch = useDispatch();

  const { customersList, customersListLength, customersListfetchStatus } = useSelector(
    (state: RootState) => state.customers
  );

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  const { handlePageChange, handleLimitChange, handleQueryChange, query, page, limit } =
    useDebouncedSearchAndPagination({
      callbackToDebounce: ({ limit, page, query }) => {
        dispatch(getCustomers({ limit, offset: page * limit, search: query }));
      },
      listLength: customersListLength,
    });

  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Search customers"
            value={query}
            variant="outlined"
          />
        </Box>
      </Box>

      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          {RequestStatus.isFetching(customersListfetchStatus) && customersList.length > 0 && (
            <LinearProgress />
          )}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Photo</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Email Address</TableCell>

                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customersList.map((customer: Customer, idx) => {
                const customerDetailsPath = getCustomerDetailsPath({ uuid: customer.uuid });

                return (
                  <TableRow hover key={idx}>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={customerDetailsPath}
                        variant="subtitle2"
                        sx={{ textDecoration: 'none' }}
                      >
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            textDecoration: 'none',
                          }}
                        >
                          <Avatar
                            src={customer.avatar?.thumbnails?.avatar}
                            sx={{
                              height: 42,
                              width: 42,
                            }}
                          >
                            {getInitials(customer.customer_label)}
                          </Avatar>
                        </Box>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={customerDetailsPath}
                        variant="subtitle2"
                      >
                        {customer.customer_label}
                      </Link>
                    </TableCell>
                    <TableCell>{customer.email}</TableCell>

                    <TableCell align="right">
                      <IconButton component={RouterLink} to={customerDetailsPath} size="large">
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {RequestStatus.isDone(customersListfetchStatus) && customersListLength === 0 && (
            <EmptyList>No customers found</EmptyList>
          )}

          {RequestStatus.isFetching(customersListfetchStatus) && customersListLength === 0 && (
            <CircularProgress sx={{ display: 'block', margin: '30px auto 10px auto' }} />
          )}
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={customersListLength}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={pagination.rowsPerPageOptions}
      />
    </Card>
  );
};

CustomerListTable.propTypes = {
  customers: PropTypes.array.isRequired,
};

export default CustomerListTable;
