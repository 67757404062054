import { Button, CircularProgress } from '@mui/material';
import type { FC } from 'react';

interface SaveAndInviteProps {
  isFetching?: any;
  onClick?: any;
  sx?: any;
}

const SaveAndInvite: FC<SaveAndInviteProps> = ({ isFetching, onClick, sx }) => {
  return (
    <Button
      type="submit"
      color="primary"
      variant="contained"
      sx={sx || { m: 1 }}
      disabled={isFetching}
      onClick={onClick}
    >
      {isFetching ? (
        <CircularProgress
          sx={{
            display: 'block',
            width: '20px !important ',
            height: '20px !important',
            margin: '0px 20px 0px 20px',
          }}
        />
      ) : (
        'Save & invite'
      )}
    </Button>
  );
};

export default SaveAndInvite;
