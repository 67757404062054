import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';

interface InputMultiselectProps {
  name: any;
  control: any;
  defaultValue: any;
  validationMessage?: any;
  choices: Array<{ value; label }>;
  disabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
}

const StyledSelect = styled(Select)`
  width: 100%;
`;

const InputMultiselect: FC<InputMultiselectProps> = (props) => {
  const {
    name,
    control,
    defaultValue,
    validationMessage,
    choices,
    disabled,
    isLoading,
    isClearable = true,
  } = props;

  const theme = useTheme();

  const overrideTheme = (selectTheme) => ({
    ...selectTheme,
    colors: {
      ...selectTheme.colors,
      primary: theme.palette?.primary?.main,
      primary25: theme.palette?.action?.active,
    },
  });

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      // height: 56,
      height: '100%',
    }),
    control: (provided, state) => ({
      ...provided,
      // height: 56,
      height: '100%',
      borderRadius: 16,
      fontFamily: theme?.typography?.fontFamily,
      fontSize: '1rem',
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      fontSize: '1rem',
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: theme?.typography?.fontFamily,
      fontSize: '1rem',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      minHeight: 56,
      height: '100%',
    }),
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched } }) => (
        <StyledSelect
          options={choices}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          isMulti
          isDisabled={disabled}
          isLoading={isLoading}
          isClearable={isClearable}
          theme={overrideTheme}
          styles={customStyles}
        />
      )}
    />
  );
};

export default InputMultiselect;
