import React, { FC, useEffect } from 'react';
import { addDays, formatISO, parseISO, subMonths } from 'date-fns';
import { useState } from 'react';
import { DateRangePicker, DateRange } from 'react-date-range';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import AnalyticsDatepickerWrapper from './AnalyticsDatepickerWrapper';
import { useTheme } from '@mui/material/styles';
import { startOfDay, endOfDay } from 'date-fns';

const AnalyticsDatepickerContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  overflow: auto;
  .rdrDateRangePickerWrapper {
    width: 100%;
    overflow: auto;
    .rdrDefinedRangesWrapper {
      //  //width: 125px;
    }
    .rdrDateRangeWrapper {
      //  width: 500px;
    }
  }
`;

interface AnalyticsDatepickerProps {
  onDatesChange: (values: any) => void;
}

const defaultDateValues = {
  startDate: subMonths(new Date(), 1),
  endDate: new Date(),
  key: 'selection',
};

const AnalyticsDatepicker: FC<AnalyticsDatepickerProps> = (props) => {
  const { onDatesChange } = props;
  let [searchParams, setSearchParams] = useSearchParams({});
  const theme = useTheme();

  const [dateValues, setDateValues] = useState([
    searchParams.get('start-date') && searchParams.get('end-date')
      ? {
          startDate: parseISO(searchParams.get('start-date')),
          endDate: parseISO(searchParams.get('end-date')),
          key: 'selection',
        }
      : defaultDateValues,
  ]);

  const otherSearchParams = Object.fromEntries(searchParams.entries());

  const handleDatesChange = (selection) => {
    setSearchParams({
      ...otherSearchParams,
      'start-date': formatISO(selection.startDate, { representation: 'date' }),
      'end-date': formatISO(selection.endDate, { representation: 'date' }),
    });
  };

  useEffect(() => {
    const startDate = searchParams.get('start-date');
    const endDate = searchParams.get('end-date');
    const valuesFromSearchParams: any = {};
    if (startDate && endDate) {
      valuesFromSearchParams.startDate = parseISO(startDate);
      valuesFromSearchParams.endDate = endOfDay(parseISO(endDate));
    }
    setDateValues([{ ...dateValues[0], ...valuesFromSearchParams }]);
  }, [searchParams]);

  const removeTimezoneOffset = (isoDate: string): string => {
    return isoDate.slice(0, 19);
  };

  useEffect(() => {
    onDatesChange({
      startDate:
        dateValues[0]?.startDate &&
        removeTimezoneOffset(formatISO(startOfDay(dateValues[0]?.startDate))),
      endDate:
        dateValues[0]?.endDate && removeTimezoneOffset(formatISO(endOfDay(dateValues[0]?.endDate))),
    });
  }, [dateValues]);

  return (
    <AnalyticsDatepickerWrapper>
      <AnalyticsDatepickerContainer>
        <DateRange
          onChange={(item) => handleDatesChange([item.selection][0])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={dateValues}
          direction="horizontal"
          rangeColors={[theme.palette?.primary?.main]}
        />
      </AnalyticsDatepickerContainer>
    </AnalyticsDatepickerWrapper>
  );
};

export default AnalyticsDatepicker;
