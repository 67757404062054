import axios from 'axios';

// import { sendNotification } from '../components/Toast';

const handleRequest = (request, thunkApi) =>
  request
    .then((response) => response.data)
    .catch((error) => {
      return thunkApi.rejectWithValue({ ...error?.response?.data } || error);
    });

export const convertDropdownToReqValues = (dropdownVal) => {
  if (!dropdownVal || !dropdownVal?.length) return null;
  return dropdownVal.map((val) => val.value);
};

const buildUrl = (url, params) => {
  Object.keys(params).forEach((key) =>
    params[key] === undefined || params[key] === null ? delete params[key] : {}
  );
  // Support URLs with named identifiers, such as '/posts/get/:id'.
  // This code replaces the ':id' part with the value of params.id
  Object.keys(params).forEach((k) => {
    if (url.indexOf(':' + k) > -1) {
      url = url.replace(':' + k, params[k]);
      delete params[k];
    }
  });

  // all the parameters that were not bound to a named identifier are appended to the URL
  const encoded = Object.entries(params)
    .map((kv) =>
      kv
        .map((anyEntry) => {
          if (
            typeof anyEntry === 'object' &&
            anyEntry.length &&
            anyEntry[0]?.value &&
            anyEntry[0]?.label
          )
            return encodeURIComponent(convertDropdownToReqValues(anyEntry));
          else return encodeURIComponent(anyEntry);
        })
        .join('=')
    )
    .join('&');

  const buildedUrl = url + (encoded.length > 0 ? '?' + encoded : '');

  return buildedUrl;
};

const fixNullStrings = (data) => {
  for (let [key, value] of Object.entries(data)) {
    if (value === 'null') {
      delete data[key];
    }
  }
  return data;
};

/**
 * Class based on createAsyncThunk issue https://github.com/reduxjs/redux-toolkit/issues/390
 */
export default class ApiRequest {
  static post =
    (url, axiosConfig = {}) =>
    (obj = {}, thunkApi) =>
      handleRequest(axios.post(url, obj, axiosConfig), thunkApi);

  static put =
    (url, axiosConfig = {}) =>
    (obj = {}, thunkApi) =>
      handleRequest(axios.put(url, fixNullStrings(obj), axiosConfig), thunkApi);

  static patch =
    (url, axiosConfig = {}) =>
    (obj = {}, thunkApi) =>
      handleRequest(axios.patch(url, fixNullStrings(obj), axiosConfig), thunkApi);

  static get =
    (url, axiosConfig = {}) =>
    (obj = {}, thunkApi) =>
      handleRequest(axios.get(buildUrl(url, obj), axiosConfig), thunkApi);

  static delete =
    (url, axiosConfig = {}) =>
    (obj = {}, thunkApi) =>
      handleRequest(axios.delete(buildUrl(url, obj), axiosConfig), thunkApi);
}

export const showErrorMessagesForAction = (action) => {
  if (!action?.payload?.errors) {
    console.error('No errors to extract from action');
    return;
  }
  try {
    Object.entries(action?.payload?.errors).forEach((error) => {
      // sendNotification(
      //   'error',
      //   typeof error[1] === 'object' ? `${error[1][0]}` : error[1] || 'Error',
      //   {
      //     autoClose: 3000,
      //   }
      // );
    });
  } catch (e) {
    console.error(e);
    // sendNotification('error', 'Unexpected error', {
    //   autoClose: 3000,
    // });
  }
};

export const isRejectedWithErrors = (action) => action?.error?.message === 'Rejected';
