import React, { FC, ReactNode } from 'react';
import { Box, Button } from '@mui/material';

interface LoadMoreButtonProps {
  onClick: (event) => any;
  title?: string;
  isVisible?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
}

const LoadMoreButton: FC<LoadMoreButtonProps> = (props) => {
  const { onClick, title = 'Load more', icon = null, isVisible = true, disabled } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        p: 1,
      }}
    >
      {isVisible && (
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={onClick}
          disabled={disabled}
          endIcon={icon}
        >
          {title}
        </Button>
      )}
    </Box>
  );
};

export default LoadMoreButton;
