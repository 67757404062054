import React, { FC, useEffect, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Button, Divider, Tab, Tabs, Typography } from '@mui/material';
import useModal from '../../../hooks/useModal';
import useDebouncedSearchAndPagination from '../../../hooks/useDebouncedSearchAndPagination';
import { getScheduledMessages, ScheduledMessageType } from '../../../slices/messaging';
import { RootState, useDispatch, useSelector } from '../../../store';
import ScheduledMessagesSettings from './ScheduledMessagesSettings';
import { RequestStatus } from '../../../utils/RequestStatus';
import { Lightbox } from 'react-modal-image';
import ScheduledMessageModal from '../MessagingChatBox/ScheduledMessageModal';
import { useParams, useSearchParams } from 'react-router-dom';

interface MessagingSettingsModalProps {}

type TabsTypes = 'scheduledMessages';

const MessagingSettingsModal: FC<MessagingSettingsModalProps> = (props) => {
  const {} = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const { uuid } = useParams();
  const dispatch = useDispatch();

  const { Component: SettingsModal, ...settingsModal } = useModal();

  const { fetchStatus, count, next, previous, items } = useSelector(
    (state: RootState) => state.messaging.thread.scheduledMessage.get
  );

  const [selectedTab, setSelectedTab] = useState<TabsTypes>('scheduledMessages');
  const [expandMedia, setExpandMedia] = useState<string | null>(null);
  const [scheduledMessagesThreadUuid, setScheduledMessagesThreadUuid] = useState<string | null>(
    searchParams.get('onlyCurrentChat') === 'true' && uuid ? uuid : null
  );

  const [openEditFor, setOpenEditFor] = useState<ScheduledMessageType | null>(null);

  const closeEditMode = (): void => {
    setOpenEditFor(null);
    setTimeout(() => {
      settingsModal.open();
    }, 150);
  };

  const { handlePageChange, handleLimitChange, handleQueryChange, query, page, limit } =
    useDebouncedSearchAndPagination({
      callbackToDebounce: ({ limit, page, query }) => {
        if (selectedTab === 'scheduledMessages')
          dispatch(
            getScheduledMessages({
              limit,
              offset: page * limit,
              search: query,
              ...((scheduledMessagesThreadUuid ||
                (searchParams.get('onlyCurrentChat') === 'true' && uuid)) && {
                thread_uuid: scheduledMessagesThreadUuid ?? uuid,
              }),
            })
          );
      },
      listLength: count,
    });

  const handleChangeTab = (tab: TabsTypes, callback: () => void): void => {
    if (selectedTab !== tab) {
      setSelectedTab(tab);
      callback();
    }
  };

  useEffect(() => {
    if (settingsModal.isOpen) {
      setScheduledMessagesThreadUuid(
        searchParams.get('onlyCurrentChat') === 'true' && uuid ? uuid : null
      );
      dispatch(
        getScheduledMessages({
          limit,
          offset: page * limit,
          ...(scheduledMessagesThreadUuid && { thread_uuid: scheduledMessagesThreadUuid }),
        })
      );
    } else {
      setTimeout(() => {
        setSelectedTab('scheduledMessages');
        setScheduledMessagesThreadUuid(null);
      }, 150);
    }
  }, [settingsModal.isOpen]);

  useEffect(() => {
    if (page > 0) handlePageChange(undefined, 0);
    else
      dispatch(
        getScheduledMessages({
          limit,
          offset: page * limit,
          search: query,
          ...(scheduledMessagesThreadUuid && { thread_uuid: scheduledMessagesThreadUuid }),
        })
      );
  }, [scheduledMessagesThreadUuid]);

  return (
    <>
      {!!expandMedia && <Lightbox large={expandMedia} onClose={(): void => setExpandMedia(null)} />}
      <SettingsModal
        {...settingsModal.props}
        handleClose={() => {
          settingsModal.close();
        }}
        sx={{
          width: 'fit-content',
          height: '80vh',
          minWidth: '1000px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SettingsIcon color={'primary'} sx={{ height: '64px', width: '64px' }} />
        <Typography color="textPrimary" variant="h5" sx={{ marginBottom: '20px' }}>
          Messaging settings
        </Typography>
        <Tabs value={selectedTab}>
          <Tab
            color={'primary'}
            onClick={() =>
              handleChangeTab('scheduledMessages', () => {
                handlePageChange(undefined, 0);
              })
            }
            value={'scheduledMessages'}
            label={'Scheduled Messages'}
          />
        </Tabs>
        <Divider />
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {selectedTab === 'scheduledMessages' ? (
            <ScheduledMessagesSettings
              items={items}
              page={page}
              limit={limit}
              count={count}
              query={query}
              handlePageChange={handlePageChange}
              handleLimitChange={handleLimitChange}
              handleQueryChange={handleQueryChange}
              isLoading={RequestStatus.isFetching(fetchStatus)}
              setExpandMedia={setExpandMedia}
              handleCloseModal={settingsModal.close}
              scheduledMessagesThreadUuid={scheduledMessagesThreadUuid}
              setScheduledMessagesThreadUuid={setScheduledMessagesThreadUuid}
              setOpenEditFor={setOpenEditFor}
            />
          ) : (
            <></>
          )}
        </Box>
      </SettingsModal>
      <Button
        variant={'contained'}
        color={'primary'}
        startIcon={<SettingsIcon />}
        onClick={() => settingsModal.open()}
      >
        Settings
      </Button>
      <ScheduledMessageModal isEdit openEditFor={openEditFor} closeEditMode={closeEditMode} />
    </>
  );
};

export default MessagingSettingsModal;
