import { Card, Link, Typography } from '@mui/material';
import { FC } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { getFileNameFromLink } from '../../../containers/Messaging/messagingHelper';
import { SxProps } from '@mui/system';

interface ChatMessageFileAttachmentProps {
  url: string;
  mime_type?: string;
  sx?: SxProps;
}

const ChatMessageFileAttachment: FC<ChatMessageFileAttachmentProps> = (props) => {
  const { url, mime_type, sx = {} } = props;

  return (
    <Card
      variant={'outlined'}
      sx={{
        display: 'flex',
        alignItems: 'center',
        px: 1,
        py: 1,
        mt: 1,
        ...sx,
      }}
    >
      <AttachFileIcon sx={{ mr: 0.5, color: 'text.secondary' }} />
      {url.split('/').length === 1 ? (
        <Typography sx={{ wordBreak: 'break-word', color: 'text.secondary' }}>
          {getFileNameFromLink(url)}
        </Typography>
      ) : (
        <Link
          href={url}
          rel={'noopener'}
          {...(!mime_type?.includes('pdf') ? { download: url } : { target: '_blank' })}
        >
          <Typography sx={{ wordBreak: 'break-word', color: 'text.secondary' }}>
            {getFileNameFromLink(url)}
          </Typography>
        </Link>
      )}
    </Card>
  );
};

export default ChatMessageFileAttachment;
