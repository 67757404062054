import { RequestStatus } from '../utils/RequestStatus';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ApiRequests from '../utils/ApiRequest';
import { ReportsVenueReqData } from '../types/reportsVenueReqData';

export interface AnalyticsState {
  dropdownChoices: {
    organizations: {
      items: Array<{ value: string; label: string }>;
      fetchStatus: string | null;
      next: string | null;
      previous: string | null;
    };
    vendors: {
      items: Array<{ value: string; label: string }>;
      fetchStatus: string | null;
      next: string | null;
      previous: string | null;
    };
    customers: {
      items: Array<{ value: string; label: string }>;
      fetchStatus: string | null;
      next: string | null;
      previous: string | null;
    };
    technicians: {
      items: Array<{ value: string; label: string }>;
      fetchStatus: string | null;
      next: string | null;
      previous: string | null;
    };
    invoices: {
      items: Array<{ value: string; label: string }>;
      fetchStatus: string | null;
      next: string | null;
      previous: string | null;
    };
  };
  selectedFilters: {
    organizations: Array<string>;
    vendors: Array<string>;
    customers: Array<string>;
    technicians: Array<string>;
    invoices: Array<string>;
  };
  chart: {
    data: any;
    fetchStatus: string | null;
  };
  invoicesTable: {
    allItems: [];
  };
  table: {
    data: {
      revenue: string | number;
      minutes: string | number;
      tips: string | number;
      billableChats: string | number;
    };
    fetchStatus: string | null;
  };
}

const initialState: AnalyticsState = {
  dropdownChoices: {
    organizations: {
      items: [],
      fetchStatus: RequestStatus.status.NULL,
      next: null,
      previous: null,
    },
    vendors: {
      items: [],
      fetchStatus: RequestStatus.status.NULL,
      next: null,
      previous: null,
    },
    customers: {
      items: [],
      fetchStatus: RequestStatus.status.NULL,
      next: null,
      previous: null,
    },
    technicians: {
      items: [],
      fetchStatus: RequestStatus.status.NULL,
      next: null,
      previous: null,
    },
    invoices: { items: [], fetchStatus: RequestStatus.status.NULL, next: null, previous: null },
  },
  selectedFilters: {
    organizations: [],
    vendors: [],
    customers: [],
    technicians: [],
    invoices: [],
  },
  chart: {
    data: {
      series: [],
      axis: {
        dataPoints: [],
      },
    },
    fetchStatus: RequestStatus.status.NULL,
  },
  invoicesTable: {
    allItems: [],
  },
  table: {
    data: {
      revenue: 0,
      minutes: 0,
      tips: 0,
      billableChats: 0,
    },
    fetchStatus: RequestStatus.status.NULL,
  },
};

export const getReportsOrganizations: any = createAsyncThunk(
  'analytics/getReportsOrganizations',
  async (data: { limit: string; offset: string; search: string; cursor?: string }, thunkApi) => {
    const { offset, limit, search, cursor } = data;
    return ApiRequests.get('/web/reports/organizations/', {})(data, thunkApi);
  }
);

export const getReportsVendors: any = createAsyncThunk(
  'analytics/getReportsVendors',
  async (
    data: {
      limit: string;
      offset: string;
      search: string;
      cursor?: string;
      organizations?: Array<any>;
    },
    thunkApi
  ) => {
    const { offset, limit, search, cursor } = data;
    return ApiRequests.get('/web/reports/vendors/', {})(data, thunkApi);
  }
);

export const getReportsCustomers: any = createAsyncThunk(
  'analytics/getReportsCustomers',
  async (
    data: {
      limit: string;
      offset: string;
      search: string;
      cursor?: string;
      organizations: Array<any>;
      vendors: Array<any>;
    },
    thunkApi
  ) => {
    const { offset, limit, search, cursor } = data;
    return ApiRequests.get('/web/reports/customers/', {})(data, thunkApi);
  }
);

export const getReportsTechnicians: any = createAsyncThunk(
  'analytics/getReportsTechnicians',
  async (
    data: {
      limit: string;
      offset: string;
      search: string;
      cursor?: string;
      organizations: Array<any>;
      vendors: Array<any>;
    },
    thunkApi
  ) => {
    const { offset, limit, search, cursor } = data;
    return ApiRequests.get('/web/reports/technicians/', {})(data, thunkApi);
  }
);

export const getReportsInvoices: any = createAsyncThunk(
  'analytics/getReportsInvoices',
  async (
    data: {
      limit: string;
      offset: string;
      search: string;
      cursor?: string;
      organizations: Array<any>;
      vendors: Array<any>;
      technicians: Array<any>;
      customers: Array<any>;
    },
    thunkApi
  ) => {
    const { offset, limit, search, cursor } = data;
    return ApiRequests.get('/web/reports/invoices/', {})(data, thunkApi);
  }
);

export const getReportsVenue: any = createAsyncThunk(
  'analytics/getReportsVenue',
  async (data: ReportsVenueReqData, thunkApi) => {
    const { ...otherData } = data;
    return ApiRequests.post('/web/reports/venue/', {})(otherData, thunkApi);
  }
);

const mapResponseItemsToDropdownChoices = (items) => {
  return items.map((item) => ({ value: item?.id || item?.uuid, label: item?.name }));
};

const sumSerie = ({ series, key }) => {
  let sumSerie = 0;
  const sumSerieSerie = series?.find((serie) => serie?.name?.toLowerCase().includes(key));
  if (sumSerieSerie) {
    sumSerie = sumSerieSerie?.data?.reduce((acc, element) => parseFloat(acc) + parseFloat(element));
  }
  return sumSerie;
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    resetState: (state: AnalyticsState) => initialState,
  },
  extraReducers: {
    [getReportsOrganizations.pending]: (state, action) => {
      state.dropdownChoices.organizations.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getReportsOrganizations.fulfilled]: (state, action) => {
      state.dropdownChoices.organizations.items = mapResponseItemsToDropdownChoices(
        action.payload.results
      );
      state.dropdownChoices.organizations.next = action.payload.next;
      state.dropdownChoices.organizations.previous = action.payload.previous;
      state.dropdownChoices.organizations.fetchStatus = RequestStatus.status.DONE;
    },
    [getReportsOrganizations.rejected]: (state, action) => {
      state.chart.fetchStatus = RequestStatus.status.ERROR;
    },
    [getReportsVendors.pending]: (state, action) => {
      state.dropdownChoices.vendors.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getReportsVendors.fulfilled]: (state, action) => {
      state.dropdownChoices.vendors.items = mapResponseItemsToDropdownChoices(
        action.payload.results
      );
      state.dropdownChoices.vendors.next = action.payload.next;
      state.dropdownChoices.vendors.previous = action.payload.previous;
      state.dropdownChoices.vendors.fetchStatus = RequestStatus.status.DONE;
    },
    [getReportsVendors.rejected]: (state, action) => {
      state.dropdownChoices.vendors.fetchStatus = RequestStatus.status.ERROR;
    },
    [getReportsCustomers.pending]: (state, action) => {
      state.dropdownChoices.customers.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getReportsCustomers.fulfilled]: (state, action) => {
      state.dropdownChoices.customers.items = mapResponseItemsToDropdownChoices(
        action.payload.results
      );
      state.dropdownChoices.customers.next = action.payload.next;
      state.dropdownChoices.customers.previous = action.payload.previous;
      state.dropdownChoices.customers.fetchStatus = RequestStatus.status.DONE;
    },
    [getReportsCustomers.rejected]: (state, action) => {
      state.dropdownChoices.customers.fetchStatus = RequestStatus.status.ERROR;
    },
    [getReportsTechnicians.pending]: (state, action) => {
      state.dropdownChoices.technicians.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getReportsTechnicians.fulfilled]: (state, action) => {
      state.dropdownChoices.technicians.items = mapResponseItemsToDropdownChoices(
        action.payload.results
      );
      state.dropdownChoices.technicians.next = action.payload.next;
      state.dropdownChoices.technicians.previous = action.payload.previous;
      state.dropdownChoices.technicians.fetchStatus = RequestStatus.status.DONE;
    },
    [getReportsTechnicians.rejected]: (state, action) => {
      state.dropdownChoices.technicians.fetchStatus = RequestStatus.status.ERROR;
    },
    [getReportsInvoices.pending]: (state, action) => {
      state.dropdownChoices.invoices.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getReportsInvoices.fulfilled]: (state, action) => {
      state.dropdownChoices.invoices.items = mapResponseItemsToDropdownChoices(
        action.payload.results
      );
      state.dropdownChoices.invoices.next = action.payload.next;
      state.dropdownChoices.invoices.previous = action.payload.previous;
      state.dropdownChoices.invoices.fetchStatus = RequestStatus.status.DONE;
    },
    [getReportsInvoices.rejected]: (state, action) => {
      state.dropdownChoices.invoices.fetchStatus = RequestStatus.status.ERROR;
    },
    [getReportsVenue.pending]: (state, action) => {
      state.chart.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getReportsVenue.fulfilled]: (state, action) => {
      // move minutes to the end, it matters when assign Y label
      let chartSeries = action.payload?.series;
      const minutesIdx = chartSeries.findIndex((serie) =>
        serie?.name?.toLowerCase().includes('minutes')
      );
      if (minutesIdx > -1 && minutesIdx !== chartSeries?.length - 1) {
        let minutesSeries = chartSeries[minutesIdx];
        chartSeries.splice(minutesIdx, 1);
        chartSeries.push(minutesSeries);
      }
      state.chart.data = { ...action.payload, series: chartSeries };
      let revenue = sumSerie({ series: action.payload.series, key: 'revenue' });
      let minutes = sumSerie({ series: action.payload.series, key: 'minutes' });
      let tips = sumSerie({ series: action.payload.series, key: 'tips' });
      let billableChats = sumSerie({ series: action.payload.series, key: 'billable' });
      // todo: not enough data now
      let allInvoices = [];
      state.table.data = { revenue, minutes, tips, billableChats };
      state.chart.fetchStatus = RequestStatus.status.DONE;
      state.table.fetchStatus = RequestStatus.status.DONE;
    },
    [getReportsVenue.rejected]: (state, action) => {
      state.chart.fetchStatus = RequestStatus.status.ERROR;
    },
  },
});

export const { resetState, resetState: clearAnalytics } = slice.actions;
export const { reducer } = slice;
