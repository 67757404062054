export const pathIdKey = ':uuid';

export const getLandingPath = ({ forRouting }: { forRouting?: boolean } = { forRouting: false }) =>
  `/`;
export const getLoginPath = ({ forRouting = false } = {}) => `/login`;
export const getOrganizationsPath = ({ forRouting = false } = {}) => `/organizations`;
export const getOrganizationsListPath = ({ forRouting = false } = {}) => `/organizations/list`;
export const getOrganizationsDetailsPath = ({ forRouting = false, id = null } = {}) =>
  `/organizations/${id || pathIdKey}`;
export const getOrganizationsDetailsEditPath = ({ forRouting = false, id = null } = {}) =>
  `/organizations/${id || pathIdKey}/edit`;
export const getOrganizationInvoicesPath = ({ forRouting = false, id = null } = {}) =>
  `/organizations/${id || pathIdKey}/invoices`;
export const getOrganizationsCustomersPath = ({ forRouting = false, id = null } = {}) =>
  `/organizations/${id || pathIdKey}/customers`;
export const getOrganizationsTechniciansPath = ({ forRouting = false, id = null } = {}) =>
  `/organizations/${id || pathIdKey}/technicians`;
export const getOrganizationsTechnicianDetailsPath = ({
  forRouting = false,
  id = null,
  technicianId = null,
} = {}) => `/organizations/${id || pathIdKey}/technicians/${technicianId || ':technicianId'}`;
export const getOrganizationsAdministratorsPath = ({ forRouting = false, id = null } = {}) =>
  `/organizations/${id || pathIdKey}/administrators`;
export const getOrganizationsAdministratorDetailsPath = ({
  forRouting = false,
  id = null,
  adminId = null,
} = {}) => `/organizations/${id || pathIdKey}/administrators/${adminId || ':adminId'}`;
export const getAddNewAdministratorForOrganization = ({ forRouting = false, id = null }) =>
  `/organizations/${id || pathIdKey}/administrators/new`;

//vendors
export const getVendorsPath = ({ forRouting = false } = {}) => `/vendors`;
export const getVendorDetailsPath = ({ forRouting = false, uuid = null } = {}) =>
  `/vendors/${forRouting ? ':uuid' : uuid}`;
export const getAddNewVendorPath = ({ forRouting = false } = {}) => `/vendors/new`;

//customers
export const getCustomersPath = ({ forRouting = false } = {}) => `/customers`;
export const getCustomerDetailsPath = ({ forRouting = false, uuid = null } = {}) =>
  `/customers/${forRouting ? ':uuid' : uuid}`;
export const getCustomerCallHistoryPath = ({ forRouting = false, uuid = null }) =>
  `/customers/${forRouting ? ':uuid' : uuid}/call-history`;
export const getCustomerBillingInfoPath = ({ forRouting = false, uuid = null }) =>
  `/customers/${forRouting ? ':uuid' : uuid}/billing-info`;
export const getCustomerConnectedOrganizationsPath = ({ forRouting = false, uuid = null }) =>
  `/customers/${forRouting ? ':uuid' : uuid}/connected-organizations`;

export const getAnalyticsPath = ({ forRouting = false } = {}) => `/analytics`;
export const getMessagingPath = ({ forRouting = false } = {}) => `/messaging`;
export const getCaseManagementPath = ({ forRouting = false } = {}) => `/case-management`;
export const getCaseManagementThreadExportedMessagesPath = ({
  forRouting = false,
  thread_uuid = null,
} = {}) => `${getCaseManagementPath({ forRouting })}/${forRouting ? ':thread_uuid' : thread_uuid}`;
export const getMessagingDetailsPath = ({ forRouting = false, id = null } = {}) =>
  `/messaging/${id || ':uuid'}`;
export const getLocationsPath = ({ forRouting = false } = {}) => `/locations`;
export const getProfilePath = ({ forRouting = false } = {}) => `/profile`;
export const getRegisterPath = ({ forRouting = false } = {}) => `/register`;
export const getPasswordRecoveryPath = ({ forRouting = false } = {}) => `/password-recovery`;
export const getNotificationsPath = ({ forRouting = false } = {}) => `/notifications`;
export const getSetNewPasswordPath = ({ forRouting = false, uuid = '', token = '' }) =>
  `/password-recovery-confirm/${forRouting ? ':uuid' : uuid}/${forRouting ? ':token' : token}`;
