import { useState, useEffect } from 'react';
import type { ChangeEvent, FC } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Container,
  Grid,
  Breadcrumbs,
  Link,
} from '@mui/material';
import { RootState, useDispatch, useSelector } from '../../../store';
import useQuery from 'src/hooks/useQuery';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useSettings from '../../../hooks/useSettings';
import GenericBreadcrumb, {
  getBreadcrumbLabelFromUserDetails,
} from '../../../components/GenericBreadcrumb';
import { getCustomerDetails } from '../../../slices/customers';

const CustomerBillingInfo: FC<any> = (props) => {
  const { ...other } = props;
  const { settings } = useSettings();
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const customerDetails = useSelector((state: RootState) => state.customers.customerDetails);

  useEffect(() => {
    if (!customerDetails?.user) {
      dispatch(getCustomerDetails({ uuid }));
    }
  }, [customerDetails]);

  return (
    <>
      <Helmet>
        <title>Customer details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Customer Details
              </Typography>
              <GenericBreadcrumb
                replaceLastUUIDWith={getBreadcrumbLabelFromUserDetails(customerDetails)}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card {...other}>
                  <CardHeader title="Customer billing" />

                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography color="textPrimary" variant="subtitle2">
                            Apple
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="textPrimary" variant="subtitle2">
                            Apple Pay
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography color="textPrimary" variant="subtitle2">
                            Credit card
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="textPrimary" variant="subtitle2">
                            Credit card
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CustomerBillingInfo;
