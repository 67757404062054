import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import BlogLayout from './components/blog/BlogLayout';
import BrowseLayout from './components/BrowseLayout';
import DashboardLayout from './containers/Layout/DashboardLayout';
import DocsLayout from './components/docs/DocsLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import ChartSquareBarIcon from './icons/ChartSquareBar';
import ChartPieIcon from './icons/ChartPie';
import ShoppingBagIcon from './icons/ShoppingBag';
import UserIcon from './icons/User';
import UsersIcon from './icons/Users';
import ShoppingCartIcon from './icons/ShoppingCart';
import FolderOpenIcon from './icons/FolderOpen';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {
  getAnalyticsPath,
  getCustomerDetailsPath,
  getCustomersPath,
  getCustomerCallHistoryPath,
  getCustomerBillingInfoPath,
  getCustomerConnectedOrganizationsPath,
  getLoginPath,
  getMessagingPath,
  getOrganizationsAdministratorsPath,
  getOrganizationsCustomersPath,
  getOrganizationsDetailsEditPath,
  getOrganizationsDetailsPath,
  getOrganizationInvoicesPath,
  getOrganizationsPath,
  getOrganizationsTechniciansPath,
  getVendorDetailsPath,
  getVendorsPath,
  getMessagingDetailsPath,
  getAddNewAdministratorForOrganization,
  getOrganizationsTechnicianDetailsPath,
  getLandingPath,
  getAddNewVendorPath,
  getOrganizationsAdministratorDetailsPath,
  getNotificationsPath,
  getPasswordRecoveryPath,
  getSetNewPasswordPath,
  getProfilePath,
  getCaseManagementPath,
  getCaseManagementThreadExportedMessagesPath,
  getLocationsPath,
} from './routesPaths';
import LoginLayout from './components/LoginLayout';
import OrganizationsLayout from './containers/Organizations/OrganizationsLayout';
import OrganizationsList from './containers/Organizations/OrganizationsList';
import Analytics from './containers/Analytics/Analytics';
import OrganizationsDetails from './containers/Organizations/OrganizationsDetails';
import OrganizationInvoices from './containers/Organizations/OrganizationInvoices';
import VendorDetails from './containers/Vendors/VendorDetails';
import _ from 'lodash';
import CustomersList from './containers/Customers/CustomersList';
import CustomerDetails from './containers/Customers/CustomerDetails';
import OrganizationsTechnicians from './containers/Organizations/OrganizationTechnicians/OrganizationTechnicians';
import OrganizationsTechnicianDetails from './containers/Organizations/OrganizationTechnicians/OrganizationTechnicianDetails';
import CustomerCallHistory from './containers/Customers/CustomerCallHistory/CustomerCallHistory';
import Messaging from './containers/Messaging/Messaging';
import OrganizationAdministratorsList from './containers/Organizations/OrganizationAdministrators/OrganizationAdministratorList';
import OrganizationAddAdministrator from './containers/Organizations/OrganizationAdministrators/OrganizationAddAdministrator';
import OrganizationCustomer from './containers/Organizations/OrganizationCustomers';
import Welcome from './containers/Welcome/Welcome';
import AddNewVendor from './containers/Vendors/AddNewVendor';
import OrganizationAdminDetails from './containers/Organizations/OrganizationAdministrators/OrganizationAdministratorsDetails';
import CustomerBillingInfo from './containers/Customers/CustomerBillingInfo/CustomerBillingInfo';
import CustomersListOfConnectedOrganizations from './containers/Customers/CustomerListOfConnectedOrganizations/CustomerListOfConnectedOrganizations';
import NotificationsList from './containers/Notifications/NotificationsList';
import Profile from './containers/Profile/Profile';
import CaseManagement from './containers/CaseManagement/CaseManagement';
import Locations from './containers/Locations/Locations';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import('./containers/Login/Login')));
const Vendors = Loadable(lazy(() => import('./containers/Vendors/VendorsList')));
//const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordRecovery = Loadable(
  lazy(() => import('./containers/PasswordRecovery/PasswordRecovery'))
);
const SetNewPassword = Loadable(lazy(() => import('./containers/SetNewPassword/SetNewPassword')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Blog pages

const BlogPostCreate = Loadable(lazy(() => import('./pages/blog/BlogPostCreate')));
const BlogPostDetails = Loadable(lazy(() => import('./pages/blog/BlogPostDetails')));
const BlogPostList = Loadable(lazy(() => import('./pages/blog/BlogPostList')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')));
// const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CustomerDetails')));
const CustomerEdit = Loadable(lazy(() => import('./pages/dashboard/CustomerEdit')));
const CustomerList = Loadable(lazy(() => import('./pages/dashboard/CustomerList')));
const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
const InvoiceDetails = Loadable(lazy(() => import('./pages/dashboard/InvoiceDetails')));
const InvoiceList = Loadable(lazy(() => import('./pages/dashboard/InvoiceList')));
const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
const OrderDetails = Loadable(lazy(() => import('./pages/dashboard/OrderDetails')));
const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const ProductCreate = Loadable(lazy(() => import('./pages/dashboard/ProductCreate')));
const ProductList = Loadable(lazy(() => import('./pages/dashboard/ProductList')));

// Docs pages

const Docs = Loadable(lazy(() => import('./pages/Docs')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Projects pages

const ProjectBrowse = Loadable(lazy(() => import('./pages/dashboard/ProjectBrowse')));
const ProjectCreate = Loadable(lazy(() => import('./pages/dashboard/ProjectCreate')));
const ProjectDetails = Loadable(lazy(() => import('./pages/dashboard/ProjectDetails')));

// Social pages

const SocialFeed = Loadable(lazy(() => import('./pages/dashboard/SocialFeed')));
const SocialProfile = Loadable(lazy(() => import('./pages/dashboard/SocialProfile')));

// Other pages

const Checkout = Loadable(lazy(() => import('./pages/Checkout')));
const Contact = Loadable(lazy(() => import('./pages/Contact')));
const Home = Loadable(lazy(() => import('./pages/Home')));
const Pricing = Loadable(lazy(() => import('./pages/Pricing')));

interface RoutesType extends PartialRouteObject {
  children?: RoutesType[];
  permissionSubject?: string;
}

const forRouting = true;

export const routes: RoutesType[] = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: getLandingPath({ forRouting }),
        element: <Welcome />,
      },
      {
        path: '/account',
        element: <Account />,
      },
      {
        path: getOrganizationsPath(),
        element: (
          <OrganizationsLayout areTabsVisible={false}>
            <OrganizationsList />
          </OrganizationsLayout>
        ),
        permissionSubject: 'organization',
      },
      {
        path: `${getOrganizationsDetailsPath({ forRouting })}`,
        element: (
          <OrganizationsLayout areTabsVisible={true}>
            <OrganizationsDetails />
          </OrganizationsLayout>
        ),
        permissionSubject: 'organization',
      },
      {
        path: `${getOrganizationsDetailsEditPath({ forRouting })}`,
        element: (
          <OrganizationsLayout areTabsVisible={true}>
            <CustomerEdit />
          </OrganizationsLayout>
        ),
        permissionSubject: 'organization',
      },
      {
        path: `${getOrganizationInvoicesPath({ forRouting })}`,
        element: (
          <OrganizationsLayout areTabsVisible={true}>
            <OrganizationInvoices />
          </OrganizationsLayout>
        ),
        permissionSubject: 'organization',
      },
      //  matches configs in OrganizationsLayout.tsx
      {
        path: `${getOrganizationsCustomersPath({ forRouting })}`,
        element: (
          <OrganizationsLayout areTabsVisible={true}>
            <OrganizationCustomer />
          </OrganizationsLayout>
        ),
        permissionSubject: 'organization',
      },
      {
        path: `${getOrganizationsTechniciansPath({ forRouting })}`,
        element: (
          <OrganizationsLayout areTabsVisible={true}>
            <OrganizationsTechnicians />
          </OrganizationsLayout>
        ),
        permissionSubject: 'organization',
      },
      {
        path: `${getOrganizationsTechnicianDetailsPath({ forRouting })}`,
        element: (
          <OrganizationsLayout areTabsVisible={true}>
            <OrganizationsTechnicianDetails />
          </OrganizationsLayout>
        ),
        permissionSubject: 'organization',
      },
      {
        path: `${getOrganizationsAdministratorsPath({ forRouting })}`,
        element: (
          <OrganizationsLayout areTabsVisible={true}>
            <OrganizationAdministratorsList />
          </OrganizationsLayout>
        ),
        permissionSubject: 'organization',
      },
      {
        path: getOrganizationsAdministratorDetailsPath({ forRouting }),
        element: (
          <OrganizationsLayout areTabsVisible={true}>
            <OrganizationAdminDetails />
          </OrganizationsLayout>
        ),
        permissionSubject: 'organization',
      },
      {
        path: getAddNewAdministratorForOrganization({ forRouting }),
        element: (
          <OrganizationsLayout areTabsVisible={true}>
            <OrganizationAddAdministrator />
          </OrganizationsLayout>
        ),
        permissionSubject: 'organization',
      },
      //vendors
      {
        path: `${getVendorsPath({ forRouting: true })}`,
        element: <Vendors />,
        permissionSubject: 'vendor',
      },
      {
        path: getVendorDetailsPath({ forRouting: true }),
        element: <VendorDetails />,
        permissionSubject: 'vendor',
      },
      {
        path: getAddNewVendorPath({ forRouting: true }),
        element: <AddNewVendor />,
        permissionSubject: 'vendor',
      },

      //customers
      {
        path: `${getCustomersPath({ forRouting: true })}`,
        element: <CustomersList />,
        permissionSubject: 'customers',
      },
      {
        path: `${getCustomerDetailsPath({ forRouting: true })}`,
        element: <CustomerDetails />,
        permissionSubject: 'customers',
      },
      {
        path: `${getCustomerCallHistoryPath({ forRouting: true })}`,
        element: <CustomerCallHistory />,
        permissionSubject: 'customers',
      },
      {
        path: `${getCustomerBillingInfoPath({ forRouting: true })}`,
        element: <CustomerBillingInfo />,
        permissionSubject: 'customers',
      },
      {
        path: `${getCustomerConnectedOrganizationsPath({ forRouting: true })}`,
        element: <CustomersListOfConnectedOrganizations />,
        permissionSubject: 'customers',
      },

      {
        path: `${getAnalyticsPath({ forRouting: true })}`,
        element: <Analytics />,
        permissionSubject: 'analytics',
      },
      {
        path: `${getCustomersPath({ forRouting: true })}/edit`,
        element: <ProductCreate />,
        permissionSubject: 'customers',
      },
      {
        path: `${getMessagingPath({ forRouting: true })}`,
        element: <Messaging />,
        permissionSubject: 'messages',
      },
      {
        path: `${getMessagingDetailsPath({ forRouting: true })}`,
        element: <Messaging />,
        permissionSubject: 'messages',
      },
      {
        path: `${getNotificationsPath({ forRouting: true })}`,
        element: <NotificationsList />,
      },
      {
        path: `${getProfilePath({ forRouting: true })}`,
        element: <Profile />,
      },
      {
        path: `${getCaseManagementPath({ forRouting: true })}`,
        element: <CaseManagement />,
        permissionSubject: 'messages',
      },
      {
        path: `${getCaseManagementThreadExportedMessagesPath({ forRouting: true })}`,
        element: <CaseManagement />,
        permissionSubject: 'messages',
      },
      {
        path: getLocationsPath(),
        element: <Locations />,
      },
    ],
  },
  {
    path: getLoginPath({}),
    element: (
      <LoginLayout>
        <Login />
      </LoginLayout>
    ),
  },
  {
    path: 'login-guarded',
    element: (
      <LoginLayout>
        <GuestGuard>
          <Login />
        </GuestGuard>
      </LoginLayout>
    ),
  },
  {
    path: 'login-unguarded',
    element: (
      <LoginLayout>
        <Login />
      </LoginLayout>
    ),
  },
  {
    path: getPasswordRecoveryPath({ forRouting }),
    element: (
      <LoginLayout>
        <PasswordRecovery />
      </LoginLayout>
    ),
  },
  {
    path: getSetNewPasswordPath({ forRouting }),
    element: (
      <LoginLayout>
        <SetNewPassword />
      </LoginLayout>
    ),
  },
  {
    path: 'verify-code',
    element: (
      <LoginLayout>
        <VerifyCode />
      </LoginLayout>
    ),
  },
];

export const getRoutes = () => {
  return routes;
};

export const getOrganizationRoutes = () => {
  const foundElements = [];
  const findRoutesPaths = (array) => {
    _.each(array, function (element) {
      if (element.path.includes(getOrganizationsPath())) foundElements.push(element);
      if (element?.children?.length > 0) {
        findRoutesPaths(element.children);
      }
    });
  };
  findRoutesPaths(routes);
  return foundElements;
};

export const publicRoutes = [
  getLoginPath({ forRouting }),
  getPasswordRecoveryPath({ forRouting }),
  getSetNewPasswordPath({ forRouting }),
];

export const isLocationInPublicRoutes = (location) => {
  let isPublic = false;
  for (let route of publicRoutes) {
    if (location.pathname.includes(route)) {
      isPublic = true;
      break;
    }
  }
  return isPublic;
};
