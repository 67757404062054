import type { FC } from 'react';
import { List } from '@mui/material';
import { MessagingThread } from '../../../types/messaging_thread';
import MessagingSidebarItem from './MessagingSidebarItem';
import { experimentalStyled } from '@mui/material/styles';
import { RequestStatus } from '../../../utils/RequestStatus';
import LoadMoreButton from '../LoadMoreButton';

const NoThread = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  paddingTop: 64,
  width: '100%',
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
}));

interface Props {
  threadsList: Array<MessagingThread>;
  activeThreadId: number | string | null;
  handleSelect: (thread: MessagingThread) => void;
  fetchStatus: string | null;
  onLoadMoreThreadsClick: any;
  isLoadMoreThreadVisible: boolean;
  wasThreadsFetchingCancelled: boolean;
  isLoadingMore: boolean;
}

const ChatThreadList: FC<Props> = (props) => {
  const {
    threadsList,
    activeThreadId,
    handleSelect,
    fetchStatus,
    onLoadMoreThreadsClick,
    isLoadMoreThreadVisible,
    wasThreadsFetchingCancelled,
    isLoadingMore,
  } = props;

  return (
    <List {...props}>
      {threadsList?.length ? (
        <>
          {threadsList.map((thread) => (
            <MessagingSidebarItem
              active={thread.uuid === activeThreadId}
              key={thread.uuid}
              onSelect={(): void => handleSelect(thread)}
              thread={thread}
              disabled={isLoadingMore}
            />
          ))}
          <LoadMoreButton
            onClick={onLoadMoreThreadsClick}
            isVisible={isLoadMoreThreadVisible}
            disabled={RequestStatus.isFetching(fetchStatus) || wasThreadsFetchingCancelled}
          />
        </>
      ) : (
        RequestStatus.isDone(fetchStatus) && <NoThread>No threads to show</NoThread>
      )}
    </List>
  );
};

export default ChatThreadList;
