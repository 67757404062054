import { useState, useEffect } from 'react';
import type { ChangeEvent, FC } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Input } from '../../components/widgets/inputs/Input';
import FileUploadInput from '../FileUpload/FileUploadInput';
import { getErrorMessageFor } from '../../utils/formsHelper';
import AvatarUploadInput from '../AvatarUploadInput/AvatarUploadInput';
import styled from 'styled-components';
import { experimentalStyled } from '@mui/material/styles';
import QRCodeImage from '../../components/QRCodeImage';

interface ProfileDetailsSummaryProps {
  user: any;
  onSubmit: any;
  control: any;
  patchValidationErrors?: Array<any> | null;
  setValue: any;
  isEditMode: boolean;
  isPatchFetching?: boolean;
  formId?: string;
}

const ProfileSummary: FC<ProfileDetailsSummaryProps> = (props) => {
  const {
    user,
    onSubmit,
    control,
    isEditMode,
    patchValidationErrors = [],
    setValue,
    isPatchFetching,
    formId,
    ...other
  } = props;

  const areValidationMessages =
    patchValidationErrors && Object.keys(patchValidationErrors).length > 0;

  return (
    <Card {...other}>
      <CardHeader title="User info" />

      <form onSubmit={onSubmit} id={formId}>
        <Divider />
        <Box sx={{ margin: '30px' }}>
          <AvatarUploadInput
            control={control}
            isEditMode={isEditMode}
            setValue={setValue}
            name="profile_image"
            defaultValue={user?.profile_image}
            storeKey={'profileImage'}
            validationMessage={
              areValidationMessages &&
              getErrorMessageFor({
                key: 'profile_image',
                errorMessages: patchValidationErrors,
              })
            }
            disabled={isPatchFetching}
          />
        </Box>
        <Divider />

        <Table>
          <TableBody>
            {/*  <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Username
                </Typography>
              </TableCell>

              <TableCell>
                <Input
                  control={control}
                  isEditMode={isEditMode}
                  defaultValue={user.username}
                  type="text"
                  name="username"
                  validationMessage={
                    areValidationMessages &&
                    getErrorMessageFor({
                      key: 'username',
                      errorMessages: patchValidationErrors,
                    })
                  }
                  disabled={isPatchFetching}
                />
              </TableCell>
            </TableRow>*/}

            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  First Name
                </Typography>
              </TableCell>

              <TableCell>
                <Input
                  control={control}
                  isEditMode={isEditMode}
                  defaultValue={user.first_name}
                  type="text"
                  name="first_name"
                  validationMessage={
                    areValidationMessages &&
                    getErrorMessageFor({
                      key: 'first_name',
                      errorMessages: patchValidationErrors,
                    })
                  }
                  disabled={isPatchFetching}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Last Name
                </Typography>
              </TableCell>

              <TableCell>
                <Input
                  control={control}
                  isEditMode={isEditMode}
                  defaultValue={user.last_name}
                  type="text"
                  name="last_name"
                  validationMessage={
                    areValidationMessages &&
                    getErrorMessageFor({
                      key: 'last_name',
                      errorMessages: patchValidationErrors,
                    })
                  }
                  disabled={isPatchFetching}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Phone Number
                </Typography>
              </TableCell>
              <TableCell>
                <Input
                  control={control}
                  isEditMode={isEditMode}
                  defaultValue={user.phone_number}
                  type="phone"
                  name="phone_number"
                  validationMessage={
                    areValidationMessages &&
                    getErrorMessageFor({
                      key: 'phone_number',
                      errorMessages: patchValidationErrors,
                    })
                  }
                  disabled={isPatchFetching}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  QR Code
                </Typography>
              </TableCell>
              <TableCell sx={{ position: 'relative' }}>
                <QRCodeImage qrCode={user?.qr_code} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </form>
    </Card>
  );
};

export default ProfileSummary;
