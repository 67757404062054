import { useState, useEffect } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  CircularProgress,
  LinearProgress,
} from '@mui/material';

import ArrowRightIcon from '../../../icons/ArrowRight';
import SearchIcon from '../../../icons/Search';
import getInitials from '../../../utils/getInitials';
import Scrollbar from '../../../components/Scrollbar';
import { RootState, useDispatch, useSelector } from '../../../store';
import { getConnectedOrganizations, getCustomers, resetState } from '../../../slices/customers';
import { getOrganizationsDetailsPath } from '../../../routesPaths';
import { RequestStatus } from '../../../utils/RequestStatus';
import { useParams } from 'react-router-dom';
import useDebouncedSearchAndPagination from '../../../hooks/useDebouncedSearchAndPagination';
import EmptyList from '../../../components/EmptyList';
import { GetDisplayOnlyValueForPhoneNumberInput } from '../../../components/widgets/inputs/Input';
import { pagination } from '../../../theme';

const CustomersListOfConnectedOrganizationsTable: FC = (props) => {
  const { ...other } = props;
  const { uuid } = useParams();

  const dispatch = useDispatch();
  const {
    connectedOrganizationsFetchStatus,
    connectedOrganizationsList,
    connectedOrganizationsListLength,
  } = useSelector((state: RootState) => state.customers);

  const { handlePageChange, handleLimitChange, handleQueryChange, query, page, limit } =
    useDebouncedSearchAndPagination({
      callbackToDebounce: ({ limit, page, query }) => {
        dispatch(getConnectedOrganizations({ limit, offset: page * limit, search: query, uuid }));
      },
      listLength: connectedOrganizationsListLength,
    });

  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Search organizations"
            value={query}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            m: 1,
            width: 240,
          }}
        ></Box>
      </Box>

      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          {RequestStatus.isFetching(connectedOrganizationsFetchStatus) &&
            connectedOrganizationsList.length > 0 && <LinearProgress />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Photo</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Email Address</TableCell>

                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {connectedOrganizationsList.map((organization, idx) => {
                return (
                  <TableRow hover key={organization.uuid}>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={getOrganizationsDetailsPath({ id: organization.uuid })}
                        variant="subtitle2"
                      >
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          <Avatar
                            src={organization.logo?.thumbnails?.avatar}
                            sx={{
                              height: 42,
                              width: 42,
                            }}
                          >
                            {getInitials(organization.name)}
                          </Avatar>
                        </Box>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={getOrganizationsDetailsPath({ id: organization.uuid })}
                        variant="subtitle2"
                      >
                        {organization.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <GetDisplayOnlyValueForPhoneNumberInput
                        defaultValue={organization.contact_phone || organization.phone}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={getOrganizationsDetailsPath({ id: organization.uuid })}
                        size="large">
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          {RequestStatus.isDone(connectedOrganizationsFetchStatus) &&
            connectedOrganizationsListLength === 0 && (
              <EmptyList>No connected organizations found</EmptyList>
            )}
          {RequestStatus.isFetching(connectedOrganizationsFetchStatus) &&
            connectedOrganizationsList.length === 0 && (
              <CircularProgress sx={{ display: 'block', margin: '30px auto 10px auto' }} />
            )}
          <TablePagination
            component="div"
            count={connectedOrganizationsListLength}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
          />
        </Box>
      </Scrollbar>
    </Card>
  );
};

export default CustomersListOfConnectedOrganizationsTable;
