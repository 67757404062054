import * as React from 'react';
import classnames from 'classnames';
import { CellComponent } from 'react-spreadsheet';
import { RootState, useSelector } from '../../../../store';
import { useTheme } from '@mui/material/styles';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Tooltip } from '@mui/material';
import { RequestStatus } from '../../../../utils/RequestStatus';
import { CELL_HEIGHT } from '../LocationsCreateBuildingModal';

const LocationsSpreadsheetCustomCell: CellComponent = ({
  column,
  row,
  setCellDimensions,
  select,
  activate,
  mode,
  dragging,
  active,
  data,
  evaluatedData,
  DataViewer,
  setCellData,
}) => {
  const rootRef = React.createRef<HTMLTableCellElement>();
  const theme = useTheme();

  const { errors, fetchStatus } = useSelector(
    (state: RootState) => state.locations.createNewStructure
  );

  const [isError, setIsError] = React.useState<
    | boolean
    | {
        row: number;
        cell: number;
        message: string;
      }
  >(false);

  React.useEffect(() => {
    if (errors.length) {
      setIsError(errors.find((error) => error.cell === column && error.row === row) ?? false);
    } else setIsError(false);
  }, [errors]);

  React.useEffect(() => {
    setCellDimensions(
      { row, column },
      {
        height: CELL_HEIGHT, //rootRef.current.offsetHeight,
        width: rootRef.current.offsetWidth,
        left: rootRef.current.offsetLeft,
        top: CELL_HEIGHT * (row + 1), //rootRef.current.offsetTop,
        // left: WIDTH * (column + 1),
        // top: HEIGHT * (row + 1),
      }
    );
  }, [setCellDimensions, column, row]);

  React.useEffect(() => {
    if (rootRef.current && active && mode === 'view') {
      rootRef.current.focus();
    }
  }, [rootRef, active, mode]);

  const handleMouseDown = React.useCallback(
    (event) => {
      if (RequestStatus.isFetching(fetchStatus)) return;
      if (mode === 'view') {
        if (event.shiftKey) {
          select({ row, column });
          return;
        }

        activate({ row, column });
      }
    },
    [select, activate, column, mode, row, fetchStatus]
  );

  const handleMouseOver = React.useCallback(() => {
    if (RequestStatus.isFetching(fetchStatus)) return;
    if (dragging) {
      select({ row, column });
    }
  }, [dragging, select, column, row, fetchStatus]);

  if (data && data.DataViewer) {
    ({ DataViewer, ...data } = data);
  }

  return (
    <td
      ref={rootRef}
      className={classnames(
        'Spreadsheet__cell',
        data && data.readOnly && 'Spreadsheet__cell--readonly',
        data && data.className
      )}
      style={{
        // borderColor: !active && isError ? theme.palette.error.main : 'none',
        ...(!active &&
          !data?.value &&
          isError && {
            outline: `solid 1px ${theme.palette.error.main}`,
          }),
      }}
      tabIndex={0}
      onMouseOver={handleMouseOver}
      onMouseDown={handleMouseDown}
    >
      <DataViewer
        row={row}
        column={column}
        cell={data}
        evaluatedCell={evaluatedData}
        setCellData={setCellData}
      />
      {!active && !data?.value && isError && (
        <Tooltip title={typeof isError !== 'boolean' ? isError?.message : ''} disableInteractive>
          <WarningAmberOutlinedIcon
            sx={{
              position: 'absolute',
              transition: 'color 150ms ease-in-out',
              color: theme.palette.action.disabled,
              ':hover': {
                color: theme.palette.warning.main,
              },
              // right: '16px'
            }}
          />
        </Tooltip>
      )}
    </td>
  );
};

export default LocationsSpreadsheetCustomCell;
