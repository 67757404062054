import { useState, useEffect } from 'react';
import type { ChangeEvent, FC } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import type { Vendor } from '../../types/vendor';
import { Input } from '../../components/widgets/inputs/Input';
import FileUploadInput from '../FileUpload/FileUploadInput';
import { getErrorMessageFor } from '../../utils/formsHelper';
import AvatarUploadInput from '../AvatarUploadInput/AvatarUploadInput';

interface VendorDetailsSummaryProps {
  vendor: Vendor;
  onSubmit: any;
  control: any;
  patchValidationErrors?: Array<any> | null;
  setValue: any;
  isEditMode: boolean;
  isPatchFetching?: boolean;
}

const VendorSummary: FC<VendorDetailsSummaryProps> = (props) => {
  const {
    vendor,
    onSubmit,
    control,
    isEditMode,
    patchValidationErrors = [],
    setValue,
    isPatchFetching,
    ...other
  } = props;

  const areValidationMessages =
    patchValidationErrors && Object.keys(patchValidationErrors).length > 0;

  return (
    <Card {...other}>
      <CardHeader title="Vendor info" />

      <form onSubmit={onSubmit} id="edit-vendor-form">
        <Divider />
        <Box sx={{ margin: '30px' }}>
          <AvatarUploadInput
            control={control}
            isEditMode={isEditMode}
            setValue={setValue}
            name="logo"
            defaultValue={vendor?.logo}
            storeKey={'vendorAvatar'}
            validationMessage={
              areValidationMessages &&
              getErrorMessageFor({
                key: 'logo',
                errorMessages: patchValidationErrors,
              })
            }
            disabled={isPatchFetching}
          />
        </Box>
        <Divider />

        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Name
                </Typography>
              </TableCell>

              <TableCell>
                <Input
                  control={control}
                  isEditMode={isEditMode}
                  defaultValue={vendor.name}
                  type="text"
                  name="name"
                  validationMessage={
                    areValidationMessages &&
                    getErrorMessageFor({
                      key: 'name',
                      errorMessages: patchValidationErrors,
                    })
                  }
                  disabled={isPatchFetching}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Address
                </Typography>
              </TableCell>
              <TableCell>
                <Input
                  control={control}
                  isEditMode={isEditMode}
                  defaultValue={vendor.address}
                  type="text"
                  name="address"
                  validationMessage={
                    areValidationMessages &&
                    getErrorMessageFor({
                      key: 'address',
                      errorMessages: patchValidationErrors,
                    })
                  }
                  disabled={isPatchFetching}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Address 2
                </Typography>
              </TableCell>
              <TableCell>
                <Input
                  control={control}
                  isEditMode={isEditMode}
                  defaultValue={vendor.address2}
                  type="text"
                  name="address2"
                  validationMessage={
                    areValidationMessages &&
                    getErrorMessageFor({
                      key: 'address2',
                      errorMessages: patchValidationErrors,
                    })
                  }
                  disabled={isPatchFetching}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  City
                </Typography>
              </TableCell>
              <TableCell>
                <Input
                  control={control}
                  isEditMode={isEditMode}
                  defaultValue={vendor.city}
                  type="text"
                  name="city"
                  validationMessage={
                    areValidationMessages &&
                    getErrorMessageFor({
                      key: 'city',
                      errorMessages: patchValidationErrors,
                    })
                  }
                  disabled={isPatchFetching}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  State
                </Typography>
              </TableCell>
              <TableCell>
                <Input
                  control={control}
                  isEditMode={isEditMode}
                  defaultValue={vendor.state}
                  type="text"
                  name="state"
                  validationMessage={
                    areValidationMessages &&
                    getErrorMessageFor({
                      key: 'state',
                      errorMessages: patchValidationErrors,
                    })
                  }
                  disabled={isPatchFetching}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Zip code
                </Typography>
              </TableCell>
              <TableCell>
                <Input
                  control={control}
                  isEditMode={isEditMode}
                  defaultValue={vendor.zip_code}
                  type="text"
                  name="zip_code"
                  validationMessage={
                    areValidationMessages &&
                    getErrorMessageFor({
                      key: 'zip_code',
                      errorMessages: patchValidationErrors,
                    })
                  }
                  disabled={isPatchFetching}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Main contact
                </Typography>
              </TableCell>
              <TableCell>
                <Input
                  control={control}
                  isEditMode={isEditMode}
                  defaultValue={vendor.main_contact}
                  type="text"
                  name="main_contact"
                  validationMessage={
                    areValidationMessages &&
                    getErrorMessageFor({
                      key: 'main_contact',
                      errorMessages: patchValidationErrors,
                    })
                  }
                  disabled={isPatchFetching}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Contact email
                </Typography>
              </TableCell>
              <TableCell>
                <Input
                  control={control}
                  isEditMode={isEditMode}
                  defaultValue={vendor.contact_email}
                  type="text"
                  name="contact_email"
                  validationMessage={
                    areValidationMessages &&
                    getErrorMessageFor({
                      key: 'contact_email',
                      errorMessages: patchValidationErrors,
                    })
                  }
                  disabled={isPatchFetching}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Contact
                </Typography>
              </TableCell>
              <TableCell>
                <Input
                  control={control}
                  isEditMode={isEditMode}
                  defaultValue={vendor.phone}
                  type="phone"
                  name="phone"
                  validationMessage={
                    areValidationMessages &&
                    getErrorMessageFor({
                      key: 'phone',
                      errorMessages: patchValidationErrors,
                    })
                  }
                  disabled={isPatchFetching}
                />
              </TableCell>
            </TableRow>
            {!isEditMode && (
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    W-9 (Required Upload Field)
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    minHeight={'50px'}
                    lineHeight="50px"
                  >
                    <a target="blank" href={vendor.file_w9.url} style={{ textDecoration: 'none' }}>
                      <Button color="primary" variant="contained" disabled={isPatchFetching}>
                        {vendor.file_w9?.name || '-'}
                      </Button>
                    </a>
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {isEditMode && (
          <Box sx={{ margin: '30px' }}>
            <FileUploadInput
              control={control}
              isEditMode={isEditMode}
              setValue={setValue}
              isAvatar={false}
              isPdf={true}
              name="file_w9"
              defaultValue={vendor.file_w9}
              storeKey={'vendorW9File'}
              validationMessage={
                areValidationMessages &&
                getErrorMessageFor({
                  key: 'file_w9',
                  errorMessages: patchValidationErrors,
                })
              }
              disabled={isPatchFetching}
            />
          </Box>
        )}
      </form>
    </Card>
  );
};

export default VendorSummary;
