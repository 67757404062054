import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import useMounted from '../../hooks/useMounted';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';
import PencilAltIcon from '../../icons/PencilAlt';
import gtm from '../../lib/gtm';
import { useParams } from 'react-router-dom';
import type { Vendor } from '../../types/vendor';
import VendorsDetailsSummary from './VendorDetailsSummary';
import { useNavigate, useLocation } from 'react-router-dom';
import { getVendorDetailsPath, getVendorsPath } from 'src/routesPaths';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useForm } from 'react-hook-form';
import useQuery from '../../hooks/useQuery';
import { RootState, useDispatch, useSelector } from '../../store';
import {
  getVendorDetails,
  patchVendor,
  clearPostPatchValidationErrorsAndFetchStatuses,
} from '../../slices/vendors';
import { clearFilesForGivenKey } from '../../slices/fileUpload';
import { RequestStatus } from '../../utils/RequestStatus';
import EditButton from 'src/components/widgets/buttons/EditButton';
import useEditMode from '../../hooks/useEditMode';
import { isObjectAsString, setFormValuesFromDetailsObject } from '../../utils/formsHelper';
import GenericBreadcrumb from '../../components/GenericBreadcrumb';

const VendorDetails: FC = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  let { uuid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vendorDetails = useSelector((state: RootState) => state.vendors.vendorDetails.vendor);
  const { postPatchFetchStatus, postPatchValidationErrors } = useSelector(
    (state: RootState) => state.vendors.vendorDetails
  );

  const { handleSubmit, control, setValue } = useForm();
  const { triggerEditMode, isEditMode } = useEditMode();

  useEffect(() => {
    if (RequestStatus.isDone(postPatchFetchStatus)) {
      dispatch(clearPostPatchValidationErrorsAndFetchStatuses());
      navigate(getVendorDetailsPath({ uuid }), { replace: true });
    }
  }, [postPatchFetchStatus]);

  useEffect(() => {
    if (mounted) dispatch(getVendorDetails({ uuid }));
    return () => {
      // dispatch(clearFilesForGivenKey({ storeKey: 'vendorAvatar' }));
    };
  }, []);

  useEffect(() => {
    vendorDetails && setFormValuesFromDetailsObject({ detailsObject: vendorDetails, setValue });
  }, [vendorDetails]);

  if (!vendorDetails) {
    return null;
  }

  const handleFormSubmit = handleSubmit((data) => {
    if (isEditMode) {
      if ((data.logo && isObjectAsString(data.logo)) || data.logo === 'null') delete data.logo;
      dispatch(patchVendor({ uuid, vendor: data }));
    }
  });

  const handleCancelClick = () => {
    setFormValuesFromDetailsObject({ detailsObject: vendorDetails, setValue });
    triggerEditMode();
    dispatch(clearPostPatchValidationErrorsAndFetchStatuses());
    // dispatch(clearFilesForGivenKey({ storeKey: 'vendorAvatar' }));
  };

  const isPatchFetching = RequestStatus.isFetching(postPatchFetchStatus);

  return (
    <>
      <Helmet>
        <title>Vendor details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Vendor Details
              </Typography>
              <GenericBreadcrumb replaceLastUUIDWith={`${vendorDetails?.name || ''}`} />
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <EditButton
                  form="edit-vendor-form"
                  type="submit"
                  isEditMode={isEditMode}
                  onCancelClick={handleCancelClick}
                  onClick={triggerEditMode}
                  isLoading={isPatchFetching}
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <VendorsDetailsSummary
                  vendor={vendorDetails}
                  onSubmit={handleFormSubmit}
                  control={control}
                  setValue={setValue}
                  isEditMode={isEditMode}
                  patchValidationErrors={postPatchValidationErrors}
                  isPatchFetching={isPatchFetching}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default VendorDetails;
