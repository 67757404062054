import { useEffect, useState } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import {
  Avatar,
  Box,
  Card,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import ArrowRightIcon from '../../icons/ArrowRight';
import PencilAltIcon from '../../icons/PencilAlt';
import SearchIcon from '../../icons/Search';
import type { Customer } from '../../types/customer_new';
import getInitials from '../../utils/getInitials';
import Scrollbar from '../../components/Scrollbar';
import { organizationApi } from '../../__fakeApi__/organizationsApi';
import useMounted from '../../hooks/useMounted';
import { Organization } from '../../types/organization';
import { getOrganizationsTechnicianDetailsPath } from '../../routesPaths';
// import { useDispatch } from '../../store';
import { getOrganizationCustomers, clearOrganizationCustomers } from '../../slices/organizations';
import { RootState, useDispatch, useSelector } from '../../store';
import { getCustomerDetailsPath } from '../../routesPaths';
import { RequestStatus } from '../../utils/RequestStatus';
import EmptyList from '../../components/EmptyList';
import useDebouncedSearchAndPagination from '../../hooks/useDebouncedSearchAndPagination';
import { pagination } from '../../theme';

interface OrganizationCustomersProps {}

const OrganizationCustomers: FC<OrganizationCustomersProps> = (props) => {
  const { ...other } = props;

  const { uuid: organizationId } = useParams();
  const dispatch = useDispatch();

  const { items, count, fetchStatus } = useSelector(
    (state: RootState) => state.organizations.details.customers
  );

  const { handlePageChange, handleLimitChange, handleQueryChange, query, page, limit } =
    useDebouncedSearchAndPagination({
      callbackToDebounce: ({ limit, page, query }) => {
        dispatch(
          getOrganizationCustomers({
            limit,
            offset: page * limit,
            search: query,
            id: organizationId,
          })
        );
      },
      listLength: count,
    });

  useEffect(() => {
    return () => {
      dispatch(clearOrganizationCustomers());
    };
  }, []);

  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Search customers"
            value={query}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            m: 1,
            width: 240,
          }}
        ></Box>
      </Box>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          {RequestStatus.isFetching(fetchStatus) && items.length > 0 && <LinearProgress />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Photo</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Email Address</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((customer: Customer, idx) => {
                const customerDetailsPath = getCustomerDetailsPath({
                  uuid: customer.uuid,
                });

                return (
                  <TableRow hover key={`customer_${idx}`}>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={customerDetailsPath}
                        variant="subtitle2"
                        underline="none"
                      >
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            textDecoration: 'none',
                          }}
                        >
                          <Avatar
                            src={customer.avatar?.url}
                            sx={{
                              height: 42,
                              width: 42,
                            }}
                          >
                            {getInitials(customer.customer_label)}
                          </Avatar>
                        </Box>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={customerDetailsPath}
                        variant="subtitle2"
                      >
                        {`${customer?.user?.last_name}, ${customer?.user?.first_name}`}
                      </Link>
                    </TableCell>
                    <TableCell>{customer.email}</TableCell>
                    <TableCell align="right">
                      <IconButton component={RouterLink} to={customerDetailsPath} size="large">
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {RequestStatus.isDone(fetchStatus) && count === 0 && (
            <EmptyList>No customers found</EmptyList>
          )}
          {RequestStatus.isFetching(fetchStatus) && items.length === 0 && (
            <CircularProgress sx={{ display: 'block', margin: '30px auto 10px auto' }} />
          )}
          <TablePagination
            component="div"
            count={count}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
          />
        </Box>
      </Scrollbar>
    </Card>
  );
};

export default OrganizationCustomers;
