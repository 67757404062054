import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

const useEditMode = (): any => {
  let [searchParams, setSearchParams] = useSearchParams();

  const triggerEditMode = (event?, type?) => {
    const wasEdit = searchParams.get('edit') === 'true';
    setSearchParams({ ...searchParams, edit: wasEdit ? 'false' : 'true' });
  };

  const isEditMode = searchParams.get('edit') === 'true';

  return { triggerEditMode, isEditMode };
};

export default useEditMode;
