import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Grid, Typography } from '@mui/material';
import GenericBreadcrumb from '../../components/GenericBreadcrumb';
import { experimentalStyled } from '@mui/material/styles';
import useSettings from '../../hooks/useSettings';
import useMounted from '../../hooks/useMounted';
import { RootState, useDispatch, useSelector } from '../../store';
import {
  cancelItemsFetching,
  getItems,
  getLocationDetails,
  getLocations,
  getLocationsStructure,
  ItemType,
  LocationType,
  resetAddNewItem,
  resetLocationDetails,
  resetLocationsState,
  setCurrentLocation,
  setItemsFetchStatus,
  setSelectedLocation,
} from '../../slices/locations';
import LocationsSidebar from './LocationsSidebar/LocationsSidebar';
import LocationsMainWindow from './LocationsMainWindow/LocationsMainWindow';
import useDebouncedSearchAndPagination from '../../hooks/useDebouncedSearchAndPagination';
import { useReactToPrint } from 'react-to-print';
import QRCodesPrintedComponent from './QRCodesPrintedComponent';
import { compact, flattenDeep, get, uniqBy } from 'lodash';
import { RequestStatus } from '../../utils/RequestStatus';
import { useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  clearOrganizationsOrderedByPrimaryList,
  getOrganizationOrderedByPrimary,
  getOrganizations,
} from '../../slices/organizations';

const CardContainer = experimentalStyled('div')(({ theme }) => ({
  display: 'block',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 10,
}));

export const organiationsListIncrement = 100;

const Locations: FC = () => {
  const { settings } = useSettings();
  const mounted = useMounted();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [preventCallback, setPreventCallback] = useState<boolean>(true);

  const refetchItemsTimeout = useRef<any>(null);

  const {
    locations: {
      items: locationsItems,
      currentLocation,
      fetchStatus: locationsFetchStatus,
      newLocation: { postFetchStatus: addNewLocationFetchStatus },
      editLocation: { fetchStatus: editLocationFetchStatus },
    },
    items: {
      items,
      fetchStatus: itemsFetchStatus,
      count: itemsCount,
      newItem: { postFetchStatus: addNewItemFetchStatus },
      editItem: { fetchStatus: editItemFetchStatus, name: editItemName },
      deleteItem: { fetchStatus: deleteItemFetchStatus },
    },
    locationDetails,
    itemDetails,
    createNewStructure: { fetchStatus: createNewStructureFetchStatus },
  } = useSelector((state: RootState) => state.locations);

  const {
    list: { fetchStatus: organizationsFetchStatus, items: organizationsItems },
  } = useSelector((state: RootState) => state.organizations);

  const [organizationUuid, setOrganizationUuid] = useState<string>(
    searchParams.get('organization_uuid') ?? null
  );

  const [locationUuid, setLocationUuid] = useState<string>(null);
  const [pagesToPrintRef, setPagesToPrintRef] = useState<MutableRefObject<HTMLDivElement>>(null);
  const [qrCodesToPrintLayout, setQrCodesToPrintLayout] = useState<Array<Array<Array<string>>>>([]);
  const [areImagesToPrintLoaded, setAreImagesToPrintLoaded] = useState<boolean>(false);
  const [shouldPrint, setShouldPrint] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');
  const [organizationsPage, setOrganizationsPage] = useState<number>(0);
  const [organizationsLimit, setOrganizationsLimit] = useState<number>(organiationsListIncrement);

  // const organizationUuid = searchParams.get('organization_uuid');

  const handleGetOrganizations = (data?: any) => {
    dispatch(
      getOrganizationOrderedByPrimary({
        search,
        offset: (organizationsPage * organizationsLimit).toString(),
        limit: organizationsLimit.toString(),
        ...data,
      })
    );
  };

  const { handlePageChange, handleLimitChange, handleQueryChange, query, page, limit } =
    useDebouncedSearchAndPagination({
      callbackToDebounce: ({ limit, page, query }) => {
        cancelItemsFetchingAndRun(() => {
          if (!preventCallback && typeof organizationUuid === 'string') {
            if (refetchItemsTimeout.current !== null) {
              clearTimeout(refetchItemsTimeout.current);
              refetchItemsTimeout.current = null;
            }
            dispatch(
              getItems({
                page_size: limit,
                page: page + 1,
                location_uuid: locationUuid,
                search: query,
                organization_uuid: organizationUuid,
              })
            );
          }
          // else preventCallback.current = false;
        });
      },
      listLength: itemsCount,
      additionalDependencyArray: [locationUuid],
    });

  const cancelItemsFetchingAndRun = (callback) => {
    dispatch(setItemsFetchStatus(RequestStatus.status.FETCHING));
    cancelItemsFetching && cancelItemsFetching();
    setTimeout(() => {
      callback();
    }, 100);
  };

  const qrCodesPageToPrintRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => qrCodesPageToPrintRef.current, //pagesToPrintRef.current
    onAfterPrint: () => {
      handleSetPrintState();
      setAreImagesToPrintLoaded(false);
      setShouldPrint(false);
      setQrCodesToPrintLayout([]);
    },
    removeAfterPrint: true,
  });

  const [isPrintStateActive, setIsPrintStateActive] = useState<boolean>(false);
  const [qrCodesToPrint, setQrCodesToPrint] = useState<
    Array<{
      qr_code: string;
      name: string;
      ancestors_names: Array<string>;
      printAmount: number;
    }>
  >([]);
  const [selectSubplaces, setSelectSubplaces] = useState<LocationType>(null);

  const isAnyLoading =
    RequestStatus.isFetching(locationsFetchStatus) ||
    RequestStatus.isFetching(itemsFetchStatus) ||
    RequestStatus.isFetching(locationDetails.fetchStatus) ||
    RequestStatus.isFetching(itemDetails.fetchStatus);

  const recursiveGetSublocations = (item: LocationType) => {
    if (Object.values(item.subplaces).length === 0)
      return {
        qr_code: item.qr_code,
        name: item.name,
        ancestors_names: item.ancestors_names,
        printAmount: 1,
      };
    else
      return [
        {
          qr_code: item.qr_code,
          name: item.name,
          ancestors_names: item.ancestors_names,
          printAmount: 1,
        },
        ...Object.values(item.subplaces).map((_item) => recursiveGetSublocations(_item)),
      ];
  };

  const shouldFetchMissingStructure = (location: LocationType): any => {
    let results = [];
    if (location.has_subplaces && Object.keys(location?.subplaces).length === 0) results.push(true);
    else if (!location.has_subplaces) results.push(false);
    else if (location.has_subplaces && Object.values(location.subplaces)?.length > 0)
      Object.values(location.subplaces).map((subplace) =>
        results.push(...shouldFetchMissingStructure(subplace))
      );
    return results;
  };

  const handleSetQrCodesUrlsToPrint = async (item: LocationType & ItemType): Promise<void> => {
    const shouldFetch = shouldFetchMissingStructure(item);
    if (
      !qrCodesToPrint.find((qrCode) => qrCode.qr_code === item.qr_code) &&
      !shouldFetch.every((result) => !result)
    ) {
      dispatch(setSelectedLocation(item));
      await dispatch(getLocationsStructure({ uuid: item.uuid }));
      setSelectSubplaces(item);
    } else {
      setQrCodesToPrint((prev) => {
        if (!item.hasOwnProperty('subplaces')) {
          if (Boolean(prev.find((_item) => _item.qr_code === item.qr_code)))
            return prev.filter((_item) => _item.qr_code !== item.qr_code);
          else
            return [
              ...prev,
              {
                qr_code: item.qr_code,
                name: item.name,
                ancestors_names: item.location.ancestors_names,
                printAmount: 1,
              },
            ];
        } else {
          if (Boolean(prev.find((_item) => _item.qr_code === item.qr_code)))
            return prev.filter((_item) => _item.qr_code !== item.qr_code);
          else
            return uniqBy(
              compact(
                flattenDeep([
                  ...prev,
                  {
                    qr_code: item.qr_code,
                    name: item.name,
                    ancestors_names: item.ancestors_names,
                    printAmount: 1,
                  },
                  ...Object.values(item.subplaces).map((_item) => recursiveGetSublocations(_item)),
                ])
              ),
              'qr_code'
            );
        }
      });
    }
  };

  const handleChangePrintAmount = (printAmount: number, qr_code: string): void => {
    setQrCodesToPrint((prev) => {
      const qrCodes = prev;
      let idx = qrCodes.findIndex((qrCode) => qrCode.qr_code === qr_code);
      return [
        ...qrCodes.slice(0, idx),
        {
          ...qrCodes[idx],
          printAmount,
        },
        ...qrCodes.slice(idx + 1, qrCodes?.length),
      ];
    });
  };

  const handleSetPrintState = (): void => {
    setIsPrintStateActive(!isPrintStateActive);
  };

  const handleGetLocations = (
    data: { parent_uuid: string | null },
    ancestors_uuids: Array<string>,
    options?: {
      preventGetItems?: boolean;
    }
  ): void => {
    const { preventGetItems = false } = options;
    dispatch(
      getLocations({
        ...data,
        page_size: 99999,
        page: 1,
        organization_uuid: organizationUuid,
      })
    );
    dispatch(setCurrentLocation(ancestors_uuids));
    setIsPrintStateActive(false);
    !preventGetItems &&
      cancelItemsFetchingAndRun(() => {
        dispatch(
          getItems({
            location_uuid: data.parent_uuid,
            page_size: limit,
            search: query,
            organization_uuid: organizationUuid,
          })
        );
      });
  };

  const handleGetLocationDetails = (
    data: { uuid: string | null },
    ancestors_uuids: Array<string>
  ): Promise<void> =>
    new Promise<void>((resolve) => {
      setPreventCallback(true);
      resolve();
    })
      .then(() => {
        const otherSearchParams = Object.fromEntries(searchParams.entries());
        setSearchParams({
          ...otherSearchParams,
          page: '0',
          current_location: ancestors_uuids.join('_'),
        });
      })
      .then(() => {
        if (refetchItemsTimeout.current !== null) {
          clearTimeout(refetchItemsTimeout.current);
          refetchItemsTimeout.current = null;
        }
        setPreventCallback(false);
        data.uuid !== null &&
          dispatch(getLocationDetails({ ...data, organization_uuid: organizationUuid }));
        dispatch(setCurrentLocation(ancestors_uuids));
        setIsPrintStateActive(false);
        cancelItemsFetchingAndRun(() => {
          dispatch(
            getItems({
              page_size: limit,
              search: query,
              organization_uuid: organizationUuid,
              ...(data.uuid !== null && { location_uuid: data.uuid }),
            })
          );
        });
      });

  useEffect(() => {
    if (
      RequestStatus.isDone(organizationsFetchStatus) &&
      organizationsItems.length > 0 &&
      !searchParams.get('organization_uuid')
    ) {
      setOrganizationUuid(organizationsItems[0].uuid);
    } else if (Boolean(searchParams.get('organization_uuid'))) {
      setOrganizationUuid(searchParams.get('organization_uuid'));
    }
  }, [organizationsFetchStatus, organizationsItems, searchParams]);

  useEffect(() => {
    if (mounted && organizationUuid) {
      // dispatch(
      //   getLocations({
      //     page_size: 99999,
      //     page: 1,
      //     organization_uuid: organizationUuid,
      //   })
      // );
      dispatch(
        getLocationsStructure({
          organization_uuid: organizationUuid,
          whole_structure: true,
        })
      );
      dispatch(setCurrentLocation([]));
      setLocationUuid(null);
      dispatch(resetLocationDetails(null));
      // handleGetLocationDetails({ uuid: null }, []);
      const current_location =
        searchParams.get('current_location')?.length > 0
          ? searchParams.get('current_location').split('_')
          : [];
      handleGetLocationDetails(
        {
          uuid: current_location.length > 0 ? current_location[current_location.length - 1] : null,
        },
        current_location
      );
    }
  }, [mounted, organizationUuid]);

  useEffect(() => {
    return () => {
      dispatch(resetLocationsState());
    };
  }, []);

  useEffect(() => {
    if (!isPrintStateActive) setQrCodesToPrint([]);
  }, [isPrintStateActive]);

  useEffect(() => {
    if (selectSubplaces !== null) {
      handleSetQrCodesUrlsToPrint(
        get(
          locationsItems,
          selectSubplaces?.ancestors_uuids.reduce(
            (acc, cur, idx) =>
              (acc =
                acc +
                cur +
                (idx === selectSubplaces?.ancestors_uuids.length - 1 ? '' : '.subplaces.')),
            ''
          ) as string,
          null
        )
      );
      setSelectSubplaces(null);
    }
  }, [selectSubplaces, locationsItems]);

  useEffect(() => {
    setLocationUuid(locationDetails?.location?.uuid ?? null);
  }, [locationDetails]);

  useEffect(() => {
    if (shouldPrint && areImagesToPrintLoaded) handlePrint();
  }, [shouldPrint, areImagesToPrintLoaded]);

  useEffect(() => {
    if (RequestStatus.isDone(addNewItemFetchStatus) || RequestStatus.isDone(editItemFetchStatus)) {
      dispatch(
        getItems({
          page_size: limit,
          page: page + 1,
          location_uuid: locationUuid,
          search: query,
          organization_uuid: organizationUuid,
        })
      );
    }
    if (RequestStatus.isDone(deleteItemFetchStatus)) {
      if (refetchItemsTimeout.current !== null) {
        clearTimeout(refetchItemsTimeout.current);
        refetchItemsTimeout.current = null;
      }
      if (items.length === 1 && page > 0) {
        const otherSearchParams = Object.fromEntries(searchParams.entries());
        setSearchParams({
          ...otherSearchParams,
          page: (page - 1).toString(),
        });
      } else {
        dispatch(
          getItems({
            page_size: limit,
            page: page + 1,
            location_uuid: locationUuid,
            search: query,
            organization_uuid: organizationUuid,
          })
        );
      }
    }
    if (
      RequestStatus.isDone(addNewItemFetchStatus) ||
      RequestStatus.isDone(createNewStructureFetchStatus)
    ) {
      if (refetchItemsTimeout.current !== null) {
        clearTimeout(refetchItemsTimeout.current);
        refetchItemsTimeout.current = null;
      }
      refetchItemsTimeout.current = setTimeout(() => {
        dispatch(
          getItems({
            page_size: limit,
            page: page + 1,
            location_uuid: locationUuid,
            search: query,
            organization_uuid: organizationUuid,
          })
        );
      }, 10 * 1000);
    }
  }, [
    addNewItemFetchStatus,
    editItemFetchStatus,
    deleteItemFetchStatus,
    createNewStructureFetchStatus,
  ]);

  useEffect(() => {
    if (
      RequestStatus.isDone(editLocationFetchStatus) ||
      RequestStatus.isDone(createNewStructureFetchStatus)
    ) {
      dispatch(
        getItems({
          page_size: limit,
          page: page + 1,
          location_uuid: locationUuid,
          search: query,
          organization_uuid: organizationUuid,
        })
      );
    }
  }, [editLocationFetchStatus, createNewStructureFetchStatus]);

  useEffect(() => {
    if (
      RequestStatus.isDone(editLocationFetchStatus) ||
      RequestStatus.isDone(createNewStructureFetchStatus)
    ) {
      dispatch(
        getLocationsStructure({
          organization_uuid: organizationUuid,
          whole_structure: true,
        })
      );
    }
  }, [editLocationFetchStatus, createNewStructureFetchStatus]);

  useEffect(() => {
    return () => {
      dispatch(clearOrganizationsOrderedByPrimaryList());
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Buildings</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          display: 'flex',
          py: 4,
        }}
      >
        <Box sx={{ display: 'none' }}>
          <QRCodesPrintedComponent
            ref={qrCodesPageToPrintRef}
            layout={qrCodesToPrintLayout}
            setAreImagesToPrintLoaded={setAreImagesToPrintLoaded}
          />
        </Box>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Buildings
              </Typography>
              <GenericBreadcrumb replaceLastUUIDWith={'CHAT'} />
              <Box
                sx={{
                  mb: -1,
                  mx: -1,
                  mt: 1,
                }}
              ></Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              mt: 3,
              mb: -5,
              pb: 3,
              flex: 1,
              position: 'relative',
              height: `calc(100% - 84px)`,
              minHeight: '200px',
            }}
          >
            <CardContainer>
              <Card sx={{ maxHeight: '100%', display: 'flex', width: '100%', height: '100%' }}>
                <Box sx={{ mt: 0, flex: 1, minHeight: '100%', display: 'flex' }}>
                  <LocationsSidebar
                    locations={Object.values(locationsItems)}
                    locationsFetchStatus={locationsFetchStatus}
                    isPrintStateActive={isPrintStateActive}
                    handleGetLocations={handleGetLocations}
                    handleGetLocationDetails={handleGetLocationDetails}
                    currentLocation={currentLocation}
                    handleSetQrCodesUrlsToPrint={handleSetQrCodesUrlsToPrint}
                    qrCodesToPrint={qrCodesToPrint}
                    isAnyLoading={isAnyLoading}
                    currentlySelectedLocation={locationDetails.location}
                    handleGetOrganizations={handleGetOrganizations}
                    limit={limit}
                    setLimit={setOrganizationsLimit}
                  />
                  <LocationsMainWindow
                    items={items}
                    itemsFetchStatus={itemsFetchStatus}
                    searchQuery={query}
                    setSearchQuery={handleQueryChange}
                    paginationMethods={{
                      onPageChange: handlePageChange,
                      onRowsPerPageChange: handleLimitChange,
                      page,
                      rowsPerPage: limit,
                      count: itemsCount,
                    }}
                    location={locationDetails.location}
                    handleSetPrintCodesState={handleSetPrintState}
                    isPrintStateActive={isPrintStateActive}
                    handlePrint={handlePrint}
                    handleSetQrCodesUrlsToPrint={handleSetQrCodesUrlsToPrint}
                    qrCodesToPrint={qrCodesToPrint}
                    handleChangePrintAmount={handleChangePrintAmount}
                    isAnyLoading={isAnyLoading}
                    setPagesToPrintRef={setPagesToPrintRef}
                    locationUuid={locationUuid}
                    setQrCodesToPrintLayout={setQrCodesToPrintLayout}
                    handleGetOrganizations={handleGetOrganizations}
                  />
                </Box>
              </Card>
            </CardContainer>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Locations;
