import React, { FC } from 'react';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Button, Tooltip } from '@mui/material';

type MarkAsReadProps = {
  onClick: () => any;
  tooltipText?: string;
  disabled?: boolean;
};

const MarkAsRead: FC<MarkAsReadProps> = (props) => {
  const { onClick, disabled, tooltipText = 'Mark all as read' } = props;
  return (
    <Tooltip title={tooltipText} placement="top">
      <Button
        color="primary"
        size="large"
        variant="text"
        onClick={onClick}
        sx={{ minWidth: '55px', display: 'block' }}
      >
        <DoneAllIcon fontSize="small" sx={{ paddingTop: '1px' }} />
      </Button>
    </Tooltip>
  );
};

export default MarkAsRead;
