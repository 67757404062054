import { Ability, AbilityBuilder } from '@casl/ability';
import _ from 'lodash';

/**
 * docs for creating credentials based on roles:
 * https://www.npmjs.com/package/@casl/react#update-ability-instance
 */
let abilityInstance = new Ability([]);

const permissions = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

let myRoles = [];

/**
 * Convert permissions naming to CRUD system
 * API: view, change, add, delete
 * CRUD: read, update, create, delete
 */
const convertToCRUDNaming = (ability) => {
  switch (ability) {
    case 'view':
      return permissions.READ;
    case 'change':
      return permissions.UPDATE;
    case 'add':
      return permissions.CREATE;
    case 'deactivate':
      return 'delete';
    default:
      return ability;
  }
};

const mapResponseToAbilities = function mapUserAuthResponseToAbilities(response) {
  if (!response.data.roles_and_permissions) {
    console.error('No roles and permissions');
    return;
  }
  myRoles = Object.keys(response.data.roles_and_permissions);
  const permissionResponse = _.uniq(_.flatten(Object.values(response.data.roles_and_permissions)));

  let permissions = [];
  for (let _permission of permissionResponse) {
    const permission = _permission.replace('-', '_');
    const [action, subject] = permission.split('_');
    permissions.push({
      action: convertToCRUDNaming(action).toUpperCase(),
      subject: subject ? subject.toUpperCase() : '',
    });
  }
  abilityInstance.update(permissions);
  return { roles: myRoles, permissions };
};

const getDefinedRules = function getDefinedRules() {
  return abilityInstance.rules;
};

const getMyRoles = () => myRoles;

const amISuperAdmin = () => {
  return myRoles.includes('SuperAdmin');
};

// exports from here are internal, if access in app, import from Can.js
export { permissions, mapResponseToAbilities, getDefinedRules, getMyRoles, amISuperAdmin };
export default abilityInstance;
