import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  InputAdornment,
  Container,
  Grid,
  Breadcrumbs,
  Link,
} from '@mui/material';
import { Input } from '../../../components/widgets/inputs/Input';
import FileUploadInput from '../../FileUpload/FileUploadInput';
import useSettings from '../../../hooks/useSettings';
import { getErrorMessageFor } from '../../../utils/formsHelper';
import { useEffect } from 'react';
import SaveAndInvite from './SaveAndInvite';
import AvatarUploadInput from '../../AvatarUploadInput/AvatarUploadInput';

const AddNewAdministratorForm = (props) => {
  const {
    control,
    hideBottomSave,
    formId,
    onFormSubmit,
    isEditMode,
    setValue,
    defaultValues,
    validationMessages,
    isPatchFetching,
    isPostFetching,
  } = props;

  const { settings } = useSettings();

  const areValidationMessages = validationMessages && Object.keys(validationMessages).length > 0;

  const isDisabled = isPostFetching || isPatchFetching;

  return (
    <form onSubmit={onFormSubmit} id={formId}>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={settings.compact ? 12 : 4} xl={settings.compact ? 12 : 3}>
            <Box sx={{ margin: '30px' }}>
              {/* <FileUploadInput
                control={control}
                isEditMode={isEditMode}
                setValue={setValue}
                name="profile_image"
                defaultValue={defaultValues?.profile_image}
                storeKey={'addNewAdminProfileImage'}
                validationMessage={
                  areValidationMessages &&
                  getErrorMessageFor({ key: 'profile_image', errorMessages: validationMessages })
                }
                disabled={isDisabled}
              /> */}
              <AvatarUploadInput
                control={control}
                isEditMode={isEditMode}
                setValue={setValue}
                name="profile_image"
                defaultValue={defaultValues?.profile_image}
                storeKey={'addNewAdminProfileImage'}
                validationMessage={
                  areValidationMessages &&
                  getErrorMessageFor({ key: 'profile_image', errorMessages: validationMessages })
                }
                disabled={isDisabled}
              />
            </Box>

            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography color="textPrimary" variant="subtitle2">
                      First Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Input
                      control={control}
                      isEditMode={isEditMode}
                      defaultValue={defaultValues?.first_name}
                      type="text"
                      name="first_name"
                      validationMessage={
                        areValidationMessages &&
                        getErrorMessageFor({ key: 'first_name', errorMessages: validationMessages })
                      }
                      disabled={isDisabled}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography color="textPrimary" variant="subtitle2">
                      Last Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Input
                      control={control}
                      isEditMode={isEditMode}
                      defaultValue={defaultValues?.last_name}
                      type="text"
                      name="last_name"
                      validationMessage={
                        areValidationMessages &&
                        getErrorMessageFor({ key: 'last_name', errorMessages: validationMessages })
                      }
                      disabled={isDisabled}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography color="textPrimary" variant="subtitle2">
                      Username
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Input
                      control={control}
                      isEditMode={isEditMode}
                      defaultValue={defaultValues?.username}
                      type="text"
                      name="username"
                      validationMessage={
                        areValidationMessages &&
                        getErrorMessageFor({ key: 'username', errorMessages: validationMessages })
                      }
                      disabled={isDisabled}
                    />
                  </TableCell>
                </TableRow>
                {/*<TableRow>*/}
                {/*  <TableCell>*/}
                {/*    <Typography color="textPrimary" variant="subtitle2">*/}
                {/*      Name*/}
                {/*    </Typography>*/}
                {/*  </TableCell>*/}
                {/*  <TableCell>*/}
                {/*    <Input*/}
                {/*      control={control}*/}
                {/*      isEditMode={isEditMode}*/}
                {/*      defaultValue={defaultValues?.name}*/}
                {/*      type="text"*/}
                {/*      name="name"*/}
                {/*      validationMessage=""*/}
                {/*    />*/}
                {/*  </TableCell>*/}
                {/*</TableRow>*/}
                <TableRow>
                  <TableCell>
                    <Typography color="textPrimary" variant="subtitle2">
                      Email
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Input
                      control={control}
                      isEditMode={isEditMode}
                      defaultValue={defaultValues?.email}
                      type="text"
                      name="email"
                      validationMessage={
                        areValidationMessages &&
                        getErrorMessageFor({ key: 'email', errorMessages: validationMessages })
                      }
                      disabled={isDisabled}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography color="textPrimary" variant="subtitle2">
                      Phone Number
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Input
                      control={control}
                      isEditMode={isEditMode}
                      defaultValue={defaultValues?.phone_number}
                      type="phone"
                      name="phone_number"
                      validationMessage={
                        areValidationMessages &&
                        getErrorMessageFor({
                          key: 'phone_number',
                          errorMessages: validationMessages,
                        })
                      }
                      disabled={isDisabled}
                    />
                  </TableCell>
                </TableRow>
                {!hideBottomSave && (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <SaveAndInvite sx={{ m: 1 }} isFetching={isPostFetching || isPatchFetching} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default AddNewAdministratorForm;
