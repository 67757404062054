import { useState, useEffect } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  LinearProgress,
  CircularProgress,
} from '@mui/material';

import ArrowRightIcon from '../../../icons/ArrowRight';
import PencilAltIcon from '../../../icons/PencilAlt';
import SearchIcon from '../../../icons/Search';
import PlusIcon from '../../../icons/Plus';
import type { Customer } from '../../../types/customer_new';
import getInitials from '../../../utils/getInitials';
import Scrollbar from '../../../components/Scrollbar';
import { RootState, useDispatch, useSelector } from '../../../store';
import { getCustomers } from '../../../slices/customers';
import useMounted from '../../../hooks/useMounted';
import { useNavigate } from 'react-router-dom';
import {
  getAddNewAdministratorForOrganization,
  getCustomerDetailsPath,
  getOrganizationsAdministratorDetailsPath,
} from 'src/routesPaths';
import { getOrganizationAdmins, clearOrganizationAdmins } from '../../../slices/organizations';
import { useParams } from 'react-router';
import { Administrator } from '../../../types/administrator';
import { RequestStatus } from '../../../utils/RequestStatus';
import Can, { permissions, subjects, ability } from '../../../utils/Can';
import EmptyList from '../../../components/EmptyList';
import useDebouncedSearchAndPagination from '../../../hooks/useDebouncedSearchAndPagination';
import { pagination } from '../../../theme';
import { GetDisplayOnlyValueForPhoneNumberInput } from '../../../components/widgets/inputs/Input';

const OrganizationAdministratorsList: FC = (props) => {
  const { ...other } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { items, fetchStatus, count } = useSelector(
    (state: RootState) => state.organizations.details.administrators
  );
  const { uuid: id } = useParams();

  const { handlePageChange, handleLimitChange, handleQueryChange, query, page, limit } =
    useDebouncedSearchAndPagination({
      callbackToDebounce: ({ limit, page, query }) => {
        dispatch(getOrganizationAdmins({ limit, offset: page * limit, search: query, id }));
      },
      listLength: count,
    });

  useEffect(() => {
    return () => {
      dispatch(clearOrganizationAdmins());
    };
  }, []);

  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Search administrator"
            value={query}
            variant="outlined"
          />
        </Box>

        <Box
          sx={{
            marginLeft: 'auto',
          }}
        >
          <Can I={permissions.CREATE} a={subjects.ORGANIZATION_ADMINS}>
            <Button
              color="primary"
              variant="contained"
              component={RouterLink}
              to={getAddNewAdministratorForOrganization({ id })}
              startIcon={<PlusIcon fontSize="small" />}
              sx={{ m: 1 }}
            >
              Administrator
            </Button>
          </Can>
        </Box>
      </Box>

      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          {RequestStatus.isFetching(fetchStatus) && items.length > 0 && <LinearProgress />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Photo</TableCell>
                <TableCell>Administrator</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((admin: Administrator, idx) => {
                const adminDetailsPath = getOrganizationsAdministratorDetailsPath({
                  id,
                  adminId: admin?.uuid,
                });

                return (
                  <TableRow hover key={idx}>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={adminDetailsPath}
                        variant="subtitle2"
                        underline="none"
                      >
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          <Avatar
                            src={admin.profile_image?.thumbnails?.avatar}
                            sx={{
                              height: 42,
                              width: 42,
                            }}
                          >
                            {getInitials(`${admin.first_name} ${admin.last_name}`)}
                          </Avatar>
                          <Box sx={{ ml: 1 }}>{}</Box>
                        </Box>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={adminDetailsPath}
                        variant="subtitle2"
                      >
                        {`${admin.last_name}, ${admin.first_name}`}
                      </Link>
                    </TableCell>
                    <TableCell>{admin.email}</TableCell>
                    <TableCell>
                      <GetDisplayOnlyValueForPhoneNumberInput defaultValue={admin.phone_number} />
                    </TableCell>

                    <TableCell align="right">
                      <IconButton component={RouterLink} to={adminDetailsPath} size="large">
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {RequestStatus.isDone(fetchStatus) && count === 0 && (
            <EmptyList>No administrators found</EmptyList>
          )}
          {RequestStatus.isFetching(fetchStatus) && items.length === 0 && (
            <CircularProgress sx={{ display: 'block', margin: '30px auto 10px auto' }} />
          )}
          <TablePagination
            component="div"
            count={count}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
          />
        </Box>
      </Scrollbar>
    </Card>
  );
};

export default OrganizationAdministratorsList;
