import { useState, useEffect } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  LinearProgress,
  CircularProgress,
} from '@mui/material';

import ArrowRightIcon from '../../../icons/ArrowRight';
import PencilAltIcon from '../../../icons/PencilAlt';
import SearchIcon from '../../../icons/Search';
import type { Customer } from '../../../types/customer_new';
import getInitials from '../../../utils/getInitials';
import Scrollbar from '../../../components/Scrollbar';
import { RootState, useDispatch, useSelector } from '../../../store';
import { getCustomers } from '../../../slices/customers';
import useMounted from '../../../hooks/useMounted';
import { useNavigate } from 'react-router-dom';
import { getCustomerDetailsPath } from 'src/routesPaths';
import { getCallHistory, resetState } from '../../../slices/customers';
import useQuery from 'src/hooks/useQuery';
import type { Call } from '../../../types/call';
import { useParams } from 'react-router-dom';
import { RequestStatus } from '../../../utils/RequestStatus';
import EmptyList from '../../../components/EmptyList';
import { pagination } from '../../../theme';

const CustomerCallHistoryTable: FC = (props) => {
  const { ...other } = props;

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(pagination.defaultRowsPerPage);
  const [query, setQuery] = useState<string>('');
  const mounted = useMounted();
  const dispatch = useDispatch();
  const { callHistoryList, callHistoryListLength, callHistoryListFetchStatus } = useSelector(
    (state: RootState) => state.customers
  );

  const { uuid } = useParams();

  useEffect(() => {
    if (mounted) dispatch(getCallHistory({ uuid, limit, offset: limit * page }));
  }, [limit, page, query, mounted]);

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };

  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2,
        }}
      >
        {/* <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Search calls"
            value={query}
            variant="outlined"
          />
        </Box> */}
      </Box>

      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          {RequestStatus.isFetching(callHistoryListFetchStatus) && callHistoryList.length > 0 && (
            <LinearProgress />
          )}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Customer</TableCell>
                <TableCell>Call cost </TableCell>
                <TableCell>Duration</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {callHistoryList.map((call: Call, idx) => {
                return (
                  <TableRow hover key={idx}>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <Avatar
                          src={'logo'}
                          sx={{
                            height: 42,
                            width: 42,
                          }}
                        >
                          {getInitials(call.customer_label)}
                        </Avatar>
                        <Box sx={{ ml: 1 }}>{call.customer_label}</Box>
                      </Box>
                    </TableCell>
                    <TableCell>{call.call_cost}</TableCell>
                    <TableCell>{call.duration}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {RequestStatus.isDone(callHistoryListFetchStatus) && callHistoryListLength === 0 && (
            <EmptyList>No call history</EmptyList>
          )}

          {RequestStatus.isFetching(callHistoryListFetchStatus) && callHistoryList.length === 0 && (
            <CircularProgress sx={{ display: 'block', margin: '30px auto 10px auto' }} />
          )}
          <TablePagination
            component="div"
            count={callHistoryListLength}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
          />
        </Box>
      </Scrollbar>
    </Card>
  );
};

export default CustomerCallHistoryTable;
