import { ItemType, LocationType } from '../../../slices/locations';
import {FC, Fragment, useEffect, useState} from 'react';
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';
import ChevronDown from '../../../icons/ChevronDown';
import ChevronRight from '../../../icons/ChevronRight';
import { useTheme } from '@mui/material/styles';
import LocationsActionModal from '../LocationsModals/LocationsItemsActionsModal';

interface LocationSidebarItemProps {
  idx?: number;
  location: LocationType;
  handleGetLocations: (
    data: { parent_uuid: string },
    ancestors_uuids: Array<string>,
    options?: {
      preventGetItems?: boolean;
    }
  ) => void;
  handleGetLocationDetails: (data: { uuid: string }, ancestors_uuids: Array<string>) => void;
  isSubLocation?: boolean;
  showCheckbox?: boolean;
  currentLocation: Array<string>;
  handleSetQrCodesUrlsToPrint: (item: LocationType | ItemType) => void;
  qrCodesToPrint: Array<{
    qr_code: string;
    name: string;
    ancestors_names: Array<string>;
    printAmount: number;
  }>;
  isAnyLoading: boolean;
  currentlySelectedLocation: LocationType;
  handleGetOrganizations: (data?: any) => void;
  hideActions?: boolean;
}

const LocationSidebarRow: FC<LocationSidebarItemProps> = (props) => {
  const {
    idx = null,
    location,
    handleGetLocations,
    handleGetLocationDetails,
    isSubLocation = false,
    showCheckbox = false,
    currentLocation,
    handleSetQrCodesUrlsToPrint,
    qrCodesToPrint,
    isAnyLoading,
    currentlySelectedLocation,
    handleGetOrganizations,
    hideActions = false,
  } = props;

  const theme = useTheme();

  const [subLocationsVisible, setSubLocationsVisible] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleOnRowClick = (options?: {
    preventClose?: boolean;
    preventGetLocation?: boolean;
    preventGetItems?: boolean;
  }): void => {
    const { preventClose = false, preventGetLocation = false, preventGetItems = false } = options;
    //handleOnChevronClick
    !preventGetLocation &&
      handleGetLocationDetails({ uuid: location.uuid }, location.ancestors_uuids);
    if (Object.values(location.subplaces)?.length === 0) {
      handleGetLocations({ parent_uuid: location.uuid }, location.ancestors_uuids, {
        preventGetItems,
      });
    } else {
      !preventClose && subLocationsVisible
        ? setSubLocationsVisible(false)
        : setSubLocationsVisible(true);
    }
  };

  const handleOnMouseEnter = (e) => {
    setIsHovered(true);
  };

  const handleOnMouseLeave = (e) => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (currentLocation.includes(location.uuid)) setSubLocationsVisible(true);
  }, [currentLocation]);

  return (
    <Fragment key={location.uuid}>
      {!!idx && idx > 0 && <Divider sx={{ mx: 0 }} />}
      <ListItem
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        sx={{
          pl: 4 * location.ancestors_uuids.length - 1,
          py: 0.25,
          minHeight: '40px',
          ...(isHovered && {
            backgroundColor: theme.palette.action.hover,
          }),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {isSubLocation && (
            <Box
              sx={{
                transform: 'translate(50%, -50%)',
                borderLeft: 2,
                borderBottom: 2,
                borderColor: 'primary.light',
                width: '16px',
                height: '8px',
                minWidth: '16px',
                minHeight: '8px',
                mr: 1.5,
              }}
            ></Box>
          )}
          {showCheckbox && location.qr_code && (
            <Checkbox
              onClick={() => {
                handleSetQrCodesUrlsToPrint(location);
              }}
              checked={Boolean(
                qrCodesToPrint.find((qrCode) => qrCode.qr_code === location.qr_code)
              )}
              sx={{ px: 0.5, py: 0 }}
              disableRipple
              disabled={isAnyLoading}
            />
          )}
          <ListItemButton
            onClick={() => {
              handleOnRowClick({ preventClose: true });
            }}
            dense
            sx={{
              flexGrow: 0,
              px: 0.5,
              borderRadius: '8px',
            }}
            disabled={isAnyLoading}
          >
            <Typography
              variant={'subtitle2'}
              {...(currentlySelectedLocation?.ancestors_uuids?.includes(location.uuid) &&
              !location.is_active
                ? { color: 'primary.light' }
                : currentlySelectedLocation?.ancestors_uuids?.includes(location.uuid)
                ? {
                    color: 'primary.main',
                  }
                : !location.is_active && { color: 'textSecondary' })}
              sx={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              {location.name}
              {!location.is_active && (
                // <Tooltip title={'Inactive'} disableInteractive arrow placement={'top'}>
                //   <WarningAmberIcon color={'error'} />
                // </Tooltip>
                <Typography color={'error'} variant={'subtitle2'}>
                  (inactive)
                </Typography>
              )}
            </Typography>
          </ListItemButton>
          {location.has_subplaces ? (
            Object.values(location.subplaces).length > 0 && subLocationsVisible ? (
              <ChevronDown
                onClick={() => {
                  if (isAnyLoading) return;
                  handleOnRowClick({
                    preventGetLocation: true,
                    preventGetItems: true,
                  });
                }}
                sx={{
                  cursor: !isAnyLoading && 'pointer',
                  color: isAnyLoading && 'action.disabled',
                  '&:hover': {
                    color: !isAnyLoading && 'primary.main',
                  },
                }}
              />
            ) : (
              <ChevronRight
                onClick={() => {
                  if (isAnyLoading) return;
                  handleOnRowClick({
                    preventGetLocation: true,
                    preventGetItems: true,
                  });
                }}
                sx={{
                  cursor: !isAnyLoading && 'pointer',
                  color: isAnyLoading && 'action.disabled',
                  '&:hover': {
                    color: !isAnyLoading && 'primary.main',
                  },
                }}
              />
            )
          ) : (
            <></>
          )}
        </Box>
        <Box
          sx={{
            display: isHovered ? 'flex' : 'none',
            // display: 'flex',
            gap: '4px',
            alignItems: 'center',
          }}
        >
          {!hideActions && (
            <>
              <LocationsActionModal
                type={'location_add'}
                location={location}
                disabled={location?.ancestors_uuids?.length > 3 || isAnyLoading}
                onClick={() => {
                  setIsHovered(false);
                }}
              />
              <LocationsActionModal
                type={'location_edit'}
                location={location}
                isRootLocation={location.ancestors_uuids.length === 1}
                disabled={isAnyLoading}
                onClick={() => {
                  setIsHovered(false);
                }}
                handleGetOrganizations={handleGetOrganizations}
              />
              <LocationsActionModal
                type={'location_delete'}
                location={location}
                disabled={isAnyLoading}
                onClick={() => {
                  setIsHovered(false);
                }}
              />
            </>
          )}
        </Box>
      </ListItem>
      {Object.values(location.subplaces)?.length > 0 && (
        <Collapse in={subLocationsVisible}>
          {Object.values(location.subplaces)?.map((subLocation) => (
            <LocationSidebarRow
              key={subLocation.uuid}
              location={subLocation}
              handleGetLocations={handleGetLocations}
              isSubLocation={true}
              handleGetLocationDetails={handleGetLocationDetails}
              showCheckbox={showCheckbox}
              currentLocation={currentLocation}
              handleSetQrCodesUrlsToPrint={handleSetQrCodesUrlsToPrint}
              qrCodesToPrint={qrCodesToPrint}
              isAnyLoading={isAnyLoading}
              currentlySelectedLocation={currentlySelectedLocation}
              handleGetOrganizations={handleGetOrganizations}
              hideActions={!subLocationsVisible}
            />
          ))}
        </Collapse>
      )}
    </Fragment>
  );
};

export default LocationSidebarRow;
