import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import * as serviceWorker from '../serviceWorkerRegistration';
import SnackbarContent from '@mui/material/SnackbarContent';
import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components';

const SnackbarWrapper = styled.div`
  .MuiSnackbarContent-root {
    position: fixed;
    top: 58px;
    left: 0;
    right: 0;
  }
`;

const StyledButton = styled(Button)`
  span {
    font-size: 1.19rem;
  }
`;

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 0,
    zIndex: 9999,
    fontSize: '1.4rem',
  },
});

const config = {
  text: 'Dostępna nowa wersja aplikacji',
  buttonText: 'Aktualizuj',
};

const SWSnackbar = (props) => {
  const [isNewSW, setIsNewSW] = useState({
    newVersionAvailable: false,
    waitingWorker: {},
  });

  const classes = useStyles();

  const onServiceWorkerUpdate = (registration) => {
    console.log('SW should be updated!');
    setIsNewSW({
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true,
    });
  };

  const updateServiceWorker = () => {
    const { waitingWorker } = isNewSW;
    waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setIsNewSW({ newVersionAvailable: false });
    window.location.reload();
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
    }
  }, []);

  return (
    <SnackbarWrapper>
      {
        isNewSW.newVersionAvailable && updateServiceWorker()
        // <SnackbarContent
        //   classes={{ root: classes.root }}
        //   message={config.text}
        //   action={
        //     <StyledButton variant="contained" onClick={updateServiceWorker}>
        //       {config.buttonText}
        //     </StyledButton>
        //   }
        // />
      }
    </SnackbarWrapper>
  );
};

export default SWSnackbar;
