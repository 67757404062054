import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from '../slices/calendar';
import { reducer as chatReducer } from '../slices/chat';
import { reducer as kanbanReducer } from '../slices/kanban';
import { reducer as mailReducer } from '../slices/mail';
import { reducer as userReducer } from '../slices/user';
import { reducer as customersReducer } from '../slices/customers';
import { reducer as organizationsReducer } from '../slices/organizations';
import { reducer as vendorsReducer } from '../slices/vendors';
import { reducer as messagingReducer } from '../slices/messaging';
import { reducer as fileUploadReducer } from '../slices/fileUpload';
import { reducer as notificationsReducer } from '../slices/notifications';
import { reducer as analyticsReducer } from '../slices/analytics';
import { reducer as configReducer } from '../slices/config';
import { reducer as locationsReducer } from '../slices/locations';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  user: userReducer,
  customers: customersReducer,
  organizations: organizationsReducer,
  vendors: vendorsReducer,
  messaging: messagingReducer,
  fileUpload: fileUploadReducer,
  notifications: notificationsReducer,
  analytics: analyticsReducer,
  config: configReducer,
  locations: locationsReducer,
});

export default rootReducer;
