import { MessagingParticipant } from '../../types/messaging_participant';
import { MessagingThread } from '../../types/messaging_thread';
import _, { reverse } from 'lodash';
import { MessagingMessage } from '../../types/messaging_message';
import { MessagingSelectedMessagesType } from '../../types/messaging-case-management/messaging_selected_messages';

export const extractParticipants = (
  _participants: Array<MessagingParticipant>,
  thread: MessagingThread
) => {
  if (
    thread?.owner?.uuid &&
    !_participants.find((participant) => participant.uuid === thread?.owner?.uuid)
  ) {
    let allParticipants = [..._participants, thread?.owner];
    return _.sortBy(allParticipants, ['last_name']);
  } else return _participants;
};

export const selectMessagesForExport = (params: {
  selectedMessages: MessagingSelectedMessagesType;
  message: MessagingMessage;
}): MessagingSelectedMessagesType => {
  const { selectedMessages: _selectedMessages, message } = params;

  let selectedMessagesArray: Array<MessagingMessage> = Object.values(_selectedMessages);
  let wasAssigned = false;

  if (selectedMessagesArray.includes(message))
    selectedMessagesArray = selectedMessagesArray.map((_message) =>
      _message?.uuid !== message?.uuid ? _message : null
    );
  else
    selectedMessagesArray = selectedMessagesArray.map((_message, idx) => {
      if (wasAssigned) return _message;
      if (
        _message === null ||
        (Date.parse(selectedMessagesArray[1]?.created) > Date.parse(message.created) &&
          idx === 0) ||
        (Date.parse(selectedMessagesArray[0]?.created) < Date.parse(message.created) && idx === 1)
      ) {
        wasAssigned = true;
        return message;
      }
      return _message;
    });

  if (Date.parse(selectedMessagesArray[1]?.created) < Date.parse(selectedMessagesArray[0]?.created))
    selectedMessagesArray = reverse(selectedMessagesArray);

  return {
    firstMessage: selectedMessagesArray[0],
    secondMessage: selectedMessagesArray[1],
  };
};

export const getFileNameFromLink = (url: string) => {
  if (!url) return;
  const urlParts = url.split('/');
  return urlParts[urlParts.length - 1];
};
