import axios from 'axios';
// import { getSessionTokens, saveSessionTokens } from './';
import { getLoginPath } from '../routesPaths';

const baseUrl = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = baseUrl;

export const isIgnore401 = () => {
  const parameter = process.env.REACT_APP_IGNORE_401;
  return parameter && parameter === 'true';
};

export default class NetworkService {
  static interceptor401;

  static setupDefaultHeaders(token) {
    if (token && typeof token === 'string') {
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    }
  }

  static resetDefaultHeaders(token) {
    axios.defaults.headers.common = {};
  }

  // static syncHeaderTokenWithStorage() {
  //   const token = getSessionTokens()?.sessionToken;
  //   if (token && token.length > 1) this.setupDefaultHeaders(token);
  // }

  // static async refreshAccessToken({ refreshToken }) {
  //   return await axios.post(`${baseUrl}Users/jwt-token/refresh/`, {
  //     refresh: refreshToken,
  //   });
  // }

  static setupInterceptors({ navigate }) {
    // Add a response interceptor
    this.interceptor401 = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        let originalRequest = error.config;

        if (+error.response.status === 401 && !isIgnore401() && !originalRequest._retry) {
          originalRequest._retry = true;
          // const oldRefreshToken = store.auth?.refreshToken || getSessionTokens().refreshToken;
          axios.interceptors.request.eject(this.interceptor401);
          navigate(getLoginPath());
        }
        return Promise.reject(error);
      }
    );
  }
}
