import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import objFromArray from '../utils/objFromArray';
import NetworkService from '../utils/network.service';
import axios from 'axios';
import ApiRequest from '../utils/ApiRequest';
import { mapResponseToAbilities } from '../utils/ability';
import { RequestStatus } from '../utils/RequestStatus';
import toast from 'react-hot-toast';
import { clearNotificationsData } from './notifications';
import {
  clearOrganizationsList,
  clearOrganizationAdmins,
  clearOrganizationInvoices,
  clearOrganizationCustomers,
  clearOrganizationTechnicians,
} from './organizations';
import { clearThreadMessages, clearThreadsList } from './messaging';
import { clearCustomers } from './customers';
import { clearVendors } from './vendors';
import { clearAnalytics } from './analytics';

interface UserState {
  isAuthenticated: boolean | null;
  isInitialized: boolean | null;
  user: any;
  patchFetchStatus: string | null;
  patchValidationErrors: any;
  fetchStatus: string | null;
  roles: [];
  permissions: [];
  token: string | null;
  resetPassword: {
    fetchStatus: string | null;
    postPasswordFetchStatus: string | null;
    postValidationErrors: any;
  };
}

const initialState: UserState = {
  isAuthenticated: false,
  isInitialized: false,
  user: {},
  patchFetchStatus: null,
  patchValidationErrors: null,
  fetchStatus: RequestStatus.status.NULL,
  roles: [],
  permissions: [],
  token: window.localStorage.getItem('accessToken') || null,
  resetPassword: {
    fetchStatus: RequestStatus.status.NULL,
    postPasswordFetchStatus: RequestStatus.status.NULL,
    postValidationErrors: null,
  },
};

export const postResetPassword: any = createAsyncThunk(
  'user/postResetPassword',
  async (data: { email: string }, thunkAPI) => {
    const { email } = data;
    return ApiRequest.post(`/web/auth/password/reset/`, {})({ email }, thunkAPI);
  }
);

export const postSetNewPassword: any = createAsyncThunk(
  'user/postSetNewPassword',
  async (
    data: {
      email: string;
      uid: string;
      token: string;
      new_password1: string;
      new_password2: string;
    },
    thunkAPI
  ) => {
    return ApiRequest.post(`/web/auth/password/reset/confirm/`, {})(data, thunkAPI);
  }
);

export const getUserDetails: any = createAsyncThunk(
  'user/getUserDetails',
  async (data: {}, thunkAPI) => {
    return ApiRequest.get(`/web/auth/user/`, {})(data, thunkAPI);
  }
);

export const patchUserDetails: any = createAsyncThunk(
  'user/patchUserDetails',
  async (
    data: {
      username: string;
      first_name: string;
      last_name: string;
      profile_image: string;
      notification_new_message_sound: string;
      phone_number: string;
    },
    thunkAPI
  ) => {
    return ApiRequest.patch(`/web/auth/user/`, {})(data, thunkAPI);
  }
);

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state: UserState, action: PayloadAction<any>): void {
      const { user, roles, permissions, token } = action.payload;
      state.isAuthenticated = true;
      state.user = user;
      state.roles = roles;
      state.permissions = permissions;
      state.token = token;
    },
    logout(state: UserState, action: PayloadAction<any>): void {
      state.isAuthenticated = false;
      state.user = {};
      state.roles = [];
      state.permissions = [];
    },
    initialize(state: UserState, action: PayloadAction<any>): void {
      const { isAuthenticated, user, roles, permissions } = action.payload;
      state.isAuthenticated = isAuthenticated;
      state.user = user;
      state.isInitialized = true;
      state.roles = roles;
      state.permissions = permissions;
    },
    getUserDetails(state: UserState, action: PayloadAction<any>): void {
      state.user = action.payload;
    },
    clearPatchValidationErrorsAndFetchStatuses(state: UserState, action: PayloadAction<any>): void {
      state.patchFetchStatus = RequestStatus.status.NULL;
      state.patchValidationErrors = null;
    },
  },
  extraReducers: {
    [postResetPassword.pending]: (state, action) => {
      state.resetPassword.fetchStatus = RequestStatus.status.FETCHING;
    },
    [postResetPassword.fulfilled]: (state, action) => {
      state.resetPassword.fetchStatus = RequestStatus.status.DONE;
      toast.success('Password reset successfully. You will receive email with instructions soon!');
    },
    [postResetPassword.rejected]: (state, action) => {
      state.resetPassword.postPasswordFetchStatus = RequestStatus.status.ERROR;
    },
    [postSetNewPassword.pending]: (state, action) => {
      state.resetPassword.postPasswordFetchStatus = RequestStatus.status.FETCHING;
    },
    [postSetNewPassword.fulfilled]: (state, action) => {
      state.resetPassword.postPasswordFetchStatus = RequestStatus.status.DONE;
      state.resetPassword.postValidationErrors = null;
    },
    [postSetNewPassword.rejected]: (state, action) => {
      state.resetPassword.fetchStatus = RequestStatus.status.ERROR;
      state.resetPassword.postValidationErrors = action.payload;
    },
    [getUserDetails.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getUserDetails.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [getUserDetails.rejected]: (state, action) => {
      state.user.fetchStatus = RequestStatus.status.ERROR;
    },
    [patchUserDetails.pending]: (state, action) => {
      state.user.patchFetchStatus = RequestStatus.status.FETCHING;
    },
    [patchUserDetails.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.patchValidationErrors = null;
      state.patchFetchStatus = RequestStatus.status.DONE;
    },
    [patchUserDetails.rejected]: (state, action) => {
      state.patchFetchStatus = RequestStatus.status.ERROR;
      state.patchValidationErrors = action.payload;
    },
  },
});

export const { reducer } = slice;

export const initialize =
  ({ navigate }): any =>
  async (dispatch): Promise<void> => {
    const accessToken = window.localStorage.getItem('accessToken');
    try {
      if (accessToken) {
        NetworkService.setupDefaultHeaders(accessToken);
        NetworkService.setupInterceptors({ navigate });
        const userResponse = await axios.get('/web/auth/user/', {});
        const { roles, permissions } = mapResponseToAbilities(userResponse);
        dispatch(
          slice.actions.initialize({
            isAuthenticated: true,
            user: userResponse.data,
            roles,
            permissions,
          })
        );
      } else {
        dispatch(slice.actions.initialize({ isAuthenticated: false, user: null }));
      }
    } catch (err) {
      console.error(err);
      dispatch(slice.actions.initialize({ isAuthenticated: false, user: null }));
    }
  };

export const login =
  (email: string, password: string, navigate: any): any =>
  async (dispatch): Promise<void> => {
    const response = await axios.post(
      '/web/auth/login/',
      { email, password },
      {
        headers: {
          Authorization: '',
        },
      }
    );
    const accessToken = response.data?.key;
    localStorage.setItem('accessToken', accessToken);
    NetworkService.setupDefaultHeaders(accessToken);
    NetworkService.setupInterceptors({ navigate });
    const userResponse = await axios.get('/web/auth/user/', {});
    const { roles, permissions } = mapResponseToAbilities(userResponse);
    dispatch(
      slice.actions.login({ user: userResponse.data, roles, permissions, token: accessToken })
    );
  };

export const logout =
  (): any =>
  async (dispatch): Promise<void> => {
    const fcmDeviceId = localStorage.getItem('fcmDeviceId');
    const response = await axios.post(
      '/web/auth/logout/',
      {},
      {
        headers: {
          'X-DEVICE-INSTANCE-ID': fcmDeviceId,
        },
      }
    );
    localStorage.removeItem('fcmDeviceId');
    localStorage.removeItem('accessToken');
    NetworkService.resetDefaultHeaders();
    dispatch(slice.actions.logout({}));
    dispatch(clearNotificationsData({}));
    dispatch(clearOrganizationsList());
    dispatch(clearOrganizationAdmins());
    dispatch(clearOrganizationCustomers());
    dispatch(clearOrganizationInvoices());
    dispatch(clearOrganizationTechnicians());
    dispatch(clearThreadsList({}));
    dispatch(clearThreadMessages({}));
    dispatch(clearCustomers());
    dispatch(clearVendors());
    dispatch(clearAnalytics());
  };

export const { clearPatchValidationErrorsAndFetchStatuses } = slice.actions;
export default slice;
