import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { getLandingPath, getLoginPath } from '../routesPaths';
import { getDefinedRules } from '../utils/ability';
import { RootState, useSelector } from '../store';
import { ability, permissions } from '../utils/Can';
import { routes } from '../routes';

// works for a 1 level nesting
const checkPermissionsToRoute = (pathName, allRoutes) => {
  if (!allRoutes || !allRoutes.length || !allRoutes[0].children) return true;
  const matchedRoute = allRoutes[0].children.find((routeConfig) => {
    return routeConfig.path.split('/')[1] === pathName.split('/')[1];
  });
  if (matchedRoute?.permissionSubject) {
    // console.warn(
    //   matchedRoute?.permissionSubject,
    //   ability.can(permissions.READ, matchedRoute.permissionSubject?.toUpperCase())
    // );
    return matchedRoute.permissionSubject
      ? ability.can(permissions.READ, matchedRoute.permissionSubject?.toUpperCase())
      : true;
  } else {
    return true;
  }
};

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const { roles, permissions, user } = useSelector((state: RootState) => state.user);

  const arePermissionsFullyLoaded = () => {
    return (
      getDefinedRules() &&
      getDefinedRules().length > 0 &&
      roles.length > 0 &&
      permissions.length > 0
    );
  };

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    navigate(getLoginPath());
    return <div></div>;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  // I don't see any reason to use it
  // if (requestedLocation && location.pathname !== requestedLocation) {
  //   setRequestedLocation(null);
  //   return <Navigate to={requestedLocation} />;
  // }
  // <Navigate to={getLandingPath()} />

  return arePermissionsFullyLoaded() && checkPermissionsToRoute(location.pathname, routes) ? (
    <>{children}</>
  ) : arePermissionsFullyLoaded() && !checkPermissionsToRoute(location.pathname, routes) ? (
    <Navigate to={getLandingPath()} />
  ) : (
    <>.</>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
