let lastBlinking = null;
let currentBlinking = null;
let originalTitle = null;
let wasBlinkingInitialized = false;

export const triggerBlinkingOnSWBroadcast = () => {
  if (wasBlinkingInitialized) return;
  wasBlinkingInitialized = true;
  if (typeof BroadcastChannel === 'undefined') return;
  const broadcast = new BroadcastChannel('fcm-channel');
  broadcast.onmessage = (event) => {
    setBlinkingTitle('🔴 New notification');
    console.log('FROM SW', event?.data);
  };
};

export const setBlinkingTitle = (text: string) => {
  clearBlinking();
  originalTitle = document.title;

  setBlinkingText({ from: originalTitle, to: text });

  // clear on focus after 10 s
  lastBlinking = setTimeout(() => {
    window.addEventListener('focus', () => {
      document.title = originalTitle;
      clearBlinking();
    });
    window.addEventListener('click', () => {
      document.title = originalTitle;
      clearBlinking();
    });
  }, 10 * 1000);
};

const setBlinkingText = ({ from, to }: { from: string; to: string }) => {
  document.title = to;
  currentBlinking = setTimeout(() => {
    setBlinkingText({ from: to, to: from });
  }, 1000);
};

const clearBlinking = () => {
  clearTimeout(lastBlinking);
  clearTimeout(currentBlinking);
};
