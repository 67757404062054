import React, { FC, useEffect, useState } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import useModal, { SimpleActions } from '../../../hooks/useModal';
import EditIcon from '@mui/icons-material/Edit';
import {
  addNewItem,
  addNewLocation,
  deleteItem,
  deleteLocation,
  editItem,
  editLocation,
  getItems,
  getLocationDetails,
  getLocations,
  ItemType,
  LocationType,
  removeLocation,
  resetAddNewItem,
  resetAddNewLocation,
  resetDeleteItem,
  resetDeleteLocation,
  resetEditItem,
  resetEditLocation,
  resetLocationDetails,
  setCurrentLocation,
  updateLocation,
} from '../../../slices/locations';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import { RootState, useDispatch, useSelector } from '../../../store';
import { useForm } from 'react-hook-form';
import { Input } from '../../../components/widgets/inputs/Input';
import { RequestStatus } from '../../../utils/RequestStatus';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { useSetFormErrors } from '../../../hooks/useSetFormError';

interface LocationsActionModalProps {
  location?: LocationType;
  isRootLocation?: boolean;
  item?: ItemType;
  type:
    | 'location_add'
    | 'location_edit'
    | 'location_delete'
    | 'item_add'
    | 'item_edit'
    | 'item_delete';
  buttonType?: 'regular' | 'icon';
  buttonSx?: any;
  disabled?: boolean;
  onClick?: any;
  handleGetOrganizations?: (data?: any) => void;
}

const LocationsActionModal: FC<LocationsActionModalProps> = (props) => {
  const {
    location = null,
    isRootLocation = false,
    item = null,
    type,
    buttonType = 'icon',
    buttonSx = {},
    disabled = false,
    onClick = () => {},
    handleGetOrganizations = null,
  } = props;

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  let [query, setQuery] = useState<string>(searchParams.get('search'));
  const page = +searchParams.get('page');
  const limit = +searchParams.get('limit');
  const organization_uuid = searchParams.get('organization_uuid');

  const {
    locations: {
      newLocation: { postFetchStatus: addNewLocationFetchStatus, errors: newLocationErrors },
      editLocation: {
        fetchStatus: editLocationFetchStatus,
        name: editLocationName,
        is_active: editLocationIsActive,
        errors: editLocationErrors,
      },
      deleteLocation: { fetchStatus: deleteLocationFetchStatus, errors: deleteLocationErrors },
    },
    items: {
      newItem: { postFetchStatus: addNewItemFetchStatus, errors: newItemErrors },
      editItem: { fetchStatus: editItemFetchStatus, errors: editItemErrors },
      deleteItem: { fetchStatus: deleteItemFetchStatus, errors: deleteItemErrors },
    },
    locationDetails,
  } = useSelector((state: RootState) => state.locations);

  const {
    list: { items: organizationsList },
  } = useSelector((state: RootState) => state.organizations);

  const { Component: Modal, ...modal } = useModal();

  const {
    handleSubmit: newLocationHandleSubmit,
    control: newLocationControl,
    reset: newLocationReset,
    setValue: newLocationSetValue,
  } = useForm({
    defaultValues: {
      name: '',
    },
  });

  const {
    handleSubmit: editLocationHandleSubmit,
    control: editLocationControl,
    reset: editLocationReset,
    setValue: editLocationSetValue,
  } = useForm({
    defaultValues: {
      name: location?.name ?? '',
    },
  });

  const {
    handleSubmit: deleteLocationHandleSubmit,
    control: deleteLocationControl,
    reset: deleteLocationReset,
    setValue: deleteLocationSetValue,
    formState: { errors: deleteLocationFormErrors },
    setError: deleteLocationSetError,
    clearErrors: deleteLocationClearErrors,
  } = useForm({
    defaultValues: {
      password: '',
    },
  });

  const {
    handleSubmit: newItemHandleSubmit,
    control: newItemControl,
    reset: newItemReset,
    setValue: newItemSetValue,
  } = useForm({
    defaultValues: {
      name: '',
    },
  });

  const {
    handleSubmit: editItemHandleSubmit,
    control: editItemControl,
    reset: editItemReset,
    setValue: editItemSetValue,
  } = useForm({
    defaultValues: {
      name: item?.name ?? '',
    },
  });

  const [showToast, setShowToast] = useState<boolean>(false);
  const [locationToUpdate, setLocationToUpdate] = useState<LocationType>(null);

  const submitNewLocation = (values) => {
    setShowToast(true);
    setLocationToUpdate(location);
    const data = { ...values, parent_uuid: location.uuid };
    dispatch(addNewLocation(data));
  };

  const submitEditLocation = (values) => {
    setShowToast(true);
    setLocationToUpdate(location);
    const data = { ...values, uuid: location.uuid };
    dispatch(editLocation(data));
  };

  const handleDeleteLocation = (values) => {
    deleteLocationClearErrors();
    setShowToast(true);
    dispatch(deleteLocation({ uuid: location.uuid, password: values.password }));
  };

  const submitNewItem = (values) => {
    setShowToast(true);
    const data = { ...values, location_uuid: location.uuid };
    dispatch(addNewItem(data));
  };

  const submitEditItem = (values) => {
    setShowToast(true);
    const data = { ...values, uuid: item.uuid };
    dispatch(editItem(data));
  };

  const handleDeleteItem = () => {
    setShowToast(true);
    dispatch(deleteItem({ uuid: item.uuid }));
  };

  const checkIfMaxActiveBuildingsReached = (): boolean => {
    console.log('asdasd');
    const organization = organizationsList.find(
      (organization) => organization.uuid === organization_uuid
    );
    console.log(organization.remaining_buildings === 0, !location.is_active);
    if (!organization) return true;
    if (organization.max_active_buildings === 0) return false;
    //unlimited buildings case
    else return organization.remaining_buildings === 0 && !location.is_active; //prevent setting inactive building as active if limit was reached
  };

  const content = {
    location_add: {
      modalType: 'location',
      actionType: 'add',
      modalIcon: <DomainAddIcon color={'primary'} sx={{ height: '64px', width: '64px' }} />,
      title: 'Add location',
      content: (
        <form id={type} onSubmit={newLocationHandleSubmit(submitNewLocation)}>
          <Input
            control={newLocationControl}
            isEditMode
            label={'Location name'}
            shrinkLabel
            name={'name'}
            sx={{
              minWidth: '450px',
              mt: 2,
              mb: 1,
            }}
          />
        </form>
      ),
      actions: {
        buttonIcon: <AddIcon fontSize={'small'} />,
        onConfirm: null,
        isDelete: false,
        confirmLabel: 'Add',
      },
    },
    location_edit: {
      modalType: 'location',
      actionType: 'edit',
      modalIcon: <EditIcon color={'primary'} sx={{ height: '64px', width: '64px' }} />,
      title: 'Edit location',
      content: (
        <form id={type} onSubmit={editLocationHandleSubmit(submitEditLocation)}>
          <Input
            control={editLocationControl}
            isEditMode
            label={'Location name'}
            shrinkLabel
            name={'name'}
            defaultValue={location?.name}
            sx={{
              minWidth: '450px',
              mt: 2,
              mb: 1,
            }}
          />
          {isRootLocation && (
            <Input
              isEditMode
              control={editLocationControl}
              type={'checkbox'}
              name={'is_active'}
              defaultValue={location.is_active}
              label={'Is active?'}
              disabled={
                organizationsList.find((organization) => organization.uuid === organization_uuid)
                  ?.max_active_buildings === 0
                  ? false
                  : organizationsList.find(
                      (organization) => organization.uuid === organization_uuid
                    )?.remaining_buildings === 0 && !location.is_active
              }
            />
          )}
        </form>
      ),
      actions: {
        buttonIcon: <EditIcon fontSize={'small'} />,
        onConfirm: null,
        isDelete: false,
        confirmLabel: 'Save',
      },
    },
    location_delete: {
      modalType: 'location',
      actionType: 'delete',
      modalIcon: <DeleteIcon color={'error'} sx={{ height: '64px', width: '64px' }} />,
      title: 'Delete location',
      content: (
        <form id={type} onSubmit={deleteLocationHandleSubmit(handleDeleteLocation)}>
          <Typography variant={'subtitle1'} sx={{ mt: 2, mb: 1, maxWidth: '500px' }}>
            {/*Are you sure you want to delete location "{location?.name}"?*/}
            Please enter your Plunjr password below to delete this item. This will permanently
            delete <b>{location?.name}</b> and all associated threads.
          </Typography>
          <Input
            control={deleteLocationControl}
            isEditMode
            label={'Password'}
            shrinkLabel
            name={'password'}
            type={'password'}
            sx={{
              minWidth: '450px',
              mt: 2,
              mb: 1,
            }}
            validationMessage={deleteLocationFormErrors?.password?.message}
          />
        </form>
      ),
      actions: {
        buttonIcon: <DeleteIcon fontSize={'small'} />,
        // onConfirm: () => {
        //   handleDeleteLocation();
        // },
        onConfirm: null,
        isDelete: true,
        confirmLabel: 'Delete',
      },
    },
    item_add: {
      modalType: 'item',
      actionType: 'add',
      modalIcon: <AddCircleIcon color={'primary'} sx={{ height: '64px', width: '64px' }} />,
      title: 'Add equipment',
      content: (
        <form id={type} onSubmit={newItemHandleSubmit(submitNewItem)}>
          <Input
            control={newItemControl}
            isEditMode
            label={'Item name'}
            shrinkLabel
            name={'name'}
            sx={{
              minWidth: '450px',
              mt: 2,
              mb: 1,
            }}
          />
        </form>
      ),
      actions: {
        buttonIcon: <AddIcon fontSize={'small'} />,
        onConfirm: null,
        isDelete: false,
        confirmLabel: 'Add',
      },
    },
    item_edit: {
      modalType: 'item',
      actionType: 'edit',
      modalIcon: <EditIcon color={'primary'} sx={{ height: '64px', width: '64px' }} />,
      title: 'Edit item',
      content: (
        <form id={type} onSubmit={editItemHandleSubmit(submitEditItem)}>
          <Input
            control={editItemControl}
            isEditMode
            label={'Item name'}
            shrinkLabel
            name={'name'}
            defaultValue={item?.name}
            sx={{
              minWidth: '450px',
              mt: 2,
              mb: 1,
            }}
          />
        </form>
      ),
      actions: {
        buttonIcon: <EditIcon fontSize={'small'} />,
        onConfirm: null,
        isDelete: false,
        confirmLabel: 'Save',
      },
    },
    item_delete: {
      modalType: 'item',
      actionType: 'delete',
      modalIcon: <DeleteIcon color={'error'} sx={{ height: '64px', width: '64px' }} />,
      title: 'Delete item',
      content: (
        <Typography variant={'subtitle1'} sx={{ mt: 2, mb: 1 }}>
          Are you sure you want to delete item "{item?.name}"?
        </Typography>
      ),
      actions: {
        buttonIcon: <DeleteIcon fontSize={'small'} />,
        onConfirm: () => {
          handleDeleteItem();
        },
        isDelete: true,
        confirmLabel: 'Delete',
      },
    },
  };

  const getErrorMessage = (errors: any, defaultMessage: string): string => {
    return typeof errors?.message === 'string' ? errors.message : defaultMessage;
  };

  const onModalTriggerButtonClick = (): void => {
    if (type === 'location_edit' || type === 'item_edit') {
      location !== null && editLocationSetValue('name', location?.name);
      item !== null && editItemSetValue('name', item?.name);
    }
    if (type === 'location_add' || type === 'item_add') {
      newLocationSetValue('name', '');
      newItemSetValue('name', '');
    }
    onClick?.();
    modal.open();
  };

  useEffect(() => {
    if (RequestStatus.isDone(addNewLocationFetchStatus)) {
      if (showToast) {
        toast.success('New location added');
        setShowToast(false);
      }
      if (locationToUpdate) {
        dispatch(setCurrentLocation(locationToUpdate.ancestors_uuids));
        dispatch(
          updateLocation({
            location: locationToUpdate,
            data: {
              has_subplaces: true,
            },
          })
        );
        dispatch(
          getLocations({
            parent_uuid: location.uuid,
            page_size: 99999,
            page: 1,
            organization_uuid: searchParams.get('organization_uuid'),
          })
        );
        setLocationToUpdate(null);
        newLocationReset();
        dispatch(resetAddNewLocation(null));
        modal.close();
      }
    } else if (RequestStatus.isError(addNewLocationFetchStatus)) {
      if (showToast) {
        toast.error(getErrorMessage(newLocationErrors, `Couldn't add new location`));
        setShowToast(false);
        setLocationToUpdate(null);
        // newLocationReset();
        dispatch(resetAddNewLocation(null));
        // modal.close();
      }
    }
  }, [addNewLocationFetchStatus, locationToUpdate, showToast]);

  useEffect(() => {
    if (RequestStatus.isDone(editLocationFetchStatus)) {
      if (showToast) {
        toast.success('Location edited');
        setShowToast(false);
      }
      if (locationToUpdate) {
        // dispatch(
        //   updateLocation({
        //     location: locationToUpdate,
        //     data: {
        //       name: editLocationName,
        //       is_active: editLocationIsActive,
        //     },
        //   })
        // );
        handleGetOrganizations !== null && isRootLocation && handleGetOrganizations();
        dispatch(resetEditLocation(null));
        if (locationDetails.location?.uuid === locationToUpdate.uuid) {
          dispatch(
            getLocationDetails({
              uuid: location.uuid,
              organization_uuid: searchParams.get('organization_uuid'),
            })
          );
        }
        setLocationToUpdate(null);
        modal.close();
      }
    } else if (RequestStatus.isError(editLocationFetchStatus)) {
      if (showToast) {
        toast.error(getErrorMessage(editLocationErrors, `Couldn't edit location`));
        // editLocationSetValue('name', location?.name);
        setShowToast(false);
        dispatch(resetEditLocation(null));
        // setLocationToUpdate(null);
        // modal.close();
      }
    }
  }, [editLocationFetchStatus, locationToUpdate, showToast]);

  useEffect(() => {
    if (RequestStatus.isDone(deleteLocationFetchStatus)) {
      if (showToast) {
        toast.success('Location removed');
        setShowToast(false);
        if (locationDetails.location?.ancestors_uuids?.includes(location.uuid)) {
          const parent_uuid =
            location?.ancestors_uuids?.length < 2
              ? null
              : location.ancestors_uuids[location.ancestors_uuids.length - 2];
          if (parent_uuid !== null) {
            dispatch(
              getLocationDetails({
                uuid: parent_uuid,
                organization_uuid: searchParams.get('organization_uuid'),
              })
            );
            dispatch(
              getItems({
                page_size: limit,
                page: page + 1,
                search: query,
                location_uuid: parent_uuid,
                organization_uuid: searchParams.get('organization_uuid'),
              })
            );
          } else {
            dispatch(
              getItems({
                page_size: limit,
                page: page + 1,
                search: query,
                organization_uuid: searchParams.get('organization_uuid'),
              })
            );
            dispatch(resetLocationDetails(null));
          }
        } else {
          dispatch(
            getItems({
              page_size: limit,
              page: page + 1,
              search: query,
              ...(locationDetails.location && { location_uuid: locationDetails.location.uuid }),
              organization_uuid: searchParams.get('organization_uuid'),
            })
          );
        }
        deleteLocationClearErrors();
        dispatch(removeLocation({ location }));
        dispatch(resetDeleteLocation(null));
        modal.close();
      }
    } else if (RequestStatus.isError(deleteLocationFetchStatus)) {
      if (showToast) {
        toast.error(getErrorMessage(deleteLocationErrors, `Couldn't remove location`));
        setShowToast(false);
        dispatch(resetDeleteLocation(null));
        // modal.close();
      }
    }
  }, [deleteLocationFetchStatus, showToast]);

  useEffect(() => {
    if (RequestStatus.isDone(addNewItemFetchStatus)) {
      if (showToast) {
        toast.success('New item added. Generating QR code might take a while.');
        setShowToast(false);
        dispatch(resetAddNewItem(null));
        newItemReset();
        modal.close();
      }
    } else if (RequestStatus.isError(addNewItemFetchStatus)) {
      if (showToast) {
        toast.error(getErrorMessage(newItemErrors, `Couldn't add new item`));
        setShowToast(false);
        dispatch(resetAddNewItem(null));
        // newItemReset();
        // modal.close();
      }
    }
  }, [addNewItemFetchStatus, showToast]);

  useEffect(() => {
    if (RequestStatus.isDone(editItemFetchStatus)) {
      if (showToast) {
        toast.success('Item edited');
        setShowToast(false);
        dispatch(resetEditItem(null));
        modal.close();
      }
    } else if (RequestStatus.isError(editItemFetchStatus)) {
      if (showToast) {
        toast.error(getErrorMessage(editItemErrors, `Couldn't edit item`));
        editItemReset();
        setShowToast(false);
        dispatch(resetEditItem(null));
        modal.close();
      }
    }
  }, [editItemFetchStatus, showToast]);

  useEffect(() => {
    if (RequestStatus.isDone(deleteItemFetchStatus)) {
      if (showToast) {
        toast.success('Item removed');
        setShowToast(false);
        dispatch(resetDeleteItem(null));
        modal.close();
      }
    } else if (RequestStatus.isError(deleteItemFetchStatus)) {
      if (showToast) {
        toast.error(getErrorMessage(deleteItemErrors, `Couldn't remove item`));
        setShowToast(false);
        dispatch(resetDeleteItem(null));
        modal.close();
      }
    }
  }, [deleteItemFetchStatus, showToast]);

  useSetFormErrors({ errors: deleteLocationErrors, setError: deleteLocationSetError });

  useEffect(() => {
    if (!modal.isOpen) {
      setTimeout(() => {
        deleteLocationClearErrors();
      }, 150);
      deleteLocationSetValue('password', '');
    }
  }, [modal]);

  return (
    <>
      <Modal
        {...modal.props}
        sx={{
          width: 'fit-content',
          height: 'fit-content',
        }}
      >
        {!!content[type] && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {content[type].modalIcon}
              <Typography variant={'h6'}>{content[type].title}</Typography>
              {content[type].content}
            </Box>
            <SimpleActions
              sx={{ mt: 2 }}
              loading={
                RequestStatus.isFetching(addNewLocationFetchStatus) ||
                RequestStatus.isFetching(editLocationFetchStatus) ||
                RequestStatus.isFetching(deleteLocationFetchStatus) ||
                RequestStatus.isFetching(addNewItemFetchStatus) ||
                RequestStatus.isFetching(editItemFetchStatus) ||
                RequestStatus.isFetching(deleteItemFetchStatus)
              }
              confirmLabel={content[type].actions.confirmLabel}
              onConfirm={() => {
                content[type].actions.onConfirm !== null && content[type].actions.onConfirm();
              }}
              onCancel={() => {
                modal.close();
              }}
              {...(content[type]?.actions?.isDelete && { confirmButtonColor: 'error' })}
              form={content[type].actions.onConfirm === null ? type : null}
            />
          </>
        )}
      </Modal>
      {buttonType === 'icon' ? (
        <IconButton
          color={content[type]?.actions?.isDelete ? 'error' : 'primary'}
          onClick={onModalTriggerButtonClick}
          sx={{
            padding: 0.5,
            borderRadius: '8px',
            ...buttonSx,
          }}
          disabled={disabled}
        >
          {content[type]?.actions?.buttonIcon}
        </IconButton>
      ) : (
        <Button
          color={content[type]?.actions?.isDelete ? 'error' : 'primary'}
          variant={'outlined'}
          onClick={onModalTriggerButtonClick}
          fullWidth
          endIcon={content[type]?.actions.buttonIcon}
          sx={{ ...buttonSx }}
          disabled={disabled}
        >
          {content[type]?.title}
        </Button>
      )}
    </>
  );
};

export default LocationsActionModal;
