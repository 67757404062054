import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@mui/material';
import useMounted from '../../hooks/useMounted';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';
import PencilAltIcon from '../../icons/PencilAlt';
import gtm from '../../lib/gtm';
import { useParams } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCustomerDetailsPath } from 'src/routesPaths';
import { useForm } from 'react-hook-form';
import CustomerDetailsSummary from './CustomerDetailsSummary';
import EditButton from '../../components/widgets/buttons/EditButton';
import Can, { permissions, subjects } from '../../utils/Can';
import { useDispatch, useSelector, RootState } from '../../store';
import { patchCustomer } from '../../slices/customers';
import { RequestStatus } from 'src/utils/RequestStatus';
import { getCustomerDetails, resetCustomerPatchStatus } from '../../slices/customers';
import { clearFilesForGivenKey } from '../../slices/fileUpload';
import useEditMode from '../../hooks/useEditMode';
import { setFormValuesFromDetailsObject } from '../../utils/formsHelper';
import GenericBreadcrumb, {
  getBreadcrumbLabelFromUserDetails,
} from '../../components/GenericBreadcrumb';

const CustomerDetails: FC = () => {
  const { settings } = useSettings();
  const [isCustomerRegistered, setIsCustomerRegistered] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { handleSubmit, control, setValue } = useForm();
  const { triggerEditMode, isEditMode } = useEditMode();

  const { customerDetails, patchValidationErrors, customerPatchStatus } = useSelector(
    (state: RootState) => state.customers
  );

  const excludeKeys = ['uuid', 'username'];

  useEffect(() => {
    setIsCustomerRegistered(!!customerDetails?.user?.uuid);
  }, [customerDetails?.user?.uuid]);

  useEffect(() => {
    dispatch(getCustomerDetails({ uuid }));

    return () => {
      dispatch(resetCustomerPatchStatus());
    };
  }, []);

  useEffect(() => {
    if (RequestStatus.isDone(customerPatchStatus)) {
      navigate(`${getCustomerDetailsPath({ uuid })}`, { replace: true });
    }
  }, [customerPatchStatus]);

  useEffect(() => {
    if (!customerDetails) return;
    customerDetails.user &&
      setFormValuesFromDetailsObject({
        setValue,
        detailsObject: customerDetails.user,
        excludeKeys,
      });
  }, [customerDetails]);

  const handleFormSubmit = handleSubmit((data) => {
    if (isEditMode) {
      console.log('customer', data);
      dispatch(patchCustomer({ uuid, ...data }));
    } else {
      navigate(`${getCustomerDetailsPath({ uuid })}?edit=true`, { replace: true });
    }
  });

  const handleCancelClick = () => {
    navigate(`${getCustomerDetailsPath({ uuid })}`, { replace: true });
    customerDetails.user &&
      setFormValuesFromDetailsObject({
        setValue,
        detailsObject: customerDetails.user,
        excludeKeys,
      });
    dispatch(clearFilesForGivenKey({ storeKey: 'customerAvatar' }));
  };

  const isPatchFetching = RequestStatus.isFetching(customerPatchStatus);

  return (
    <>
      <Helmet>
        <title>Customer details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Customer Details
              </Typography>
              <GenericBreadcrumb
                replaceLastUUIDWith={getBreadcrumbLabelFromUserDetails(customerDetails)}
              />
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                {/* <Can I={permissions.UPDATE} a={subjects.CUSTOMERS}>
                  {isCustomerRegistered && (
                    <EditButton
                      isEditMode={isEditMode}
                      form="edit-customer-form"
                      type="submit"
                      onCancelClick={handleCancelClick}
                      isLoading={isPatchFetching}
                    />
                  )}
                </Can> */}
                <EditButton
                  isEditMode={isEditMode}
                  form="edit-customer-form"
                  type="submit"
                  onCancelClick={handleCancelClick}
                  isLoading={isPatchFetching}
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CustomerDetailsSummary
                  handleFormSubmit={handleFormSubmit}
                  control={control}
                  isEditMode={isEditMode}
                  setValue={setValue}
                  customerDetails={customerDetails}
                  patchValidationErrors={patchValidationErrors}
                  isCustomerRegistered={isCustomerRegistered}
                  isPatchFetching={isPatchFetching}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CustomerDetails;
