import { useCallback, useState, useEffect } from 'react';
import type { FC, ChangeEvent } from 'react';
import { Link as RouterLink, useParams, useSearchParams } from 'react-router-dom';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import useMounted from '../../../hooks/useMounted';
import gtm from '../../../lib/gtm';
import useSettings from '../../../hooks/useSettings';
import {
  clearOrganizationTechnicianDetails,
  getOrganizationTechnicianDetails,
  patchOrganizationTechnicianDetails,
  setPatchTechnicianFetchStatus,
  setPatchTechnicianValidationErrors,
} from '../../../slices/organizations';
import { RootState, useDispatch, useSelector } from '../../../store';
import { useForm } from 'react-hook-form';
import EditButton from '../../../components/widgets/buttons/EditButton';
import { RequestStatus } from '../../../utils/RequestStatus';
import OrganizationTechnicianDetailsSummary from './OrganizationTechnicianDetailsSummary';
import useEditMode from '../../../hooks/useEditMode';
import { setFormValuesFromDetailsObject } from '../../../utils/formsHelper';
import { replaceLastUUIDInOrganizationBreadcrumbWith } from '../../../slices/config';
import { getBreadcrumbLabelFromUserDetails } from '../../../components/GenericBreadcrumb';

const formId = 'edit-technician-form';

const TechnicianDetails: FC = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const dispatch = useDispatch();
  const { uuid: id, technicianId } = useParams();
  const { technician, fetchStatus, patchFetchStatus, patchValidationErrors } = useSelector(
    (state: RootState) => state.organizations.details.technicianDetails
  );
  const { handleSubmit, setValue, getValues, control, register } = useForm({
    defaultValues: technician,
  });

  const { triggerEditMode, isEditMode } = useEditMode();

  const onCancelClick = () => {
    triggerEditMode();
    setFormValuesFromDetailsObject({ setValue, detailsObject: technician });
  };

  const handleFormSubmit = handleSubmit((data) => {
    if (isEditMode) {
      for (let [key, value] of Object.entries(data)) {
        if (value === 'null') delete data[key];
      }
      // it was a string anyway
      delete data.user;
      dispatch(patchOrganizationTechnicianDetails({ organizationId: id, technicianId, ...data }));
    }
  });

  useEffect(() => {
    if (RequestStatus.isDone(patchFetchStatus)) {
      dispatch(setPatchTechnicianValidationErrors(null));
      dispatch(setPatchTechnicianFetchStatus(RequestStatus.status.NULL));
      triggerEditMode();
    }
  }, [patchFetchStatus]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(() => {
    if (mounted) dispatch(getOrganizationTechnicianDetails({ organizationId: id, technicianId }));
  }, [mounted]);

  useEffect(() => {
    return () => {
      dispatch(clearOrganizationTechnicianDetails());
    };
  }, []);

  useEffect(() => {
    if (!technician) return;
    setFormValuesFromDetailsObject({ setValue, detailsObject: technician });
    dispatch(
      replaceLastUUIDInOrganizationBreadcrumbWith(
        getBreadcrumbLabelFromUserDetails({ user: technician })
      )
    );
  }, [technician]);

  const isPatchFetching = RequestStatus.isFetching(patchFetchStatus);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Box sx={{ height: 50 }}></Box>
            </Grid>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {`${technician?.first_name || ''} ${technician?.last_name || ''}`}
              </Typography>
            </Grid>

            <Grid item>
              <Box sx={{ m: -1 }}>
                <EditButton
                  isEditMode={isEditMode}
                  form={formId}
                  onClick={triggerEditMode}
                  onCancelClick={onCancelClick}
                  isLoading={isPatchFetching}
                />
              </Box>
            </Grid>
          </Grid>

          <Divider />

          <OrganizationTechnicianDetailsSummary
            onSubmit={handleFormSubmit}
            formId={formId}
            control={control}
            isEditMode={isEditMode}
            validationErrors={patchValidationErrors}
            technician={technician}
            isPatchFetching={isPatchFetching}
          />
        </Container>
      </Box>
    </>
  );
};

export default TechnicianDetails;
