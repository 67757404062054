import { Box, Button, Collapse, Divider, TablePagination, Typography } from '@mui/material';
import { Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useState } from 'react';
import { getItems, ItemType, LocationType } from '../../../slices/locations';
import { pagination } from '../../../theme';
import LocationsItemsList from './LocationsItemsList';
import ChatContactSearch from '../../../components/dashboard/chat/ChatContactSearch';
import LocationsMainWindowToolbar from './LocationsMainWindowToolbar';
import { RequestStatus } from '../../../utils/RequestStatus';
import toast from 'react-hot-toast';
import { useDispatch } from '../../../store';
import { useSearchParams } from 'react-router-dom';

interface PlacesMainWindowProps {
  items: Array<ItemType>;
  itemsFetchStatus: string | null;
  searchQuery: string;
  setSearchQuery: any;
  paginationMethods: {
    onPageChange: any;
    onRowsPerPageChange: any;
    page: number;
    rowsPerPage: number;
    count: number;
  };
  location: LocationType;
  handleSetPrintCodesState: () => void;
  isPrintStateActive: boolean;
  handlePrint: any;
  handleSetQrCodesUrlsToPrint: (item: LocationType | ItemType) => void;
  qrCodesToPrint: Array<{
    qr_code: string;
    name: string;
    ancestors_names: Array<string>;
    printAmount: number;
  }>;
  handleChangePrintAmount: (printAmount: number, qr_code: string) => void;
  isAnyLoading: boolean;
  setPagesToPrintRef: Dispatch<SetStateAction<MutableRefObject<HTMLDivElement>>>;
  locationUuid?: string;
  setQrCodesToPrintLayout: Dispatch<SetStateAction<Array<Array<Array<string>>>>>;
  handleGetOrganizations: (data?: any) => void;
}

const LocationsMainWindow: FC<PlacesMainWindowProps> = (props) => {
  const {
    items,
    itemsFetchStatus,
    searchQuery,
    setSearchQuery,
    paginationMethods,
    location,
    handleSetPrintCodesState,
    isPrintStateActive,
    handlePrint,
    handleSetQrCodesUrlsToPrint,
    qrCodesToPrint,
    handleChangePrintAmount,
    isAnyLoading,
    setPagesToPrintRef,
    locationUuid = null,
    setQrCodesToPrintLayout,
    handleGetOrganizations,
  } = props;

  const dispatch = useDispatch();

  const [isGettingAllItems, setIsGettingAllItems] = useState<boolean>(false);
  const [warningToast, setWarningToast] = useState<string>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const paginationHeight = '52px';
  const allItemsSelected =
    items?.length > 0 &&
    items?.every((item) =>
      Boolean(qrCodesToPrint.find((qrCode) => qrCode.qr_code === item.qr_code))
    );
  const someItemsSelected =
    !items?.every((item) =>
      Boolean(qrCodesToPrint.find((qrCode) => qrCode.qr_code === item.qr_code))
    ) &&
    !items?.every(
      (item) => !Boolean(qrCodesToPrint.find((qrCode) => qrCode.qr_code === item.qr_code))
    );

  const handleSelectAllItemsToPrint = (): void => {
    if (paginationMethods.count !== items.length) {
      dispatch(
        getItems({
          page_size: paginationMethods?.count ?? 10,
          page: 1,
          location_uuid: locationUuid,
          search: '',
          organization_uuid: searchParams.get('organization_uuid'),
        })
      );
      if (paginationMethods.count > 200)
        setWarningToast(toast.loading('Processing all items might take a while, please wait.'));
    }
    setTimeout(() => {
      setIsGettingAllItems(true);
    }, 250);
  };

  const handleSelectAllItemsOnCurrentPage = (): void => {
    if (someItemsSelected) {
      items
        ?.filter(
          (item) => !Boolean(qrCodesToPrint.find((qrCode) => qrCode.qr_code === item.qr_code))
        )
        ?.map((item) => handleSetQrCodesUrlsToPrint(item));
    } else items?.map((item) => handleSetQrCodesUrlsToPrint(item));
  };

  useEffect(() => {
    if (isGettingAllItems) {
      if (RequestStatus.isDone(itemsFetchStatus)) {
        setIsGettingAllItems(false);
        toast.dismiss(warningToast);
        if (someItemsSelected) {
          items
            ?.filter(
              (item) => !Boolean(qrCodesToPrint.find((qrCode) => qrCode.qr_code === item.qr_code))
            )
            ?.map((item) => handleSetQrCodesUrlsToPrint(item));
        } else items?.map((item) => handleSetQrCodesUrlsToPrint(item));
      } else if (RequestStatus.isError(itemsFetchStatus)) {
        setIsGettingAllItems(false);
        toast.dismiss(warningToast);
      }
    }
  }, [isGettingAllItems, itemsFetchStatus]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
            height: `calc(100% - ${paginationHeight}px)`,
          }}
        >
          <LocationsMainWindowToolbar
            location={location}
            handleSetPrintCodesState={handleSetPrintCodesState}
            isPrintStateActive={isPrintStateActive}
            handlePrint={handlePrint}
            handleChangePrintAmount={handleChangePrintAmount}
            qrCodesToPrint={qrCodesToPrint}
            setPagesToPrintRef={setPagesToPrintRef}
            setQrCodesToPrintLayout={setQrCodesToPrintLayout}
            isAnyLoading={isAnyLoading}
            handleGetOrganizations={handleGetOrganizations}
          />
          <Box
            sx={{
              py: 1,
            }}
          >
            <ChatContactSearch
              onChange={setSearchQuery}
              query={searchQuery}
              results={[]}
              hideResultsPreview={true}
              placeholder={'Search machines/devices/parts'}
              onClear={() => {
                setSearchQuery({ target: { value: '' } });
              }}
              isFocused={false}
              onClickAway={() => {}}
              onFocus={() => {}}
              onSelect={() => {}}
            />
          </Box>
          <Divider />
          <Collapse in={isPrintStateActive}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 2,
                py: 1,
              }}
            >
              <Box>
                {/*<FormControlLabel*/}
                {/*  onClick={handleSelectAllItemsToPrint}*/}
                {/*  control={*/}
                {/*    <Checkbox checked={allItemsSelected} indeterminate={someItemsSelected} />*/}
                {/*  }*/}
                {/*  label={'Select all items'}*/}
                {/*  sx={{ px: 1.5 }}*/}
                {/*  disabled={!items?.length || isAnyLoading}*/}
                {/*/>*/}
                <Button onClick={handleSelectAllItemsToPrint} variant={'outlined'} sx={{ mr: 2 }}>
                  Select all items in this location
                </Button>
                {/*<FormControlLabel*/}
                {/*  onClick={handleSelectAllItemsOnCurrentPage}*/}
                {/*  control={*/}
                {/*    <Checkbox checked={allItemsSelected} indeterminate={someItemsSelected} />*/}
                {/*  }*/}
                {/*  label={'Select all items on current page'}*/}
                {/*  sx={{ px: 1.5 }}*/}
                {/*  disabled={!items?.length || isAnyLoading}*/}
                {/*/>*/}
              </Box>
              <Typography>
                {qrCodesToPrint.length} out of {paginationMethods?.count ?? 0} selected
              </Typography>
            </Box>
            <Divider />
          </Collapse>
          <LocationsItemsList
            items={items}
            itemsFetchStatus={itemsFetchStatus}
            handleSetQrCodesUrlsToPrint={handleSetQrCodesUrlsToPrint}
            qrCodesToPrint={qrCodesToPrint}
            isPrintStateActive={isPrintStateActive}
            isAnyLoading={isAnyLoading}
          />
        </Box>
        <div>
          <Divider />
          <TablePagination
            component="div"
            rowsPerPageOptions={pagination.rowsPerPageOptions}
            sx={{
              height: `${paginationHeight}px`,
            }}
            {...paginationMethods}
          />
        </div>
      </Box>
    </>
  );
};

export default LocationsMainWindow;
