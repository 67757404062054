import type { FC } from 'react';
import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Divider,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { experimentalStyled, useTheme } from '@mui/material/styles';
import { MessagingParticipant } from '../../../types/messaging_participant';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import styled from 'styled-components';
import { pagination as _pagination, sizes } from '../../../theme';
import { MessagingThread } from '../../../types/messaging_thread';
import { MessagingMessage } from '../../../types/messaging_message';
import MessagingPinnedMessages from './MessagingPinnedMessages';
import MessagingCaseManagement from './MessagingCaseManagement';
import { LoadMoreButtonsConfig } from '../../../types/messaging_load_more_buttons_config';
import { useNavigate, useParams } from 'react-router-dom';
import { getCaseManagementThreadExportedMessagesPath } from '../../../routesPaths';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupIcon from '@mui/icons-material/Group';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import useModal from '../../../hooks/useModal';
import { format } from 'date-fns';
import { RootState, useDispatch, useSelector } from '../../../store';
import {
  getThreadDetails,
  getThreadUsers,
  inviteChatParticipants,
  removeChatParticipants,
  resetGetThreadUsers,
  resetInviteChatParticipants,
  resetRemoveChatParticipants,
} from '../../../slices/messaging';
import { RequestStatus } from '../../../utils/RequestStatus';
import toast from 'react-hot-toast';
import { debounce } from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { ThreadParticipant } from '../../../types/thread_participant';
import { getConstants, Timeframe } from '../../../slices/config';
import ScheduledMessageModal from './ScheduledMessageModal';

interface MessagingThreadToolbarProps {
  participants: Array<MessagingParticipant>;
  threadAciveParticipants: Array<MessagingParticipant>;
  detailsFetchStatus: string | null;
  thread: MessagingThread;
  messages: Array<MessagingMessage>;
  pinnedMessages: Array<MessagingMessage>;
  pinnedMessagesFetchStatus: string | null;
  handleGetThreadPinnedMessages: any;
  handleChangeMesssagePinnedStatus: (data: { is_pinned: boolean; uuid: string }) => void;
  setMessageToScrollTo: Dispatch<SetStateAction<string>>;
  isLoadMorePinnedMessagesVisible: boolean;
  onLoadMorePinnedMessagesClick: any;
  changeMessagePinnedPostFetchStatus: string | null;
  getThreadMessagesWithInterval: (params: {
    id?: string | number;
    cursor?: string;
    page_size?: string | number;
    options?: {
      dispatchedFromPinnedMessages?: boolean;
      omitGetNewThreadMessages?: boolean;
      clearCurrentMessages?: boolean;
    };
  }) => void;
  messagesFetchStatus: string | null;
  checkIfMessageLoaded: (params: { uuid: string }) => boolean;
  loadMoreButtonsConfig: LoadMoreButtonsConfig;
  handleExportMessages: (
    data: {
      name: string;
      start_date: string;
      end_date: string;
      start_message_uuid: string;
      end_message_uuid: string;
    },
    exportType: 'all' | 'dateRange' | 'selection'
  ) => void;
}

//@ts-ignore
const ParticipantAvatar = experimentalStyled(Avatar)(({ styleProps }) => {
  if (styleProps.small) {
    return {
      height: 30,
      width: 30,
      '&:nth-of-type(2)': {
        mt: '10px',
      },
    };
  }
  return {};
});

const NamesContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: grid;
`;

const StyledMenuItem = experimentalStyled(MenuItem)(({ theme }) => ({
  ['&.MuiListItem-root']: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const anonUserLabel = 'Anon user';

interface ThreadUsersPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  my_organization: boolean;
  search: string;
}

const initialPagination: ThreadUsersPagination = {
  page: 0,
  rowsPerPage: _pagination.defaultRowsPerPage,
  total: 0,
  my_organization: true,
  search: '',
};

const defaultAccessPeriod: Timeframe = {
  label: null,
  period: null,
};

const MessagingThreadToolbar: FC<MessagingThreadToolbarProps> = (props) => {
  const {
    participants: _participants,
    threadAciveParticipants,
    detailsFetchStatus,
    thread,
    messages,
    pinnedMessages,
    pinnedMessagesFetchStatus,
    handleGetThreadPinnedMessages,
    handleChangeMesssagePinnedStatus,
    setMessageToScrollTo,
    isLoadMorePinnedMessagesVisible,
    onLoadMorePinnedMessagesClick,
    changeMessagePinnedPostFetchStatus,
    getThreadMessagesWithInterval,
    messagesFetchStatus,
    checkIfMessageLoaded,
    loadMoreButtonsConfig,
    handleExportMessages,
    ...other
  } = props;

  const navigate = useNavigate();
  const { uuid } = useParams();
  const { fetchStatus: removeChatParticipantsFetchStatus } = useSelector(
    (state: RootState) => state.messaging.thread.removeChatParticipants
  );
  const { fetchStatus: inviteChatParticipantsFetchStatus, message: inviteChatParticipantsMessage } =
    useSelector((state: RootState) => state.messaging.thread.inviteChatParticipants);
  const {
    fetchStatus: getThreadUsersFetchStatus,
    items: threadUsers,
    count: threadUsersCount,
  } = useSelector((state: RootState) => state.messaging.thread.threadUsers);
  const {
    dialogs: { access_periods },
    configFetchStatus,
  } = useSelector((state: RootState) => state.config);

  const dispatch = useDispatch();
  const theme = useTheme();

  const listContainerRef = useRef<HTMLDivElement>(null);

  // const participants = extractParticipants(_participants, thread);

  const [pagination, setPagination] = useState<ThreadUsersPagination>(initialPagination);

  const [participantsNames, setParticipantsNames] = useState<string>('');

  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [removeSectionOpenFor, setRemoveSectionOpenFor] = useState<ThreadParticipant | null>(null);
  const [addSectionOpenFor, setAddSectionOpenFor] = useState<ThreadParticipant | null>(null);

  const [selectedAccessDuration, setSelectedAccessDuration] = useState<Timeframe>(
    access_periods[0] ?? defaultAccessPeriod
  );

  const displayNames = threadAciveParticipants
    .reduce(
      (names, threadAciveParticipant) => [
        ...names,
        threadAciveParticipant.first_name
          ? threadAciveParticipant.first_name
          : threadAciveParticipant.username || anonUserLabel,
      ],
      []
    )
    .join(', ');

  const { Component: ManageParticipantsModal, ...manageParticipantsModal } = useModal();

  const handleRemoveChatParticipant = (): void => {
    if (!removeSectionOpenFor) return;
    dispatch(
      removeChatParticipants({
        threadUuid: uuid,
        uuids: [removeSectionOpenFor?.uuid],
      })
    );
  };

  const handleInviteChatParticipant = (): void => {
    if (!uuid || !addSectionOpenFor) return;
    const params: {
      threadUuid: string;
      accesses: Array<{
        user: string;
        period?: number;
      }>;
    } = {
      accesses: [
        {
          user: addSectionOpenFor?.uuid,
          ...(selectedAccessDuration.period !== -1 && { period: selectedAccessDuration.period }),
        },
      ],
      threadUuid: uuid,
    };

    dispatch(inviteChatParticipants(params));
  };

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };
  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPagination((prev) => ({ ...prev, page: 0, rowsPerPage: newRowsPerPage }));
  };

  const handleChangeManageParticipantsContext = (isMyOrganization: boolean): void => {
    setPagination((prev) => ({ ...prev, page: 0, my_organization: isMyOrganization }));
    setAddSectionOpenFor(null);
    setRemoveSectionOpenFor(null);
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchInputValue(e.target.value);
    debouncedCountryInputChange(e.target.value);
  };

  const searchCallback = (value) => {
    setPagination((prev) => ({ ...prev, page: 0, search: value }));
  };

  const debouncedCountryInputChange = useCallback(debounce(searchCallback, 1000), []);

  const handleClearInput = (): void => {
    onSearchChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
  };

  const handleGetThreadUsers = (): void => {
    dispatch(
      getThreadUsers({
        thread_uuid: uuid,
        search: pagination.search,
        my_organizations: pagination.my_organization,
        limit: pagination.rowsPerPage,
        offset: pagination.rowsPerPage * pagination.page,
      })
    );
  };

  const handleOpenAddSectionFor = (user: ThreadParticipant): void => {
    if (!user) return;
    setRemoveSectionOpenFor(null);
    setAddSectionOpenFor((prev) => (prev?.uuid === user?.uuid ? null : user));
    setTimeout(() => {
      setSelectedAccessDuration(access_periods[0] ?? defaultAccessPeriod);
    }, 150);
  };

  const handleOpenRemoveSectionFor = (user: ThreadParticipant): void => {
    if (!user) return;
    setAddSectionOpenFor(null);
    setRemoveSectionOpenFor((prev) => (prev?.uuid === user?.uuid ? null : user));
    setTimeout(() => {
      setSelectedAccessDuration(access_periods[0] ?? defaultAccessPeriod);
    }, 150);
  };

  useEffect(() => {
    if (RequestStatus.isNull(configFetchStatus)) dispatch(getConstants());
  }, [configFetchStatus]);

  useEffect(() => {
    if (thread.uuid !== uuid) setParticipantsNames('');
    else setParticipantsNames(displayNames.length ? displayNames : 'No active participants');
  }, [detailsFetchStatus, uuid]);

  useEffect(() => {
    if (manageParticipantsModal.isOpen) handleGetThreadUsers();
    else {
      setTimeout(() => {
        setPagination(initialPagination);
        setSearchInputValue(initialPagination.search);
        dispatch(resetGetThreadUsers(null));
      }, 150);
    }
  }, [
    uuid,
    manageParticipantsModal.isOpen,
    pagination.rowsPerPage,
    pagination.page,
    pagination.my_organization,
    pagination.search,
  ]);

  useEffect(() => {
    if (RequestStatus.isDone(removeChatParticipantsFetchStatus)) {
      toast.success('Participant removed.');
      setRemoveSectionOpenFor(null);
      handleGetThreadUsers();
      dispatch(resetRemoveChatParticipants(null));
      !!uuid && dispatch(getThreadDetails({ id: uuid }));
    }
    if (RequestStatus.isError(removeChatParticipantsFetchStatus)) {
      toast.error("Participant couldn't be removed. Try again later.");
      handleGetThreadUsers();
      dispatch(resetRemoveChatParticipants(null));
    }
  }, [removeChatParticipantsFetchStatus]);

  useEffect(() => {
    if (RequestStatus.isDone(inviteChatParticipantsFetchStatus)) {
      toast.success(inviteChatParticipantsMessage ?? 'Participant added.');
      setAddSectionOpenFor(null);
      handleGetThreadUsers();
      dispatch(resetInviteChatParticipants(null));
      !!uuid && dispatch(getThreadDetails({ id: uuid }));
    }
    if (RequestStatus.isError(inviteChatParticipantsFetchStatus)) {
      toast.error("Participant couldn't be added. Try again later.");
      handleGetThreadUsers();
      dispatch(resetInviteChatParticipants(null));
    }
  }, [inviteChatParticipantsFetchStatus]);

  useEffect(() => {
    if (RequestStatus.isDone(getThreadUsersFetchStatus)) {
      setPagination((prev) => ({ ...prev, total: threadUsersCount }));
      if (listContainerRef.current !== null) listContainerRef.current.scrollTop = 0;
    }
    if (RequestStatus.isError(getThreadUsersFetchStatus)) {
      toast.error('Error getting data. Try again later.');
    }
  }, [getThreadUsersFetchStatus]);

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          display: 'flex',
          flexShrink: 0,
          px: 2,
          py: 1,
          height: sizes.messaging.toolbarHeight,
          overflow: 'hidden',
        }}
        {...other}
      >
        <ManageParticipantsModal
          {...manageParticipantsModal.props}
          sx={{
            width: 'fit-content',
            height: 'fit-content',
            minWidth: '650px',
          }}
        >
          <GroupIcon color={'primary'} sx={{ height: '64px', width: '64px' }} />
          <Typography color="textPrimary" variant="h5" sx={{ marginBottom: '20px' }}>
            Manage participants
          </Typography>
          <Box sx={{ display: 'flex', gap: '16px', mb: 2 }}>
            <ButtonGroup>
              <Button
                disabled={RequestStatus.isFetching(getThreadUsersFetchStatus)}
                sx={{ minWidth: '120px !important' }}
                variant={pagination.my_organization ? 'contained' : 'outlined'}
                onClick={() => handleChangeManageParticipantsContext(true)}
              >
                Organization
              </Button>
              <Button
                disabled={RequestStatus.isFetching(getThreadUsersFetchStatus)}
                sx={{ minWidth: '120px !important' }}
                variant={pagination.my_organization ? 'outlined' : 'contained'}
                onClick={() => handleChangeManageParticipantsContext(false)}
              >
                All
              </Button>
            </ButtonGroup>
            <TextField
              onChange={onSearchChange}
              value={searchInputValue}
              size={'small'}
              placeholder={'Search'}
              InputProps={{
                startAdornment: <SearchIcon />,
                endAdornment: searchInputValue.length > 0 && (
                  <IconButton onClick={handleClearInput} color={'primary'}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              fullWidth
            />
          </Box>
          <Box
            ref={listContainerRef}
            sx={{
              overflowY: 'scroll',
              maxHeight: 'calc(70vh - 200px)',
              pr: 2,
            }}
          >
            {threadUsers.length > 0
              ? threadUsers.map((user, idx) => {
                  return (
                    <Box
                      sx={{
                        border: `solid 1px ${theme.palette.divider}`,
                        borderRadius: '16px',
                        mb: 1,
                        maxWidth: '554px',
                      }}
                    >
                      <ListItem
                        // onClick={() =>
                        //   setExpandedRow((prev) => (prev === user?.uuid ? null : user?.uuid))
                        // }
                        sx={{
                          pr: user?.is_in_thread ? '128px' : '96px',
                        }}
                        secondaryAction={
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              gap: '8px',
                            }}
                          >
                            {user?.is_in_thread && (
                              <Tooltip
                                title={'User already added to this thread'}
                                arrow
                                disableInteractive
                                placement={'top'}
                              >
                                <CheckIcon color={'primary'} />
                              </Tooltip>
                            )}
                            <IconButton
                              color={'primary'}
                              sx={{
                                border: `solid 1px ${theme.palette.divider}`,
                                padding: 0.5,
                                borderRadius: '8px',
                              }}
                              disabled={user?.is_in_thread}
                              onClick={() => handleOpenAddSectionFor(user)}
                            >
                              <AddIcon fontSize={'small'} />
                            </IconButton>
                            <IconButton
                              color={'error'}
                              sx={{
                                border: `solid 1px ${theme.palette.divider}`,
                                padding: 0.5,
                                borderRadius: '8px',
                              }}
                              disabled={!user?.is_in_thread}
                              onClick={() => handleOpenRemoveSectionFor(user)}
                            >
                              <DeleteIcon fontSize={'small'} />
                            </IconButton>
                          </Box>
                        }
                      >
                        <ListItemIcon>
                          <Avatar
                            sx={{
                              height: 32,
                              width: 32,
                            }}
                            src={user?.avatar}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            color: 'textPrimary',
                            noWrap: true,
                            variant: 'subtitle2',
                          }}
                          secondaryTypographyProps={{
                            color: 'textSecondary',
                            noWrap: true,
                            variant: 'body2',
                          }}
                          primary={`${user?.first_name} ${user?.last_name}`}
                          secondary={
                            user?.access_expires_at && user?.is_in_thread
                              ? `Access expires at ${format(
                                  new Date(user.access_expires_at),
                                  'MMM dd, yyyy '
                                )}, ${format(new Date(user.access_expires_at), 'hh:mm aaa')}`
                              : ''
                          }
                        />
                      </ListItem>
                      <Collapse in={removeSectionOpenFor?.uuid === user?.uuid} timeout={300}>
                        <Box
                          sx={{
                            px: 2,
                            pb: 1,
                          }}
                        >
                          <Divider />
                          <Box sx={{ height: '4px' }}>
                            {RequestStatus.isFetching(removeChatParticipantsFetchStatus) && (
                              <LinearProgress />
                            )}
                          </Box>
                          <Typography color={'textSecondary'} variant={'h6'} align={'center'}>
                            Are you sure you want to remove {user?.first_name} {user?.last_name}{' '}
                            from this chat?
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-around',
                              gap: '32px',
                              mt: 1,
                            }}
                          >
                            <Button
                              variant={'outlined'}
                              sx={{ minWidth: '150px' }}
                              onClick={() => setRemoveSectionOpenFor(null)}
                            >
                              No
                            </Button>
                            <Button
                              variant={'contained'}
                              color={'error'}
                              sx={{ minWidth: '150px' }}
                              onClick={handleRemoveChatParticipant}
                              disabled={RequestStatus.isFetching(removeChatParticipantsFetchStatus)}
                            >
                              Yes
                            </Button>
                          </Box>
                        </Box>
                      </Collapse>
                      <Collapse in={addSectionOpenFor?.uuid === user?.uuid} timeout={300}>
                        <Box
                          sx={{
                            px: 2,
                            pb: 1,
                          }}
                        >
                          <Divider />
                          <Box sx={{ height: '4px' }}>
                            {RequestStatus.isFetching(inviteChatParticipantsFetchStatus) && (
                              <LinearProgress />
                            )}
                          </Box>
                          <Typography variant={'h6'} color={'textSecondary'}>
                            Select amount of time to add {user?.first_name ?? ''}{' '}
                            {user?.last_name ?? ''} to this chat for:
                          </Typography>
                          <TextField
                            size={'small'}
                            select
                            fullWidth
                            sx={{ my: 1 }}
                            value={selectedAccessDuration.period}
                            disabled={!RequestStatus.isDone(configFetchStatus)}
                            onChange={(e) => {
                              setSelectedAccessDuration(
                                access_periods.find((option) => option.period === +e.target.value)
                              );
                            }}
                            SelectProps={{
                              renderValue: (selected) => (
                                <Typography
                                  variant={'subtitle1'}
                                  align={'center'}
                                  sx={{ width: '100%' }}
                                >
                                  {/*{access_periods.find((option) => option.period === selected)?.label || ''}*/}
                                  {selectedAccessDuration?.label ?? ''}
                                </Typography>
                              ),
                            }}
                          >
                            {access_periods.map((option) => (
                              <MenuItem value={option.period} key={option.period}>
                                <Typography
                                  variant={'subtitle1'}
                                  align={'center'}
                                  sx={{ width: '100%' }}
                                >
                                  {option.label}
                                </Typography>
                              </MenuItem>
                            ))}
                          </TextField>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-around',
                              gap: '32px',
                              mt: 1,
                            }}
                          >
                            <Button
                              variant={'outlined'}
                              sx={{ minWidth: '150px' }}
                              onClick={() => setAddSectionOpenFor(null)}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant={'contained'}
                              sx={{ minWidth: '150px' }}
                              onClick={handleInviteChatParticipant}
                              disabled={RequestStatus.isFetching(inviteChatParticipantsFetchStatus)}
                            >
                              Add
                            </Button>
                          </Box>
                        </Box>
                      </Collapse>
                    </Box>
                  );
                })
              : (RequestStatus.isDone(getThreadUsersFetchStatus) ||
                  RequestStatus.isError(getThreadUsersFetchStatus)) && (
                  <Typography variant={'h6'} color={'textSecondary'} sx={{ py: 1 }}>
                    No users found
                  </Typography>
                )}
          </Box>
          <Box sx={{ height: '4px' }}>
            {RequestStatus.isFetching(getThreadUsersFetchStatus) && <LinearProgress />}
          </Box>
          <TablePagination
            count={pagination.total}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            rowsPerPageOptions={_pagination.rowsPerPageOptions}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            component={'div'}
          />
        </ManageParticipantsModal>
        <PopupState variant="popover" popupId="chat-popup-menu">
          {(popupState) => (
            <>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  '& .MuiAvatar-root': threadAciveParticipants?.length > 1 && {
                    height: 30,
                    width: 30,
                    '&:nth-of-type(2)': {
                      mt: '10px',
                    },
                  },
                  cursor: 'pointer',
                  maxWidth: '100%',
                }}
                {...bindTrigger(popupState)}
              >
                <AvatarGroup max={2}>
                  {threadAciveParticipants?.map((threadAciveParticipant) => (
                    <ParticipantAvatar
                      key={threadAciveParticipant.id}
                      src={threadAciveParticipant.avatar}
                      //@ts-ignore
                      styleProps={{ small: threadAciveParticipant.length > 1 }}
                    />
                  ))}
                </AvatarGroup>
                <NamesContainer>
                  <Typography
                    color="textPrimary"
                    noWrap
                    sx={{
                      ml: 2,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '100%',
                      flexGrow: 1,
                    }}
                    variant="subtitle2"
                  >
                    {participantsNames}
                  </Typography>
                </NamesContainer>
              </Box>
              {/*<Box sx={{ flexGrow: 1 }} />*/}
              <Menu
                // PaperProps={{
                //   sx: {
                //     minWidth: '250px',
                //   },
                // }}
                {...bindMenu(popupState)}
              >
                {threadAciveParticipants.map((threadAciveParticipant, idx) => (
                  <Box>
                    <Box
                      sx={{
                        px: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '32px',
                      }}
                      // onClick={popupState.close}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', my: 0.5 }}>
                        <ParticipantAvatar
                          key={threadAciveParticipant.id}
                          src={threadAciveParticipant.avatar}
                          //@ts-ignore
                          styleProps={{ small: threadAciveParticipant.length > 1 }}
                        />
                        <Typography color="textPrimary" sx={{ ml: 2, mr: 1 }} variant="subtitle2">
                          {threadAciveParticipant.first_name || threadAciveParticipant.last_name
                            ? `${threadAciveParticipant.last_name} ${threadAciveParticipant.first_name} `
                            : threadAciveParticipant?.username || anonUserLabel}
                          {!!threadAciveParticipant.access_expires_at &&
                            `(Access expires at ${format(
                              new Date(threadAciveParticipant.access_expires_at),
                              'MMM dd, yyyy'
                            )}, ${format(
                              new Date(threadAciveParticipant.access_expires_at),
                              'hh:mm aaa'
                            )})`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Menu>
            </>
          )}
        </PopupState>
        <Button
          variant={'text'}
          startIcon={<GroupIcon />}
          sx={{ ml: 2 }}
          onClick={() => manageParticipantsModal.open()}
        >
          Manage participants
        </Button>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          display: 'flex',
          flexShrink: 0,
          px: 2,
          py: 1,
          height: sizes.messaging.pinnedMessagesHeight,
          overflow: 'hidden',
        }}
      >
        <MessagingPinnedMessages
          pinnedMessages={pinnedMessages}
          pinnedMessagesFetchStatus={pinnedMessagesFetchStatus}
          participants={_participants}
          handleGetThreadPinnedMessages={handleGetThreadPinnedMessages}
          handleChangeMesssagePinnedStatus={handleChangeMesssagePinnedStatus}
          setMessageToScrollTo={setMessageToScrollTo}
          isLoadMorePinnedMessagesVisible={isLoadMorePinnedMessagesVisible}
          onLoadMorePinnedMessagesClick={onLoadMorePinnedMessagesClick}
          changeMessagePinnedPostFetchStatus={changeMessagePinnedPostFetchStatus}
          getThreadMessagesWithInterval={getThreadMessagesWithInterval}
          messagesFetchStatus={messagesFetchStatus}
          checkIfMessageLoaded={checkIfMessageLoaded}
        />
        <MessagingCaseManagement
          messages={messages}
          participants={_participants}
          loadMoreButtonsConfig={loadMoreButtonsConfig}
          handleExportMessages={handleExportMessages}
        />
        <Button
          startIcon={<ImportExportIcon />}
          variant={'text'}
          onClick={() => {
            navigate(getCaseManagementThreadExportedMessagesPath({ thread_uuid: thread.uuid }));
          }}
        >
          Exported messages
        </Button>
        <ScheduledMessageModal />
      </Box>
    </>
  );
};

MessagingThreadToolbar.propTypes = {
  participants: PropTypes.array,
};

MessagingThreadToolbar.defaultProps = {
  participants: [],
};

export default MessagingThreadToolbar;
