import React, { Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import { RootState, useDispatch, useSelector } from '../../../store';
import { RequestStatus } from '../../../utils/RequestStatus';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import LocationsMediaLibraryModal from '../LocationsModals/LocationsMediaLibraryModal';
import LocationsMediaFileUpload from '../LocationsModals/LocationsMediaFileUpload';
import { organiationsListIncrement } from '../Locations';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import LocationsUploadCSVModal from '../LocationsModals/LocationsCreateBuildingModal';

interface LocationsSidebarToolbarProps {
  handleGetOrganizations: (data?: any) => void;
  limit: number;
  setLimit: Dispatch<SetStateAction<number>>;
}

export const allOrganizationsOption = {
  value: 'all-organizations',
  label: 'All organizations',
};

const LocationsSidebarToolbar: FC<LocationsSidebarToolbarProps> = (props) => {
  const { handleGetOrganizations, limit, setLimit } = props;

  const { items, fetchStatus, count } = useSelector(
    (state: RootState) => state.organizations.listOrderedByPrimary
  );
  const { items: locations, fetchStatus: locationsFetchStatus } = useSelector(
    (state: RootState) => state.locations.locations
  );

  const dispatch = useDispatch();
  const theme = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectOpen, setSelectOpen] = useState<boolean>(false);

  const [selectedOrganization, setSelectedOrganization] = useState<string>(
    searchParams.get('organization_uuid')
  );

  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);

  // const handleGetOrganizations = (data?: any) => {
  //   dispatch(
  //     getOrganizations({
  //       search,
  //       offset: (page * limit).toString(),
  //       limit: limit.toString(),
  //       ...data,
  //     })
  //   );
  // };

  const handleChangeOrganization = (e) => {
    if (e.target.value === 'load-more') return;
    const otherSearchParams = Object.fromEntries(searchParams.entries());
    setSearchParams({
      ...otherSearchParams,
      organization_uuid: e.target.value,
    });
    setSelectedOrganization(e.target.value);
  };

  const handleLoadMoreOrganizations = () => {
    handleGetOrganizations({
      limit: limit + organiationsListIncrement,
    });
    setLimit((prev) => prev + organiationsListIncrement);
  };

  const getMaxBuildingsAmount = (): number | ReactNode => {
    const organization = items.find((organization) => organization.uuid === selectedOrganization);
    if (!organization) return null;
    const max = organization.max_active_buildings;
    if (max === 0) return <AllInclusiveIcon sx={{ mx: 0.5 }} />;
    else return max;
  };

  const getActiveBuildingsAmount = (): string => {
    const active = Object.values(locations)
      ?.map((location) => (location.is_active ? 1 : 0))
      .reduce((prev, cur) => (prev += cur), 0);

    return `${active} ${active !== 1 ? 'buildings' : 'building'}`;
  };

  useEffect(() => {
    handleGetOrganizations();
  }, []);

  useEffect(() => {
    if (items.length > 0) {
      if (selectedOrganization === null) {
        handleChangeOrganization({
          target: { value: searchParams.get('organization_uuid') ?? items?.[0]?.uuid },
        });
      } else if (searchParams.get('organization_uuid') === null) {
        const otherSearchParams = Object.fromEntries(searchParams.entries());
        setSearchParams({
          ...otherSearchParams,
          organization_uuid: selectedOrganization,
        });
      }
    }
  }, [items, searchParams]);

  return (
    <Box
      sx={{
        p: 2,
        pb: 0,
      }}
    >
      <TextField
        select
        fullWidth
        label={'Organization'}
        InputLabelProps={{
          shrink: true,
        }}
        SelectProps={{
          onOpen: (e) => {
            handleGetOrganizations();
            setSelectOpen(true);
          },
          onClose: (e) => {
            //@ts-ignore
            if (e.target.textContent === 'Load more') return;
            setSelectOpen(false);
          },
          open: selectOpen,
          MenuProps: {
            sx: {
              maxHeight: '30vh',
            },
          },
          value:
            items.length > 0 && !!items.find((item) => item.uuid === selectedOrganization)
              ? items.find((item) => item.uuid === selectedOrganization)?.uuid
              : '',
          onChange: handleChangeOrganization,
        }}
      >
        {items.length > 0
          ? items.map((organization) => (
              <MenuItem key={organization.uuid} value={organization.uuid}>
                <Typography variant={'subtitle2'}>{organization.name}</Typography>
              </MenuItem>
            ))
          : !RequestStatus.isFetching(fetchStatus) && (
              <MenuItem>
                <Typography variant={'subtitle2'}>No organizations found</Typography>
              </MenuItem>
            )}
        {/*<MenuItem value={allOrganizationsOption.value} key={allOrganizationsOption.value}>*/}
        {/*  <Typography color={'primary'} variant={'subtitle2'}>*/}
        {/*    {allOrganizationsOption.label}*/}
        {/*  </Typography>*/}
        {/*</MenuItem>*/}
        {items.length < count && (
          <MenuItem
            onClick={handleLoadMoreOrganizations}
            sx={{
              borderTop: `solid 1px ${theme.palette.divider}`,
            }}
            value={'load-more'}
          >
            <Typography variant={'body2'}>Load more</Typography>
          </MenuItem>
        )}
        {RequestStatus.isFetching(fetchStatus) && (
          <MenuItem sx={{ p: 0 }}>
            <LinearProgress sx={{ mb: '-12px', height: '8px', width: '100%' }} />
          </MenuItem>
        )}
      </TextField>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 1,
        }}
      >
        <LocationsUploadCSVModal
          organization={items.find((organization) => organization.uuid === selectedOrganization)}
          onUploadFilesClick={() => setTimeout(() => setUploadModalOpen(true), 150)}
          disabled={selectedOrganization === null}
        />
        <LocationsMediaLibraryModal
          organization={items.find((organization) => organization.uuid === selectedOrganization)}
          onUploadFilesClick={() => setTimeout(() => setUploadModalOpen(true), 150)}
          disabled={selectedOrganization === null}
        />
        <LocationsMediaFileUpload
          organization={items.find((organization) => organization.uuid === selectedOrganization)}
          open={uploadModalOpen}
          setOpen={setUploadModalOpen}
        />
      </Box>
      <Typography
        variant={'subtitle2'}
        // color={'textSecondary'}
        sx={{ mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {RequestStatus.isFetching(locationsFetchStatus) ? (
          <CircularProgress size={12} sx={{ mx: 1 }} />
        ) : (
          getActiveBuildingsAmount()
        )}{' '}
        active out of{' '}
        {RequestStatus.isFetching(fetchStatus) ? (
          <CircularProgress size={12} sx={{ mx: 1 }} />
        ) : (
          getMaxBuildingsAmount()
        )}{' '}
        available
      </Typography>
      <Divider sx={{ mt: 1, mx: -3 }} />
    </Box>
  );
};

export default LocationsSidebarToolbar;
