import { createSlice, createAsyncThunk, PayloadAction, current } from '@reduxjs/toolkit';
import _ from 'lodash';
import ApiRequest from '../utils/ApiRequest';
import { RequestStatus } from '../utils/RequestStatus';
import { MessagingThread } from '../types/messaging_thread';
import { MessagingMessage } from '../types/messaging_message';
import { MessagingParticipant } from '../types/messaging_participant';
import axios from 'axios';
import { getCursorFromUrl } from '../utils/listCursorHelper';
import { MessagingLocation } from '../types/messaging_location';
import { TranslateTargetLanguage } from '../types/translate_target_language';
import { ThreadParticipant } from '../types/thread_participant';

export interface ScheduledMessageType {
  uuid: string;
  thread: string;
  name: string;
  text: string;
  image: {
    uuid: string;
    url: string;
    thumbnails: {
      avatar: string;
      thumb: string;
    };
  };
  file: {
    url: string;
    size: number;
    name: string;
    mime_type: string;
  };
  created: string;
  modified: string;
  sending_rules?: Array<{
    uuid: string;
    start_sending_at: string;
    repeat_every: string;
  }>;
}

interface MessagingState {
  threadsList: {
    items: Array<MessagingThread>;
    fetchStatus: string | null;
    next: string | null;
    previous: string | null;
    count: number;
    allCount: number;
    initialLength: number;
    wasFetchingCancelled: boolean;
    isLoadingMore: boolean;
  };
  thread: {
    details: {
      participants: Array<MessagingParticipant>;
      active_participants: Array<MessagingParticipant>;
      owner: any;
      uuid: string | null;
      type: string | null;
      unread_count: number | null;
      fetchStatus: string | null;
      object: MessagingLocation | null;
    };
    messages: {
      items: Array<MessagingMessage>;
      nextCursor: string | null;
      previousCursor: string | null;
      count: number;
      initialLength: number;
      fetchStatus: string | null;
      postFetchStatus: string | null;
      clearCurrentMessages: boolean;
    };
    newMessages: {
      items: Array<MessagingMessage>;
      count: number;
      fetchStatus: string | null;
    };
    pinnedMessages: {
      items: Array<MessagingMessage>;
      nextCursor: string | null;
      previousCursor: string | null;
      count: number;
      initialLength: number;
      fetchStatus: string | null;
    };
    actions: {
      endChatFetchStatus: string | null;
      initPaymentFetchStatus: string | null;
      requestLoginFetchStatus: string | null;
      markAllAsReadFetchStatus: string | null;
      changeMessagePinnedStatus: {
        postFetchStatus: string | null;
        messageUuid: string | null;
      };
    };
    caseManagement: {
      exportedMessagesList: {
        results: Array<any>;
        count: number;
        next: string;
        previous: string;
        fetchStatus: string | null;
      };
      exportErrors: {
        [key: string]: any;
      };
      byDateRange: {
        postFetchStatus: string | null;
      };
      bySelection: {
        postFetchStatus: string | null;
      };
      all: {
        postFetchStatus: string | null;
      };
      deleteExportedMesssagesFile: {
        fetchStatus: string | null;
      };
    };
    translations: {
      fetchStatus: string | null;
      postFetchStatus: string | null;
    };
    removeChatParticipants: {
      fetchStatus: string | null;
    };
    threadUsers: {
      fetchStatus: string | null;
      count: number;
      next: string | null;
      previous: string | null;
      items: Array<ThreadParticipant>;
    };
    inviteChatParticipants: {
      fetchStatus: string | null;
      message: string | null;
    };
    scheduledMessage: {
      get: {
        fetchStatus: string | null;
        count: number;
        next: string | null;
        previous: string | null;
        items: Array<ScheduledMessageType>;
      };
      post: {
        fetchStatus: string | null;
        error: {
          [key: string]: Array<string>;
        };
      };
      patch: {
        fetchStatus: string | null;
        error: {
          [key: string]: Array<string>;
        };
      };
      delete: {
        fetchStatus: string | null;
      };
      details: {
        fetchStatus: string | null;
        message: ScheduledMessageType;
      };
    };
    blastMessages: {
      post: {
        fetchStatus: string | null;
        errors: any;
      };
    };
  };
}

const initialState: MessagingState = {
  threadsList: {
    items: [],
    fetchStatus: RequestStatus.status.NULL,
    next: null,
    previous: null,
    count: 0,
    allCount: 0,
    initialLength: 20,
    wasFetchingCancelled: false,
    isLoadingMore: false,
  },
  thread: {
    details: {
      participants: [],
      active_participants: [],
      owner: null,
      uuid: null,
      type: null,
      unread_count: 0,
      fetchStatus: RequestStatus.status.NULL,
      object: null,
    },
    messages: {
      items: [],
      nextCursor: null,
      previousCursor: null,
      count: 0,
      initialLength: 20,
      fetchStatus: RequestStatus.status.NULL,
      postFetchStatus: RequestStatus.status.NULL,
      clearCurrentMessages: true,
    },
    newMessages: {
      items: [],
      count: 0,
      fetchStatus: RequestStatus.status.NULL,
    },
    pinnedMessages: {
      items: [],
      nextCursor: null,
      previousCursor: null,
      count: 0,
      initialLength: 20,
      fetchStatus: RequestStatus.status.NULL,
    },
    actions: {
      endChatFetchStatus: RequestStatus.status.NULL,
      initPaymentFetchStatus: RequestStatus.status.NULL,
      requestLoginFetchStatus: RequestStatus.status.NULL,
      markAllAsReadFetchStatus: RequestStatus.status.NULL,
      changeMessagePinnedStatus: {
        postFetchStatus: RequestStatus.status.NULL,
        messageUuid: null,
      },
    },
    caseManagement: {
      exportedMessagesList: {
        results: [],
        count: 0,
        next: null,
        previous: null,
        fetchStatus: RequestStatus.status.NULL,
      },
      exportErrors: {},
      byDateRange: {
        postFetchStatus: RequestStatus.status.NULL,
      },
      bySelection: {
        postFetchStatus: RequestStatus.status.NULL,
      },
      all: {
        postFetchStatus: RequestStatus.status.NULL,
      },
      deleteExportedMesssagesFile: {
        fetchStatus: RequestStatus.status.NULL,
      },
    },
    translations: {
      fetchStatus: RequestStatus.status.NULL,
      postFetchStatus: RequestStatus.status.NULL,
    },
    removeChatParticipants: {
      fetchStatus: RequestStatus.status.NULL,
    },
    threadUsers: {
      fetchStatus: RequestStatus.status.NULL,
      count: 0,
      next: null,
      previous: null,
      items: [],
    },
    inviteChatParticipants: {
      fetchStatus: RequestStatus.status.NULL,
      message: null,
    },
    scheduledMessage: {
      get: {
        fetchStatus: RequestStatus.status.NULL,
        count: 0,
        next: null,
        previous: null,
        items: [],
      },
      post: {
        fetchStatus: RequestStatus.status.NULL,
        error: {},
      },
      patch: {
        fetchStatus: RequestStatus.status.NULL,
        error: {},
      },
      delete: {
        fetchStatus: RequestStatus.status.NULL,
      },
      details: {
        fetchStatus: RequestStatus.status.NULL,
        message: null,
      },
    },
    blastMessages: {
      post: {
        fetchStatus: RequestStatus.status.NULL,
        errors: {},
      },
    },
  },
};

const ThreadsCancelToken = axios.CancelToken;
let cancelThreadsFetching;
export const getThreads: any = createAsyncThunk(
  'messaging/getThreads',
  async (
    data: {
      search?: string;
      type?: string;
      is_unanswered?: string;
      is_unclaimed?: string;
      my_threads?: string;
      is_empty?: string;
      limit?: number;
      offset?: number | string;
      isLoadingMore?: boolean;
    },
    thunkAPI
  ) => {
    const { limit: _limit, isLoadingMore, ...otherData } = data;
    const limit = _limit <= 0 ? 20 : _limit;
    return ApiRequest.get('/web/messaging/threads/', {
      cancelToken: new ThreadsCancelToken((canceler) => {
        cancelThreadsFetching = canceler;
      }),
    })({ ...otherData, limit, is_empty: false }, thunkAPI);
  }
);

const ThreadMessagesCancelToken = axios.CancelToken;
let cancelThreadMessagesFetching;
export const getThreadMessages: any = createAsyncThunk(
  'messaging/getThreadMessages',
  async (
    data: {
      id: string;
      cursor?: string;
      page_size?: string;
    },
    thunkAPI
  ) => {
    const { id, ...otherData } = data;
    return ApiRequest.get(`/web/messaging/threads/${id}/messages/`, {
      cancelToken: new ThreadMessagesCancelToken((canceler) => {
        cancelThreadMessagesFetching = canceler;
      }),
    })(otherData, thunkAPI);
  }
);

const NewThreadMessagesCancelToken = axios.CancelToken;
let cancelNewThreadMessagesFetching;
export const getNewThreadMessages: any = createAsyncThunk(
  'messaging/getNewThreadMessages',
  async (
    data: {
      id: string;
      cursor?: string;
      page_size?: string;
    },
    thunkAPI
  ) => {
    const { id, ...otherData } = data;
    return ApiRequest.get(`/web/messaging/threads/${id}/messages/`, {
      cancelToken: new NewThreadMessagesCancelToken((canceler) => {
        cancelNewThreadMessagesFetching = canceler;
      }),
    })(otherData, thunkAPI);
  }
);

export const getThreadPinnedMessages: any = createAsyncThunk(
  'messaging/getThreadPinnedMessages',
  async (
    data: {
      id: string;
      cursor?: string;
      page_size?: string;
    },
    thunkAPI
  ) => {
    const { id, ...otherData } = data;
    return ApiRequest.get(`/web/messaging/threads/${id}/messages/`, {})(
      { ...otherData, is_pinned: true },
      thunkAPI
    );
  }
);

export const getThreadDetails: any = createAsyncThunk(
  'messaging/getThreadDetails',
  async (data: { id: string }, thunkAPI) => {
    const { id, ...otherData } = data;
    return ApiRequest.get(`/web/messaging/threads/${id}/`, {})(otherData, thunkAPI);
  }
);

export const postThreadMessage: any = createAsyncThunk(
  'messaging/postThreadMessage',
  async (
    data: { id: string; text?: string; imageId: string; shouldPinMessage?: boolean },
    thunkAPI
  ) => {
    const { id, shouldPinMessage, ...otherData } = data;
    return ApiRequest.post(`/web/messaging/threads/${id}/messages/`, {})(
      { ...otherData, is_pinned: shouldPinMessage },
      thunkAPI
    );
  }
);

export const postThreadImage: any = createAsyncThunk(
  'messaging/postThreadImage',
  async (data: { id: string; image: any }, thunkAPI) => {
    const { id, image } = data;
    const formData = new FormData();
    formData.append('image', image.file[0]);
    return ApiRequest.post(`/web/files/`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })(formData, thunkAPI);
  }
);

export const postEndChat: any = createAsyncThunk(
  'messaging/postEndChat',
  async (data: { uuid: string }, thunkAPI) => {
    const { uuid, ...otherData } = data;
    return ApiRequest.post(`/web/messaging/threads/${uuid}/end-chat/`, {})(otherData, thunkAPI);
  }
);

export const postInitPayment: any = createAsyncThunk(
  'messaging/postInitPayment',
  async (data: { uuid: string; amount: string }, thunkAPI) => {
    const { uuid, ...otherData } = data;
    return ApiRequest.post(`/web/messaging/threads/${uuid}/init-payment/`, {})(otherData, thunkAPI);
  }
);

export const postRequestLogin: any = createAsyncThunk(
  'messaging/postRequestLogin',
  async (data: { uuid: string }, thunkAPI) => {
    const { uuid, ...otherData } = data;
    return ApiRequest.post(`/web/messaging/threads/${uuid}/request-login/`, {})({}, thunkAPI);
  }
);

export const postMarkAllAsRead: any = createAsyncThunk(
  'messaging/postMarkAllAsRead',
  async (data: {}, thunkAPI) => {
    const { ...otherData } = data;
    return ApiRequest.post(`/web/messaging/threads/mark-all-as-read/`, {})(otherData, thunkAPI);
  }
);

export const changeMesssagePinnedStatus: any = createAsyncThunk(
  'messaging/changeMesssagePinnedStatus',
  async (data: { is_pinned: boolean; thread_uuid: string; uuid: string }, thunkAPI) => {
    return ApiRequest.put(
      `/web/messaging/threads/${data.thread_uuid}/messages/${data.uuid}/pin/`,
      {}
    )({ is_pinned: data.is_pinned }, thunkAPI);
  }
);

export const getExportedMessages: any = createAsyncThunk(
  'messaging/getExportedMessages',
  async (data: { limit: number; offset: number }, thunkAPI) => {
    return ApiRequest.get(`/web/messaging/cases/`, {})(data, thunkAPI);
  }
);

export const getExportedMessagesForThread: any = createAsyncThunk(
  'messaging/getExportedMessagesForThread',
  async (data: { thread_uuid: string; limit: number; offset: number }, thunkAPI) => {
    const { thread_uuid, ...otherData } = data;
    return ApiRequest.get(`/web/messaging/threads/${thread_uuid}/cases/`, {})(otherData, thunkAPI);
  }
);

export const exportMessagesByDateRange: any = createAsyncThunk(
  'messaging/exportByDateRange',
  async (
    data: { name: string; start_date: string; end_date: string; thread_uuid: string },
    thunkAPI
  ) => {
    const { name, start_date, end_date, thread_uuid } = data;
    return ApiRequest.post(`/web/messaging/threads/${thread_uuid}/cases/`, {})(
      {
        name,
        start_date,
        end_date,
      },
      thunkAPI
    );
  }
);

export const exportMessagesBySelection: any = createAsyncThunk(
  'messaging/exportBySelection',
  async (
    data: {
      name: string;
      start_message_uuid: string;
      end_maessage_uuid: string;
      thread_uuid: string;
    },
    thunkAPI
  ) => {
    return ApiRequest.post(`/web/messaging/threads/${data.thread_uuid}/cases/`, {})(data, thunkAPI);
  }
);

export const exportAllMessages: any = createAsyncThunk(
  'messaging/exportAll',
  async (data: { name: string; thread_uuid: string }, thunkAPI) => {
    return ApiRequest.post(`/web/messaging/threads/${data.thread_uuid}/cases/`, {})(data, thunkAPI);
  }
);

export const deleteExportedMessagesFile: any = createAsyncThunk(
  'messaging/deleteExportedMessagesFile',
  async (data: { uuid: string }, thunkAPI) => {
    return ApiRequest.delete(`/web/messaging/cases/${data.uuid}/`, {})(data, thunkAPI);
  }
);

export const getTranslationsForMessage: any = createAsyncThunk(
  'messaging/getTranslationsForMessage',
  async (data: { threadUuid: string; messageUuid: string }, thunkAPI) => {
    return ApiRequest.get(
      `/web/messaging/threads/${data.threadUuid}/messages/${data.messageUuid}/translations/`,
      {}
    )({}, thunkAPI);
  }
);

export const translateMessage: any = createAsyncThunk(
  'messaging/translateMessage',
  async (
    data: {
      threadUuid: string;
      messageUuid: string;
      target_language: TranslateTargetLanguage;
    },
    thunkAPI
  ) => {
    return ApiRequest.post(
      `/web/messaging/threads/${data.threadUuid}/messages/${data.messageUuid}/translations/`,
      {}
    )({ target_language: data.target_language }, thunkAPI);
  }
);

export const removeChatParticipants: any = createAsyncThunk(
  'messaging/removeParticipantsFromChat',
  async (data: { threadUuid: string; uuids: Array<string> }, thunkAPI) => {
    const { threadUuid, uuids } = data;
    return ApiRequest.post(`/web/messaging/threads/${threadUuid}/participants/remove/`, {})(
      { participants: uuids },
      thunkAPI
    );
  }
);

export const getThreadUsers: any = createAsyncThunk(
  'messaging/getThreadUsers',
  async (
    data: {
      thread_uuid: string;
      search: string;
      my_organizations: boolean;
      limit: number;
      offset: number;
    },
    thunkAPI
  ) => {
    return ApiRequest.get(`/web/messaging/threads/users/`, {})(data, thunkAPI);
  }
);

export const inviteChatParticipants: any = createAsyncThunk(
  'messaging/inviteChatParticipants',
  async (
    data: {
      threadUuid: string;
      accesses: Array<{
        user: string;
        period?: number;
      }>;
    },
    thunkAPI
  ) => {
    const { threadUuid, accesses } = data;
    return ApiRequest.post(`/web/messaging/threads/${threadUuid}/participants/invite/`, {})(
      { accesses },
      thunkAPI
    );
  }
);

export const getScheduledMessages: any = createAsyncThunk(
  'messaging/getScheduledMessages',
  async (
    data: {
      thread_uuid?: string;
      ordering: 'created' | 'modified' | 'last_send_at';
      limit: number;
      offset: number;
      search: string;
    },
    thunkAPI
  ) => {
    const { thread_uuid, ordering = 'created', limit, offset, search } = data;
    return ApiRequest.get(`/web/messaging/scheduled-messages/`, {})(
      {
        ordering,
        limit,
        offset,
        search,
        ...(thread_uuid && { thread_uuid }),
      },
      thunkAPI
    );
  }
);

export const getScheduledMessageDetails: any = createAsyncThunk(
  'messaging/getScheduledMessageDetails',
  async (
    data: {
      uuid: string;
    },
    thunkAPI
  ) => {
    return ApiRequest.get(`/web/messaging/scheduled-messages/${data.uuid}`, {})({}, thunkAPI);
  }
);

export const postScheduledMessage: any = createAsyncThunk(
  'messaging/postScheduledMessage',
  async (
    data: {
      thread: string;
      name: string;
      text: string;
      image: string;
      file: string;
      sending_rules: Array<{
        start_sending_at: string;
        repeat_every: string;
      }>;
    },
    thunkAPI
  ) => {
    return ApiRequest.post(`/web/messaging/scheduled-messages/`, {})(data, thunkAPI);
  }
);

export const patchScheduledMessage: any = createAsyncThunk(
  'messaging/patchScheduledMessage',
  async (
    data: {
      uuid: string;
      thread: string;
      name: string;
      text: string;
      image: string;
      file: string;
      sending_rules: Array<{
        start_sending_at: string;
        repeat_every: string;
      }>;
    },
    thunkAPI
  ) => {
    const { uuid, ...other } = data;
    return ApiRequest.patch(`/web/messaging/scheduled-messages/${uuid}`, {})(other, thunkAPI);
  }
);

export const deleteScheduledMessage: any = createAsyncThunk(
  'messaging/deleteScheduledMessage',
  async (
    data: {
      uuid: string;
    },
    thunkAPI
  ) => {
    return ApiRequest.delete(`/web/messaging/scheduled-messages/${data.uuid}`, {})({}, thunkAPI);
  }
);

export const postBlastMessage: any = createAsyncThunk(
  'messaging/postBlastMessage',
  async (
    data: {
      name: string;
      text: string;
      image: string;
      file: string;
      audience: 'all' | 'technicians' | 'customers';
    },
    thunkAPI
  ) => {
    return ApiRequest.post(`/web/messaging/blast-messages/`, {})(data, thunkAPI);
  }
);

const slice = createSlice({
  name: 'messaging',
  initialState,
  reducers: {
    setPinnedMessageUuid(state: MessagingState, action?: PayloadAction<any>): void {
      state.thread.actions.changeMessagePinnedStatus.messageUuid = action.payload;
    },
    setClearCurrentMessages(state: MessagingState, action?: PayloadAction<any>): void {
      state.thread.messages.clearCurrentMessages = action.payload;
    },
    clearThreadsList(state: MessagingState, action?: PayloadAction<any>): void {
      state.threadsList = initialState.threadsList;
    },
    clearThreadDetails(state: MessagingState, action?: PayloadAction<any>): void {
      state.thread.details = initialState.thread.details;
    },
    clearThreadMessages(state: MessagingState, action?: PayloadAction<any>): void {
      state.thread.messages = initialState.thread.messages;
    },
    clearNewThreadMessages(state: MessagingState, action?: PayloadAction<any>): void {
      state.thread.newMessages = initialState.thread.newMessages;
    },
    clearThreadPinnedMessages(state: MessagingState, action?: PayloadAction<any>): void {
      state.thread.pinnedMessages = initialState.thread.pinnedMessages;
    },
    clearThreadMessagesPost(state: MessagingState, action?: PayloadAction<any>): void {
      state.thread.messages.postFetchStatus = initialState.thread.messages.postFetchStatus;
    },
    setThreadsListFetchStatus(state: MessagingState, action?: PayloadAction<string | null>): void {
      state.threadsList.fetchStatus = action.payload || RequestStatus.status.NULL;
    },
    setMarkAllAsReadFetchStatus(
      state: MessagingState,
      action?: PayloadAction<string | null>
    ): void {
      state.thread.actions.markAllAsReadFetchStatus = action.payload || RequestStatus.status.NULL;
    },
    resetExportedMessages(state: MessagingState, action?: PayloadAction<any>): void {
      state.thread.caseManagement = initialState.thread.caseManagement;
    },
    resetDeleteExportedMessagesFileStatus(
      state: MessagingState,
      action?: PayloadAction<any>
    ): void {
      state.thread.caseManagement.deleteExportedMesssagesFile.fetchStatus =
        RequestStatus.status.NULL;
    },
    resetTranslationsFetchStatuses(state: MessagingState, action?: PayloadAction<any>): void {
      state.thread.translations = initialState.thread.translations;
    },
    resetRemoveChatParticipants(state: MessagingState, action?: PayloadAction<any>) {
      state.thread.removeChatParticipants = initialState.thread.removeChatParticipants;
    },
    resetGetThreadUsers(state: MessagingState, action?: PayloadAction<any>) {
      state.thread.threadUsers = initialState.thread.threadUsers;
    },
    resetInviteChatParticipants(state: MessagingState, action?: PayloadAction<any>) {
      state.thread.inviteChatParticipants = initialState.thread.inviteChatParticipants;
    },
    resetPostScheduledMessageStatus(state: MessagingState, action?: PayloadAction<any>) {
      state.thread.scheduledMessage.post = initialState.thread.scheduledMessage.post;
    },
    resetPatchScheduledMessageStatus(state: MessagingState, action?: PayloadAction<any>) {
      state.thread.scheduledMessage.patch = initialState.thread.scheduledMessage.patch;
    },
    resetDeleteScheduledMessageStatus(state: MessagingState, action?: PayloadAction<any>) {
      state.thread.scheduledMessage.delete = initialState.thread.scheduledMessage.delete;
    },
    resetPostBlastMessage(state: MessagingState, action?: PayloadAction<any>) {
      state.thread.blastMessages.post = initialState.thread.blastMessages.post;
    },
    resetGetScheduledMessageDetails(state: MessagingState, action?: PayloadAction<any>) {
      state.thread.scheduledMessage.details = initialState.thread.scheduledMessage.details;
    },
  },
  extraReducers: {
    [getThreads.pending]: (state, action) => {
      state.threadsList.wasFetchingCancelled = false;
      state.threadsList.fetchStatus = RequestStatus.status.FETCHING;
      state.threadsList.isLoadingMore = !!action?.meta?.arg?.isLoadingMore;
    },
    [getThreads.fulfilled]: (state, action) => {
      // case for "load more" only
      if (action.payload?.previous) {
        state.threadsList.items = [...state.threadsList.items, ...action.payload?.results];
        state.threadsList.count = state.threadsList.count + action.payload?.results?.length;
      } else {
        state.threadsList.items = action.payload?.results;
        state.threadsList.count = action.payload?.results?.length || 0;
      }
      state.threadsList.allCount = action.payload?.count || 0;
      state.threadsList.next = action.payload?.next;
      state.threadsList.previous = action.payload?.previous;
      state.threadsList.wasFetchingCancelled = false;
      state.threadsList.isLoadingMore = false;
      state.threadsList.fetchStatus = RequestStatus.status.DONE;
    },
    [getThreads.rejected]: (state, action) => {
      state.threadsList.wasFetchingCancelled = action?.error?.message === 'Rejected';
      state.threadsList.isLoadingMore = false;
      state.threadsList.fetchStatus = RequestStatus.status.ERROR;
    },
    [getThreadMessages.pending]: (state, action) => {
      state.thread.messages.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getThreadMessages.fulfilled]: (state, action) => {
      const currentState = current(state);
      if (currentState.thread.messages.clearCurrentMessages) {
        // state.thread.messages.clearCurrentMessages = false;
        state.thread.messages.items = _.reverse(action.payload?.results);
        state.thread.messages.count =
          action.payload?.results?.length < state.thread.messages.initialLength
            ? state.thread.messages.initialLength
            : action.payload?.results?.length;
        state.thread.messages.nextCursor = getCursorFromUrl(action.payload.next);
        state.thread.messages.previousCursor = getCursorFromUrl(action.payload.previous);
      } else if (
        Date.parse(currentState.thread.messages.items[0]?.created) >
        Date.parse(action.payload?.results?.[0]?.created)
      ) {
        state.thread.messages.items = _.reverse([
          ..._.reverse(state.thread.messages.items),
          ...action.payload?.results,
        ]);
        state.thread.messages.count = state.thread.messages.count + action.payload?.results?.length;
        state.thread.messages.nextCursor = getCursorFromUrl(action.payload.next);
      } else if (
        Date.parse(
          currentState.thread.messages.items[currentState.thread.messages.items?.length - 1]
            ?.created
        ) < Date.parse(action.payload?.results?.[action.payload?.results?.length - 1]?.created)
      ) {
        state.thread.messages.items = _.reverse([
          ...action.payload?.results,
          ..._.reverse(state.thread.messages.items),
        ]);

        state.thread.messages.previousCursor = getCursorFromUrl(action.payload.previous);
        state.thread.messages.count = state.thread.messages.count + action.payload?.results?.length;
      }
      state.thread.messages.fetchStatus = RequestStatus.status.DONE;
    },
    [getThreadMessages.rejected]: (state, action) => {
      state.thread.messages.fetchStatus = RequestStatus.status.ERROR;
      state.thread.messages.items = initialState.thread.messages.items;
      state.thread.messages.nextCursor = initialState.thread.messages.nextCursor;
      state.thread.messages.previousCursor = initialState.thread.messages.previousCursor;
      state.thread.messages.count = initialState.thread.messages.count;
    },
    [getNewThreadMessages.pending]: (state, action) => {
      state.thread.newMessages.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getNewThreadMessages.fulfilled]: (state, action) => {
      state.thread.newMessages.items = _.reverse(action.payload?.results);
      state.thread.newMessages.count = action.payload?.results?.length;
      state.thread.newMessages.fetchStatus = RequestStatus.status.DONE;
    },
    [getNewThreadMessages.rejected]: (state, action) => {
      state.thread.newMessages.fetchStatus = RequestStatus.status.ERROR;
      state.thread.newMessages.items = initialState.thread.newMessages.items;
      state.thread.newMessages.count = initialState.thread.newMessages.count;
    },
    [getThreadPinnedMessages.pending]: (state, action) => {
      state.thread.pinnedMessages.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getThreadPinnedMessages.fulfilled]: (state, action) => {
      if (action.payload?.previous) {
        state.thread.pinnedMessages.items = [
          ...state.thread.pinnedMessages.items,
          ...action.payload?.results,
        ];
        state.thread.pinnedMessages.count =
          state.thread.pinnedMessages.count + action.payload?.results?.length;
      } else {
        state.thread.pinnedMessages.items = action.payload?.results;
        state.thread.pinnedMessages.count =
          action.payload?.results?.length < state.thread.pinnedMessages.initialLength
            ? state.thread.pinnedMessages.initialLength
            : action.payload?.results?.length;
      }
      state.thread.pinnedMessages.nextCursor = getCursorFromUrl(action.payload.next);
      state.thread.pinnedMessages.previousCursor = getCursorFromUrl(action.payload.previous);
      state.thread.pinnedMessages.fetchStatus = RequestStatus.status.DONE;
    },
    [getThreadPinnedMessages.rejected]: (state, action) => {
      state.thread.pinnedMessages.fetchStatus = RequestStatus.status.ERROR;
      state.thread.pinnedMessages.items = initialState.thread.pinnedMessages.items;
      state.thread.pinnedMessages.nextCursor = initialState.thread.pinnedMessages.nextCursor;
      state.thread.pinnedMessages.previousCursor =
        initialState.thread.pinnedMessages.previousCursor;
      state.thread.pinnedMessages.count = initialState.thread.pinnedMessages.count;
    },
    [getThreadDetails.pending]: (state, action) => {
      state.thread.details.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getThreadDetails.fulfilled]: (state, action) => {
      state.thread.details = action.payload;
      state.thread.details.fetchStatus = RequestStatus.status.DONE;
    },
    [getThreadDetails.rejected]: (state, action) => {
      state.thread.details.fetchStatus = RequestStatus.status.ERROR;
    },
    [postThreadMessage.pending]: (state, action) => {
      state.thread.messages.postFetchStatus = RequestStatus.status.FETCHING;
    },
    [postThreadMessage.fulfilled]: (state, action) => {
      state.thread.messages.postFetchStatus = RequestStatus.status.DONE;
    },
    [postThreadMessage.rejected]: (state, action) => {
      state.thread.messages.postFetchStatus = RequestStatus.status.ERROR;
    },
    [postEndChat.pending]: (state, action) => {
      state.thread.actions.endChatFetchStatus = RequestStatus.status.FETCHING;
    },
    [postEndChat.fulfilled]: (state, action) => {
      state.thread.actions.endChatFetchStatus = RequestStatus.status.DONE;
    },
    [postEndChat.rejected]: (state, action) => {
      state.thread.actions.endChatFetchStatus = RequestStatus.status.ERROR;
    },
    [postInitPayment.pending]: (state, action) => {
      state.thread.actions.initPaymentFetchStatus = RequestStatus.status.FETCHING;
    },
    [postInitPayment.fulfilled]: (state, action) => {
      state.thread.actions.initPaymentFetchStatus = RequestStatus.status.DONE;
    },
    [postInitPayment.rejected]: (state, action) => {
      state.thread.actions.initPaymentFetchStatus = RequestStatus.status.ERROR;
    },
    [postRequestLogin.pending]: (state, action) => {
      state.thread.actions.requestLoginFetchStatus = RequestStatus.status.FETCHING;
    },
    [postRequestLogin.fulfilled]: (state, action) => {
      state.thread.actions.requestLoginFetchStatus = RequestStatus.status.DONE;
    },
    [postRequestLogin.rejected]: (state, action) => {
      state.thread.actions.requestLoginFetchStatus = RequestStatus.status.ERROR;
    },
    [postMarkAllAsRead.pending]: (state, action) => {
      state.thread.actions.markAllAsReadFetchStatus = RequestStatus.status.FETCHING;
    },
    [postMarkAllAsRead.fulfilled]: (state, action) => {
      state.thread.actions.markAllAsReadFetchStatus = RequestStatus.status.DONE;
    },
    [postMarkAllAsRead.rejected]: (state, action) => {
      state.thread.actions.markAllAsReadFetchStatus = RequestStatus.status.ERROR;
    },
    [changeMesssagePinnedStatus.pending]: (state, action) => {
      state.thread.actions.changeMessagePinnedStatus.postFetchStatus =
        RequestStatus.status.FETCHING;
    },
    [changeMesssagePinnedStatus.fulfilled]: (state, action) => {
      const currentState = current(state);
      state.thread.messages.items = currentState.thread.messages.items.map((message) =>
        message.uuid === state.thread.actions.changeMessagePinnedStatus.messageUuid
          ? { ...message, is_pinned: action.payload.is_pinned }
          : message
      );
      state.thread.actions.changeMessagePinnedStatus.messageUuid = null;
      state.thread.actions.changeMessagePinnedStatus.postFetchStatus = RequestStatus.status.DONE;
    },
    [changeMesssagePinnedStatus.rejected]: (state, action) => {
      state.thread.actions.changeMessagePinnedStatus.postFetchStatus = RequestStatus.status.ERROR;
    },

    [getExportedMessages.pending]: (state, action) => {
      state.thread.caseManagement.exportedMessagesList.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getExportedMessages.fulfilled]: (state, action) => {
      state.thread.caseManagement.exportedMessagesList = {
        ...action.payload,
        fetchStatus: RequestStatus.status.DONE,
      };
    },
    [getExportedMessages.rejected]: (state, action) => {
      state.thread.caseManagement.exportedMessagesList.fetchStatus = RequestStatus.status.ERROR;
    },

    [getExportedMessagesForThread.pending]: (state, action) => {
      state.thread.caseManagement.exportedMessagesList.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getExportedMessagesForThread.fulfilled]: (state, action) => {
      state.thread.caseManagement.exportedMessagesList = {
        ...action.payload,
        fetchStatus: RequestStatus.status.DONE,
      };
    },
    [getExportedMessagesForThread.rejected]: (state, action) => {
      state.thread.caseManagement.exportedMessagesList.fetchStatus = RequestStatus.status.ERROR;
    },

    [exportMessagesByDateRange.pending]: (state, action) => {
      state.thread.caseManagement.byDateRange.postFetchStatus = RequestStatus.status.FETCHING;
    },
    [exportMessagesByDateRange.fulfilled]: (state, action) => {
      state.thread.caseManagement.byDateRange.postFetchStatus = RequestStatus.status.DONE;
    },
    [exportMessagesByDateRange.rejected]: (state, action) => {
      state.thread.caseManagement.exportErrors = action.payload;
      state.thread.caseManagement.byDateRange.postFetchStatus = RequestStatus.status.ERROR;
    },

    [exportMessagesBySelection.pending]: (state, action) => {
      state.thread.caseManagement.bySelection.postFetchStatus = RequestStatus.status.FETCHING;
    },
    [exportMessagesBySelection.fulfilled]: (state, action) => {
      state.thread.caseManagement.bySelection.postFetchStatus = RequestStatus.status.DONE;
    },
    [exportMessagesBySelection.rejected]: (state, action) => {
      state.thread.caseManagement.exportErrors = action.payload;
      state.thread.caseManagement.bySelection.postFetchStatus = RequestStatus.status.ERROR;
    },

    [exportAllMessages.pending]: (state, action) => {
      state.thread.caseManagement.all.postFetchStatus = RequestStatus.status.FETCHING;
    },
    [exportAllMessages.fulfilled]: (state, action) => {
      state.thread.caseManagement.all.postFetchStatus = RequestStatus.status.DONE;
    },
    [exportAllMessages.rejected]: (state, action) => {
      state.thread.caseManagement.exportErrors = action.payload;
      state.thread.caseManagement.all.postFetchStatus = RequestStatus.status.ERROR;
    },

    [deleteExportedMessagesFile.pending]: (state, action) => {
      state.thread.caseManagement.deleteExportedMesssagesFile.fetchStatus =
        RequestStatus.status.FETCHING;
    },
    [deleteExportedMessagesFile.fulfilled]: (state, action) => {
      state.thread.caseManagement.deleteExportedMesssagesFile.fetchStatus =
        RequestStatus.status.DONE;
    },
    [deleteExportedMessagesFile.rejected]: (state, action) => {
      state.thread.caseManagement.deleteExportedMesssagesFile.fetchStatus =
        RequestStatus.status.ERROR;
    },

    [getTranslationsForMessage.pending]: (state, action) => {
      state.thread.translations.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getTranslationsForMessage.fulfilled]: (state, action) => {
      const currentState = current(state);
      state.thread.translations.fetchStatus = RequestStatus.status.DONE;
      state.thread.messages.items = currentState.thread.messages.items.map((message) =>
        action.meta.arg.messageUuid === message.uuid
          ? { ...message, translations: action.payload }
          : message
      );
      state.thread.newMessages.items = currentState.thread.newMessages.items.map((message) =>
        action.meta.arg.messageUuid === message.uuid
          ? { ...message, translations: action.payload }
          : message
      );
      state.thread.pinnedMessages.items = currentState.thread.pinnedMessages.items.map((message) =>
        action.meta.arg.messageUuid === message.uuid
          ? { ...message, translations: action.payload }
          : message
      );
    },
    [getTranslationsForMessage.rejected]: (state, action) => {
      state.thread.translations.fetchStatus = RequestStatus.status.ERROR;
    },

    [translateMessage.pending]: (state, action) => {
      state.thread.translations.postFetchStatus = RequestStatus.status.FETCHING;
    },
    [translateMessage.fulfilled]: (state, action) => {
      const currentState = current(state);
      state.thread.translations.postFetchStatus = RequestStatus.status.DONE;
      state.thread.messages.items = currentState.thread.messages.items.map((message) =>
        action.meta.arg.messageUuid === message.uuid
          ? { ...message, translations: action.payload }
          : message
      );
      state.thread.newMessages.items = currentState.thread.newMessages.items.map((message) =>
        action.meta.arg.messageUuid === message.uuid
          ? { ...message, translations: action.payload }
          : message
      );
      state.thread.pinnedMessages.items = currentState.thread.pinnedMessages.items.map((message) =>
        action.meta.arg.messageUuid === message.uuid
          ? { ...message, translations: action.payload }
          : message
      );
    },
    [translateMessage.rejected]: (state, action) => {
      state.thread.translations.postFetchStatus = RequestStatus.status.ERROR;
    },
    [removeChatParticipants.pending]: (state, action) => {
      state.thread.removeChatParticipants.fetchStatus = RequestStatus.status.FETCHING;
    },
    [removeChatParticipants.fulfilled]: (state, action) => {
      state.thread.removeChatParticipants.fetchStatus = RequestStatus.status.DONE;
    },
    [removeChatParticipants.rejected]: (state, action) => {
      state.thread.removeChatParticipants.fetchStatus = RequestStatus.status.ERROR;
    },

    [getThreadUsers.pending]: (state, action) => {
      state.thread.threadUsers.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getThreadUsers.fulfilled]: (state, action) => {
      state.thread.threadUsers.items = action.payload.results;
      state.thread.threadUsers.count = action.payload.count;
      state.thread.threadUsers.next = action.payload.next;
      state.thread.threadUsers.previous = action.payload.previous;
      state.thread.threadUsers.fetchStatus = RequestStatus.status.DONE;
    },
    [getThreadUsers.rejected]: (state, action) => {
      state.thread.threadUsers.fetchStatus = RequestStatus.status.ERROR;
    },

    [inviteChatParticipants.pending]: (state, action) => {
      state.thread.inviteChatParticipants.fetchStatus = RequestStatus.status.FETCHING;
    },
    [inviteChatParticipants.fulfilled]: (state, action) => {
      state.thread.inviteChatParticipants.message = action.payload.message ?? null;
      state.thread.inviteChatParticipants.fetchStatus = RequestStatus.status.DONE;
    },
    [inviteChatParticipants.rejected]: (state, action) => {
      state.thread.inviteChatParticipants.fetchStatus = RequestStatus.status.ERROR;
    },

    [getScheduledMessages.pending]: (state, action) => {
      state.thread.scheduledMessage.get.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getScheduledMessages.fulfilled]: (state, action) => {
      state.thread.scheduledMessage.get.items = action.payload.results;
      state.thread.scheduledMessage.get.count = action.payload.count;
      state.thread.scheduledMessage.get.next = action.payload.next;
      state.thread.scheduledMessage.get.previous = action.payload.previous;
      state.thread.scheduledMessage.get.fetchStatus = RequestStatus.status.DONE;
    },
    [getScheduledMessages.rejected]: (state, action) => {
      state.thread.scheduledMessage.get.fetchStatus = RequestStatus.status.ERROR;
    },

    [postScheduledMessage.pending]: (state, action) => {
      state.thread.scheduledMessage.post.fetchStatus = RequestStatus.status.FETCHING;
    },
    [postScheduledMessage.fulfilled]: (state, action) => {
      state.thread.scheduledMessage.post.fetchStatus = RequestStatus.status.DONE;
    },
    [postScheduledMessage.rejected]: (state, action) => {
      state.thread.scheduledMessage.post.error = action.payload;
      state.thread.scheduledMessage.post.fetchStatus = RequestStatus.status.ERROR;
    },

    [patchScheduledMessage.pending]: (state, action) => {
      state.thread.scheduledMessage.patch.fetchStatus = RequestStatus.status.FETCHING;
    },
    [patchScheduledMessage.fulfilled]: (state, action) => {
      state.thread.scheduledMessage.patch.fetchStatus = RequestStatus.status.DONE;
    },
    [patchScheduledMessage.rejected]: (state, action) => {
      state.thread.scheduledMessage.patch.error = action.payload;
      state.thread.scheduledMessage.patch.fetchStatus = RequestStatus.status.ERROR;
    },

    [deleteScheduledMessage.pending]: (state, action) => {
      state.thread.scheduledMessage.delete.fetchStatus = RequestStatus.status.FETCHING;
    },
    [deleteScheduledMessage.fulfilled]: (state, action) => {
      state.thread.scheduledMessage.delete.fetchStatus = RequestStatus.status.DONE;
    },
    [deleteScheduledMessage.rejected]: (state, action) => {
      state.thread.scheduledMessage.delete.fetchStatus = RequestStatus.status.ERROR;
    },

    [postBlastMessage.pending]: (state, action) => {
      state.thread.blastMessages.post.fetchStatus = RequestStatus.status.FETCHING;
    },
    [postBlastMessage.fulfilled]: (state, action) => {
      state.thread.blastMessages.post.fetchStatus = RequestStatus.status.DONE;
    },
    [postBlastMessage.rejected]: (state, action) => {
      state.thread.blastMessages.post.errors = action.payload.hasOwnProperty('detail')
        ? { detail: [action.payload.detail] }
        : action.payload;
      state.thread.blastMessages.post.fetchStatus = RequestStatus.status.ERROR;
    },

    [getScheduledMessageDetails.pending]: (state, action) => {
      state.thread.scheduledMessage.details.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getScheduledMessageDetails.fulfilled]: (state, action) => {
      state.thread.scheduledMessage.details.message = action.payload;
      state.thread.scheduledMessage.details.fetchStatus = RequestStatus.status.DONE;
    },
    [getScheduledMessageDetails.rejected]: (state, action) => {
      state.thread.scheduledMessage.details.fetchStatus = RequestStatus.status.ERROR;
    },
  },
});

export const { reducer } = slice;
export const {
  setPinnedMessageUuid,
  setClearCurrentMessages,
  clearThreadsList,
  clearThreadDetails,
  clearThreadMessages,
  clearNewThreadMessages,
  clearThreadPinnedMessages,
  clearThreadMessagesPost,
  setThreadsListFetchStatus,
  setMarkAllAsReadFetchStatus,
  resetExportedMessages,
  resetDeleteExportedMessagesFileStatus,
  resetTranslationsFetchStatuses,
  resetRemoveChatParticipants,
  resetGetThreadUsers,
  resetInviteChatParticipants,
  resetPostScheduledMessageStatus,
  resetPatchScheduledMessageStatus,
  resetDeleteScheduledMessageStatus,
  resetPostBlastMessage,
  resetGetScheduledMessageDetails,
} = slice.actions;

export { cancelThreadsFetching, cancelThreadMessagesFetching, cancelNewThreadMessagesFetching };

export default slice;
