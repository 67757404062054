import { useState, useEffect, useCallback, ChangeEvent, createContext } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { customerApi } from '../../__fakeApi__/customerApi';
import { CustomerListTable } from '../../components/dashboard/customer';
import useMounted from '../../hooks/useMounted';
import ChevronRightIcon from '../../icons/ChevronRight';
import DownloadIcon from '../../icons/Download';
import PlusIcon from '../../icons/Plus';
import UploadIcon from '../../icons/Upload';
import useSettings from '../../hooks/useSettings';
import gtm from '../../lib/gtm';
import type { Customer } from '../../types/customer';
import GenericBreadcrumb from '../../components/GenericBreadcrumb';
import { getOrganizationRoutes } from '../../routes';
import { getOrganizationsDetailsPath, pathIdKey } from '../../routesPaths';
import { RootState, useDispatch, useSelector } from '../../store';
import { getOrganizationDetails } from '../../slices/organizations';
import { replaceLastUUIDInOrganizationBreadcrumbWith } from '../../slices/config';

interface OrganizationsListProps {
  children: any;
  areTabsVisible?: boolean;
}

const tabs = [
  {
    label: 'Profile',
    value: 'profile',
  },
  {
    label: 'Customers',
    value: 'customers',
  },
  {
    label: 'Technicians',
    value: 'technicians',
  },
  {
    label: 'Administrators',
    value: 'administrators',
  },
];

const OrganizationsList: FC<OrganizationsListProps> = ({ children, areTabsVisible = true }) => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uuid: organizationId } = useParams();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [currentTab, setCurrentTab] = useState<string>();

  const organizationRoutes = getOrganizationRoutes();

  const organizationName = useSelector(
    (state: RootState) => state.organizations?.details?.organization?.name
  );

  const replaceLastUUIDWith = useSelector(
    (state: RootState) => state.config.breadcrumb.replaceLastUUIDWith
  );

  const getCustomers = useCallback(async () => {
    try {
      const data = await customerApi.getCustomers();

      if (mounted.current) {
        setCustomers(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  const handleTabsChange = (event: ChangeEvent<{}>, value: string) => {
    if (value === 'profile') {
      navigate(getOrganizationsDetailsPath({ id: organizationId }));
    } else {
      const foundRouteToNavigate = organizationRoutes.find((route) => route.path.includes(value));
      const pathToNavigate = foundRouteToNavigate?.path?.replace(
        pathIdKey,
        organizationId || '9999'
      );
      navigate(pathToNavigate);
    }
  };

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(() => {
    let tabToSet = 'profile';
    tabs.forEach((tab) => {
      if (location.pathname.includes(tab.value)) tabToSet = tab.value;
    });
    setCurrentTab(tabToSet);
    return () => {
      dispatch(replaceLastUUIDInOrganizationBreadcrumbWith(null));
    };
  }, [location.pathname]);

  useEffect(() => {
    if (!organizationName && organizationId) {
      dispatch(getOrganizationDetails({ id: organizationId }));
    }
  }, [location.pathname, organizationId]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  return (
    <>
      <Helmet>
        <title>Dashboard Organizations</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Organizations List
              </Typography>
              <GenericBreadcrumb
                replaceFirstUUIDWith={organizationName}
                replaceLastUUIDWith={replaceLastUUIDWith}
              />
              <Box
                sx={{
                  mb: -1,
                  mx: -1,
                  mt: 1,
                }}
              ></Box>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}></Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card sx={{ overflow: 'visible' }}>
              {areTabsVisible && (
                <>
                  <Tabs
                    indicatorColor="primary"
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    textColor="primary"
                    value={currentTab}
                    variant="scrollable"
                  >
                    {tabs.map((tab) => (
                      <Tab key={tab.value} label={tab.label} value={tab.value} />
                    ))}
                  </Tabs>
                  <Divider />
                </>
              )}
              {children}
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default OrganizationsList;
