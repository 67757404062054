import type { FC } from 'react';
import { RootState, useDispatch, useSelector } from '../../store';
import { clearFilesForGivenKey, clearUploadDetails, postFile } from '../../slices/fileUpload';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { RequestStatus } from '../../utils/RequestStatus';
import FileDropzone from '../../components/FileDropzone';
import { experimentalStyled } from '@mui/material/styles';

const FileUploadInputWrapper = experimentalStyled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    maxWidth: 'unset',
  },
}));

const DropzoneContainer = experimentalStyled('div')``;

interface FileUploadInputProps {
  setValue: any;
  name: string;
  defaultValue: any;
  control: any;
  isEditMode: boolean;
  isAvatar?: boolean;
  isPdf?: boolean;
  storeKey: string;
  validationMessage?: string;
  disabled?: boolean;
}

const FileUploadInput: FC<FileUploadInputProps> = (props) => {
  const {
    setValue,
    name,
    defaultValue,
    isEditMode,
    validationMessage,
    storeKey,
    isAvatar = true,
    isPdf = false,
    disabled = false,
  } = props;
  const dispatch = useDispatch();

  const { fetchStatus, uploadResponse } = useSelector(
    (state: RootState) => state.fileUpload.files[storeKey] || {}
  );

  const [files, setFiles] = useState<any[]>([]);
  const [avatarPreviewUrl, setAvatarPreviewUrl] = useState<string | null>(null);

  const handleDrop = (newFiles: any): void => {
    setFiles(newFiles);
    dispatch(postFile({ file: newFiles[0], storeKey, ...{ type: isPdf ? 'pdf-file' : 'image' } }));
  };

  const handleRemove = (file): void => {
    setFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
    setValue(name, null);
  };

  const handleRemoveAll = (): void => {
    setFiles([]);
  };

  const handleRemoveAvatar = (event) => {
    event.stopPropagation();
    handleRemoveAll();
    dispatch(clearUploadDetails(null));
    setValue(name, null);
    setAvatarPreviewUrl(null);
  };

  useEffect(() => {
    setValue(name, defaultValue?.uuid || null);

    if (isPdf && defaultValue) {
      setFiles([defaultValue]);
    } else {
      setAvatarPreviewUrl(defaultValue?.thumbnails?.avatar || null);
    }
  }, [defaultValue]);

  useEffect(() => {
    setAvatarPreviewUrl(defaultValue?.thumbnails?.avatar || null);
  }, [isEditMode]);

  useEffect(() => {
    if (
      RequestStatus.isDone(fetchStatus) &&
      uploadResponse &&
      uploadResponse[0]?.payload &&
      setValue &&
      name
    ) {
      setValue(name, uploadResponse[0].payload.uuid);
      setAvatarPreviewUrl(uploadResponse[0]?.payload?.thumbnails?.avatar || null);
    }
  }, [fetchStatus]);

  useEffect(() => {
    return () => {
      if (storeKey) dispatch(clearFilesForGivenKey({ storeKey }));
    };
  }, []);

  return (
    <FileUploadInputWrapper>
      <DropzoneContainer>
        <input {...props} type={'text'} style={{ display: 'none' }} />
        <FileDropzone
          accept={`${isPdf ? 'application/pdf' : 'image/*'}`}
          maxFiles={1}
          isAvatar={isAvatar}
          onDrop={handleDrop}
          onRemove={handleRemove}
          onRemoveAll={handleRemoveAll}
          files={files}
          avatarUrl={avatarPreviewUrl}
          isLoading={RequestStatus.isFetching(fetchStatus)}
          disabled={disabled || !isEditMode}
          onRemoveAvatar={handleRemoveAvatar}
          validationMessage={validationMessage}
        />
      </DropzoneContainer>
    </FileUploadInputWrapper>
  );
};
export default FileUploadInput;
