import { createContext, useContext, useEffect, useState } from 'react';
import useModal, { SimpleActions } from '../hooks/useModal';
import { MenuItem, TextField, Typography } from '@mui/material';
import LockClockIcon from '@mui/icons-material/LockClock';
import AuthContext from './AccessTokenContext';
import { RootState, useDispatch, useSelector } from '../store';
import { RequestStatus } from '../utils/RequestStatus';
import {
  grantAccessToItem,
  grantAccessToLocation,
  resetGrantAccessStatus,
} from '../slices/locations';
import toast from 'react-hot-toast';
import { getConstants, Timeframe } from '../slices/config';
import { getThreadDetails } from '../slices/messaging';
import { useParams } from 'react-router-dom';

interface UserRequestingAccess {
  access_uuid: string;
  user: string;
  user_organization: string;
  user_organization_uuid: string;
  resource: string;
  resource_type: 'item' | 'location';
  resource_uuid: string;
  resource_organization: string;
  resource_organization_uuid: string;
  [key: string]: string;
}

export const GrantAccessToChatContext = createContext<{
  handleOpenGrantAccessModal: (user: UserRequestingAccess) => void;
  handleCloseGrantAccessModal: () => void;
}>({
  handleOpenGrantAccessModal: () => {},
  handleCloseGrantAccessModal: () => {},
});

const defaultAccessPeriod: Timeframe = {
  label: null,
  period: null,
};

const GrantAccessToChatContextProvider = (props) => {
  const { children } = props;

  const dispatch = useDispatch();

  const { item, location } = useSelector((state: RootState) => state.locations.grantAccess);
  const {
    dialogs: { access_periods },
    configFetchStatus,
  } = useSelector((state: RootState) => state.config);

  const { Component: GrantAccessModal, ...grantAccessModal } = useModal();

  const [userToGrantAccessTo, setUserToGrantAccessTo] = useState<UserRequestingAccess | null>(null);
  const [selectedAccessDuration, setSelectedAccessDuration] = useState<Timeframe>(
    access_periods[0] ?? defaultAccessPeriod
  );

  const { user } = useContext(AuthContext);
  const { uuid } = useParams(); //threadUuid

  const handleOpenGrantAccessModal = (user: UserRequestingAccess): void => {
    setUserToGrantAccessTo(user);
  };

  const handleCloseGrantAccessModal = (): void => {
    grantAccessModal.close();
    setTimeout(() => {
      setUserToGrantAccessTo(null);
      setSelectedAccessDuration(access_periods[0] ?? defaultAccessPeriod);
    }, 150);
  };

  const handleGrantAccess = (): void => {
    const params: {
      data: { access_uuid: string; period?: number };
      organization_uuid: string;
      uuid: string;
    } = {
      data: {
        access_uuid: userToGrantAccessTo.access_uuid,
        ...(selectedAccessDuration.period !== -1 && { period: selectedAccessDuration.period }),
      },
      organization_uuid: userToGrantAccessTo.resource_organization_uuid,
      uuid: userToGrantAccessTo.resource_uuid,
    };

    const callback = (): void =>
      userToGrantAccessTo.resource_type === 'item'
        ? dispatch(grantAccessToItem(params))
        : dispatch(grantAccessToLocation(params));

    callback();
  };

  useEffect(() => {
    if (!!userToGrantAccessTo) grantAccessModal.open();
  }, [userToGrantAccessTo]);

  useEffect(() => {
    if (
      RequestStatus.isDone(item.fetchStatus) ||
      (RequestStatus.isDone(location.fetchStatus) && (item.message || location.message))
    ) {
      handleCloseGrantAccessModal();
      toast.success(item.message ?? location.message ?? 'Access granted');
      !!uuid && dispatch(getThreadDetails({ id: uuid }));
      dispatch(resetGrantAccessStatus(null));
    }
    if (
      RequestStatus.isError(item.fetchStatus) ||
      (RequestStatus.isError(location.fetchStatus) && (item.error || location.error))
    ) {
      toast.error(item.error ?? location.error ?? 'Something went wrong');
      dispatch(resetGrantAccessStatus(null));
    }
  }, [
    item.fetchStatus,
    location.fetchStatus,
    item.message,
    location.message,
    item.error,
    location.error,
  ]);

  useEffect(() => {
    if (RequestStatus.isNull(configFetchStatus)) dispatch(getConstants());
  }, [configFetchStatus]);

  return (
    <GrantAccessToChatContext.Provider
      value={{
        handleOpenGrantAccessModal,
        handleCloseGrantAccessModal,
      }}
    >
      <GrantAccessModal
        {...grantAccessModal.props}
        handleClose={handleCloseGrantAccessModal}
        sx={{
          width: 'fit-content',
          height: 'fit-content',
        }}
      >
        <LockClockIcon color={'primary'} sx={{ height: '64px', width: '64px' }} />
        <Typography color="textPrimary" variant="h5" sx={{ marginBottom: '20px' }}>
          Access requested
        </Typography>
        <Typography variant={'subtitle2'}>
          Select amount of time to grant <b>{userToGrantAccessTo?.user ?? ''}</b> (from organization{' '}
          <b>{userToGrantAccessTo?.user_organization ?? ''}</b>) access to{' '}
          <b>{userToGrantAccessTo?.resource}</b> for:
        </Typography>
        <TextField
          select
          fullWidth
          sx={{ mt: 1 }}
          value={selectedAccessDuration.period}
          disabled={!RequestStatus.isDone(configFetchStatus)}
          onChange={(e) => {
            setSelectedAccessDuration(
              access_periods.find((option) => option.period === +e.target.value)
            );
          }}
          SelectProps={{
            renderValue: (selected) => (
              <Typography variant={'subtitle1'} align={'center'} sx={{ width: '100%' }}>
                {/*{access_periods.find((option) => option.period === selected)?.label || ''}*/}
                {selectedAccessDuration.label ?? ''}
              </Typography>
            ),
          }}
        >
          {access_periods.map((option) => (
            <MenuItem value={option.period} key={option.period}>
              <Typography variant={'subtitle1'} align={'center'} sx={{ width: '100%' }}>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
        <SimpleActions
          onConfirm={handleGrantAccess}
          onCancel={handleCloseGrantAccessModal}
          sx={{ mt: 4 }}
          loading={
            RequestStatus.isFetching(item.fetchStatus) ||
            RequestStatus.isFetching(location.fetchStatus)
          }
          confirmLabel={'Grant Access'}
        />
      </GrantAccessModal>
      {children}
    </GrantAccessToChatContext.Provider>
  );
};

export default GrantAccessToChatContextProvider;
