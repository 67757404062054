// workaround, FCM onMessage method from lib is broken + sockets for Safari
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import firebase, { isFCMChannelSupported } from '../lib/firebase';
import {
  DecodedNotificationData,
  FCMNotificationEvent,
  SocketNotificationEvent,
} from '../types/notificationMessage';

const socketReconnectTimeout = 5 * 1000;
const retryCount = 5;
const shouldNotReconnectByCode = (event): boolean => {
  console.warn('WebSocket close error', event?.code);
  // close codes: https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code
  // obvious errors below
  // usually token error or BE error
  if ([1011].includes(event?.code)) return true;
  // usually network error
  else if ([1006].includes(event?.code)) return false;
  return false;
};
/**
 * Previous solution with 'onMessage' could trigger the same event multiple times
 * **/
export const useIsWritingSocket: any = (
  // @ts-ignore
  { uuid, token, instanceId } = {},
  watchExistingVariables
) => {
  const [message, setMessage] = useState<any>(null);
  const channelRef: MutableRefObject<any> = useRef();
  const socketRef: MutableRefObject<any> = useRef();
  const isSocketDisconnectedRef: MutableRefObject<boolean> = useRef(false);
  const timeoutRef: MutableRefObject<any> = useRef();
  const currentRetryCount: MutableRefObject<number> = useRef<number>(0);

  const tryReconnect = (event) => {
    if (shouldNotReconnectByCode(event) || currentRetryCount.current >= retryCount) return;
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(function () {
      if (!isSocketDisconnectedRef.current) return;
      currentRetryCount.current = currentRetryCount.current + 1;
      socketRef.current = null;
      connectSocket({ uuid, token, instanceId });
    }, socketReconnectTimeout);
  };

  const connectSocket = ({ uuid, token, instanceId }) => {
    if (!socketRef.current)
      socketRef.current = new WebSocket(
        `${process.env.REACT_APP_NOTIFICATION_SOCKETS_URL}threads/${uuid}/?instance_id=${instanceId}&token=${token}`
      );
    socketRef.current.onopen = (event) => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      isSocketDisconnectedRef.current = false;
      console.log('WebSocket is open now.');
    };
    socketRef.current.onerror = (event) => {
      console.error('WebSocket error observed:', event);
    };
    socketRef.current.onclose = (event) => {
      isSocketDisconnectedRef.current = true;
      console.log(`Socket closed. Reinitialization in a few sec.`);
      tryReconnect(event);
    };
    if (!socketRef.current.onmessage)
      socketRef.current.onmessage = (event: SocketNotificationEvent) => {
        let decodedData: DecodedNotificationData;
        try {
          decodedData = JSON.parse(event.data);
        } catch (error) {
          console.error('Unable to parse socket data \n', error);
        }
        setMessage(decodedData);
      };
  };

  const sendIsTyping = () => {
    if (!socketRef.current) return;
    socketRef.current.send(JSON.stringify({ type: 'thread.typing' }));
  };

  const resetMessage = () => {
    setMessage(null);
  };

  useEffect(() => {
    if (
      WebSocket &&
      uuid &&
      token &&
      !channelRef.current &&
      (!socketRef.current || (socketRef.current && !socketRef.current.onmessage))
    ) {
      connectSocket({ uuid, token, instanceId });
    } else if (!token || !uuid) {
      if (channelRef.current) channelRef.current.onmessage = null;
      if (socketRef.current) socketRef.current.onmessage = null;
      channelRef.current = null;
      socketRef.current = null;
    }
    return () => {
      if (channelRef.current) channelRef.current.onmessage = null;
      if (socketRef.current) socketRef.current.onmessage = null;
    };
  }, [watchExistingVariables]);
  return { message, sendIsTyping, resetMessage };
};
