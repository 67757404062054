import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ChangeEvent, FC, ReactNode, useState } from 'react';
import { sizes } from '../../../theme';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { PopupState as PopupStateType } from 'material-ui-popup-state/core';
import { MessagingMessage } from '../../../types/messaging_message';
import { MessagingParticipant } from '../../../types/messaging_participant';
import useModal, { SimpleActions } from '../../../hooks/useModal';
import FolderIcon from '@mui/icons-material/Folder';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import AnalyticsDatepicker from '../../Analytics/AnalyticsDatepicker';
import MessagingCaseManagementSelection from './MessagingCaseManagementSelection';
import { LoadMoreButtonsConfig } from '../../../types/messaging_load_more_buttons_config';
import { MessagingSelectedMessagesType } from '../../../types/messaging-case-management/messaging_selected_messages';
import Scrollbar from '../../../components/Scrollbar';

interface MessagingCaseManagementProps {
  messages: Array<MessagingMessage>;
  participants: Array<MessagingParticipant>;
  loadMoreButtonsConfig: LoadMoreButtonsConfig;
  handleExportMessages: (
    data: {
      name: string;
      start_date: string;
      end_date: string;
      start_message_uuid: string;
      end_message_uuid: string;
    },
    exportType: 'all' | 'dateRange' | 'selection'
  ) => void;
}

const exportTypes: {
  [key: string]: 'all' | 'dateRange' | 'selection';
} = {
  ALL: 'all',
  DATE_RANGE: 'dateRange',
  SELECTION: 'selection',
};

const MessagingCaseManagement: FC<MessagingCaseManagementProps> = (props) => {
  const { messages, participants, loadMoreButtonsConfig, handleExportMessages } = props;

  const theme = useTheme();

  const [dateRange, setDateRange] = useState<{ startDate: string; endDate: string }>({
    startDate: null,
    endDate: null,
  });

  const [selectedMessages, setSelectedMessages] = useState<MessagingSelectedMessagesType>({
    firstMessage: null,
    secondMessage: null,
  });

  const [filename, setFilename] = useState<string>('');

  const [exportType, setExportType] = useState<'all' | 'dateRange' | 'selection'>(null);
  const { Component: DateRangeMessagesExportModal, ...dateRangeMessagesExportModal } = useModal();
  const { Component: SelectionExportModal, ...selectionExportModal } = useModal();
  const { Component: ConfirmExportModal, ...confirmExportModal } = useModal();
  const handleDateRangeMessagesExport = (popupState: PopupStateType): void => {
    popupState.close();
    setExportType(exportTypes.DATE_RANGE);
    dateRangeMessagesExportModal.open();
  };
  const handleSelectionMessagesExport = (popupState: PopupStateType): void => {
    popupState.close();
    setExportType(exportTypes.SELECTION);
    selectionExportModal.open();
  };
  const handleAllMessagesExport = (popupState: PopupStateType): void => {
    popupState.close();
    setExportType(exportTypes.ALL);
    confirmExportModal.open();
  };

  const menuItems: Array<{
    value: string;
    label: string;
    onClick: (popupState: PopupStateType) => void;
    icon?: ReactNode;
  }> = [
    {
      value: 'date-range',
      label: 'Export by date range',
      onClick: handleDateRangeMessagesExport,
      icon: <CalendarTodayIcon />,
    },
    {
      value: 'selection',
      label: 'Export selection',
      onClick: handleSelectionMessagesExport,
      icon: <BallotOutlinedIcon />,
    },
    {
      value: 'all',
      label: 'Export all',
      onClick: handleAllMessagesExport,
      icon: <AutoAwesomeMotionIcon />,
    },
  ];

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilename(e.target.value);
  };

  return (
    <PopupState variant="popover" popupId="case-management-menu">
      {(popupState) => (
        <>
          <DateRangeMessagesExportModal
            {...dateRangeMessagesExportModal.props}
            onCancel={() => {
              dateRangeMessagesExportModal.close();
            }}
            sx={{
              width: 'fit-content',
              height: 'fit-content',
            }}
          >
            <Typography color="textPrimary" variant="h5" sx={{ marginBottom: '20px' }}>
              Select date range to export messages
            </Typography>
            <Scrollbar
              style={{
                height: 'calc(75vh - 150px)',
                minWidth: '700px',
              }}
            >
              <AnalyticsDatepicker
                onDatesChange={(dates) => {
                  setDateRange(dates);
                }}
              />
            </Scrollbar>
            <SimpleActions
              sx={{ mt: 2 }}
              confirmLabel={'Export'}
              onConfirm={() => {
                dateRangeMessagesExportModal.close();
                setTimeout(() => {
                  confirmExportModal.open();
                }, 150);
              }}
              onCancel={() => {
                dateRangeMessagesExportModal.close();
              }}
            />
          </DateRangeMessagesExportModal>
          <SelectionExportModal
            {...selectionExportModal.props}
            onCancel={() => {
              selectionExportModal.close();
            }}
            sx={{
              width: 'fit-content',
              height: 'fit-content',
            }}
          >
            <Typography color="textPrimary" variant="h5" sx={{ marginBottom: '20px' }}>
              Select first and last message to export
            </Typography>
            <MessagingCaseManagementSelection
              messages={messages}
              participants={participants}
              loadMoreButtonsConfig={loadMoreButtonsConfig}
              selectedMessages={selectedMessages}
              setSelectedMessages={setSelectedMessages}
            />
            <SimpleActions
              sx={{ mt: 2 }}
              confirmLabel={'Export'}
              onConfirm={() => {
                selectionExportModal.close();
                setTimeout(() => {
                  confirmExportModal.open();
                }, 150);
              }}
              onCancel={() => {
                selectionExportModal.close();
              }}
              disableConfirm={Object.values(selectedMessages).some((message) => message === null)}
            />
          </SelectionExportModal>
          <ConfirmExportModal
            {...confirmExportModal.props}
            sx={{
              height: 'auto',
              minHeight: sizes.modal.minHeight,
              width: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography color="textPrimary" variant="h5" sx={{ marginBottom: '20px' }}>
              Choose a name for exported file and export
            </Typography>
            <TextField
              onChange={onChange}
              value={filename}
              label={'File Name'}
              InputLabelProps={{ shrink: true }}
              sx={{ my: 2 }}
            />
            <SimpleActions
              sx={{ mt: 2 }}
              confirmLabel={'Export'}
              onConfirm={() => {
                confirmExportModal.close();
                handleExportMessages(
                  {
                    name: filename,
                    start_date: dateRange.startDate,
                    end_date: dateRange.endDate,
                    start_message_uuid: selectedMessages.firstMessage?.uuid ?? null,
                    end_message_uuid: selectedMessages.secondMessage?.uuid ?? null,
                  },
                  exportType
                );
                setTimeout(() => {
                  setFilename('');
                }, 150);
              }}
              onCancel={() => {
                confirmExportModal.close();
                setExportType(exportTypes.ALL);
                setDateRange({
                  startDate: null,
                  endDate: null,
                });
                setSelectedMessages({
                  firstMessage: null,
                  secondMessage: null,
                });
                setTimeout(() => {
                  setFilename('');
                }, 150);
              }}
              disableConfirm={!filename.length}
            />
          </ConfirmExportModal>
          <Button
            variant={'text'}
            sx={{ display: 'inline-flex', alignItems: 'center' }}
            {...bindTrigger(popupState)}
          >
            <FolderIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography variant={'subtitle2'}>Case Management</Typography>
          </Button>
          <Menu
            {...bindMenu(popupState)}
            PaperProps={{
              sx: {
                backgroundColor: (theme) => theme.palette.background.default,
                overflow: 'hidden',
              },
            }}
            MenuListProps={{
              sx: {
                py: 0,
              },
            }}
          >
            {menuItems.map((item) => {
              return (
                <MenuItem
                  value={item.value}
                  onClick={() => {
                    item.onClick?.(popupState);
                    popupState.close();
                  }}
                >
                  {item.icon && (
                    <ListItemIcon sx={{ color: theme.palette.primary.main }}>
                      {item.icon}
                    </ListItemIcon>
                  )}
                  <ListItemText>{item.label}</ListItemText>
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )}
    </PopupState>
  );
};

export default MessagingCaseManagement;
