import { useState, useEffect } from 'react';
import type { ChangeEvent, FC } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  InputAdornment,
  Container,
  Grid,
  Breadcrumbs,
  CircularProgress,
  Link,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { GenericInput } from 'src/components/widgets/inputs/GenericInput';
import { RootState, useDispatch, useSelector } from '../../store';
import useQuery from 'src/hooks/useQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import ChevronRightIcon from '../../icons/ChevronRight';

import {
  postCreateAdminFromExistingUser,
  clearPostFetchStatuses,
} from '../../slices/organizations';
import { useParams } from 'react-router';
import toast from 'react-hot-toast';
import { RequestStatus } from 'src/utils/RequestStatus';
import { getOrganizationsAdministratorsPath, getVendorsPath } from '../../routesPaths';
import { Helmet } from 'react-helmet-async';
import useSettings from '../../hooks/useSettings';
import { useForm } from 'react-hook-form';
import { Input } from '../../components/widgets/inputs/Input';
import FileUploadInput from '../FileUpload/FileUploadInput';
import PlusIcon from '../../icons/Plus';
import { postVendor, clearPostPatchValidationErrorsAndFetchStatuses } from '../../slices/vendors';
import { convertPhoneToSend, getErrorMessageFor, isObjectAsString } from '../../utils/formsHelper';
import { clearFilesForGivenKey } from '../../slices/fileUpload';
import GenericBreadcrumb from '../../components/GenericBreadcrumb';

const AddNewVendor: FC<any> = (props) => {
  const {} = props;
  const { settings } = useSettings();
  const [query, setQuery] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<string>('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const { postPatchFetchStatus, postPatchValidationErrors } = useSelector(
    (state: RootState) => state.vendors.vendorDetails
  );

  const areValidationMessages =
    postPatchValidationErrors && Object.keys(postPatchValidationErrors).length > 0;

  useEffect(() => {
    return () => {
      dispatch(clearPostPatchValidationErrorsAndFetchStatuses());
      // dispatch(clearFilesForGivenKey({ storeKey: 'newVendorAvatar' }));
    };
  }, []);

  const onSubmit = handleSubmit((data) => {
    dispatch(postVendor(data));
  });

  useEffect(() => {
    if (RequestStatus.isDone(postPatchFetchStatus)) {
      toast.success('Vendor added!');
      setTimeout(() => {
        navigate(getVendorsPath());
      }, 1500);
    }
  }, [postPatchFetchStatus]);

  const formId = 'new-vendor-form';

  return (
    <>
      <Helmet>
        <title>Dashboard: Add Vendor </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                New Vendor
              </Typography>
              <GenericBreadcrumb />
              <Box
                sx={{
                  mb: -1,
                  mx: -1,
                  mt: 1,
                }}
              ></Box>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  startIcon={
                    RequestStatus.isFetching(postPatchFetchStatus) ? (
                      <CircularProgress
                        sx={{
                          width: '20px !important ',
                          height: '20px !important',
                          marginRight: '10px',
                          color: 'white',
                        }}
                      />
                    ) : (
                      <PlusIcon fontSize="small" />
                    )
                  }
                  sx={{ m: 1 }}
                  variant="contained"
                  type="submit"
                  form={formId}
                >
                  Add
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card {...props}>
              <form onSubmit={onSubmit} id={formId}>
                <Box sx={{ margin: '30px' }}>
                  <FileUploadInput
                    control={control}
                    isEditMode={true}
                    setValue={setValue}
                    isAvatar={true}
                    name="logo"
                    defaultValue={null}
                    storeKey={'newVendorAvatar'}
                    validationMessage={
                      areValidationMessages &&
                      getErrorMessageFor({
                        key: 'logo',
                        errorMessages: postPatchValidationErrors,
                      })
                    }
                  />
                </Box>

                <Divider />
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="subtitle2">
                          Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Input
                          control={control}
                          isEditMode={true}
                          type="text"
                          name="name"
                          validationMessage={
                            areValidationMessages &&
                            getErrorMessageFor({
                              key: 'name',
                              errorMessages: postPatchValidationErrors,
                            })
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="subtitle2">
                          Address
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Input
                          control={control}
                          isEditMode={true}
                          type="text"
                          name="address"
                          validationMessage={
                            areValidationMessages &&
                            getErrorMessageFor({
                              key: 'address',
                              errorMessages: postPatchValidationErrors,
                            })
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="subtitle2">
                          Address 2
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Input
                          control={control}
                          isEditMode={true}
                          type="text"
                          name="address2"
                          validationMessage={
                            areValidationMessages &&
                            getErrorMessageFor({
                              key: 'address2',
                              errorMessages: postPatchValidationErrors,
                            })
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="subtitle2">
                          City
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Input
                          control={control}
                          isEditMode={true}
                          type="text"
                          name="city"
                          validationMessage={
                            areValidationMessages &&
                            getErrorMessageFor({
                              key: 'city',
                              errorMessages: postPatchValidationErrors,
                            })
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="subtitle2">
                          State
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Input
                          control={control}
                          isEditMode={true}
                          type="text"
                          name="state"
                          validationMessage={
                            areValidationMessages &&
                            getErrorMessageFor({
                              key: 'state',
                              errorMessages: postPatchValidationErrors,
                            })
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="subtitle2">
                          Zip code
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Input
                          control={control}
                          isEditMode={true}
                          type="text"
                          name="zip_code"
                          validationMessage={
                            areValidationMessages &&
                            getErrorMessageFor({
                              key: 'zip_code',
                              errorMessages: postPatchValidationErrors,
                            })
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="subtitle2">
                          Main contact
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Input
                          control={control}
                          isEditMode={true}
                          type="text"
                          name="main_contact"
                          validationMessage={
                            areValidationMessages &&
                            getErrorMessageFor({
                              key: 'main_contact',
                              errorMessages: postPatchValidationErrors,
                            })
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="subtitle2">
                          Contact email
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Input
                          control={control}
                          isEditMode={true}
                          type="text"
                          name="contact_email"
                          validationMessage={
                            areValidationMessages &&
                            getErrorMessageFor({
                              key: 'contact_email',
                              errorMessages: postPatchValidationErrors,
                            })
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography color="textPrimary" variant="subtitle2">
                          Phone
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Input
                          control={control}
                          isEditMode={true}
                          type="phone"
                          name="phone"
                          validationMessage={
                            (areValidationMessages &&
                              getErrorMessageFor({
                                key: 'phone',
                                errorMessages: postPatchValidationErrors,
                              })) ||
                            errors.phone_number?.message
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Box sx={{ margin: '30px' }}>
                  <FileUploadInput
                    control={control}
                    isEditMode={true}
                    setValue={setValue}
                    isAvatar={false}
                    isPdf={true}
                    name="file_w9"
                    defaultValue={null}
                    storeKey={'newVendorw9File'}
                    validationMessage={
                      areValidationMessages &&
                      getErrorMessageFor({
                        key: 'file_w9',
                        errorMessages: postPatchValidationErrors,
                      })
                    }
                  />
                </Box>
              </form>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AddNewVendor;
