import { useEffect, useState } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import {
  Avatar,
  Box,
  Card,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  LinearProgress,
  CircularProgress,
} from '@mui/material';
import ArrowRightIcon from '../../icons/ArrowRight';
import SearchIcon from '../../icons/Search';
import getInitials from '../../utils/getInitials';
import Scrollbar from '../../components/Scrollbar';

import { Organization } from '../../types/organization';
import { getOrganizationsDetailsPath } from '../../routesPaths';
import { getOrganizations } from '../../slices/organizations';
import { RootState, useDispatch, useSelector } from '../../store';
import { RequestStatus } from '../../utils/RequestStatus';
import EmptyList from '../../components/EmptyList';
import useDebouncedSearchAndPagination from '../../hooks/useDebouncedSearchAndPagination';
import { GetDisplayOnlyValueForPhoneNumberInput } from '../../components/widgets/inputs/Input';
import { pagination } from '../../theme';

const OrganizationsList: FC = (props) => {
  const { ...other } = props;
  const dispatch = useDispatch();
  const { items, fetchStatus, count } = useSelector((state: RootState) => state.organizations.list);

  const { handlePageChange, handleLimitChange, handleQueryChange, query, page, limit } =
    useDebouncedSearchAndPagination({
      callbackToDebounce: ({ limit, page, query }) => {
        dispatch(getOrganizations({ limit, offset: page * limit, search: query }));
      },
      listLength: count,
    });

  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Search organizations"
            value={query}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            m: 1,
            width: 240,
          }}
        >
          {/*<TextField*/}
          {/*  label="Sort By"*/}
          {/*  name="sort"*/}
          {/*  onChange={handleSortChange}*/}
          {/*  select*/}
          {/*  SelectProps={{ native: true }}*/}
          {/*  value={sort}*/}
          {/*  variant="outlined"*/}
          {/*>*/}
          {/*  {sortOptions.map((option) => (*/}
          {/*    <option key={option.value} value={option.value}>*/}
          {/*      {option.label}*/}
          {/*    </option>*/}
          {/*  ))}*/}
          {/*</TextField>*/}
        </Box>
      </Box>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          {RequestStatus.isFetching(fetchStatus) && items.length > 0 && <LinearProgress />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Logo</TableCell>
                <TableCell>Name</TableCell>
                {/*<TableCell>Region</TableCell>*/}
                <TableCell>Contact #</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((organization, idx) => {
                return (
                  <TableRow hover key={organization.uuid}>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={getOrganizationsDetailsPath({ id: organization.uuid })}
                        variant="subtitle2"
                      >
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          <Avatar
                            src={organization.logo?.thumbnails?.avatar}
                            sx={{
                              height: 42,
                              width: 42,
                            }}
                          >
                            {getInitials(organization.name)}
                          </Avatar>
                        </Box>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={getOrganizationsDetailsPath({ id: organization.uuid })}
                        variant="subtitle2"
                      >
                        {organization.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <GetDisplayOnlyValueForPhoneNumberInput
                        defaultValue={organization.contact_phone || organization.phone}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={getOrganizationsDetailsPath({ id: organization.uuid })}
                        size="large">
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {RequestStatus.isDone(fetchStatus) && count === 0 && (
            <EmptyList>No organizations found</EmptyList>
          )}
          {RequestStatus.isFetching(fetchStatus) && items.length === 0 && (
            <CircularProgress sx={{ display: 'block', margin: '30px auto 10px auto' }} />
          )}
          <TablePagination
            component="div"
            count={count}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
          />
        </Box>
      </Scrollbar>
    </Card>
  );
};

export default OrganizationsList;
