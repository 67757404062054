import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import GenericBreadcrumb from '../../components/GenericBreadcrumb';
import useSettings from '../../hooks/useSettings';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  TablePagination,
  Typography,
} from '@mui/material';
import { RootState, useDispatch, useSelector } from '../../store';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  deleteExportedMessagesFile,
  getExportedMessages,
  getExportedMessagesForThread,
  resetExportedMessages,
} from '../../slices/messaging';
import CaseManagementExportedMessagesList from './CaseManagementExportedMessagesList';
import { getMessagingDetailsPath } from '../../routesPaths';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useDebouncedSearchAndPagination from '../../hooks/useDebouncedSearchAndPagination';
import { pagination } from '../../theme';

const CaseManagement: FC = () => {
  const { settings } = useSettings();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { thread_uuid } = useParams();

  const {
    exportedMessagesList: { results, count, fetchStatus },
    deleteExportedMesssagesFile: { fetchStatus: deleteExportedMessagesFileFetchStatus },
  } = useSelector((state: RootState) => state.messaging.thread.caseManagement);

  const [searchParams, setSearchParams] = useSearchParams();

  const { handlePageChange, handleLimitChange, handleQueryChange, query, page, limit } =
    useDebouncedSearchAndPagination({
      callbackToDebounce: ({ limit, page }) => {
        if (typeof thread_uuid !== 'undefined')
          dispatch(
            getExportedMessagesForThread({
              thread_uuid: thread_uuid,
              limit,
              offset: page * limit,
            })
          );
        else dispatch(getExportedMessages({ limit, offset: page * limit }));
      },
      listLength: count,
    });

  const handleDeleteExportedMessagesFile = (uuid: string): void => {
    dispatch(deleteExportedMessagesFile({ uuid }));
  };

  useEffect(() => {
    return () => {
      dispatch(resetExportedMessages(null));
    };
  }, []);

  useEffect(() => {
    if (typeof thread_uuid !== 'undefined')
      dispatch(
        getExportedMessagesForThread({
          thread_uuid: thread_uuid,
          limit,
          offset: page * limit,
        })
      );
    else dispatch(getExportedMessages({ limit, offset: page * limit }));
  }, [thread_uuid]);

  return (
    <>
      <Helmet>
        <title>Case Management</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          display: 'flex',
          py: 4,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Case Management
              </Typography>
              <GenericBreadcrumb replaceLastUUIDWith={"THREAD'S EXPORTED MESSAGES"} />
              <Box
                sx={{
                  mb: -1,
                  mx: -1,
                  mt: 1,
                }}
              ></Box>
            </Grid>
          </Grid>
          {thread_uuid && (
            <Button
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                navigate(getMessagingDetailsPath({ id: thread_uuid }));
              }}
              sx={{
                mt: 2,
              }}
            >
              Back to thread
            </Button>
          )}
          {/*<Box*/}
          {/*  sx={{*/}
          {/*    mt: 3,*/}
          {/*    mb: -5,*/}
          {/*    pb: 3,*/}
          {/*    flex: 1,*/}
          {/*    position: 'relative',*/}
          {/*    height: threadUuid ? `calc(100% - 84px - 52.5px)` : `calc(100% - 84px)`,*/}
          {/*    minHeight: '200px',*/}
          {/*  }}*/}
          {/*>*/}
          <Card sx={{ maxHeight: '100%', display: 'flex', width: '100%', mt: 3, overflow: 'auto' }}>
            <Box
              sx={{ mt: 0, flex: 1, minHeight: '100%', display: 'flex', flexDirection: 'column' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                }}
              >
                <CaseManagementExportedMessagesList
                  exportedMessages={results}
                  fetchStatus={fetchStatus}
                  handleRefreshData={(options: { preventPageChange?: boolean }) => {
                    const { preventPageChange = false } = options;
                    if (page > 0 && !preventPageChange)
                      setSearchParams({ ...searchParams, page: '0' });
                    else {
                      if (typeof thread_uuid !== 'undefined')
                        dispatch(
                          getExportedMessagesForThread({
                            thread_uuid: thread_uuid,
                            limit,
                            offset: !preventPageChange ? 0 : page * limit,
                          })
                        );
                      else
                        dispatch(
                          getExportedMessages({
                            limit,
                            offset: !preventPageChange ? 0 : page * limit,
                          })
                        );
                    }
                  }}
                  deleteExportedMessagesFileFetchStatus={deleteExportedMessagesFileFetchStatus}
                  handleDeleteExportedMessagesFile={handleDeleteExportedMessagesFile}
                />
                <Box>
                  <Divider />
                  <TablePagination
                    component="div"
                    count={count}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={pagination.rowsPerPageOptions}
                  />
                </Box>
              </Box>
            </Box>
          </Card>
          {/*</Box>*/}
        </Container>
      </Box>
    </>
  );
};

export default CaseManagement;
