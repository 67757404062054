export const qrCodesDragDropLayout = {
  style: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    width: '100%',
  },
  pageLayout: (pageNumber) => [
    [`${pageNumber}-0-0`, `${pageNumber}-0-1`, `${pageNumber}-0-2`, `${pageNumber}-0-3`],
    [`${pageNumber}-1-0`, `${pageNumber}-1-1`, `${pageNumber}-1-2`, `${pageNumber}-1-3`],
    [`${pageNumber}-2-0`, `${pageNumber}-2-1`, `${pageNumber}-2-2`, `${pageNumber}-2-3`],
    [`${pageNumber}-3-0`, `${pageNumber}-3-1`, `${pageNumber}-3-2`, `${pageNumber}-3-3`],
    [`${pageNumber}-4-0`, `${pageNumber}-4-1`, `${pageNumber}-4-2`, `${pageNumber}-4-3`],
  ],
  qrCodeSize: {
    print: {
      inches: 2,
      pixels: `${2 * 96}px`, //https://www.w3.org/TR/css3-values/#absolute-lengths
    },
    preview: {
      inches: 1,
      pixels: `${96}px`, //https://www.w3.org/TR/css3-values/#absolute-lengths
    },
  },
};
