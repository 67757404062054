import { useState, useEffect } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  CircularProgress,
  LinearProgress,
  Typography,
  Avatar,
} from '@mui/material';

import ArrowRightIcon from '../../../icons/ArrowRight';
import PencilAltIcon from '../../../icons/PencilAlt';
import SearchIcon from '../../../icons/Search';
import ImageIcon from '../../../icons/Image';
import type { Product, InventoryType } from '../../../types/product';
import Scrollbar from '../../../components/Scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import { getVendorDetailsPath } from 'src/routesPaths';
import { RootState, useDispatch, useSelector } from '../../../store';
import useMounted from '../../../hooks/useMounted';
import { getVendors } from '../../../slices/vendors';
import {
  getSearchedCandidatesForAdmin,
  clearCandidatesForAdmin,
} from '../../../slices/organizations';
import type { User } from '../../../types/user';
import { RequestStatus } from '../../../utils/RequestStatus';
import { useParams } from 'react-router';
import EmptyList from '../../../components/EmptyList';
import useDebouncedSearchAndPagination from '../../../hooks/useDebouncedSearchAndPagination';
import { pagination } from '../../../theme';
import getInitials from '../../../utils/getInitials';

interface OrganizationAddAdministratorUsersListTableProps {
  query: string;
  handleSelectUser: any;
  selectedUser: string;
}

const OrganizationAddAdministratorUsersListTable: FC<OrganizationAddAdministratorUsersListTableProps> =
  (props) => {
    const { query, handleSelectUser, selectedUser } = props;

    const dispatch = useDispatch();
    const { uuid: organizationUuid } = useParams();
    const { items, count, fetchStatus } = useSelector(
      (state: RootState) => state.organizations.details.administrators.searchedCandidatesForAdmin
    );

    useEffect(() => {
      if (query.length === 0) {
        dispatch(clearCandidatesForAdmin());
      }
    }, [query]);

    const {} = useDebouncedSearchAndPagination({
      callbackToDebounce: ({ limit, page, query }) => {
        if (query.length > 0) {
          dispatch(
            getSearchedCandidatesForAdmin({
              limit,
              offset: page * limit,
              search: query.trim(),
              uuid: organizationUuid,
            })
          );
        }
      },
      listLength: count,
      propsQuery: query.trim(),
    });

    useEffect(() => {
      return () => {
        dispatch(clearCandidatesForAdmin());
      };
    }, []);

    return (
      <>
        <Scrollbar>
          <Box sx={{ minWidth: 1200 }}>
            {RequestStatus.isFetching(fetchStatus) && items.length > 0 && <LinearProgress />}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Photo</TableCell>

                  <TableCell>First name</TableCell>
                  <TableCell>Last name</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((user: User) => {
                  return (
                    <TableRow hover key={user.uuid}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedUser === user.uuid}
                          color="primary"
                          onChange={(e) => {
                            if (selectedUser === user.uuid) {
                              handleSelectUser('');
                            } else {
                              handleSelectUser(user.uuid);
                            }
                          }}
                          value={selectedUser === user.uuid}
                        />
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          <Avatar
                            src={user?.profile_image?.thumbnails?.avatar}
                            sx={{
                              height: 42,
                              width: 42,
                            }}
                          >
                            {getInitials(
                              user?.first_name && user?.last_name
                                ? user.first_name[0] + user.last_name[0]
                                : 'T'
                            )}
                          </Avatar>
                        </Box>
                      </TableCell>
                      <TableCell>{user?.first_name}</TableCell>
                      <TableCell>{user?.last_name}</TableCell>
                      <TableCell>{user?.email || user?.username}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {items.length === 0 &&
              (RequestStatus.isDone(fetchStatus) || RequestStatus.isNull(fetchStatus)) &&
              query === '' && <EmptyList>Type in email to find user</EmptyList>}

            {RequestStatus.isDone(fetchStatus) && query !== '' && items.length === 0 && (
              <EmptyList>User not found</EmptyList>
            )}
            {RequestStatus.isFetching(fetchStatus) && items.length === 0 && (
              <CircularProgress sx={{ display: 'block', margin: '30px auto 10px auto' }} />
            )}
          </Box>
        </Scrollbar>
      </>
    );
  };

export default OrganizationAddAdministratorUsersListTable;
