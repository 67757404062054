import ChevronRightIcon from '../icons/ChevronRight';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getRoutes } from '../routes';
import { getLandingPath } from '../routesPaths';
import { Link as RouterLink } from 'react-router-dom';
import { User } from '../types/user';

const isUUID = (value) => {
  return !!value.match(
    '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
  );
};

interface GenericBreadcrumbProps {
  replaceFirstUUIDWith?: string;
  replaceLastUUIDWith?: string;
}

interface BreadcrumbConfigType {
  path: string;
  label: string;
  uuidIdx?: number;
}

const firstBreadcrumbRoute: BreadcrumbConfigType = { path: getLandingPath({}), label: 'DASHBOARD' };

const getBreadcrumbLabelFromUserDetails = (userDetails: {
  user: User;
  customer_label?: string;
}) => {
  if (userDetails?.user?.first_name || userDetails?.user?.last_name)
    return `${userDetails?.user?.first_name || ''} ${userDetails?.user?.last_name || ''}`;
  else if (
    !userDetails?.user?.first_name &&
    !userDetails?.user?.last_name &&
    userDetails?.customer_label
  )
    return userDetails?.customer_label || '';
  else if (
    !userDetails?.user?.first_name &&
    !userDetails?.user?.last_name &&
    userDetails?.user?.username
  )
    return userDetails?.user?.username || '';
};

const GenericBreadcrumb: FC<GenericBreadcrumbProps> = (props) => {
  const { replaceFirstUUIDWith, replaceLastUUIDWith } = props;

  const location = useLocation();
  const [breadConfigs, setBreadConfigs] = useState([]);
  const lastUUIDIdxRef = useRef(0);

  const createLinks = (pathname) => {
    const splittedPath = pathname.split('/').filter((path) => path !== '');
    let breadcrubsConfig: Array<BreadcrumbConfigType> = [firstBreadcrumbRoute];
    let lastUUIDIdx = 0;
    for (let i = 0; i < splittedPath?.length; i++) {
      let currentBreadcrumb = '';
      for (let j = 0; j <= i; j++) {
        currentBreadcrumb = currentBreadcrumb + '/' + splittedPath[j];
      }
      if (currentBreadcrumb === '') continue;
      const label = splittedPath[i]?.toUpperCase();
      breadcrubsConfig.push({
        path: currentBreadcrumb,
        label,
        uuidIdx: isUUID(label) ? lastUUIDIdx || 0 : null,
      });
      isUUID(label) && lastUUIDIdx++;
    }
    lastUUIDIdxRef.current = lastUUIDIdx - 1;
    setBreadConfigs(breadcrubsConfig);
  };

  const getLabel = ({ label, uuidIdx }) => {
    if (typeof uuidIdx !== 'number') return label.replaceAll('-', ' ');
    if (replaceFirstUUIDWith && uuidIdx === 0) return replaceFirstUUIDWith;
    else if (replaceLastUUIDWith && uuidIdx === lastUUIDIdxRef.current) return replaceLastUUIDWith;
    else return label.replaceAll('-', ' ');
  };

  useEffect(() => {
    createLinks(location.pathname);
  }, [location?.pathname, replaceFirstUUIDWith, replaceLastUUIDWith]);

  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<ChevronRightIcon fontSize="small" />}
        sx={{ mt: 1 }}
      >
        {breadConfigs.map(
          ({ path, label, uuidIdx }, idx) =>
            idx !== breadConfigs.length - 1 && (
              <Link
                color="textPrimary"
                component={RouterLink}
                to={path}
                variant="subtitle2"
                key={`breadcrumb-${idx}`}
              >
                {getLabel({ label, uuidIdx })}
              </Link>
            )
        )}
        <Typography color="textSecondary" variant="subtitle2">
          {breadConfigs?.length &&
            breadConfigs[breadConfigs.length - 1] &&
            breadConfigs[breadConfigs.length - 1]?.label &&
            getLabel(breadConfigs[breadConfigs.length - 1])}
        </Typography>
      </Breadcrumbs>
    </>
  );
};

export { getBreadcrumbLabelFromUserDetails };

export default GenericBreadcrumb;
