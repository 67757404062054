import { useState, useEffect } from 'react';
import type { ChangeEvent, FC } from 'react';
import { Box, Divider, Typography, Container, Grid } from '@mui/material';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { RootState, useDispatch, useSelector } from '../../../store';
import {
  getOrganizationAdminDetails,
  patchOrganizationDetails,
  patchOrganizationAdminDetails,
  clearOrganizationAdminDetails,
  setPatchValidationErrors,
  setPatchFetchStatus,
  setPatchAdminValidationErrors,
  setPatchAdminFetchStatus,
} from '../../../slices/organizations';
import { useParams } from 'react-router';
import { RequestStatus } from 'src/utils/RequestStatus';
import useSettings from '../../../hooks/useSettings';
import { useForm } from 'react-hook-form';
import AddNewAdministratorForm from './AddNewAdministratorForm';
import useMounted from '../../../hooks/useMounted';
import EditButton from '../../../components/widgets/buttons/EditButton';
import {
  convertPhoneToSend,
  getErrorMessageFor,
  isObjectAsString,
  setFormValuesFromDetailsObject,
} from '../../../utils/formsHelper';
import useEditMode from '../../../hooks/useEditMode';
import { replaceLastUUIDInOrganizationBreadcrumbWith } from '../../../slices/config';
import { getBreadcrumbLabelFromUserDetails } from '../../../components/GenericBreadcrumb';

const formId = 'edit-admin-form';

interface OrganizationAdminDetailsProps {}

const OrganizationAdminDetails: FC<any> = (props) => {
  const {} = props;
  const mounted = useMounted();
  const { settings } = useSettings();
  const { uuid: organizationId, adminId } = useParams();
  const dispatch = useDispatch();
  const { admin, fetchStatus, patchFetchStatus, patchValidationErrors } = useSelector(
    (state: RootState) => state.organizations.details.administratorDetails
  );
  const { handleSubmit, control, setValue } = useForm();
  const { triggerEditMode, isEditMode } = useEditMode();

  const handleFormSubmit = handleSubmit((data) => {
    if (isEditMode) {
      dispatch(patchOrganizationAdminDetails({ ...data, organizationId, adminId }));
    }
  });

  const onCancelClick = () => {
    triggerEditMode();
    setFormValuesFromDetailsObject({ setValue, detailsObject: admin });
  };

  // const setFormValuesFromAdmin = () => {
  //   for (let [key, value] of Object.entries(admin)) {
  //     if (key === 'profile_image') {
  //       setValue(key, value?.uuid);
  //     } else {
  //       setValue(key, String(value));
  //     }
  //   }
  // };

  useEffect(() => {
    if (RequestStatus.isDone(patchFetchStatus)) {
      dispatch(setPatchAdminValidationErrors(null));
      dispatch(setPatchAdminFetchStatus(RequestStatus.status.NULL));
      triggerEditMode();
    }
  }, [patchFetchStatus]);

  useEffect(() => {
    return () => {
      dispatch(clearOrganizationAdminDetails());
    };
  }, []);

  useEffect(() => {
    if (mounted) dispatch(getOrganizationAdminDetails({ organizationId, adminId }));
  }, [mounted]);

  useEffect(() => {
    if (!admin) return;
    setFormValuesFromDetailsObject({ setValue, detailsObject: admin });
    dispatch(
      replaceLastUUIDInOrganizationBreadcrumbWith(
        getBreadcrumbLabelFromUserDetails({ user: admin })
      )
    );
  }, [admin]);

  const isPatchFetching = RequestStatus.isFetching(patchFetchStatus);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Box sx={{ height: 50 }}></Box>
            </Grid>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {`${admin?.first_name} ${admin?.last_name}`}
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <EditButton
                  isEditMode={isEditMode}
                  form={formId}
                  onCancelClick={onCancelClick}
                  onClick={triggerEditMode}
                  isLoading={isPatchFetching}
                />
              </Box>
            </Grid>
          </Grid>
          <Divider />

          <AddNewAdministratorForm
            control={control}
            formId={formId}
            onFormSubmit={handleFormSubmit}
            hideBottomSave={true}
            setValue={setValue}
            defaultValues={admin}
            isEditMode={isEditMode}
            validationMessages={patchValidationErrors}
            isPatchFetching={isPatchFetching}
          />
        </Container>
      </Box>
    </>
  );
};

export default OrganizationAdminDetails;
