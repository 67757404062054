import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Switch,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { pagination } from '../../../theme';
import Scrollbar from '../../../components/Scrollbar';
import {
  deleteScheduledMessage,
  getScheduledMessages,
  resetDeleteScheduledMessageStatus,
  ScheduledMessageType,
} from '../../../slices/messaging';
import { format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ChatMessageFileAttachment from '../../../components/dashboard/chat/ChatMessageFileAttachment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MessageIcon from '@mui/icons-material/Message';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getMessagingDetailsPath } from '../../../routesPaths';
import { RootState, useDispatch, useSelector } from '../../../store';
import { RequestStatus } from '../../../utils/RequestStatus';
import toast from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';
import ScheduledMessageModal from '../MessagingChatBox/ScheduledMessageModal';

interface ScheduledMessagesSettingsProps {
  items: Array<ScheduledMessageType>;
  page: number;
  limit: number;
  count: number;
  query: string;
  handlePageChange: any;
  handleLimitChange: any;
  handleQueryChange: any;
  isLoading: boolean;
  setExpandMedia: Dispatch<SetStateAction<string | null>>;
  handleCloseModal: () => void;
  scheduledMessagesThreadUuid: string | null;
  setScheduledMessagesThreadUuid: Dispatch<SetStateAction<string | null>>;
  setOpenEditFor: Dispatch<SetStateAction<ScheduledMessageType | null>>;
}

const ScheduledMessagesSettings: FC<ScheduledMessagesSettingsProps> = (props) => {
  const {
    items,
    page,
    limit,
    count,
    query,
    handlePageChange,
    handleLimitChange,
    handleQueryChange,
    isLoading,
    setExpandMedia,
    handleCloseModal,
    scheduledMessagesThreadUuid,
    setScheduledMessagesThreadUuid,
    setOpenEditFor,
  } = props;

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const { fetchStatus: deleteFetchStatus } = useSelector(
    (state: RootState) => state.messaging.thread.scheduledMessage.delete
  );

  const [showDeleteMessage, setShowDeleteMessage] = useState<string | null>(null);
  // const [openEditFor, setOpenEditFor] = useState<ScheduledMessageType | null>(null);
  //
  // const closeEditMode = (): void => {
  //   setOpenEditFor(null);
  // };

  useEffect(() => {
    if (RequestStatus.isDone(deleteFetchStatus)) {
      dispatch(
        getScheduledMessages({
          limit,
          offset: page * limit,
          search: query,
          ...(scheduledMessagesThreadUuid && { thread_uuid: scheduledMessagesThreadUuid }),
        })
      );
      toast.success('Scheduled message deleted.');
      dispatch(resetDeleteScheduledMessageStatus({}));
    } else if (RequestStatus.isError(deleteFetchStatus)) {
      toast.error('There was an error removing scheduled message');
      dispatch(resetDeleteScheduledMessageStatus({}));
    }
  }, [deleteFetchStatus]);

  return (
    <>
      <Box sx={{ height: '4px', width: '100%', mt: '-1px' }}>{isLoading && <LinearProgress />}</Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        <TextField
          size={'small'}
          sx={{ my: 2 }}
          label={'Search'}
          onChange={handleQueryChange}
          value={query}
          fullWidth
          InputProps={{
            startAdornment: (
              <SearchIcon
                sx={{
                  color: 'action.disabled',
                  mr: 1,
                }}
              />
            ),
            endAdornment: query?.length > 0 && (
              <IconButton
                color={'primary'}
                onClick={() => {
                  handleQueryChange({ target: { value: '' } });
                }}
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
        <FormControlLabel
          sx={{
            minWidth: '195px',
          }}
          control={<Switch />}
          label={'Only current chat'}
          checked={uuid === scheduledMessagesThreadUuid}
          onChange={(e, checked) => {
            const otherSearchParams = Object.fromEntries(searchParams.entries());
            setSearchParams({
              ...otherSearchParams,
              onlyCurrentChat: uuid === scheduledMessagesThreadUuid ? 'false' : 'true',
            });
            uuid === scheduledMessagesThreadUuid
              ? setScheduledMessagesThreadUuid(null)
              : setScheduledMessagesThreadUuid(uuid as string);
          }}
          disabled={!uuid}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Scrollbar style={{ height: '444px', paddingRight: '16px' }}>
          {items.length > 0
            ? items.map((item) => (
                <Card
                  variant={'outlined'}
                  sx={{
                    borderRadius: '8px',
                    my: 1,
                    p: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box
                      sx={{
                        '& > *': {
                          my: 0.5,
                        },
                      }}
                    >
                      <Typography variant={'h6'}>{item.name}</Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 0.5,
                        }}
                      >
                        <MessageIcon sx={{ color: 'text.secondary' }} />
                        <Typography variant={'subtitle2'}>
                          <b>Message:</b>
                        </Typography>
                        <Typography variant={'subtitle2'}>{item.text ?? 'None'}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 0.5,
                          alignItems: 'center',
                        }}
                      >
                        <AttachFileIcon sx={{ color: 'text.secondary' }} />
                        <Typography variant={'subtitle2'}>
                          <b>Attachment:</b>
                        </Typography>
                        {item.image ? (
                          <>
                            <img
                              onClick={(): void => setExpandMedia(item?.image?.url)}
                              src={item?.image?.url}
                              alt={'attachment'}
                              style={{
                                height: '64px',
                                width: '64px',
                                objectFit: 'contain',
                                borderRadius: '8px',
                                border: `solid 1px ${theme.palette.divider}`,
                                cursor: 'pointer',
                              }}
                            />
                          </>
                        ) : item.file ? (
                          <ChatMessageFileAttachment
                            url={item.file.url}
                            mime_type={item.file.mime_type}
                            sx={{ mt: 0 }}
                          />
                        ) : (
                          <Typography variant={'subtitle2'}>None</Typography>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 0.5,
                          alignItems: 'center',
                        }}
                      >
                        {' '}
                        <AccessTimeIcon sx={{ color: 'text.secondary' }} />
                        <Typography variant={'subtitle2'}>
                          <b>Created at:</b>
                        </Typography>
                        <Typography variant={'subtitle2'}>
                          {item.created ? format(new Date(item.created), 'dd MMMM yyyy') : 'N/A'}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 0.5,
                      }}
                    >
                      <Tooltip title={'Go to chat'} disableInteractive arrow placement={'top'}>
                        <span>
                          <IconButton
                            color={'primary'}
                            sx={{
                              border: `solid 1px ${theme.palette.divider}`,
                              padding: 0.5,
                              borderRadius: '8px',
                            }}
                            onClick={() => {
                              navigate(
                                getMessagingDetailsPath({ forRouting: false, id: item.thread })
                              );
                              handleCloseModal();
                            }}
                          >
                            <MessageIcon fontSize={'small'} />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <IconButton
                        color={'primary'}
                        sx={{
                          border: `solid 1px ${theme.palette.divider}`,
                          padding: 0.5,
                          borderRadius: '8px',
                        }}
                        onClick={() => {
                          handleCloseModal();
                          setTimeout(() => {
                            setOpenEditFor(item);
                          }, 150);
                        }}
                      >
                        <EditIcon fontSize={'small'} />
                      </IconButton>
                      <Tooltip title={'Delete'} disableInteractive arrow placement={'top'}>
                        <span>
                          <IconButton
                            color={'error'}
                            sx={{
                              border: `solid 1px ${theme.palette.divider}`,
                              padding: 0.5,
                              borderRadius: '8px',
                            }}
                            onClick={() => {
                              setShowDeleteMessage(item.uuid);
                            }}
                          >
                            <DeleteIcon fontSize={'small'} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Collapse in={showDeleteMessage === item.uuid}>
                    <Box
                      sx={{
                        px: 2,
                        pb: 1,
                      }}
                    >
                      <Divider />
                      <Box sx={{ height: '4px' }}>
                        {RequestStatus.isFetching(deleteFetchStatus) && <LinearProgress />}
                      </Box>
                      <Typography
                        noWrap={true}
                        color={'textSecondary'}
                        variant={'h6'}
                        align={'center'}
                      >
                        Are you sure you want to delete <b>{item?.name}</b>?
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          gap: '32px',
                          mt: 1,
                        }}
                      >
                        <Button
                          variant={'outlined'}
                          sx={{ minWidth: '150px' }}
                          onClick={() => setShowDeleteMessage(null)}
                        >
                          No
                        </Button>
                        <Button
                          variant={'contained'}
                          color={'error'}
                          sx={{ minWidth: '150px' }}
                          onClick={() => dispatch(deleteScheduledMessage({ uuid: item.uuid }))}
                          disabled={RequestStatus.isFetching(deleteFetchStatus)}
                        >
                          Yes
                        </Button>
                      </Box>
                    </Box>
                  </Collapse>
                </Card>
              ))
            : !isLoading && <Typography variant={'h6'}>No items</Typography>}
        </Scrollbar>
      </Box>
      <Box>
        <Divider />
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={pagination.rowsPerPageOptions}
        />
      </Box>
      {/*<ScheduledMessageModal isEdit openEditFor={openEditFor} closeEditMode={closeEditMode} />*/}
    </>
  );
};
export default ScheduledMessagesSettings;
