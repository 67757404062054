export const convertSelectedToArrays = (values: any, stringifyForSearchParams?: boolean) => {
  let converted = {};
  for (let [key, arrayOfObj] of Object.entries(values)) {
    // @ts-ignore
    if (arrayOfObj && arrayOfObj?.length) {
      // @ts-ignore
      const convertedElement = arrayOfObj.map((item) => item.value);
      if (stringifyForSearchParams) {
        // @ts-ignore
        converted[key] = JSON.stringify(convertedElement)
          .replaceAll('[', '')
          .replaceAll(']', '')
          .replaceAll('\\"', '')
          .replaceAll('"', '');
      } else converted[key] = convertedElement;
    } else converted[key] = stringifyForSearchParams ? '' : [];
  }
  return converted;
};

export const stringifyObjectValues = (values) => {
  let converted = {};
  for (let [key, value] of values) converted[key] = JSON.stringify(value);
  return converted;
};
