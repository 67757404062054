import { useTheme } from '@mui/material/styles';
import { ListItemButton, ListItemText } from '@mui/material';
import { RequestStatus } from '../../../../utils/RequestStatus';
import { getMediaLibraryFiles } from '../../../../slices/locations';
import {
  INITIAL_PAGE_SIZE,
  MediaFilesPaginationContext,
} from '../../../../contexts/MediaFilesPaginationContext';
import * as React from 'react';
import { useContext } from 'react';
import { RootState, useDispatch, useSelector } from '../../../../store';
import { useSearchParams } from 'react-router-dom';

const CustomOption = (props) => {
  const { innerProps, innerRef, data, isFocused } = props;

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();

  const { fetchStatus } = useSelector((state: RootState) => state.locations.mediaLibraryFiles);

  const { pageSize, query, setPage, setPageSize } = useContext(MediaFilesPaginationContext);

  return (
    <ListItemButton
      disabled={RequestStatus.isFetching(fetchStatus)}
      key={data.value}
      ref={innerRef}
      {...innerProps}
      {...(data.value === 'load-more' && {
        onClick: () => {
          setPage((prev) => prev + 1);
          dispatch(
            getMediaLibraryFiles({
              organization_uuid: searchParams.get('organization_uuid'),
              page: 1,
              page_size: pageSize + INITIAL_PAGE_SIZE,
              search: query,
            })
          );
          setPageSize();
        },
      })}
      className="custom-option"
      sx={{
        ...(data.value === 'load-more' && {
          borderTop: `solid 1px ${theme.palette.divider}`,
        }),
        ...(isFocused && {
          backgroundColor: theme.palette.action.hover,
        }),
      }}
    >
      <ListItemText
        primary={data.label}
        primaryTypographyProps={{
          sx: { wordBreak: 'break-all', maxWidth: '318px' },
        }}
      />
    </ListItemButton>
  );
};

export default CustomOption;
