import { ChangeEvent, FC } from 'react';
import { ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material';
import QRCodeImage from '../../../../components/QRCodeImage';

interface LocationsPrintAmountRowProps {
  qrCode: {
    qr_code: string;
    name: string;
    ancestors_names: Array<string>;
    printAmount: number;
  };
  handleChangePrintAmount: (printAmount: number, qr_code: string) => void;
}

const LocationsPrintAmountRow: FC<LocationsPrintAmountRowProps> = (props) => {
  const { qrCode, handleChangePrintAmount } = props;

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = parseInt(event.target.value, 10);
    handleChangePrintAmount(Number.isNaN(value) ? 1 : value, qrCode.qr_code);
  };

  return (
    <ListItem
      key={`${qrCode.qr_code}-${qrCode.name}`}
      sx={{
        pl: 0,
      }}
    >
      <ListItemIcon>
        <QRCodeImage
          QRCodePlaceholderStyle={{ width: '100px', height: '100px', top: '16px' }}
          qrCode={qrCode.qr_code}
          qrCodeImageStyle={{
            width: '75px',
            height: '75px',
          }}
        />
      </ListItemIcon>
      <ListItemText primary={qrCode.name} secondary={qrCode.ancestors_names.join(' / ')} />
      <TextField
        sx={{ minWidth: '100px', width: '100px' }}
        label={'Amount'}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          min: 1,
        }}
        InputProps={{
          onKeyPress: (e) => {
            const re = /[0-9]/;
            if (!re.test(e.key)) e.preventDefault();
          },
        }}
        onChange={handleOnChange}
        value={qrCode.printAmount}
        type={'number'}
        defaultValue={qrCode.printAmount}
      />
    </ListItem>
  );
};

export default LocationsPrintAmountRow;
