import type { FC } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import type { DropzoneOptions } from 'react-dropzone';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import DuplicateIcon from '../icons/Duplicate';
import XIcon from '../icons/X';
import bytesToSize from '../utils/bytesToSize';

interface FileDropzoneProps extends DropzoneOptions {
  files?: any[];
  onRemove?: (file: any) => void;
  onRemoveAll?: () => void;
  onUpload?: () => void;
  isAvatar?: boolean;
  avatarUrl?: string;
  isLoading?: boolean;
  onRemoveAvatar?: (event) => void;
  validationMessage?: string;
}

const FileDropzone: FC<FileDropzoneProps> = (props) => {
  const {
    accept,
    disabled,
    files,
    getFilesFromEvent,
    maxFiles,
    maxSize,
    minSize,
    noClick,
    noDrag,
    noDragEventsBubbling,
    noKeyboard,
    onDrop,
    onDropAccepted,
    onDropRejected,
    onFileDialogCancel,
    onRemove,
    onRemoveAll,
    onUpload,
    preventDropOnDocument,
    isAvatar,
    avatarUrl,
    onRemoveAvatar = () => {},
    isLoading,
    validationMessage,
    ...other
  } = props;

  // We did not add the remaining props to avoid component complexity
  // but you can simply add it if you need to.
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    minSize,
    onDrop,
    disabled: disabled || isLoading,
  });

  return (
    <div {...other}>
      <Box
        sx={{
          alignItems: 'center',
          border: 1,
          borderRadius: 1,
          borderColor: validationMessage ? 'red' : 'divider',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          outline: 'none',
          p: 6,
          opacity: disabled || isLoading ? 1 : 0.8,
          ...(isDragActive && {
            backgroundColor: 'action.active',
            opacity: 0.5,
          }),
          '&:hover':
            disabled || isLoading
              ? {}
              : {
                  backgroundColor: 'action.hover',
                  cursor: 'pointer',
                  opacity: 0.5,
                },
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {!isAvatar ? (
          <>
            <Box
              sx={{
                '& img': {
                  width: 100,
                },
              }}
            >
              <img alt="Select file" src="/static/undraw_add_file2_gvbb.svg" />
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography color="textPrimary" variant="h6">
                {/* {`Select file${maxFiles && maxFiles === 1 ? '' : 's'}`} */}
                Select W-9 File (Required Upload Field)
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography color="textPrimary" variant="body1">
                  {`Drop file${maxFiles && maxFiles === 1 ? '' : 's'}`}{' '}
                  <Link color="primary" underline="always">
                    browse
                  </Link>{' '}
                  thorough your machine
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <div style={{ position: 'relative' }}>
            <Avatar
              src={avatarUrl}
              sx={{
                height: 100,
                width: 100,
              }}
            />
            {avatarUrl && !disabled && (
              <Tooltip title="Remove">
                <IconButton
                  edge="end"
                  onClick={onRemoveAvatar}
                  sx={{
                    position: 'absolute',
                    top: -12,
                    right: -12,
                  }}
                  size="large">
                  <XIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {isLoading && (
              <CircularProgress
                sx={{
                  position: 'absolute',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  top: 30,
                  left: 30,
                }}
              />
            )}
          </div>
        )}
      </Box>
      {validationMessage && (
        <Box sx={{ fontSize: '13px', color: 'red', marginLeft: '10px' }}>
          <p>{validationMessage}</p>
        </Box>
      )}

      {!isAvatar && files.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <List>
            {files.map((file) => (
              <ListItem
                key={file.path}
                sx={{
                  border: 1,
                  borderColor: 'divider',
                  borderRadius: 1,
                  '& + &': {
                    mt: 1,
                  },
                }}
              >
                {isLoading ? (
                  <CircularProgress
                    sx={{
                      width: '20px !important ',
                      height: '20px !important',
                      marginRight: '20px',
                    }}
                  />
                ) : (
                  <ListItemIcon>
                    <DuplicateIcon fontSize="small" />
                  </ListItemIcon>
                )}

                <ListItemText
                  primary={file.name}
                  primaryTypographyProps={{
                    color: 'textPrimary',
                    variant: 'subtitle2',
                  }}
                  secondary={bytesToSize(file.size)}
                />
                <Tooltip title="Remove">
                  <IconButton
                    edge="end"
                    onClick={() => onRemove && !disabled && onRemove(file)}
                    size="large">
                    <XIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
          {/* <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 2,
            }}
          >
            <Button color="primary" onClick={onRemoveAll} size="small" type="button" variant="text">
              Remove All
            </Button>
            <Button
              color="primary"
              onClick={onUpload}
              size="small"
              sx={{ ml: 2 }}
              type="button"
              variant="contained"
            >
              Upload
            </Button>
          </Box> */}
        </Box>
      )}
    </div>
  );
};

FileDropzone.propTypes = {
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  disabled: PropTypes.bool,
  files: PropTypes.array,
  getFilesFromEvent: PropTypes.func,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  preventDropOnDocument: PropTypes.bool,
  validationMessage: PropTypes.string,
};

FileDropzone.defaultProps = {
  files: [],
};

export default FileDropzone;
