import styled from 'styled-components';
import pngBlank from '../../assets/pngBlank.png';

export const CropperWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 90%;
  position: relative;
  background-image: url(${pngBlank});
`;

export const CropPhotoModalContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .zoomSlider {
    margin: 0 10px;
  }
  .actions {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
`;
