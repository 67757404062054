import { Box } from '@mui/material';
import { getTime, parseISO } from 'date-fns';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { ChatMessage } from '../../../components/dashboard/chat';
import Scrollbar from '../../../components/Scrollbar';
import useAuth from '../../../hooks/useAuth';
import { MessagingSelectedMessagesType } from '../../../types/messaging-case-management/messaging_selected_messages';
import { LoadMoreButtonsConfig } from '../../../types/messaging_load_more_buttons_config';
import { MessagingMessage } from '../../../types/messaging_message';
import { MessagingParticipant } from '../../../types/messaging_participant';
import { getUserNameFromUser } from '../../../utils/messagingNames';
import LoadMoreButton from '../LoadMoreButton';
import { selectMessagesForExport } from '../messagingHelper';
import { alpha, useTheme } from '@mui/material/styles';

interface MessagingCaseManagementSelectionProps {
  messages: Array<MessagingMessage>;
  participants: Array<MessagingParticipant>;
  loadMoreButtonsConfig: LoadMoreButtonsConfig;
  selectedMessages: MessagingSelectedMessagesType;
  setSelectedMessages: Dispatch<SetStateAction<MessagingSelectedMessagesType>>;
}

const MessagingCaseManagementSelection: FC<MessagingCaseManagementSelectionProps> = (props) => {
  const { messages, participants, loadMoreButtonsConfig, selectedMessages, setSelectedMessages } =
    props;

  const { user } = useAuth();
  const theme = useTheme();

  useEffect(() => {
    setSelectedMessages({ firstMessage: null, secondMessage: null });
    // return () => {
    // };
  }, []);

  return (
    <Scrollbar
      style={{
        height: 'calc(75vh - 150px)',
        minWidth: '700px',
      }}
    >
      <Box sx={{ py: 2 }}>
        {messages.length > 0 && (
          <LoadMoreButton
            title={loadMoreButtonsConfig.loadOlder?.title}
            onClick={loadMoreButtonsConfig.loadOlder?.onClick}
            isVisible={loadMoreButtonsConfig.loadOlder?.visible}
            disabled={loadMoreButtonsConfig.loadOlder?.disabled}
            icon={loadMoreButtonsConfig.loadOlder?.icon}
          />
        )}
        {messages.length ? (
          messages.map((message) => {
            {
              let participant = message.sender
                ? participants.find(
                    (_participant) =>
                      _participant.uuid === message.sender.uuid ||
                      _participant.username === message.sender.username
                  )
                : null;
              if (participant && !participant.senderName) {
                participant = {
                  ...participant,
                  senderName: getUserNameFromUser(participant),
                };
              }
              if (message.sender && message.sender?.uuid === user.uuid) {
                participant = {
                  avatar: user?.profile_image?.thumbnails?.avatar,
                  username: user.username,
                  uuid: message?.sender?.uuid,
                  senderName: 'Me',
                  senderType: 'user',
                };
              } else if (!participant) {
                const name = getUserNameFromUser(message.sender);
                participant = {
                  avatar: message.sender_avatar,
                  senderName: name,
                  username: name,
                  uuid: message?.sender?.uuid || getTime(new Date()).toString(),
                  senderType: null,
                };
              }
              if (participant && message.sender_avatar && !participant.avatar)
                participant.avatar = message.sender_avatar;

              const isBetweenSelectedMessages =
                Date.parse(message.created) > Date.parse(selectedMessages.firstMessage?.created) &&
                Date.parse(message.created) < Date.parse(selectedMessages.secondMessage?.created);

              return (
                <Box
                  onClick={() => {
                    if (isBetweenSelectedMessages) return;
                    setSelectedMessages(
                      selectMessagesForExport({
                        selectedMessages,
                        message,
                      })
                    );
                  }}
                  key={`${message.uuid}-pinned`}
                  sx={{
                    px: 1,
                    borderRadius: '16px',
                    '&:hover': {
                      transition: 'background-color 100ms ease-out',
                      backgroundColor:
                        !isBetweenSelectedMessages && alpha(theme.palette.primary.light, 0.1),
                    },
                    ...((selectedMessages.firstMessage?.uuid === message.uuid ||
                      selectedMessages.secondMessage?.uuid === message.uuid) && {
                      backgroundColor: alpha(theme.palette.primary.light, 0.2),
                    }),
                    ...(isBetweenSelectedMessages && {
                      backgroundColor: alpha(theme.palette.primary.light, 0.05),
                    }),
                    cursor: !isBetweenSelectedMessages && 'pointer',
                  }}
                >
                  <ChatMessage
                    uuid={message.uuid}
                    textBody={message.text}
                    imageBody={message.image}
                    fileBody={message.file}
                    contentType={message.image ? 'image' : 'text'}
                    createdAt={parseISO(message.created)}
                    key={`${message.uuid}-pinned`}
                    senderAvatar={participant?.avatar}
                    senderName={participant.senderName}
                    senderType={participant?.senderType}
                    extraContext={message.extra_context}
                    boxSx={{ mb: 1 }}
                    pinConfig={{
                      is_pinned: message.is_pinned,
                      showPinActions: false,
                    }}
                  />
                </Box>
              );
            }
          })
        ) : (
          <>asd</>
        )}
        {messages.length > 0 && (
          <LoadMoreButton
            title={loadMoreButtonsConfig.loadNewer?.title}
            onClick={loadMoreButtonsConfig.loadNewer?.onClick}
            isVisible={loadMoreButtonsConfig.loadNewer?.visible}
            disabled={loadMoreButtonsConfig.loadNewer?.disabled}
            icon={loadMoreButtonsConfig.loadNewer?.icon}
          />
        )}
      </Box>
    </Scrollbar>
  );
};

export default MessagingCaseManagementSelection;
