export const replaceObjValueDeep = (
  object: any,
  key: string,
  newValue: any,
  pathSeparator: string
) => {
  object[key] = newValue;
  if (Object.keys(object[pathSeparator])?.length > 0)
    Object.entries(object[pathSeparator]).map(([_key, value]) => {
      replaceObjValueDeep(value, key, newValue, pathSeparator);
    });
};
