import { FC } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { ItemType, LocationType } from '../../../slices/locations';
import QRCodeImage from '../../../components/QRCodeImage';
import { useNavigate } from 'react-router-dom';
import { getMessagingDetailsPath } from '../../../routesPaths';
import useModal from '../../../hooks/useModal';
import X from '../../../icons/X';
import ChatIcon from '@mui/icons-material/Chat';
import { useTheme } from '@mui/material/styles';
import LocationsActionModal from '../LocationsModals/LocationsItemsActionsModal';

interface BuildingsMachinesItemProps {
  item: ItemType;
  handleSetQrCodesUrlsToPrint: (item: LocationType | ItemType) => void;
  qrCodesToPrint: Array<{
    qr_code: string;
    name: string;
    ancestors_names: Array<string>;
    printAmount: number;
  }>;
  isPrintStateActive: boolean;
  isAnyLoading: boolean;
}

const LocationsItemsRow: FC<BuildingsMachinesItemProps> = (props) => {
  const { item, handleSetQrCodesUrlsToPrint, qrCodesToPrint, isPrintStateActive, isAnyLoading } =
    props;

  const navigate = useNavigate();
  const theme = useTheme();

  const { Component: DescriptionModal, ...descriptionModalProps } = useModal();

  const handleRedirectToChat = (thread_uuid: string): void => {
    navigate(getMessagingDetailsPath({ id: thread_uuid }));
  };

  return (
    <ListItem
      key={item.uuid}
      sx={{
        p: 0,
      }}
      // secondaryAction={
      //   <Box
      //     sx={{
      //       display: 'flex',
      //       flexDirection: 'column',
      //     }}
      //   >
      //     <Button
      //       variant={'outlined'}
      //       endIcon={<ChatIcon />}
      //       sx={{ mb: 1 }}
      //       disabled={!item.qr_code}
      //       onClick={() => {
      //         // if (!item.qr_code) return;
      //         // printImage(item.qr_code);
      //         handleRedirectToChat(item.thread_uuid);
      //       }}
      //     >
      //       <Typography variant={'subtitle2'}>Go to chat</Typography>
      //     </Button>
      //   </Box>
      // }
    >
      <DescriptionModal
        {...descriptionModalProps}
        handleClose={() => {
          descriptionModalProps.close();
        }}
        sx={{
          height: 'fit-content',
          maxWidth: '750px',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant={'h5'}>Description</Typography>
          <IconButton
            onClick={() => {
              descriptionModalProps.close();
            }}
            color={'primary'}
          >
            <X />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 3 }} />
        {item.description}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            sx={{
              m: 1,
              mt: 3,
              margin: 0,
              width: '100px',
              py: 1,
              fontSize: '16px',
            }}
            onClick={() => {
              descriptionModalProps.close();
            }}
            variant={'outlined'}
          >
            Close
          </Button>
        </Box>
      </DescriptionModal>
      {isPrintStateActive && item.qr_code && (
        <Checkbox
          onClick={() => {
            handleSetQrCodesUrlsToPrint(item);
          }}
          checked={Boolean(qrCodesToPrint.find((qrCode) => qrCode.qr_code === item.qr_code))}
          disabled={isAnyLoading}
        />
      )}
      <Box
        component={isPrintStateActive ? ListItemButton : ListItem}
        onClick={() => {
          // if (!isPrintStateActive) handleRedirectToChat(item.thread_uuid);
          // else handleSetQrCodesUrlsToPrint(item);
          if (isPrintStateActive) handleSetQrCodesUrlsToPrint(item);
        }}
      >
        <ListItemIcon>
          <QRCodeImage
            QRCodePlaceholderStyle={{ width: '100px', height: '100px', top: '16px' }}
            qrCode={item.qr_code}
            qrCodeImageStyle={{
              width: '100px',
              height: '100px',
            }}
          />
        </ListItemIcon>

        <Box>
          <ListItemText
            // primary={item.name}
            // secondary={item.location?.ancestors_names.join(' / ')}
            // secondaryTypographyProps={{
            //   sx: {
            //     display: '-webkit-box',
            //     overflow: 'hidden',
            //     WebkitBoxOrient: 'vertical',
            //     WebkitLineClamp: 1,
            //     wordBreak: 'break-all',
            //     width: 'fit-content',
            //   },
            // }}
            disableTypography
          >
            <Tooltip title={item.name} disableInteractive arrow placement={'top'}>
              <Typography
                variant={'body1'}
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  wordBreak: 'break-all',
                  width: 'fit-content',
                }}
              >
                {item.name}
              </Typography>
            </Tooltip>
            <Tooltip
              title={item.location?.ancestors_names.join(' / ')}
              disableInteractive
              arrow
              placement={'bottom'}
            >
              <Typography
                variant={'body2'}
                color={'textSecondary'}
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  wordBreak: 'break-all',
                  width: 'fit-content',
                }}
              >
                {item.location?.ancestors_names.join(' / ')}
              </Typography>
            </Tooltip>
          </ListItemText>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              // ml: 2,
              // mt: 1,
            }}
          >
            <LocationsActionModal
              type={'item_edit'}
              item={item}
              buttonSx={{
                border: `solid 1px ${theme.palette.divider}`,
              }}
              disabled={isPrintStateActive || isAnyLoading}
            />
            <LocationsActionModal
              type={'item_delete'}
              item={item}
              buttonSx={{
                border: `solid 1px ${theme.palette.divider}`,
              }}
              disabled={isPrintStateActive || isAnyLoading}
            />
          </Box>
          {/*<ListItemText secondary={!isPrintStateActive && '(Click to go to chat)'} />*/}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Button
          variant={'outlined'}
          endIcon={<ChatIcon />}
          sx={{ mb: 1, mx: 1, minWidth: '140px' }}
          disabled={!item.thread_uuid || !item.location.is_active}
          onClick={() => {
            // if (!item.qr_code) return;
            // printImage(item.qr_code);
            handleRedirectToChat(item.thread_uuid);
          }}
        >
          <Typography variant={'subtitle2'}>Go to chat</Typography>
        </Button>
      </Box>
    </ListItem>
  );
};

export default LocationsItemsRow;
