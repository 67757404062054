const anonUserName = 'Anonymous User';

export const getUserNameFromUser = (user: any, customLabel?: string) => {
  return customLabel
    ? customLabel
    : user?.first_name || user?.last_name
    ? `${user?.first_name || ''} ${user?.last_name || ''}`
    : user?.username
    ? user.username
    : anonUserName;
};

export const getUserNameFromUserLastNameFirst = (user: any, customLabel?: string) => {
  return customLabel
    ? customLabel
    : user?.first_name || user?.last_name
    ? `${user?.last_name || ''} ${user?.first_name || ''} `
    : user?.username
    ? user.username
    : anonUserName;
};
