import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { MessagingThread } from '../../../types/messaging_thread';
import {
  getUserNameFromUser,
  getUserNameFromUserLastNameFirst,
} from '../../../utils/messagingNames';
import { format, getTime, parseISO } from 'date-fns';
import { convertISOToNotificationsDate } from '../../../utils/convertDate';
import { extractParticipants } from '../messagingHelper';

interface ChatThreadItemProps {
  active?: boolean;
  onSelect: () => void;
  thread: MessagingThread;
  disabled?: boolean;
}

const extractDetails = (thread: MessagingThread) => {
  let otherParticipants = thread.participants;
  let customLabel;
  let ownerAvatar;
  let displayParticipants;
  if (thread?.type === 'CUSTOMER') {
    customLabel = thread?.customer_label ? thread.customer_label : null;
    ownerAvatar = typeof thread?.owner?.avatar === 'string' ? thread?.owner?.avatar : null;
    displayParticipants = extractParticipants(otherParticipants, thread).filter(
      (participant) => participant?.uuid !== thread?.owner?.uuid
    );
  }
  let displayNames = customLabel
    ? customLabel
    : otherParticipants
        .reduce(
          (names, participant) => [...names, getUserNameFromUserLastNameFirst(participant)],
          []
        )
        .join(', ');
  let displaySender = '';
  let displayMessage = '';
  let displayDate = '';
  const lastMessage = thread.last_message?.text || '';

  if (lastMessage) {
    const sender = thread.last_message?.sender?.first_name;
    const message = lastMessage;
    displayDate = convertISOToNotificationsDate(thread.last_message?.created);
    displaySender = `${sender ? sender + ': ' : ''}`;
    displayMessage = message;
  }

  if (thread.object !== null) {
    const { name, location_info } = thread.object;
    displayNames = `${location_info} - ${name}`;
  }

  return {
    ownerAvatar,
    otherParticipants: displayParticipants || otherParticipants,
    displayNames,
    displaySender,
    displayMessage,
    displayDate,
  };
};

const ChatThreadItem: FC<ChatThreadItemProps> = (props) => {
  const { active, thread, onSelect, ...other } = props;

  const details = extractDetails(thread);

  return (
    <ListItem
      button
      onClick={onSelect}
      sx={{
        backgroundColor: active && 'action.selected',
        boxShadow: (theme) => active && `inset 4px 0px 0px ${theme.palette.primary.main}`,
        '.MuiTypography-subtitle2': {
          fontWeight: thread.unread_count > 0 && 700,
        },
        '.MuiTypography-body2': {
          fontWeight: thread.unread_count > 0 && 600,
        },
      }}
      {...other}
    >
      <ListItemAvatar
        sx={{
          display: 'flex',
          justifyContent: {
            sm: 'flex-start',
            xs: 'center',
          },
        }}
      >
        <AvatarGroup
          max={2}
          sx={{
            '& .MuiAvatar-root':
              details.otherParticipants?.length > 1
                ? {
                    height: 26,
                    width: 26,
                    '&:nth-of-type(2)': {
                      mt: '10px',
                    },
                  }
                : {
                    height: 36,
                    width: 36,
                  },
          }}
        >
          {thread?.type === 'CUSTOMER' && (
            <Avatar key={String(details.ownerAvatar)} src={details.ownerAvatar} />
          )}
          {details.otherParticipants?.map((participant) => (
            <Avatar key={participant.uuid} src={participant.avatar} />
          ))}
        </AvatarGroup>
      </ListItemAvatar>
      <ListItemText
        primary={details.displayNames}
        primaryTypographyProps={{
          color: 'textPrimary',
          noWrap: true,
          variant: 'subtitle2',
        }}
        secondary={
          <>
            <div>{details.displayDate}</div>
            <div>
              {details.displaySender}
              <span style={{ fontStyle: 'italic' }}>{details.displayMessage}</span>
            </div>
          </>
        }
        secondaryTypographyProps={{
          color: 'textSecondary',
          noWrap: true,
          variant: 'body2',
        }}
        sx={{
          display: {
            sm: 'block',
            xs: 'none',
          },
        }}
      />
      <Box
        sx={{
          alignItems: 'flex-end',
          display: {
            sm: 'flex',
            xs: 'none',
          },
          flexDirection: 'column',
          ml: 2,
        }}
      >
        {thread.unread_count > 0 && (
          <Chip
            // @ts-ignore
            color="error"
            label={thread.unread_count}
            size="small"
            sx={{
              height: 18,
              mt: '2px',
              minWidth: 18,
              p: '2px',
            }}
          />
        )}
      </Box>
    </ListItem>
  );
};

ChatThreadItem.propTypes = {
  active: PropTypes.bool,
  onSelect: PropTypes.func,
  // @ts-ignore
  thread: PropTypes.object.isRequired,
};

ChatThreadItem.defaultProps = {
  active: false,
};

export default ChatThreadItem;
