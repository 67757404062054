/**
 * convert permissions from api to imported values
 * export const NAME_TO_IMPORT = 'NAME_FROM_API';
 */
export const ADMINS = 'ADMINS';
export const MESSAGES = 'MESSAGES';
export const VENDOR = 'VENDOR';
export const ORGANIZATION = 'ORGANIZATION';
export const TECHNICIANS = 'TECHNICIANS';
export const CUSTOMERS = 'CUSTOMERS';
export const ANALYTICS = 'ANALYTICS';
// Organization
export const ORGANIZATION_ADMINS = 'ORGANIZATION-ADMINS';
export const ORGANIZATION_MESSAGES = 'ORGANIZATION-MESSAGES';
export const ORGANIZATION_TECHNICIANS = 'ORGANIZATION-TECHNICIANS';
export const ORGANIZATION_ANALYTICS = 'ORGANIZATION-ANALYTICS';
export const ORGANIZATION_CUSTOMERS = 'ORGANIZATION-CUSTOMERS';
// Vendor
export const VENDOR_ADMINS = 'VENDOR-ADMINS';
export const VENDOR_MESSAGES = 'VENDOR-MESSAGES';
export const VENDOR_ANALYTICS = 'VENDOR-ANALYTICS';
