import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import type { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LinearProgress from '../../components/LinearProgress';
import { mainAppColors } from '../../theme';
import Scrollbar from '../../components/Scrollbar';
import useOnClickOutside from '../../hooks/useClickOutside';

interface AnalyticsLinearChartProps {
  chartData: any;
  isLoading: boolean;
}

const DotIcon = (props: { categoryColor }) => {
  const { categoryColor } = props;
  return (
    <div
      style={{
        display: 'block',
        backgroundColor: categoryColor,
        borderRadius: '50%',
        width: '12px',
        height: '12px',
      }}
    />
  );
};

const normalizeChartDataValues = (chartSeries) => {
  for (let chartSerieIdx in chartSeries) {
    let serieData = chartSeries[chartSerieIdx].data.map((data) => parseFloat(data));
    chartSeries[chartSerieIdx] = { ...chartSeries[chartSerieIdx], data: serieData };
  }
  return chartSeries;
};

const generateYAxis = ({ theme, selectedSeries }) => {
  const revenueAxis = {
    axisBorder: {
      color: theme.palette.divider,
      show: true,
    },
    axisTicks: {
      color: theme.palette.divider,
      show: true,
    },
    labels: {
      style: {
        colors: theme.palette.text.secondary,
      },
    },
    seriesName: 'Revenue',
    title: {
      text: 'Amount',
      rotate: 0,
      offsetY: 175,
      offsetX: 25,
    },
  };

  const tipsAxis = {
    seriesName: 'Revenue',
    show: false,
  };

  const billableChatsAxis = {
    seriesName: 'Revenue',
    show: false,
  };

  const minutesAxis = {
    axisTicks: {
      color: theme.palette.divider,
      show: true,
    },
    axisBorder: {
      color: theme.palette.divider,
      show: true,
    },
    labels: {
      style: {
        colors: theme.palette.text.secondary,
      },
    },
    opposite: true,
    seriesName: 'Minutes',
    title: {
      text: 'Minutes',
      rotate: 0,
      offsetY: 175,
      offsetX: -25,
    },
  };

  let yaxis = [];
  // only number of axis and position in array matters
  if (selectedSeries.length === 4) {
    yaxis.push(revenueAxis);
    yaxis.push(tipsAxis);
    yaxis.push(billableChatsAxis);
    yaxis.push(minutesAxis);
  } else if (selectedSeries.length < 4 && selectedSeries.length !== 0) {
    if (selectedSeries.includes('Revenue')) yaxis.push(revenueAxis);
    if (selectedSeries.includes('Tips') && selectedSeries.includes('Revenue')) yaxis.push(tipsAxis);
    if (selectedSeries.includes('Tips') && !selectedSeries.includes('Revenue'))
      yaxis.push(revenueAxis);
    if (
      selectedSeries.includes('Billable Chats') &&
      !selectedSeries.includes('Tips') &&
      !selectedSeries.includes('Revenue')
    )
      yaxis.push(revenueAxis);
    if (
      selectedSeries.includes('Billable Chats') &&
      !selectedSeries.includes('Tips') &&
      selectedSeries.includes('Revenue')
    )
      yaxis.push(billableChatsAxis);
    if (
      selectedSeries.includes('Billable Chats') &&
      selectedSeries.includes('Tips') &&
      !selectedSeries.includes('Revenue')
    ) {
      yaxis.push(billableChatsAxis);
      // seriesName must match any visible series
      yaxis = yaxis.map((axis) => {
        return { ...axis, seriesName: axis.seriesName === 'Revenue' ? 'Tips' : axis.seriesName };
      });
    }
    if (selectedSeries.includes('Minutes')) yaxis.push(minutesAxis);
    else yaxis = [revenueAxis];
  } else if (selectedSeries.length === 0) {
    // only to have any visual
    yaxis.push(revenueAxis);
    yaxis.push(minutesAxis);
  }
  return yaxis;
};

const generateData = ({ chartData, theme, selectedSeries, setTooltip }) => {
  if (!chartData || !theme || !selectedSeries) return { chartSeries: [], chartOptions: {} };
  let chartSeries = chartData?.series?.filter((item) => selectedSeries?.includes(item.name));
  if (chartSeries?.length) {
    chartSeries = normalizeChartDataValues(chartSeries);
  }

  const yaxis = generateYAxis({ theme, selectedSeries });

  const chartOptions: ApexOptions = {
    tooltip: {
      enabled: false,
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: function (event, chartContext, config) {
          const { seriesIndex, dataPointIndex, w } = config;
          const series = config.w.config.series;
          const date = w.globals.labels[dataPointIndex];
          const category = w.globals.initialSeries[seriesIndex]?.name;
          const categoryColor = w.globals.initialSeries[seriesIndex]?.color;
          const value = series[seriesIndex]?.data[dataPointIndex];
          const currentSerie = chartData.series.find((serie) => serie.name === category);
          const breakdown = currentSerie?.details[dataPointIndex] || [];

          setTimeout(() => {
            setTooltip((prevState) => ({
              ...prevState,
              open: true,
              date,
              category,
              categoryColor,
              value,
              currentSerie,
              breakdown,
            }));
          }, 50);
        },
      },
    },
    colors: chartSeries?.map((item) => item.color),
    dataLabels: {
      enabled: false,
    },
    grid: {
      borderColor: theme.palette.divider,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      show: false,
    },
    markers: {
      hover: {
        size: undefined,
        sizeOffset: 2,
      },
      radius: 2,
      shape: 'circle',
      size: 4,
      strokeWidth: 0,
    },
    stroke: {
      curve: 'smooth',
      lineCap: 'butt',
      width: 3,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      categories: chartData?.xaxis?.dataPoints || chartData?.axis?.dataPoints,
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis,
  };
  return { chartSeries, chartOptions };
};

// Based on Chart7 component
const AnalyticsChart: FC<AnalyticsLinearChartProps> = (props) => {
  const { chartData, isLoading } = props;
  const theme = useTheme();
  const [selectedSeries, setSelectedSeries] = useState([]);
  const [wasSelectTouched, setWasSelectTouched] = useState(false);
  const [tooltip, setTooltip] = useState({
    open: false,
    date: '',
    category: '',
    categoryColor: 'white',
    value: '',
    currentSerie: {},
    breakdown: [],
  });
  const tooltipRef = useRef<HTMLDivElement>(null);
  const tooltipScrollRef = useRef<any>(null);
  useOnClickOutside(tooltipRef, () => {
    setTooltip((prevState) => (prevState?.open ? { ...prevState, open: false } : prevState));
  });

  const handleChange = (event, name: string) => {
    if (!event.target.checked) {
      setSelectedSeries(selectedSeries.filter((item) => item !== name));
    } else {
      setSelectedSeries([...selectedSeries, name]);
    }
  };

  const scrollTooltipTop = () => {
    // eslint-disable-next-line no-underscore-dangle
    if (tooltipScrollRef?.current?._container) {
      // eslint-disable-next-line no-underscore-dangle
      tooltipScrollRef.current._container.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (tooltip.open) scrollTooltipTop();
  }, [tooltip.open]);

  useEffect(() => {
    if (chartData.series?.length && !wasSelectTouched) {
      setWasSelectTouched(true);
      const allSeries = chartData.series.map((element) => element.name);
      setSelectedSeries(allSeries);
    }
  }, [chartData]);

  const { chartSeries, chartOptions } = generateData({
    chartData,
    theme,
    selectedSeries,
    setTooltip,
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        p: 3,
      }}
    >
      <Card>
        <LinearProgress show={isLoading} />
        <CardHeader
          disableTypography
          title={
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography color="textPrimary" variant="h6">
                Revenue vs Minutes
              </Typography>
            </Box>
          }
        />
        <CardContent>
          {/* tooltip */}
          <Card
            ref={tooltipRef}
            sx={{
              minWidth: '100px',
              maxWidth: '70%',
              marginLeft: 'auto',
              marginRight: 'auto',
              minHeight: '50px',
              color: 'black',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 9999999,
              maxHeight: '380px',
              display: tooltip.open ? 'block' : 'none',
              position: 'absolute',
            }}
          >
            <CardContent sx={{ marginTop: 0, paddingTop: 0, width: '100%', maxHeight: '100%' }}>
              <Box
                sx={{
                  paddingTop: 1,
                  paddingBottom: 1,
                  color: mainAppColors.primary,
                  fontWeight: 600,
                }}
              >
                {tooltip.date}
              </Box>
              <Box
                sx={{
                  paddingBottom: tooltip.breakdown?.length ? 2 : 0,
                }}
              >
                <div style={{ display: 'inline-block', height: '11px', marginRight: '3px' }}>
                  <DotIcon categoryColor={tooltip.categoryColor} />
                </div>
                <Box sx={{ display: 'inline' }}>
                  {tooltip.category}:{' '}
                  <Box sx={{ display: 'inline', fontWeight: 600 }}>{tooltip.value}</Box>
                </Box>
              </Box>
              <Box>
                <Scrollbar ref={tooltipScrollRef}>
                  <Box sx={{ maxHeight: '260px' }}>
                    {tooltip.breakdown.map((item, idx) => (
                      <Grid
                        container
                        flexWrap="nowrap"
                        justifyContent="space-between"
                        key={idx}
                        sx={{ maxWidth: '100%' }}
                      >
                        <Grid item xs={9} sx={{ wordBreak: 'break-all' }}>
                          {item?.payee || item?.technician}
                        </Grid>

                        <Grid item xs={3}>
                          {item.call_payment_amount || item?.duration || '-'}
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </Scrollbar>
              </Box>
            </CardContent>
          </Card>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {chartData?.series?.map((item) => (
              <Box
                key={item.name}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  mr: 2,
                }}
              >
                <Checkbox
                  checked={selectedSeries.some((visibleItem) => visibleItem === item.name)}
                  color="primary"
                  onChange={(event) => handleChange(event, item.name)}
                />
                <Box
                  sx={{
                    backgroundColor: item.color,
                    borderRadius: '50%',
                    height: 8,
                    ml: 1,
                    mr: 2,
                    width: 8,
                  }}
                />
                <Typography color="textPrimary" variant="subtitle2">
                  {item.name}
                </Typography>
              </Box>
            ))}
          </Box>
          <Chart height="350" options={chartOptions} series={chartSeries} type="bar" />
        </CardContent>
      </Card>
    </Box>
  );
};

export default AnalyticsChart;
