import { Backdrop, Box, Button, CircularProgress, Fade, Modal, Typography } from '@mui/material';
import React, { FC, useRef, useState } from 'react';
import { experimentalStyled } from '@mui/material/styles';
import { sizes } from '../theme';

const ModalComponent: FC<any> = (props) => {
  const { isOpen, handleClose, children, isConfirmation, title, description, sx, ...otherProps } =
    props;
  const modal = useRef(null);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Box
          ref={modal}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: isConfirmation ? 'auto' : sizes.modal.regularHeight,
            minHeight: sizes.modal.minHeight,
            width: isConfirmation ? 'auto' : sizes.modal.regularWidth,
            minWidth: sizes.modal.minWidth,
            maxWidth: '70vw',
            maxHeight: '80vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 5,
            borderRadius: '10px',
            overflow: 'hidden',
            ...sx,
          }}
        >
          {isConfirmation ? (
            <ContentContainer>
              <Typography color="textPrimary" variant="h5" sx={{ marginBottom: '20px' }}>
                {title}
              </Typography>
              {description && (
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  sx={{ marginTop: '20px', marginBottom: '20px' }}
                >
                  {description}
                </Typography>
              )}
              <SimpleActions {...otherProps} />
            </ContentContainer>
          ) : (
            children
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

interface SimpleActionsProps {
  onConfirm: any;
  onCancel: any;
  confirmLabel?: string;
  cancelLabel?: string;
  isConformation?: boolean | null;
  sx?: any;
  form?: string;
  loading?: boolean;
  disableConfirm?: boolean;
  confirmButtonColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
}

const SimpleActionsContainer = experimentalStyled('div')(({ theme, sx, ...otherProps }) => ({
  width: '100%',
  display: 'flex',
  gap: '32px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: 'auto',
}));

const ContentContainer = experimentalStyled('div')(({ theme, sx, ...otherProps }) => ({
  height: '100%',
  minHeight: sizes.modal.minHeight,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const SimpleActions: FC<SimpleActionsProps> = (props) => {
  const {
    onConfirm = () => {},
    onCancel = () => {},
    confirmLabel = 'Ok',
    cancelLabel = 'Cancel',
    isConformation,
    form,
    disableConfirm = false,
    confirmButtonColor = 'primary',
    loading = false,
    ...otherProps
  } = props;

  return (
    <SimpleActionsContainer className="actions" {...otherProps}>
      <Button
        type="button"
        onClick={onCancel}
        color="primary"
        sx={{
          m: 1,
          margin: 0,
          minWidth: '160px',
          py: 1,
          fontSize: '16px',
        }}
        variant="outlined"
      >
        {cancelLabel}
      </Button>
      <Button
        type={form ? 'submit' : 'button'}
        form={form}
        onClick={form ? null : onConfirm}
        color={confirmButtonColor}
        sx={{
          m: 1,
          minWidth: '160px',
          margin: 0,
          py: 1,
          fontSize: '16px',
        }}
        variant="contained"
        disabled={disableConfirm}
      >
        {confirmLabel}
        {loading && <CircularProgress size={16} sx={{ ml: 1, color: 'white' }} />}
      </Button>
    </SimpleActionsContainer>
  );
};

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return {
    Component: ModalComponent,
    ContentContainer,
    SimpleActions,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    isOpen,
    props: {
      isOpen,
      handleClose: () => setIsOpen(false),
    },
  };
};

export default useModal;
