import React, { Dispatch, FC, SetStateAction, useCallback, useRef, useState } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import useModal from '../../../hooks/useModal';
import { Organization } from '../../../types/organization';
import { useTheme } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import DescriptionIcon from '@mui/icons-material/Description';
import { formatBytes } from '../../../utils/formatBytes';
import DeleteIcon from '@mui/icons-material/Delete';
import { AutoSizer, List as VirtualizedList } from 'react-virtualized';
import { useMedia } from '../../../hooks/customHooks';
import { useDispatch } from '../../../store';
import { cancelFileUpload, uploadFile } from '../../../slices/locations';

interface LocationsMediaFileUploadProps {
  organization: Organization;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const fileUploadStatuses = {
  NOT_STARTED: 'NOT_STARTED',
  UPLOADING: 'UPLOADING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

const LocationsMediaFileUpload: FC<LocationsMediaFileUploadProps> = (props) => {
  const { organization, open, setOpen } = props;

  const dispatch = useDispatch();
  const theme = useTheme();

  const { Component: FileUploadModal, ...fileUploadModal } = useModal();

  const [files, setFiles] = useState<
    Array<{
      file: File;
      uploadStatus: string;
      id: string;
    }>
  >([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadedAmount, setUploadedAmount] = useState<number>(0);
  const [isCloseDuringUpload, setIsCloseDuringUpload] = useState<boolean>(false);
  const [isCancelUpload, setIsCancelUpload] = useState<boolean>(false);
  const abortUpload = useRef<{
    abort: boolean;
    clearFiles: boolean;
  }>({
    abort: false,
    clearFiles: false,
  });

  const onDrop = useCallback((acceptedFiles) => {
    handleAddToQueue(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: isUploading,
  });

  const isLessThan850 = useMedia(['(max-height: 850px)'], [true], false);

  const handleAddToQueue = (_files): void => {
    setFiles((prev) => [
      ...prev,
      ..._files.map((_file, idx) => ({
        file: _file,
        uploadStatus: fileUploadStatuses.NOT_STARTED,
        id: `${_file.name}-${prev.length + idx}`,
      })),
    ]);
  };

  const handleUploadFile = (
    file: { file: File; uploadStatus: string; id: string },
    index
  ): Promise<void | { errors: any }> =>
    new Promise(async (resolve, reject) => {
      if (file.uploadStatus !== fileUploadStatuses.NOT_STARTED)
        reject({ errors: { alreadyUploaded: true } });
      else {
        setFiles((prev) => [
          ...prev.slice(0, index),
          { file: file.file, uploadStatus: fileUploadStatuses.UPLOADING, id: file.id },
          ...prev.slice(index + 1, prev.length),
        ]);

        const response = await dispatch(
          uploadFile({
            //@ts-ignore
            organization_uuid: organization.uuid,
            file: file.file,
          })
        );

        if (response.meta.requestStatus === 'fulfilled') {
          setUploadedAmount((prev) => prev + 1);
          resolve();
        } else if (response.meta.requestStatus === 'rejected') reject(response);
      }
      //
    });

  const handleStartUpload = async (): Promise<void> => {
    setUploadedAmount(
      files.filter((file) => file.uploadStatus === fileUploadStatuses.SUCCESS)?.length
    );
    setIsUploading(true);

    for (let i = 0; i < files.length; i++) {
      if (abortUpload?.current?.abort) {
        setIsUploading(false);
        if (abortUpload.current.clearFiles) {
          setFiles([]);
          setUploadedAmount(0);
        }
        abortUpload.current = {
          abort: false,
          clearFiles: false,
        };
        break;
      }
      await handleUploadFile(files[i], i)
        .then(() => {
          setFiles((prev) => {
            let _files = prev;
            _files[i].uploadStatus = fileUploadStatuses.SUCCESS;
            return _files;
          });
        })
        .catch((err) => {
          if (err?.errors?.alreadyUploaded) {
          } else {
            setFiles((prev) => {
              let _files = prev;
              _files[i].uploadStatus = abortUpload.current?.abort
                ? fileUploadStatuses.NOT_STARTED
                : fileUploadStatuses.FAILED;
              return _files;
            });
          }
        });
    }

    //Cleanup after upload ends
    if (abortUpload?.current?.abort) {
      abortUpload.current = {
        abort: false,
        clearFiles: false,
      };
    }
    setIsUploading(false);
    setIsCloseDuringUpload(false);
    setIsCancelUpload(false);
  };

  const handleAbortUpload = (clearFiles: boolean = false): void => {
    abortUpload.current = {
      abort: true,
      clearFiles,
    };
    setTimeout(() => cancelFileUpload && cancelFileUpload(), 150);
  };

  const removeFileFromList = (file: { file: File; uploadStatus: string; id: string }): void => {
    setFiles((prev) => prev.filter((_file) => file?.id !== _file?.id));
    if (file?.uploadStatus === fileUploadStatuses.SUCCESS) setUploadedAmount((prev) => prev - 1);
  };

  const handleRemoveAllFiles = (): void => {
    const onlyUploaded = files.filter(
      (file) => file.uploadStatus !== fileUploadStatuses.NOT_STARTED
    );
    setFiles(onlyUploaded);
    setUploadedAmount(onlyUploaded.length);
  };

  const uploadButtonDisabled =
    isUploading ||
    files.length === 0 ||
    files.filter((file) => [fileUploadStatuses.NOT_STARTED].includes(file?.uploadStatus))
      ?.length === 0;

  const removeAllButtonDisabled =
    isUploading ||
    files.filter((file) => file.uploadStatus !== fileUploadStatuses.NOT_STARTED)?.length ===
      files.length ||
    files.length === 0;

  const noRowsRenderer = () => (
    <ListItemText
      primary={'No files selected'}
      primaryTypographyProps={{
        noWrap: true,
        color: 'textSecondary',
        variant: 'h6',
        align: 'center',
      }}
    />
  );

  const rowRenderer = ({ key, index, style }) => {
    const file = files[index];

    return (
      <Box key={key} style={style}>
        <ListItem
          sx={{
            border: `solid 1px ${theme.palette.divider}`,
            borderRadius: '8px',
            my: 1,
          }}
          dense
          secondaryAction={
            <IconButton
              sx={{
                padding: 0.5,
              }}
              color={'error'}
              onClick={() => {
                removeFileFromList(file);
              }}
              disabled={isUploading || file.uploadStatus !== fileUploadStatuses.NOT_STARTED}
            >
              <DeleteIcon />
            </IconButton>
          }
        >
          <ListItemIcon
            sx={{
              color: 'action.disabled',
            }}
          >
            <DescriptionIcon fontSize={'large'} />
            {file.uploadStatus === fileUploadStatuses.UPLOADING ? (
              <CircularProgress
                size={16}
                sx={{
                  position: 'absolute',
                  top: '8px',
                }}
              />
            ) : file.uploadStatus === fileUploadStatuses.SUCCESS ? (
              <CheckCircleIcon
                color={'success'}
                sx={{
                  position: 'absolute',
                  width: '16px',
                  height: '16px',
                  top: '8px',
                  backgroundColor: 'white',
                  borderRadius: '8px',
                }}
              />
            ) : (
              file.uploadStatus === fileUploadStatuses.FAILED && (
                <CancelIcon
                  color={'error'}
                  sx={{
                    position: 'absolute',
                    width: '16px',
                    height: '16px',
                    top: '8px',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  }}
                />
              )
            )}
          </ListItemIcon>
          <Tooltip title={file.file?.name} disableInteractive placement={'top'} arrow>
            <ListItemText
              primary={file.file?.name}
              secondary={formatBytes(file.file?.size)}
              primaryTypographyProps={{
                sx: {
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  maxWidth: '600px',
                },
              }}
            />
          </Tooltip>
        </ListItem>
      </Box>
    );
  };

  return (
    <>
      <FileUploadModal
        {...fileUploadModal.props}
        isOpen={open}
        handleClose={() => {
          if (!isUploading) {
            setTimeout(() => {
              setFiles([]);
              setUploadedAmount(0);
            }, 150);
            setOpen(false);
          }
        }}
        sx={{
          minWidth: '650px',
          width: 'fit-content',
          height: 'fit-content',
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 3,
                minWidth: '250px',
              }}
            >
              <UploadFileIcon color={'primary'} sx={{ height: '64px', width: '64px' }} />
              <Typography variant={'h5'}>Files upload</Typography>
            </Box>
            <Box
              {...getRootProps()}
              sx={{
                outline: `dashed 2px ${theme.palette.text.secondary}`,
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                cursor: !isUploading && 'pointer',
                ...(isUploading && {
                  backgroundColor: theme.palette.action.disabled,
                }),
                transition: 'filter 150ms, background-color 150ms',
                ...(isDragActive &&
                  !isUploading && {
                    backgroundColor: 'background.paper',
                    filter: 'brightness(75%)',
                  }),
              }}
            >
              <Typography
                variant={'subtitle2'}
                sx={{
                  color: theme.palette.text.secondary,
                  my: 3,
                }}
              >
                Drag & drop or select file from disk
              </Typography>
              <input {...getInputProps()} />
            </Box>

            {!isLessThan850 && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    my: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {isUploading && <CircularProgress size={16} sx={{ mr: 1 }} />}
                    <Typography>
                      {uploadedAmount} out of {files.length} files succesfully uploaded
                    </Typography>
                  </Box>
                  <Button
                    color={'error'}
                    startIcon={<DeleteIcon />}
                    onClick={handleRemoveAllFiles}
                    disabled={removeAllButtonDisabled}
                  >
                    Remove all
                  </Button>
                </Box>
                <Box
                  sx={{
                    height: `calc(50vh - 150px)`,
                    minWidth: '450px',
                    mb: 2,
                  }}
                >
                  <AutoSizer>
                    {({ height, width }) => (
                      <VirtualizedList
                        style={{
                          paddingRight: '24px',
                        }}
                        height={height}
                        width={width}
                        rowRenderer={rowRenderer}
                        rowCount={files.length}
                        rowHeight={70}
                        overscanRowCount={25}
                        noRowsRenderer={noRowsRenderer}
                        data={files}
                      />
                    )}
                  </AutoSizer>
                </Box>
              </>
            )}
          </div>
          {isLessThan850 && (
            <Box sx={{ ml: 2, pl: 2, borderLeft: `solid 1px ${theme.palette.divider}` }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  my: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {isUploading && <CircularProgress size={16} sx={{ mr: 1 }} />}
                  <Typography>
                    {uploadedAmount} out of {files.length} files succesfully uploaded
                  </Typography>
                </Box>
                <Button
                  color={'error'}
                  startIcon={<DeleteIcon />}
                  onClick={handleRemoveAllFiles}
                  disabled={removeAllButtonDisabled}
                >
                  Remove all
                </Button>
              </Box>
              <Box
                sx={{
                  height: `calc(50vh - 100px)`,
                  minWidth: '450px',
                }}
              >
                <AutoSizer>
                  {({ height, width }) => (
                    <VirtualizedList
                      style={{
                        paddingRight: '24px',
                      }}
                      height={height}
                      width={width}
                      rowRenderer={rowRenderer}
                      rowCount={files.length}
                      rowHeight={70}
                      overscanRowCount={25}
                      noRowsRenderer={noRowsRenderer}
                      data={files}
                    />
                  )}
                </AutoSizer>
              </Box>
            </Box>
          )}
        </Box>
        {/*<SimpleActions*/}
        {/*  //@ts-ignore*/}
        {/*  style={{*/}
        {/*    marginTop: '24px',*/}
        {/*  }}*/}
        {/*  onConfirm={handleStartUpload}*/}
        {/*  onCancel={() => {*/}
        {/*    setOpen(false);*/}
        {/*    if (isUploading) {*/}
        {/*      abortUpload.current = true;*/}
        {/*      // setFiles([]);*/}
        {/*      // setUploadedAmount(0);*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  confirmLabel={'Upload'}*/}
        {/*  disableConfirm={uploadButtonDisabled}*/}
        {/*/>*/}
        {/*<Collapse in={isCancelDuringUpload}>*/}
        {isCloseDuringUpload && (
          <Typography
            variant={'subtitle2'}
            align={'center'}
            sx={{
              mt: isLessThan850 ? 2 : 0.25,
              mb: -3,
            }}
          >
            Are you sure you want to close? This action will abort currently ongoing upload.
          </Typography>
        )}
        {isUploading && !isCloseDuringUpload && (
          <Typography
            variant={'subtitle2'}
            align={'center'}
            sx={{
              mt: isLessThan850 ? 2 : 0.25,
              mb: -3,
            }}
          >
            To cancel, close this pop-up
          </Typography>
        )}
        {isCancelUpload && (
          <Typography
            variant={'subtitle2'}
            align={'center'}
            sx={{
              mt: isLessThan850 ? 2 : 0.25,
              mb: -3,
            }}
          >
            Are you sure you want to cancel current upload?
          </Typography>
        )}
        {/*</Collapse>*/}
        <Grid container spacing={2} justifyContent={'space-between'} marginTop={2}>
          <Grid item xs={12} md={3}>
            <Button
              fullWidth
              variant={'outlined'}
              sx={{ py: 1, fontSize: '16px' }}
              onClick={() => {
                if (isCloseDuringUpload) {
                  setIsCloseDuringUpload(false);
                } else if (isUploading) {
                  setIsCancelUpload(false);
                  setIsCloseDuringUpload(true);
                } else {
                  setOpen(false);
                  setTimeout(() => {
                    setFiles([]);
                    setUploadedAmount(0);
                  }, 150);
                }
              }}
            >
              {isCloseDuringUpload ? 'No' : 'Close'}
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            {/*<Collapse in={isCloseDuringUpload}>*/}
            {isCloseDuringUpload && (
              <Button
                fullWidth
                variant={'contained'}
                color={'error'}
                sx={{ py: 1, fontSize: '16px' }}
                onClick={() => {
                  setOpen(false);
                  setTimeout(() => {
                    handleAbortUpload(true);
                  }, 150);
                }}
              >
                Yes
              </Button>
            )}
            {/*</Collapse>*/}
          </Grid>
          <Grid item xs={12} md={3}>
            {isCancelUpload && (
              <Button
                fullWidth
                variant={'outlined'}
                sx={{ py: 1, fontSize: '16px' }}
                onClick={() => {
                  setIsCancelUpload(false);
                }}
                // disabled={uploadButtonDisabled}
              >
                No
              </Button>
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {/*{isUploading ? (*/}
            {/*  <Button*/}
            {/*    fullWidth*/}
            {/*    variant={'contained'}*/}
            {/*    color={'error'}*/}
            {/*    sx={{ py: 1, fontSize: '16px' }}*/}
            {/*    onClick={() => {*/}
            {/*      if (isCancelUpload) {*/}
            {/*        handleAbortUpload();*/}
            {/*        setIsCancelUpload(false);*/}
            {/*      } else {*/}
            {/*        setIsCloseDuringUpload(false);*/}
            {/*        setIsCancelUpload(true);*/}
            {/*      }*/}
            {/*    }}*/}
            {/*    // disabled={uploadButtonDisabled}*/}
            {/*  >*/}
            {/*    {isCancelUpload ? 'Yes' : 'Cancel'}*/}
            {/*  </Button>*/}
            {/*) : (*/}
            <Button
              fullWidth
              variant={'contained'}
              sx={{ py: 1, fontSize: '16px' }}
              onClick={handleStartUpload}
              disabled={uploadButtonDisabled}
            >
              Upload
            </Button>
            {/*)}*/}
          </Grid>
        </Grid>
      </FileUploadModal>
    </>
  );
};

export default LocationsMediaFileUpload;
