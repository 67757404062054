import type { ChangeEvent, FC, MouseEvent } from 'react';
import { useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import type { OrganizationInvoice } from '../../types/organization_invoice';
import { format, parseISO } from 'date-fns';

import {
  Avatar,
  Box,
  Card,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  LinearProgress,
  CircularProgress,
  Button,
} from '@mui/material';
import ArrowRightIcon from '../../icons/ArrowRight';
import SearchIcon from '../../icons/Search';
import getInitials from '../../utils/getInitials';
import Scrollbar from '../../components/Scrollbar';
import { getOrganizationInvoices, clearOrganizationInvoices } from '../../slices/organizations';
import { RootState, useDispatch, useSelector } from '../../store';
import { RequestStatus } from '../../utils/RequestStatus';
import EmptyList from '../../components/EmptyList';
import useDebouncedSearchAndPagination from '../../hooks/useDebouncedSearchAndPagination';
import { pagination } from '../../theme';

const OrganizationInvoices: FC = (props) => {
  const { ...other } = props;
  const dispatch = useDispatch();
  const { organizationInvoices, organizationInvoicesFetchStatus, organizationInvoicesCount } =
    useSelector((state: RootState) => state.organizations.details);

  const { uuid: id } = useParams();

  const { handlePageChange, handleLimitChange, handleQueryChange, query, page, limit } =
    useDebouncedSearchAndPagination({
      callbackToDebounce: ({ limit, page, query }) => {
        dispatch(getOrganizationInvoices({ id, limit, offset: page * limit, search: query }));
      },
      listLength: organizationInvoicesCount,
    });

  useEffect(() => {
    return () => {
      dispatch(clearOrganizationInvoices());
    };
  }, []);

  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Search invoices"
            value={query}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            m: 1,
            width: 240,
          }}
        ></Box>
      </Box>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          {RequestStatus.isFetching(organizationInvoicesFetchStatus) &&
            organizationInvoices.length > 0 && <LinearProgress />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Modified at</TableCell>
                <TableCell>Pdf</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizationInvoices?.map((invoice: OrganizationInvoice, idx) => {
                return (
                  <TableRow hover key={invoice.uuid}>
                    <TableCell>
                      <Typography color="textPrimary" variant="subtitle2">
                        {invoice.name}
                      </Typography>
                    </TableCell>
                    <TableCell>{format(parseISO(invoice.created), 'MM/dd/yyyy')}</TableCell>
                    <TableCell>{format(parseISO(invoice.modified), 'MM/dd/yyyy')}</TableCell>
                    <TableCell>
                      <Typography color="textPrimary" variant="subtitle2">
                        <a href={invoice.pdf} target="_blank" style={{ textDecoration: 'none' }}>
                          <Button color="primary" variant="contained">
                            See invoice
                          </Button>
                        </a>
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {RequestStatus.isDone(organizationInvoicesFetchStatus) &&
            organizationInvoicesCount === 0 && <EmptyList>No invoices found</EmptyList>}
          {RequestStatus.isFetching(organizationInvoicesFetchStatus) &&
            organizationInvoices.length === 0 && (
              <CircularProgress sx={{ display: 'block', margin: '30px auto 10px auto' }} />
            )}
          <TablePagination
            component="div"
            count={organizationInvoicesCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
          />
        </Box>
      </Scrollbar>
    </Card>
  );
};

export default OrganizationInvoices;
