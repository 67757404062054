import { RequestStatus } from './../utils/RequestStatus';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { mailApi } from '../__fakeApi__/mailApi';
import type { AppThunk } from '../store';
import type { Email, Label } from '../types/mail';
import objFromArray from '../utils/objFromArray';
import { authApi } from '../__fakeApi__/authApi';
import NetworkService from '../utils/network.service';
import axios from 'axios';
import ApiRequest from '../utils/ApiRequest';
import { Technician } from '../types/technician';
import type { Administrator } from '../types/administrator';
import { OrganizationInvoice } from '../types/organization_invoice';

interface OrganizationState {
  list: {
    items: Array<any> | null;
    limit?: number;
    offset?: number;
    fetchStatus: string;
    count: number;
    next?: string;
    previous?: string;
  };
  listOrderedByPrimary: {
    items: Array<any> | null;
    limit?: number;
    offset?: number;
    fetchStatus: string;
    count: number;
    next?: string;
    previous?: string;
  };
  details: {
    organization: any;
    fetchStatus: string;
    organizationInvoices: Array<OrganizationInvoice> | [];
    organizationInvoicesCount: number;
    organizationInvoicesFetchStatus: string;
    patchFetchStatus: string;
    patchValidationErrors: any;
    customers: {
      items: Array<any>;
      limit?: number;
      offset?: number;
      fetchStatus: string;
      count: number;
      next?: string;
      previous?: string;
    };
    technicians: {
      items: Array<any>;
      limit?: number;
      offset?: number;
      fetchStatus: string;
      count: number;
      next?: string;
      previous?: string;
    };
    technicianDetails: {
      technician: Technician | null;
      fetchStatus: string;
      patchFetchStatus: string;
      patchValidationErrors: any;
    };
    administrators: {
      items: Array<any>;
      limit?: number;
      offset?: number;
      fetchStatus: string;
      count: number;
      next?: string;
      previous?: string;
      searchedCandidatesForAdmin: {
        items: Array<any>;
        limit?: 0;
        offset?: 0;
        fetchStatus: string;
        count: number;
        next?: string;
        previous?: string;
      };
      postExistingFetchStatus: string;
      postCreateNewAdminAndInviteFetchStatus: string;
      postCreateNewAdminAndInviteValidationErrors: any;
    };
    administratorDetails: {
      admin: Administrator | null;
      fetchStatus: string;
      patchFetchStatus: string;
      patchValidationErrors: any;
    };
  };
}

const initialState: OrganizationState = {
  list: { items: [], count: 0, next: null, previous: null, fetchStatus: RequestStatus.status.NULL },
  listOrderedByPrimary: {
    items: [],
    count: 0,
    next: null,
    previous: null,
    fetchStatus: RequestStatus.status.NULL,
  },
  details: {
    organization: {},
    fetchStatus: RequestStatus.status.NULL,
    organizationInvoices: [],
    organizationInvoicesFetchStatus: RequestStatus.status.NULL,
    organizationInvoicesCount: 0,
    patchFetchStatus: RequestStatus.status.NULL,
    patchValidationErrors: null,
    customers: {
      items: [],
      fetchStatus: RequestStatus.status.NULL,
      count: 0,
    },
    technicians: {
      items: [],
      fetchStatus: RequestStatus.status.NULL,
      count: 0,
    },
    technicianDetails: {
      technician: null,
      fetchStatus: RequestStatus.status.NULL,
      patchFetchStatus: RequestStatus.status.NULL,
      patchValidationErrors: null,
    },
    administrators: {
      items: [],
      fetchStatus: RequestStatus.status.NULL,
      count: 0,
      searchedCandidatesForAdmin: {
        items: [],
        fetchStatus: RequestStatus.status.NULL,
        count: 0,
      },
      postExistingFetchStatus: RequestStatus.status.NULL,
      postCreateNewAdminAndInviteFetchStatus: RequestStatus.status.NULL,
      postCreateNewAdminAndInviteValidationErrors: null,
    },
    administratorDetails: {
      admin: null,
      fetchStatus: RequestStatus.status.NULL,
      patchFetchStatus: RequestStatus.status.NULL,
      patchValidationErrors: null,
    },
  },
};

export const getOrganizations: any = createAsyncThunk(
  'organizations/getOrganizations',
  async (data: { search: string; offset: string; limit: string }, thunkAPI) => {
    return ApiRequest.get('/web/organizations/', {})(data, thunkAPI);
  }
);

export const getOrganizationOrderedByPrimary: any = createAsyncThunk(
  'organizations/getOrganizationOrderedByPrimary',
  async (data: { search: string; offset: string; limit: string }, thunkAPI) => {
    return ApiRequest.get('/web/organizations/', {})(
      { ordering: '-is_primary,name', ...data },
      thunkAPI
    );
  }
);

export const getOrganizationDetails: any = createAsyncThunk(
  'organizations/getOrganizationDetails',
  async (data: { id: string }, thunkAPI) => {
    const { id, ...otherData } = data;
    return ApiRequest.get(`/web/organizations/${id}/`, {})(otherData, thunkAPI);
  }
);

export const getOrganizationCustomers: any = createAsyncThunk(
  'organizations/getOrganizationCustomers',
  async (data: { id: string; limit: string; offset: number; search: string }, thunkAPI) => {
    const { id, ...otherData } = data;
    return ApiRequest.get(`/web/organizations/${id}/customers/`, {})(otherData, thunkAPI);
  }
);

export const getOrganizationTechnicians: any = createAsyncThunk(
  'organizations/getOrganizationTechnicians',
  async (data: { id: string }, thunkAPI) => {
    const { id, ...otherData } = data;
    return ApiRequest.get(`/web/organizations/${id}/technicians/`, {})(otherData, thunkAPI);
  }
);

export const getOrganizationTechnicianDetails: any = createAsyncThunk(
  'organizations/getOrganizationTechnicianDetails',
  async (data: { organizationId: string; technicianId: string }, thunkAPI) => {
    const { organizationId, technicianId } = data;
    return ApiRequest.get(
      `/web/organizations/${organizationId}/technicians/${technicianId}`,
      {}
    )(thunkAPI);
  }
);

export const getOrganizationAdmins: any = createAsyncThunk(
  'organizations/getOrganizationAdmins',
  async (data: { id: string; limit: number; offset: number; search: string }, thunkAPI) => {
    const { id, ...otherData } = data;
    return ApiRequest.get(`/web/organizations/${id}/admins/`, {})(otherData, thunkAPI);
  }
);

export const getOrganizationAdminDetails: any = createAsyncThunk(
  'organizations/getOrganizationAdminDetails',
  async (data: { organizationId: string; adminId: string }, thunkAPI) => {
    const { organizationId, adminId, ...otherData } = data;
    return ApiRequest.get(`/web/organizations/${organizationId}/admins/${adminId}/`, {})(
      otherData,
      thunkAPI
    );
  }
);

export const getSearchedCandidatesForAdmin: any = createAsyncThunk(
  'organizations/getSearchedCandidatesForAdmin',
  async (data: { search: string; limit: number; offset: number; uuid: string }, thunkAPI) => {
    const { uuid, ...otherData } = data;
    return ApiRequest.get(`/web/organizations/${uuid}/admins/user_candidates_for_admin/`, {})(
      otherData,
      thunkAPI
    );
  }
);

export const postCreateAdminFromExistingUser: any = createAsyncThunk(
  'organizations/postCreateAdminFromExistingUser',
  async (data: { userUuid: string; organizationUuid: string }, thunkAPI) => {
    const { organizationUuid, userUuid } = data;
    return ApiRequest.post(
      `/web/organizations/${organizationUuid}/admins/create_from_existing_user/`,
      {}
    )({ user_uuid: userUuid }, thunkAPI);
  }
);

export const postCreateAdminAndInvite: any = createAsyncThunk(
  'organizations/postCreateAdminAndInvite',
  async (
    data: {
      organization_uuid: string;
      admin: {
        first_name: string;
        last_name: string;
        phone_number: string;
        email: string;
        profile_image: string;
      };
    },
    thunkApi
  ) => {
    const { organization_uuid, admin } = data;
    return ApiRequest.post(`/web/organizations/${organization_uuid}/admins/invite/`, {})(
      admin,
      thunkApi
    );
  }
);

export const patchOrganizationDetails: any = createAsyncThunk(
  'organizations/patchOrganizationDetails',
  async (
    data: {
      uuid: string;
      name: string;
      logo: string;
      description: string;
      address: string;
      main_contact: string;
      contact_email: string;
      phone: string;
      union: string;
      billing_information: string;
      invoices: string;
    },
    thunkAPI
  ) => {
    const { uuid, ...otherData } = data;
    return ApiRequest.patch(`/web/organizations/${String(uuid)}/`, {})({ ...otherData }, thunkAPI);
  }
);

export const patchOrganizationTechnicianDetails: any = createAsyncThunk(
  'organizations/patchOrganizationTechnicianDetails',
  async (
    data: {
      organizationId?: string;
      technicianId?: string;
      uuid: string;
      first_name: string;
      last_name: string;
      // logo: string;
      // phone: string;
      email: string;
    },
    thunkAPI
  ) => {
    const { organizationId, technicianId, uuid, ...otherData } = data;
    return ApiRequest.patch(`/web/technicians/${technicianId}/`, {})({ ...otherData }, thunkAPI);
  }
);

export const patchOrganizationAdminDetails: any = createAsyncThunk(
  'organizations/patchOrganizationAdminDetails',
  async (
    data: {
      organizationId?: string;
      adminId?: string;
      uuid: string;
      email: string;
      first_name: string;
      last_name: string;
      name: string;
      phone_number: string;
      profile_image: null;
      username: string;
    },
    thunkAPI
  ) => {
    const { organizationId, adminId, uuid, ...otherData } = data;
    return ApiRequest.patch(`/web/organizations/${organizationId}/admins/${adminId}/`, {})(
      { ...otherData },
      thunkAPI
    );
  }
);

export const getOrganizationInvoices: any = createAsyncThunk(
  'organizations/getOrganizationInvoices',
  async (data: { id: string; limit: number; offset: number; search: string }, thunkAPI) => {
    const { id, ...otherData } = data;
    return ApiRequest.get(`/web/organizations/${id}/invoices/`, {})(otherData, thunkAPI);
  }
);

const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    getOrganizations(state: OrganizationState, action: PayloadAction<any>): void {
      const { user } = action.payload;
    },
    clearPostFetchStatuses: (state: OrganizationState) => {
      state.details.administrators.postExistingFetchStatus = RequestStatus.status.NULL;
      state.details.administrators.postCreateNewAdminAndInviteFetchStatus =
        RequestStatus.status.NULL;
    },
    clearOrganizationCustomers: (state: OrganizationState) => {
      state.details.customers.count = 0;
      state.details.customers.fetchStatus = RequestStatus.status.NULL;
      state.details.customers.items = [];
    },
    clearOrganizationsList: (state: OrganizationState) => {
      state.list = initialState.list;
    },
    clearOrganizationsOrderedByPrimaryList: (state: OrganizationState) => {
      state.listOrderedByPrimary = initialState.listOrderedByPrimary;
    },
    clearOrganizationTechnicians: (state: OrganizationState) => {
      state.details.technicians = initialState.details.technicians;
    },
    clearOrganizationAdmins: (state: OrganizationState) => {
      state.details.administrators.count = 0;
      state.details.administrators.items = [];
      state.details.administrators.fetchStatus = RequestStatus.status.NULL;
    },
    clearOrganizationAdminDetails: (state: OrganizationState) => {
      state.details.administratorDetails = {
        admin: null,
        fetchStatus: RequestStatus.status.NULL,
        patchFetchStatus: RequestStatus.status.NULL,
        patchValidationErrors: null,
      };
    },
    clearCandidatesForAdmin: (state: OrganizationState) => {
      state.details.administrators.searchedCandidatesForAdmin.count = 0;
      state.details.administrators.searchedCandidatesForAdmin.fetchStatus =
        RequestStatus.status.NULL;
      state.details.administrators.searchedCandidatesForAdmin.items = [];
    },
    clearTechnicians: (state: OrganizationState) => {
      state.details.technicians.items = [];
      state.details.technicians.count = 0;
      state.details.technicians.fetchStatus = RequestStatus.status.NULL;
    },
    clearOrganizationDetails: (state: OrganizationState) => {
      state.details.organization = {};
    },
    clearOrganizationInvoices: (state: OrganizationState) => {
      state.details.organizationInvoices = [];
      state.details.organizationInvoicesCount = 0;
      state.details.organizationInvoicesFetchStatus = RequestStatus.status.NULL;
    },
    clearOrganizationTechnicianDetails: (state: OrganizationState) => {
      state.details.organization.technicianDetails =
        initialState.details.organization.technicianDetails;
    },
    setPatchFetchStatus: (state: OrganizationState, action?: PayloadAction<any>) => {
      state.details.patchFetchStatus = action?.payload || RequestStatus.status.NULL;
    },
    setPatchTechnicianFetchStatus: (state: OrganizationState, action?: PayloadAction<any>) => {
      state.details.technicianDetails.patchFetchStatus =
        action?.payload || RequestStatus.status.NULL;
    },
    setPatchValidationErrors: (state: OrganizationState, action?: PayloadAction<any>) => {
      state.details.patchValidationErrors = action?.payload || null;
    },
    setPatchTechnicianValidationErrors: (state: OrganizationState, action?: PayloadAction<any>) => {
      state.details.technicianDetails.patchFetchStatus =
        action?.payload || RequestStatus.status.NULL;
    },
    setPatchAdminFetchStatus: (state: OrganizationState, action?: PayloadAction<any>) => {
      state.details.administratorDetails.fetchStatus = action?.payload || null;
    },
    setPatchAdminValidationErrors: (state: OrganizationState, action?: PayloadAction<any>) => {
      state.details.administratorDetails.patchValidationErrors = action?.payload || null;
    },
    setPostCreateNewAdminAndInviteFetchStatus: (
      state: OrganizationState,
      action?: PayloadAction<any>
    ) => {
      state.details.administrators.postCreateNewAdminAndInviteFetchStatus = action?.payload || null;
    },
    setPostCreateNewAdminAndInviteValidationErrors: (
      state: OrganizationState,
      action?: PayloadAction<any>
    ) => {
      state.details.administrators.postCreateNewAdminAndInviteValidationErrors =
        action?.payload || null;
    },
  },
  extraReducers: {
    [getOrganizations.pending]: (state, action) => {
      state.list.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getOrganizations.fulfilled]: (state, action) => {
      state.list.fetchStatus = RequestStatus.status.DONE;
      state.list.items = action.payload?.results;
      state.list.count = action.payload?.count;
      state.list.next = action.payload?.next;
      state.list.previous = action.payload?.previous;
    },
    [getOrganizations.rejected]: (state, action) => {
      state.details.fetchStatus = RequestStatus.status.ERROR;
    },
    [getOrganizationOrderedByPrimary.pending]: (state, action) => {
      state.listOrderedByPrimary.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getOrganizationOrderedByPrimary.fulfilled]: (state, action) => {
      state.listOrderedByPrimary.fetchStatus = RequestStatus.status.DONE;
      state.listOrderedByPrimary.items = action.payload?.results;
      state.listOrderedByPrimary.count = action.payload?.count;
      state.listOrderedByPrimary.next = action.payload?.next;
      state.listOrderedByPrimary.previous = action.payload?.previous;
    },
    [getOrganizationOrderedByPrimary.rejected]: (state, action) => {
      state.listOrderedByPrimary.fetchStatus = RequestStatus.status.ERROR;
    },
    [getOrganizationDetails.pending]: (state, action) => {
      state.details.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getOrganizationDetails.fulfilled]: (state, action) => {
      state.details.fetchStatus = RequestStatus.status.DONE;
      state.details.organization = action.payload;
    },
    [getOrganizationDetails.rejected]: (state, action) => {
      state.list.fetchStatus = RequestStatus.status.ERROR;
    },
    [getOrganizationInvoices.pending]: (state, action) => {
      state.details.organizationInvoicesFetchStatus = RequestStatus.status.FETCHING;
    },
    [getOrganizationInvoices.fulfilled]: (state, action) => {
      state.details.organizationInvoices = action.payload.results;
      state.details.organizationInvoicesCount = action.payload.count;
      state.details.organizationInvoicesFetchStatus = RequestStatus.status.DONE;
    },
    [getOrganizationInvoices.rejected]: (state, action) => {
      state.details.organizationInvoicesFetchStatus = RequestStatus.status.ERROR;
    },
    [getOrganizationCustomers.pending]: (state, action) => {
      state.details.customers.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getOrganizationCustomers.pending]: (state, action) => {
      state.details.customers.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getOrganizationCustomers.fulfilled]: (state, action) => {
      state.details.customers.count = action.payload.count;
      state.details.customers.items = action.payload.results;
      state.details.customers.fetchStatus = RequestStatus.status.DONE;
    },
    [getOrganizationCustomers.rejected]: (state, action) => {
      state.details.customers.fetchStatus = RequestStatus.status.ERROR;
    },
    [getOrganizationTechnicians.pending]: (state, action) => {
      state.details.technicians.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getOrganizationTechnicians.fulfilled]: (state, action) => {
      state.details.technicians.fetchStatus = RequestStatus.status.DONE;
      state.details.technicians.items = action.payload.results;
      state.details.technicians.count = action.payload.count;
    },
    [getOrganizationTechnicians.rejected]: (state, action) => {
      state.details.technicians.fetchStatus = RequestStatus.status.ERROR;
    },
    [getOrganizationAdmins.pending]: (state, action) => {
      state.details.administrators.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getOrganizationAdmins.fulfilled]: (state, action) => {
      state.details.administrators.fetchStatus = RequestStatus.status.DONE;
      state.details.administrators.items = action.payload.results;
      state.details.administrators.count = action.payload.count;
    },
    [getSearchedCandidatesForAdmin.pending]: (state, action) => {
      state.details.administrators.searchedCandidatesForAdmin.fetchStatus =
        RequestStatus.status.FETCHING;
    },
    [getSearchedCandidatesForAdmin.fulfilled]: (state, action) => {
      state.details.administrators.searchedCandidatesForAdmin.fetchStatus =
        RequestStatus.status.DONE;
      state.details.administrators.searchedCandidatesForAdmin.items = action.payload.results;
      state.details.administrators.searchedCandidatesForAdmin.count = action.payload.count;
    },
    [getSearchedCandidatesForAdmin.rejected]: (state, action) => {
      state.details.administrators.searchedCandidatesForAdmin.fetchStatus =
        RequestStatus.status.ERROR;
    },
    [postCreateAdminFromExistingUser.pending]: (state, action) => {
      state.details.administrators.postExistingFetchStatus = RequestStatus.status.FETCHING;
    },
    [postCreateAdminFromExistingUser.fulfilled]: (state, action) => {
      state.details.administrators.postExistingFetchStatus = RequestStatus.status.DONE;
    },
    [postCreateAdminFromExistingUser.rejected]: (state, action) => {
      state.details.administrators.postExistingFetchStatus = RequestStatus.status.ERROR;
    },
    [postCreateAdminAndInvite.pending]: (state, action) => {
      state.details.administrators.postCreateNewAdminAndInviteFetchStatus =
        RequestStatus.status.FETCHING;
    },
    [postCreateAdminAndInvite.fulfilled]: (state, action) => {
      state.details.administrators.postCreateNewAdminAndInviteFetchStatus =
        RequestStatus.status.DONE;
    },

    [postCreateAdminAndInvite.rejected]: (state, action) => {
      state.details.administrators.postCreateNewAdminAndInviteFetchStatus =
        RequestStatus.status.ERROR;
      state.details.administrators.postCreateNewAdminAndInviteValidationErrors = action.payload;
    },
    [getOrganizationAdminDetails.pending]: (state, action) => {
      state.details.administratorDetails.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getOrganizationAdminDetails.fulfilled]: (state, action) => {
      state.details.administratorDetails.admin = action.payload;
      state.details.administratorDetails.fetchStatus = RequestStatus.status.DONE;
    },
    [getOrganizationAdminDetails.rejected]: (state, action) => {
      state.details.administratorDetails.fetchStatus = RequestStatus.status.ERROR;
    },
    [patchOrganizationDetails.pending]: (state, action) => {
      state.details.patchFetchStatus = RequestStatus.status.FETCHING;
    },
    [patchOrganizationDetails.fulfilled]: (state, action) => {
      state.details.organization = action.payload;
      state.details.patchFetchStatus = RequestStatus.status.DONE;
    },
    [patchOrganizationDetails.rejected]: (state, action) => {
      state.details.patchFetchStatus = RequestStatus.status.ERROR;
      delete action.payload.status;
      state.details.patchValidationErrors = action.payload;
    },
    [getOrganizationTechnicianDetails.pending]: (state, action) => {
      state.details.technicianDetails.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getOrganizationTechnicianDetails.fulfilled]: (state, action) => {
      state.details.technicianDetails.technician = action.payload;
      state.details.technicianDetails.fetchStatus = RequestStatus.status.DONE;
    },
    [getOrganizationTechnicianDetails.rejected]: (state, action) => {
      state.details.technicianDetails.fetchStatus = RequestStatus.status.ERROR;
    },
    [patchOrganizationTechnicianDetails.pending]: (state, action) => {
      state.details.technicianDetails.patchFetchStatus = RequestStatus.status.FETCHING;
    },
    [patchOrganizationTechnicianDetails.fulfilled]: (state, action) => {
      state.details.technicianDetails.technician = action.payload;
      state.details.technicianDetails.patchFetchStatus = RequestStatus.status.DONE;
    },
    [patchOrganizationTechnicianDetails.rejected]: (state, action) => {
      state.details.technicianDetails.patchFetchStatus = RequestStatus.status.ERROR;
      state.details.technicianDetails.patchValidationErrors = action.payload;
    },
    [patchOrganizationAdminDetails.pending]: (state, action) => {
      state.details.administratorDetails.patchFetchStatus = RequestStatus.status.FETCHING;
    },
    [patchOrganizationAdminDetails.fulfilled]: (state, action) => {
      state.details.administratorDetails.admin = action.payload;
      state.details.administratorDetails.patchFetchStatus = RequestStatus.status.DONE;
    },
    [patchOrganizationAdminDetails.rejected]: (state, action) => {
      state.details.administratorDetails.patchFetchStatus = RequestStatus.status.ERROR;
      state.details.administratorDetails.patchValidationErrors = action.payload;
    },
  },
});

export const {
  clearPostFetchStatuses,
  clearOrganizationAdmins,
  clearOrganizationAdminDetails,
  clearOrganizationCustomers,
  clearCandidatesForAdmin,
  clearTechnicians,
  clearOrganizationDetails,
  clearOrganizationInvoices,
  clearOrganizationsOrderedByPrimaryList,
  clearOrganizationTechnicianDetails,
  setPatchFetchStatus,
  setPatchValidationErrors,
  setPatchTechnicianFetchStatus,
  setPatchTechnicianValidationErrors,
  setPatchAdminValidationErrors,
  setPostCreateNewAdminAndInviteFetchStatus,
  setPatchAdminFetchStatus,
  setPostCreateNewAdminAndInviteValidationErrors,
  clearOrganizationsList,
  clearOrganizationTechnicians,
} = slice.actions;
export const { reducer } = slice;

export default slice;
