import ChatAltIcon from '../icons/ChatAlt';
import UserAddIcon from '../icons/UserAdd';
import DotIcon from '../icons/Dot';

export const getNotificationIcon = (itemType) =>
  itemType === 'NEW_MESSAGE' || itemType === 'thread.new_message'
    ? ChatAltIcon
    : itemType === 'INVITATION' || itemType === 'thread.joined'
    ? UserAddIcon
    : DotIcon;
