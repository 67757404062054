import { FC, useEffect, useRef } from 'react';
// import { TextField } from '@material-ui/core';
// import { experimentalStyled } from '@material-ui/core/styles';
import {
  experimentalStyled,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Dropdown } from '../../../types/dropdown';

interface GenericInputProps {
  type: string;
  name: string;
  validationMessage: string;
  isEditMode: boolean;
  defaultValue: string;
  control: any;
  label?: string;
  onChange?: any;
  value?: any;
  helperText?: any;
  error?: any;
  inputRef?: any;
  variant?: any;
  multiline?: boolean;
  maxRows?: number;
  minRows?: number;
  rows?: number;
  sx?: object;
}

export const StyledFormControl: any = experimentalStyled(FormControl)(({ theme }) => ({
  width: '100%',
  //maxWidth: '750px',
  //maxHeight: '80px',
  // minHeight: '75px',
  // '> div': {
  //   height: '50px',
  // },
  '.MuiFormHelperText-root': {
    marginLeft: '0px',
    marginRight: '0px',
    fontSize: '0.68rem',
    lineHeight: '1.2',
  },
}));

// use component Input instead
export const DropdownInput: FC<any> = (props) => {
  const { id, choices, label, noValueMessage, helperText, error } = props;

  const selectRef = useRef<any>(null);

  return (
    <StyledFormControl fullWidth>
      <TextField
        {...props}
        select
        ref={selectRef}
        variant="outlined"
        error={error}
        label={label || null}
        InputLabelProps={{
          shrink: true,
        }}
      >
        {choices?.length ? (
          choices.map((choice: Dropdown) => (
            <MenuItem key={choice.value} value={choice.value}>
              <Typography
                sx={{
                  ...(selectRef.current !== null && {
                    maxWidth: `${selectRef.current.offsetWidth - 14 - 32}px`,
                  }),
                  textOverflow: 'ellipsis',
                  overflowX: 'hidden',
                }}
              >
                {choice.label}
              </Typography>
            </MenuItem>
          ))
        ) : (
          <MenuItem key={'no-val'} value={''} disabled>
            {noValueMessage || ''}
          </MenuItem>
        )}
      </TextField>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </StyledFormControl>
  );
};
