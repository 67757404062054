import { useRef, useState } from 'react';
import type { ElementType, FC } from 'react';
import { subDays, subHours } from 'date-fns';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import BellIcon from '../../icons/Bell';
import ChatAltIcon from '../../icons/ChatAlt';
import CreditCardIcon from '../../icons/CreditCard';
import ShoppingCartIcon from '../../icons/ShoppingCart';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getMessagingDetailsPath, getMessagingPath, getNotificationsPath } from '../../routesPaths';
import { Notification } from '../../types/notification';
import { getNotificationIcon } from '../../utils/notificationsHelper';

const iconsMap: Record<string, ElementType> = {
  item_shipped: ShoppingCartIcon,
  new_message: ChatAltIcon,
  order_placed: CreditCardIcon,
};

interface NotificationsPopoverProps {
  notificationsCount?: number;
  notifications?: Array<Notification>;
  onMarkNotificationsAsRead?: () => void;
  onMarkNotificationAsRead?: (notification: Notification) => void;
}

const NotificationsPopover: FC<NotificationsPopoverProps> = (props) => {
  const {
    notificationsCount = 0,
    notifications,
    onMarkNotificationsAsRead,
    onMarkNotificationAsRead,
  } = props;
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleItemClick = (notification: Notification): void => {
    onMarkNotificationAsRead(notification);
    handleClose();
  };

  const routeToAllNotifications = () => {
    setOpen(false);
    navigate(getNotificationsPath({}));
  };

  return <>
    <Tooltip title="Notifications">
      <IconButton color="inherit" ref={anchorRef} onClick={handleOpen} size="large">
        <Badge color="error" badgeContent={notificationsCount}>
          <BellIcon fontSize="small" />
        </Badge>
      </IconButton>
    </Tooltip>
    <Popover
      anchorEl={anchorRef.current}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: { width: 320 },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography color="textPrimary" variant="h6">
          Notifications
        </Typography>
      </Box>
      {!notifications || notifications?.length === 0 ? (
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            There are no notifications
          </Typography>
        </Box>
      ) : (
        <>
          <List disablePadding>
            {notifications.map((notification) => {
              const Icon = getNotificationIcon(notification?.type);
              const linkTo = getMessagingDetailsPath({
                id: notification?.data?.data_message?.thread_uuid,
              });

              return (
                <ListItem
                  divider
                  key={notification?.uuid}
                  onClick={(event) => handleItemClick(notification)}
                >
                  <ListItemAvatar>
                    <RouterLink to={linkTo}>
                      <Avatar
                        sx={{
                          backgroundColor: 'primary.main',
                          color: 'primary.contrastText',
                        }}
                      >
                        {Icon && <Icon fontSize="small" />}
                      </Avatar>
                    </RouterLink>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <RouterLink to={linkTo}>
                        <Typography color="textPrimary" variant="subtitle2">
                          {notification?.data?.message_title}
                        </Typography>
                      </RouterLink>
                    }
                    secondary={notification?.data?.message_body}
                  />
                </ListItem>
              );
            })}
          </List>
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1,
        }}
      >
        <Button color="primary" size="small" variant="text" onClick={routeToAllNotifications}>
          Show full list
        </Button>
        {notifications && notifications.length > 0 && (
          <Button color="primary" size="small" variant="text" onClick={onMarkNotificationsAsRead}>
            Mark all as read
          </Button>
        )}
      </Box>
    </Popover>
  </>;
};

export default NotificationsPopover;
