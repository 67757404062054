import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import InputMultiselect from './InputMultiselect';
import { RequestStatus } from '../../utils/RequestStatus';

const FiltersContainer = styled.div``;

const SelectRowContainer = styled.div`
  margin-top: 10px;
  max-width: 100%;
`;

const SelectMultipleRowContainer = styled.div`
  margin-top: 10px;
  display: flex;
  > div {
    flex: 1;
    max-width: 100%;
    height: 100%;
  }
  > div:not(:last-child) {
    margin-right: 10px;
  }
`;

interface AnalyticsFiltersProps {
  customersChoices: { items: Array<{ value; label }>; fetchStatus: string | null };
  techniciansChoices: { items: Array<{ value; label }>; fetchStatus: string | null };
  organizationsChoices: { items: Array<{ value; label }>; fetchStatus: string | null };
  vendorsChoices: { items: Array<{ value; label }>; fetchStatus: string | null };
  invoicesChoices: { items: Array<{ value; label }>; fetchStatus: string | null };
  onDropdownsChange: (value: any, wasOrganizationChanged?: boolean) => void;
  userOrganizationsCount: number | null;
}

const AnalyticsFilters: FC<AnalyticsFiltersProps> = (props) => {
  const {
    customersChoices,
    techniciansChoices,
    organizationsChoices,
    vendorsChoices,
    invoicesChoices,
    onDropdownsChange,
    userOrganizationsCount,
  } = props;

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const onSubmit = (data) => {};

  const organizationDropdownChange = useWatch({
    control,
    defaultValue: null, // default value before the render
    name: 'organizations',
  });

  const otherDropdownsChange = useWatch({
    control,
    defaultValue: null, // default value before the render
    name: ['technicians'],
  });

  useEffect(() => {
    if (organizationDropdownChange != null) {
      setValue('technicians', null);
      const values = getValues();
      onDropdownsChange(values, true);
    }
  }, [organizationDropdownChange]);

  useEffect(() => {
    if (otherDropdownsChange != null) {
      const values = getValues();
      onDropdownsChange(values);
    }
  }, [otherDropdownsChange]);

  return (
    <FiltersContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        {userOrganizationsCount && userOrganizationsCount > 1 ? (
          <SelectRowContainer>
            <Typography color="textPrimary" variant="subtitle2">
              Organizations
            </Typography>
            <InputMultiselect
              choices={organizationsChoices?.items}
              control={control}
              name={'organizations'}
              defaultValue={''}
              validationMessage={''}
              isLoading={RequestStatus.isFetching(organizationsChoices?.fetchStatus)}
            />
          </SelectRowContainer>
        ) : (
          <></>
        )}

        {/*<SelectRowContainer>*/}
        {/*  <Typography color="textPrimary" variant="subtitle2">*/}
        {/*    Vendors*/}
        {/*  </Typography>*/}
        {/*  <InputMultiselect*/}
        {/*    choices={vendorsChoices?.items}*/}
        {/*    control={control}*/}
        {/*    name={'vendors'}*/}
        {/*    defaultValue={''}*/}
        {/*    validationMessage={''}*/}
        {/*    isLoading={RequestStatus.isFetching(vendorsChoices?.fetchStatus)}*/}
        {/*  />*/}
        {/*</SelectRowContainer>*/}

        <SelectMultipleRowContainer>
          {/*<div>*/}
          {/*  <Typography color="textPrimary" variant="subtitle2">*/}
          {/*    Customers*/}
          {/*  </Typography>*/}
          {/*  <InputMultiselect*/}
          {/*    choices={customersChoices?.items}*/}
          {/*    control={control}*/}
          {/*    name={'customers'}*/}
          {/*    defaultValue={''}*/}
          {/*    validationMessage={''}*/}
          {/*    isLoading={RequestStatus.isFetching(customersChoices?.fetchStatus)}*/}
          {/*  />*/}
          {/*</div>*/}
          <div>
            <Typography color="textPrimary" variant="subtitle2">
              Technicians
            </Typography>
            <InputMultiselect
              choices={techniciansChoices?.items}
              control={control}
              name={'technicians'}
              defaultValue={''}
              validationMessage={''}
              isLoading={RequestStatus.isFetching(techniciansChoices?.fetchStatus)}
            />
          </div>
        </SelectMultipleRowContainer>

        {/*<SelectRowContainer>*/}
        {/*  <Typography color="textPrimary" variant="subtitle2">*/}
        {/*    Invoices*/}
        {/*  </Typography>*/}
        {/*  <InputMultiselect*/}
        {/*    choices={invoicesChoices?.items}*/}
        {/*    control={control}*/}
        {/*    name={'invoices'}*/}
        {/*    defaultValue={''}*/}
        {/*    validationMessage={''}*/}
        {/*    isLoading={RequestStatus.isFetching(invoicesChoices?.fetchStatus)}*/}
        {/*  />*/}
        {/*</SelectRowContainer>*/}
      </form>
    </FiltersContainer>
  );
};

export default AnalyticsFilters;
