import { useSearchParams } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
import useFirstRender from './useFirstRender';
import { debounce } from 'lodash';
import type { MouseEvent, ChangeEvent } from 'react';
import { pagination } from '../theme';

interface useDebouncedSearchAndPaginationProps {
  callbackToDebounce: any;
  listLength: number;
  propsQuery?: string;
  additionalDependencyArray?: Array<any>;
}

const useDebouncedSearchAndPagination = (props: useDebouncedSearchAndPaginationProps) => {
  const { callbackToDebounce, listLength, propsQuery, additionalDependencyArray = [] } = props;

  const [searchParams, setSearchParams] = useSearchParams({
    page: '0',
    limit: String(pagination.defaultRowsPerPage),
    search: '',
  });

  const [preventCallbackOnPageChange, setPreventCallbackOnPageChange] = useState<boolean>(false);

  let [query, setQuery] = useState<string>(searchParams.get('search'));
  const page = +searchParams.get('page');
  const limit = +searchParams.get('limit');

  if (propsQuery) {
    query = propsQuery;
  }

  const firstRender = useFirstRender();

  useEffect(() => {
    if (!preventCallbackOnPageChange) callbackToDebounce({ limit, page, query });
    else setPreventCallbackOnPageChange(false);
  }, [limit, page]);

  const debouncedCallback = useCallback(
    debounce((query) => {
      const otherSearchParams = Object.fromEntries(searchParams.entries());
      setSearchParams({
        ...otherSearchParams,
        search: query,
        ...(query?.length > 0 && { page: '0' }),
      });
      callbackToDebounce({ limit, page: query?.length > 0 ? 0 : page, query });
      setPreventCallbackOnPageChange(false);
    }, 600),
    [searchParams, ...additionalDependencyArray]
  );

  useEffect(() => {
    if (!firstRender || query !== '') {
      setPreventCallbackOnPageChange(query.length > 0);
      debouncedCallback(query);
    } else if (query === '' && preventCallbackOnPageChange) setPreventCallbackOnPageChange(false);
  }, [query]);

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    const otherSearchParams = Object.fromEntries(searchParams.entries());
    setSearchParams({
      ...otherSearchParams,
      page: newPage.toString(),
    });
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newLimit = parseInt(event.target.value, 10);
    const otherSearchParams = Object.fromEntries(searchParams.entries());
    if (newLimit * page >= listLength) {
      const newPage = newLimit * page > listLength ? Math.floor(listLength / newLimit) : 0;

      setSearchParams({
        ...otherSearchParams,
        page: newPage.toString(),
        limit: newLimit.toString(),
      });
    } else {
      setSearchParams({
        ...otherSearchParams,
        limit: newLimit.toString(),
      });
    }
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };

  return { handlePageChange, handleLimitChange, handleQueryChange, query, page, limit };
};

export default useDebouncedSearchAndPagination;
