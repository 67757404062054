import { experimentalStyled } from '@mui/material/styles';

const EmptyList = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  paddingTop: 30,
  paddingBottom: 30,
  width: '100%',
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
  fontSize: '30px',
}));

export default EmptyList;
