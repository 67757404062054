import {
  Box,
  Card,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Avatar,
  LinearProgress,
  CircularProgress,
  Button,
} from '@mui/material';
import { useState, useEffect } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import ArrowRightIcon from '../../icons/ArrowRight';
import ArchiveIcon from '@mui/icons-material/Check';
import SearchIcon from '../../icons/Search';
import Scrollbar from '../../components/Scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import { Vendor } from '../../types/vendor';
import { RootState, useDispatch, useSelector } from '../../store';
import useMounted from '../../hooks/useMounted';
import getInitials from 'src/utils/getInitials';
import { RequestStatus } from '../../utils/RequestStatus';
import {
  getNotifications,
  patchMarkNotificationAsRead,
  postMarkNotificationsAsRead,
  setPatchFetchStatus,
} from '../../slices/notifications';
import { convertISOToNotificationsDate } from '../../utils/convertDate';
import ChatAltIcon from '../../icons/ChatAlt';
import UserAddIcon from '../../icons/UserAdd';
import { getNotificationIcon } from '../../utils/notificationsHelper';
import { getMessagingDetailsPath } from '../../routesPaths';
import { Notification } from '../../types/notification';
import { useFCMBGMessageOrSocketChannel } from '../../hooks/useFCMBGMessageOrSocketChannel';
import useDebouncedSearchAndPagination from '../../hooks/useDebouncedSearchAndPagination';
import { pagination } from '../../theme';

const NotificationsListTable: FC<any> = (props) => {
  const {
    count: notificationsListLength,
    fetchStatus,
    items,
  } = useSelector((state: RootState) => state.notifications.list);
  const { patchFetchStatus } = useSelector(
    (state: RootState) => state.notifications.notificationsPreview
  );
  const mounted = useMounted();
  const dispatch = useDispatch();

  const { isAuthenticated, user, token } = useSelector((state: RootState) => state.user);

  const notificationEvent = useFCMBGMessageOrSocketChannel(
    {
      uuid: user?.uuid,
      token,
    },
    [user?.uuid, token]
  );

  const { handlePageChange, handleLimitChange, handleQueryChange, query, page, limit } =
    useDebouncedSearchAndPagination({
      callbackToDebounce: ({ limit, page, query }) => {
        dispatch(getNotifications({ limit, offset: page * limit, search: query }));
      },
      listLength: notificationsListLength,
    });

  useEffect(() => {
    dispatch(getNotifications({ limit, offset: page * limit, search: query }));
  }, [notificationEvent]);

  useEffect(() => {
    if (mounted) dispatch(getNotifications({ limit, offset: page * limit }));
  }, [limit, page, mounted, patchFetchStatus]);

  useEffect(() => {
    if (mounted && RequestStatus.isDone(patchFetchStatus)) {
      dispatch(setPatchFetchStatus(RequestStatus.status.NULL));
      setTimeout(() => {
        dispatch(getNotifications({ limit, offset: page * limit }));
      }, 100);
    }
  }, [patchFetchStatus]);

  useEffect(() => {
    return () => {
      dispatch(setPatchFetchStatus(RequestStatus.status.NULL));
    };
  }, []);

  const onMarkAllNotificationsAsRead = () => {
    dispatch(postMarkNotificationsAsRead());
  };

  const onMarkNotificationAsRead = (notification: Notification) => {
    dispatch(patchMarkNotificationAsRead({ id: notification.uuid, is_read: true }));
  };

  return (
    <Card>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Search notifications"
            value={query}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 240,
          }}
        >
          <Button
            color="primary"
            size="large"
            variant="text"
            onClick={onMarkAllNotificationsAsRead}
          >
            <ArchiveIcon fontSize="small" />
            Mark all as read
          </Button>
        </Box>
      </Box>
      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          {(RequestStatus.isFetching(fetchStatus) || RequestStatus.isFetching(patchFetchStatus)) &&
            items.length > 0 && <LinearProgress />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Text</TableCell>
                <TableCell>Date</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item: any) => {
                const linkTo = getMessagingDetailsPath({
                  id: item?.data?.data_message?.thread_uuid,
                });
                const Icon = getNotificationIcon(item?.type);
                return (
                  <TableRow hover key={item.uuid}>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={linkTo}
                        variant="subtitle2"
                        sx={{ textDecoration: 'none' }}
                      >
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            textDecoration: 'none',
                          }}
                        >
                          <RouterLink to={linkTo}>
                            <Avatar
                              sx={{
                                backgroundColor: item?.is_read ? 'gray' : 'primary.main',
                                color: 'primary.contrastText',
                              }}
                            >
                              <Icon fontSize="small" />
                            </Avatar>
                          </RouterLink>
                        </Box>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={linkTo}
                        variant="subtitle2"
                        sx={{ fontWeight: !item?.is_read && 700 }}
                      >
                        {item?.data?.message_title}
                      </Link>
                    </TableCell>
                    <TableCell sx={{ fontWeight: !item?.is_read && 600 }}>
                      {item?.data?.message_body}
                    </TableCell>
                    <TableCell sx={{ fontWeight: !item?.is_read && 600 }}>
                      {item?.modified ? convertISOToNotificationsDate(item?.modified) : ''}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton size="large">
                        <ArchiveIcon
                          fontSize="small"
                          onClick={(event) => onMarkNotificationAsRead(item)}
                        />
                      </IconButton>
                      <IconButton to={linkTo} component={RouterLink} size="large">
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {RequestStatus.isFetching(fetchStatus) && items.length === 0 && (
            <CircularProgress sx={{ display: 'block', margin: '30px auto 10px auto' }} />
          )}
          <TablePagination
            component="div"
            count={notificationsListLength}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
          />
        </Box>
      </Scrollbar>
    </Card>
  );
};

export default NotificationsListTable;
