import { ItemType, LocationType } from '../../../slices/locations';
import { Dispatch, FC, SetStateAction } from 'react';
import { Box, LinearProgress, List, ListItemText } from '@mui/material';
import LocationSidebarRow from './LocationsSidebarRow';
import Scrollbar from '../../../components/Scrollbar';
import { RequestStatus } from '../../../utils/RequestStatus';
import LocationsSidebarToolbar from './LocationsSidebarToolbar';
import { useDispatch } from '../../../store';

interface LocationsSidebarProps {
  locations: Array<LocationType>;
  locationsFetchStatus: string | null;
  isPrintStateActive: boolean;
  handleGetLocations: (
    data: { parent_uuid: string },
    ancestors_uuids: Array<string>,
    options?: {
      preventGetItems?: boolean;
    }
  ) => void;
  handleGetLocationDetails: (data: { uuid: string }, ancestors_uuids: Array<string>) => void;
  currentLocation: Array<string>;
  handleSetQrCodesUrlsToPrint: (item: LocationType | ItemType) => void;
  qrCodesToPrint: Array<{
    qr_code: string;
    name: string;
    ancestors_names: Array<string>;
    printAmount: number;
  }>;
  isAnyLoading: boolean;
  currentlySelectedLocation: LocationType;
  handleGetOrganizations: (data?: any) => void;
  limit: number;
  setLimit: Dispatch<SetStateAction<number>>;
}

const LocationsSidebar: FC<LocationsSidebarProps> = (props) => {
  const {
    locations,
    locationsFetchStatus,
    isPrintStateActive,
    handleGetLocations,
    handleGetLocationDetails,
    currentLocation,
    handleSetQrCodesUrlsToPrint,
    qrCodesToPrint,
    isAnyLoading,
    currentlySelectedLocation,
    handleGetOrganizations,
    limit,
    setLimit,
  } = props;

  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        borderRight: 1,
        borderColor: 'divider',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        width: 450,
        minWidth: 450,
      }}
    >
      <LocationsSidebarToolbar
        handleGetOrganizations={handleGetOrganizations}
        limit={limit}
        setLimit={setLimit}
      />
      <Box sx={{ height: '4px' }}>
        {RequestStatus.isFetching(locationsFetchStatus) && <LinearProgress />}
      </Box>
      <Scrollbar options={{ suppressScrollX: true }} className={'all-results'}>
        <List sx={{ p: 0 }}>
          {locations.length > 0 ? (
            locations?.map((location, idx) => (
              <LocationSidebarRow
                idx={idx}
                key={location.uuid}
                location={location}
                showCheckbox={isPrintStateActive}
                handleGetLocations={handleGetLocations}
                handleGetLocationDetails={handleGetLocationDetails}
                currentLocation={currentLocation}
                handleSetQrCodesUrlsToPrint={handleSetQrCodesUrlsToPrint}
                qrCodesToPrint={qrCodesToPrint}
                isAnyLoading={isAnyLoading}
                currentlySelectedLocation={currentlySelectedLocation}
                handleGetOrganizations={handleGetOrganizations}
              />
            ))
          ) : (
            <ListItemText
              primary={'No buildings found'}
              primaryTypographyProps={{
                noWrap: true,
                color: 'textSecondary',
                variant: 'h6',
                align: 'center',
              }}
            />
          )}
          {/*{locations?.map((location) => (*/}
          {/*  <LocationSidebarItem*/}
          {/*    location={location}*/}
          {/*    showCheckbox={isPrintStateActive}*/}
          {/*    isSubLocation={true}*/}
          {/*  />*/}
          {/*))}*/}
        </List>
      </Scrollbar>
    </Box>
  );
};

export default LocationsSidebar;
