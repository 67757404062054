import { createContext, ReactNode, useState } from 'react';

export const INITIAL_PAGE_SIZE = 25;

export const MediaFilesPaginationContext = createContext<{
  page: number;
  pageSize: number;
  query: string;
  shouldFetchOnSelectOpen: boolean;
  setPage: any;
  setPageSize: any;
  setQuery: any;
  setShouldFetchOnSelectOpen: any;
}>({
  page: 1,
  pageSize: INITIAL_PAGE_SIZE,
  query: '',
  shouldFetchOnSelectOpen: false,
  setPage: () => {},
  setPageSize: () => {},
  setQuery: () => {},
  setShouldFetchOnSelectOpen: () => {},
});

interface MediaFilesPaginationContextProviderProps {
  children: ReactNode;
}

const MediaFilesPaginationContextProvider = (props) => {
  const { children } = props;

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(INITIAL_PAGE_SIZE);
  const [query, setQuery] = useState<string>('');
  const [shouldFetchOnSelectOpen, setShouldFetchOnSelectOpen] = useState<boolean>(false);

  const handleChangePage = (page: number): void => {
    setPage(page);
  };

  const handleIncreasePageSize = (): void => {
    setPageSize((prev) => prev + INITIAL_PAGE_SIZE);
  };

  const handleSetQuery = (_query: string): void => {
    setQuery(_query);
  };

  const handleSetShouldFetchOnSelectOpen = (shouldFetch: boolean): void => {
    setShouldFetchOnSelectOpen(shouldFetch);
  };

  return (
    <MediaFilesPaginationContext.Provider
      value={{
        page,
        pageSize,
        query,
        shouldFetchOnSelectOpen,
        setPage: handleChangePage,
        setPageSize: handleIncreasePageSize,
        setQuery: handleSetQuery,
        setShouldFetchOnSelectOpen: handleSetShouldFetchOnSelectOpen,
      }}
    >
      {children}
    </MediaFilesPaginationContext.Provider>
  );
};

export default MediaFilesPaginationContextProvider;
export const MediaFilesPaginationContextConsumer = MediaFilesPaginationContext.Consumer;
