import { RequestStatus } from './../utils/RequestStatus';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { Vendor } from '../types/vendor';
import ApiRequests from '../utils/ApiRequest';
import ApiRequest from '../utils/ApiRequest';

export interface VendorsState {
  count: number;
  list: Array<Vendor> | [];
  fetchStatus: any;
  postFetchStatus: any;
  //todo: refacor when BE validation response structure will change
  postValidationErrors: {
    [key: string]: string[] | string;
  };
  vendorDetails: {
    fetchStatus: any;
    vendor: Vendor | null;
    postPatchFetchStatus: any;
    postPatchValidationErrors: any;
  };
}

const initialState: VendorsState = {
  count: 0,
  fetchStatus: null,
  list: [],
  postFetchStatus: null,
  postValidationErrors: null,
  vendorDetails: {
    fetchStatus: RequestStatus.status.NULL,
    vendor: null,
    postPatchFetchStatus: RequestStatus.status.NULL,
    postPatchValidationErrors: null,
  },
};

export const getVendors: any = createAsyncThunk(
  'vendors/getVendors',
  async (data: { limit: string; offset: string; search: string }, thunkApi) => {
    return ApiRequests.get('/web/vendors/')(data, thunkApi);
  }
);

export const getVendorDetails: any = createAsyncThunk(
  'vendors/getVendorDetails',
  async (data: { uuid: string }, thunkApi) => {
    const { uuid } = data;
    return ApiRequests.get(`/web/vendors/${uuid}/`)({}, thunkApi);
  }
);

export const patchVendor: any = createAsyncThunk(
  'vendors/patchVendor',
  async (data: { uuid: string; vendor: Vendor }, thunkApi) => {
    const { uuid, vendor } = data;
    console.log('patchVendor', data);
    return ApiRequest.patch(`/web/vendors/${uuid}/`)(vendor, thunkApi);
  }
);

export const postVendor: any = createAsyncThunk(
  'vendors/postVendor',
  async (data: Vendor, thunkApi) => {
    const { uuid, ...otherData } = data;
    console.log('post Vendor, otherData', otherData);
    return ApiRequest.post('/web/vendors/')(otherData, thunkApi);
  }
);

const slice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    resetState: (state: VendorsState) => initialState,
    clearPostPatchValidationErrorsAndFetchStatuses: (state: VendorsState) => {
      state.vendorDetails.postPatchFetchStatus = null;
      state.vendorDetails.postPatchValidationErrors = null;
    },
  },
  extraReducers: {
    [getVendors.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getVendors.fulfilled]: (state, action) => {
      state.list = action.payload.results;
      state.count = action.payload.count;
      state.fetchStatus = RequestStatus.status.DONE;
    },
    [getVendors.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.status.ERROR;
    },
    [getVendorDetails.pending]: (state, action) => {
      state.vendorDetails.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getVendorDetails.fulfilled]: (state, action) => {
      state.vendorDetails.fetchStatus = RequestStatus.status.DONE;
      state.vendorDetails.vendor = action.payload;
    },
    [getVendorDetails.rejected]: (state, action) => {
      state.vendorDetails.fetchStatus = RequestStatus.status.ERROR;
    },
    [patchVendor.pending]: (state, action) => {
      state.vendorDetails.postPatchFetchStatus = RequestStatus.status.FETCHING;
    },
    [patchVendor.fulfilled]: (state, action) => {
      state.vendorDetails.postPatchFetchStatus = RequestStatus.status.DONE;
      state.vendorDetails.vendor = action.payload;
    },
    [patchVendor.rejected]: (state, action) => {
      state.vendorDetails.postPatchFetchStatus = RequestStatus.status.ERROR;
      state.vendorDetails.postPatchValidationErrors = action.payload;
    },
    [postVendor.pending]: (state, action) => {
      state.vendorDetails.postPatchFetchStatus = RequestStatus.status.FETCHING;
    },
    [postVendor.fulfilled]: (state, action) => {
      state.vendorDetails.postPatchFetchStatus = RequestStatus.status.DONE;
    },
    [postVendor.rejected]: (state, action) => {
      state.vendorDetails.postPatchFetchStatus = RequestStatus.status.ERROR;
      state.vendorDetails.postPatchValidationErrors = action.payload;
    },
  },
});

export const {
  resetState,
  resetState: clearVendors,
  clearPostPatchValidationErrorsAndFetchStatuses,
} = slice.actions;
export const { reducer } = slice;
