import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useParams, useSearchParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  LinearProgress,
  CircularProgress,
} from '@mui/material';
import ArrowRightIcon from '../../../icons/ArrowRight';
import SearchIcon from '../../../icons/Search';
import getInitials from '../../../utils/getInitials';
import Scrollbar from '../../../components/Scrollbar';
import useMounted from '../../../hooks/useMounted';
import { getOrganizationsTechnicianDetailsPath } from '../../../routesPaths';
import { getOrganizationTechnicians, clearTechnicians } from '../../../slices/organizations';
import { RootState, useDispatch, useSelector } from '../../../store';
import { RequestStatus } from '../../../utils/RequestStatus';
import EmptyList from '../../../components/EmptyList';
import useDebouncedSearchAndPagination from '../../../hooks/useDebouncedSearchAndPagination';
import { pagination } from '../../../theme';
import { GetDisplayOnlyValueForPhoneNumberInput } from '../../../components/widgets/inputs/Input';
import InputMultiselect from '../../Analytics/InputMultiselect';
import { useForm, useWatch } from 'react-hook-form';
import { convertDropdownToReqValues } from '../../../utils/ApiRequest';
import useFirstRender from '../../../hooks/useFirstRender';

interface OrganizationTechniciansProps {}

const technicianTypesName = 'types';

const OrganizationTechnicians: FC<OrganizationTechniciansProps> = (props) => {
  const { ...other } = props;
  const { uuid: id } = useParams();
  const dispatch = useDispatch();

  const { items, fetchStatus, count } = useSelector(
    (state: RootState) => state.organizations.details.technicians
  );
  const { technicianTypesChoices } = useSelector((state: RootState) => state.config.dropdownValues);
  const organization = useSelector((state: RootState) => state.organizations.details.organization);
  const firstRender = useFirstRender();
  let organizationId = id || organization.uuid;
  const [searchParams, setSearchParams] = useSearchParams();

  const getInitialLabelFilter = () => {
    const otherSearchParams = Object.fromEntries(searchParams.entries());
    const values = otherSearchParams?.label?.split(',') || null;
    if (!values) return technicianTypesChoices;
    const selectedValues = values.map((value) => {
      return technicianTypesChoices.find((choice) => choice.value === value);
    });
    return selectedValues;
  };
  const { control, setValue } = useForm({
    defaultValues: {
      [technicianTypesName]: getInitialLabelFilter(),
    },
  });
  const organizationDropdownChange = useWatch({
    control,
    defaultValue: null, // default value before the render
    name: technicianTypesName,
  });
  const [filters, setFilters] = useState({});

  const { handlePageChange, handleLimitChange, handleQueryChange, query, page, limit } =
    useDebouncedSearchAndPagination({
      callbackToDebounce: ({ limit, page, query }) => {
        dispatch(
          getOrganizationTechnicians({
            limit,
            offset: page * limit,
            search: query,
            id: organizationId,
            label: Object.fromEntries(searchParams.entries())?.label,
          })
        );
      },
      listLength: count,
    });

  useEffect(() => {
    if (firstRender) return;
    const otherSearchParams = Object.fromEntries(searchParams.entries());
    let newSearchParams = {
      ...otherSearchParams,
      label: encodeURIComponent(convertDropdownToReqValues(organizationDropdownChange)).replaceAll(
        '%2C',
        ','
      ),
    };
    if (newSearchParams.label === null) {
      delete newSearchParams.label;
    }
    setSearchParams(newSearchParams);
    setFilters({ label: organizationDropdownChange });
  }, [organizationDropdownChange]);

  useEffect(() => {
    if (firstRender) return;
    const otherSearchParams = Object.fromEntries(searchParams.entries());
    dispatch(
      getOrganizationTechnicians({
        limit,
        offset: page * limit,
        search: query,
        id: organizationId,
        label: otherSearchParams?.label,
      })
    );
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(clearTechnicians());
    };
  }, []);

  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Search technicians"
            value={query}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500,
          }}
        >
          {technicianTypesChoices?.length ? (
            <InputMultiselect
              choices={technicianTypesChoices}
              control={control}
              name={technicianTypesName}
              defaultValue={''}
              validationMessage={''}
              isLoading={RequestStatus.isFetching(fetchStatus)}
            />
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          {RequestStatus.isFetching(fetchStatus) && items.length > 0 && <LinearProgress />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Photo</TableCell>
                <TableCell>Technician</TableCell>
                {/*<TableCell>Region</TableCell>*/}
                <TableCell>Email</TableCell>
                <TableCell>Contact #</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((technician, idx) => {
                return (
                  <TableRow hover key={technician.uuid}>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={getOrganizationsTechnicianDetailsPath({
                          id,
                          technicianId: technician.uuid,
                        })}
                        variant="subtitle2"
                      >
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          <Avatar
                            src={technician?.user?.profile_image?.thumbnails?.avatar}
                            sx={{
                              height: 42,
                              width: 42,
                            }}
                          >
                            {getInitials(
                              technician?.first_name && technician?.last_name
                                ? technician.first_name[0] + technician.last_name[0]
                                : 'T'
                            )}
                          </Avatar>
                        </Box>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={getOrganizationsTechnicianDetailsPath({
                          id,
                          technicianId: technician.uuid,
                        })}
                        variant="subtitle2"
                      >
                        {`${technician.last_name}, ${technician.first_name}`}
                      </Link>
                    </TableCell>
                    <TableCell>{technician.email}</TableCell>
                    <TableCell>
                      <GetDisplayOnlyValueForPhoneNumberInput
                        defaultValue={technician.phone_number}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={getOrganizationsTechnicianDetailsPath({
                          id,
                          technicianId: technician.uuid,
                        })}
                        size="large"
                      >
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {RequestStatus.isDone(fetchStatus) && count === 0 && (
            <EmptyList>No technicians found</EmptyList>
          )}
          {RequestStatus.isFetching(fetchStatus) && items.length === 0 && (
            <CircularProgress sx={{ display: 'block', margin: '30px auto 10px auto' }} />
          )}
          <TablePagination
            component="div"
            count={count}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
          />
        </Box>
      </Scrollbar>
    </Card>
  );
};

OrganizationTechnicians.propTypes = {};

export default OrganizationTechnicians;
