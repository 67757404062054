import { RequestStatus } from '../utils/RequestStatus';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import ApiRequest from '../utils/ApiRequest';
import { cloneDeep, get, set, uniqBy } from 'lodash';
import axios from 'axios';
import { replaceObjValueDeep } from '../utils/replace-obj-value-deep';
import { extractNewLocationErrors } from '../utils/locations/extract-new-location-errors';

export interface ItemType {
  uuid: string;
  name: string;
  description: string;
  location: {
    uuid: string;
    name: string;
    description: string;
    ancestors_names: Array<string>;
    ancestors_uuids: Array<string>;
    is_active: boolean;
  };
  thread_uuid: string;
  qr_code: string;
}

export interface LocationType {
  uuid: string;
  name: string;
  description: string;
  has_subplaces: boolean;
  has_items: boolean;
  is_active: boolean;
  ancestors_names: Array<string>;
  ancestors_uuids: Array<string>;
  thread_uuid: string;
  qr_code: string;
  subplaces: { [key: string]: LocationType } | Array<LocationType>;
}

export interface MediaLibraryFile {
  uuid: string;
  file_label: string;
  file: {
    uuid: string;
    url: string;
    size: number;
    name: string;
    mime_type: string;
  };
}

export interface LocationsState {
  locations: {
    items: { [key: string]: LocationType };
    fetchStatus: string | null;
    count: number;
    next: string;
    previous: string;
    currentLocation: Array<string>;
    selectedLocation: LocationType;
    newLocation: {
      postFetchStatus: string | null;
      errors: any;
    };
    editLocation: {
      fetchStatus: string | null;
      name: string;
      is_active: boolean;
      errors: any;
    };
    deleteLocation: {
      fetchStatus: string | null;
      errors: any;
    };
  };
  items: {
    items: Array<ItemType>;
    fetchStatus: string | null;
    count: number;
    next: string;
    previous: string;
    newItem: {
      postFetchStatus: string | null;
      errors: any;
    };
    editItem: {
      fetchStatus: string | null;
      name: string;
      errors: any;
    };
    deleteItem: {
      fetchStatus: string | null;
      errors: any;
    };
  };
  locationDetails: {
    location: LocationType;
    fetchStatus: string | null;
  };
  itemDetails: {
    item: ItemType;
    fetchStatus: string | null;
  };
  mediaLibraryFiles: {
    count: number;
    items: Array<MediaLibraryFile>;
    allItems: Array<MediaLibraryFile>;
    fetchStatus: string | null;
  };
  uploadFile: {
    fetchStatus: string | null;
  };
  replaceFile: {
    fetchStatus: string | null;
  };
  deleteFile: {
    fetchStatus: string | null;
  };
  createNewStructure: {
    fetchStatus: string | null;
    errors: any;
  };
  grantAccess: {
    item: {
      fetchStatus: string | null;
      message: string | null;
      error: any;
    };
    location: {
      fetchStatus: string | null;
      message: string | null;
      error: any;
    };
  };
}

const initialState: LocationsState = {
  locations: {
    items: {},
    fetchStatus: RequestStatus.status.NULL,
    count: 0,
    next: null,
    previous: null,
    currentLocation: [],
    selectedLocation: null,
    newLocation: {
      postFetchStatus: RequestStatus.status.NULL,
      errors: null,
    },
    editLocation: {
      fetchStatus: RequestStatus.status.NULL,
      name: null,
      is_active: false,
      errors: null,
    },
    deleteLocation: {
      fetchStatus: RequestStatus.status.NULL,
      errors: {},
    },
  },
  items: {
    items: [],
    fetchStatus: RequestStatus.status.NULL,
    count: 0,
    next: null,
    previous: null,
    newItem: {
      postFetchStatus: RequestStatus.status.NULL,
      errors: null,
    },
    editItem: {
      fetchStatus: RequestStatus.status.NULL,
      name: null,
      errors: null,
    },
    deleteItem: {
      fetchStatus: RequestStatus.status.NULL,
      errors: null,
    },
  },
  locationDetails: {
    location: {
      uuid: null,
      ancestors_names: [],
      ancestors_uuids: [],
      description: null,
      name: null,
      is_active: false,
      has_items: false,
      has_subplaces: false,
      thread_uuid: null,
      qr_code: null,
      subplaces: [],
    },
    fetchStatus: RequestStatus.status.NULL,
  },
  itemDetails: {
    item: null,
    fetchStatus: RequestStatus.status.NULL,
  },
  mediaLibraryFiles: {
    count: 0,
    items: [],
    allItems: [],
    fetchStatus: RequestStatus.status.NULL,
  },
  uploadFile: {
    fetchStatus: RequestStatus.status.NULL,
  },
  replaceFile: {
    fetchStatus: RequestStatus.status.NULL,
  },
  deleteFile: {
    fetchStatus: RequestStatus.status.NULL,
  },
  createNewStructure: {
    fetchStatus: RequestStatus.status.NULL,
    errors: [],
  },
  grantAccess: {
    item: {
      fetchStatus: RequestStatus.status.NULL,
      message: null,
      error: null,
    },
    location: {
      fetchStatus: RequestStatus.status.NULL,
      message: null,
      error: null,
    },
  },
};

export const getLocations: any = createAsyncThunk(
  'locations/getLocations',
  async (
    data: {
      page: number;
      page_size: number;
      organization_uuid: string;
      parent_uuid?: string | null;
      search?: string | null;
    },
    thunkApi
  ) => {
    return ApiRequest.get(`/web/organizations/${data.organization_uuid}/locations/`)(
      data,
      thunkApi
    );
  }
);

export const getLocationsStructure: any = createAsyncThunk(
  'locations/getLocationsStructure',
  async (
    data: { organization_uuid: string; uuid?: string; whole_structure?: boolean },
    thunkApi
  ) => {
    const { organization_uuid, uuid = null, whole_structure = true } = data;
    if (uuid)
      return ApiRequest.get(`/web/organizations/${organization_uuid}/locations/${uuid}/structure/`)(
        { whole_structure },
        thunkApi
      );
    else
      return ApiRequest.get(`/web/organizations/${organization_uuid}/locations/structure/`)(
        { whole_structure },
        thunkApi
      );
  }
);

const ItemsCancelToken = axios.CancelToken;
let cancelItemsFetching;
export const getItems: any = createAsyncThunk(
  'locations/getItems',
  async (
    data: {
      page: number;
      page_size: number;
      organization_uuid: string;
      location_uuid?: string | null;
      search?: string | null;
      include_subplaces?: boolean;
    },
    thunkApi
  ) => {
    return ApiRequest.get(`/web/organizations/${data.organization_uuid}/items/`, {
      cancelToken: new ItemsCancelToken((canceler) => {
        cancelItemsFetching = canceler;
      }),
    })({ ...data, include_subplaces: true }, thunkApi);
  }
);

export const getLocationDetails: any = createAsyncThunk(
  'locations/getLocationDetails',
  async (data: { uuid: string; organization_uuid: string }, thunkAPI) => {
    return ApiRequest.get(
      `/web/organizations/${data.organization_uuid}/locations/${data.uuid}/?include_subplaces=true`,
      {}
    )({}, thunkAPI);
  }
);

export const getItemDetails: any = createAsyncThunk(
  'locations/getItemDetails',
  async (data: { uuid: string; organization_uuid: string }, thunkApi) => {
    return ApiRequest.get(`/web/organizations/${data.organization_uuid}/items/${data.uuid}/`)(
      {},
      thunkApi
    );
  }
);

export const addNewLocation: any = createAsyncThunk(
  `locations/addNewLocation`,
  async (data: { parent_uuid: string; name: string }, thunkAPI) => {
    return ApiRequest.post(`/web/locations/`, {})(data, thunkAPI);
  }
);

export const editLocation: any = createAsyncThunk(
  `locations/editLocation`,
  async (data: { uuid: string; name: string; is_active: boolean }, thunkAPI) => {
    const { uuid, ...other } = data;
    return ApiRequest.put(`/web/locations/${uuid}/`, {})(other, thunkAPI);
  }
);

export const deleteLocation: any = createAsyncThunk(
  `locations/deleteLocation`,
  async (data: { uuid: string; password: string }, thunkAPI) => {
    return ApiRequest.delete(`/web/locations/${data.uuid}/`, {
      data: {
        password: data.password,
      },
    })({}, thunkAPI);
  }
);

export const addNewItem: any = createAsyncThunk(
  `locations/addNewItem`,
  async (data: { location_uuid: string; name: string }, thunkAPI) => {
    return ApiRequest.post(`/web/items/`, {})(data, thunkAPI);
  }
);

export const editItem: any = createAsyncThunk(
  `locations/editItem`,
  async (data: { uuid: string; name: string }, thunkAPI) => {
    return ApiRequest.put(`/web/items/${data.uuid}/`, {})({ name: data.name }, thunkAPI);
  }
);

export const deleteItem: any = createAsyncThunk(
  `locations/deleteItem`,
  async (data: { uuid: string }, thunkAPI) => {
    return ApiRequest.delete(`/web/items/${data.uuid}/`, {})({}, thunkAPI);
  }
);

export const getMediaLibraryFiles: any = createAsyncThunk(
  `locations/getMediaLibraryFiles`,
  async (
    data: {
      organization_uuid: string;
      page: number;
      page_size: number;
      search: string;
    },
    thunkAPI
  ) => {
    const { organization_uuid, ...other } = data;
    return ApiRequest.get(`/web/organizations/${organization_uuid}/item-files/`, {})(
      other,
      thunkAPI
    );
  }
);

const FileUploadCancelToken = axios.CancelToken;
let cancelFileUpload;
export const uploadFile: any = createAsyncThunk(
  `locations/uploadFile`,
  async (
    data: {
      organization_uuid: string;
      file: File;
    },
    thunkAPI
  ) => {
    const { organization_uuid, file } = data;

    const formData = new FormData();
    formData.append('file', new File([file], file.name, { type: file.type }), file.name);

    return ApiRequest.post(`/web/organizations/${organization_uuid}/item-files/upload/`, {
      cancelToken: new FileUploadCancelToken((canceler) => {
        cancelFileUpload = canceler;
      }),
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })(formData, thunkAPI);
  }
);

export const replaceFile: any = createAsyncThunk(
  `locations/replaceFile`,
  async (
    data: {
      uuid: string;
      file: File;
    },
    thunkAPI
  ) => {
    const { uuid, file } = data;

    const formData = new FormData();
    formData.append('file', new File([file], file.name, { type: file.type }), file.name);

    return ApiRequest.patch(`/web/item-files/${uuid}/`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })(formData, thunkAPI);
  }
);

export const deleteFile: any = createAsyncThunk(
  `locations/deleteFile`,
  async (data: { uuid: string }, thunkAPI) => {
    return ApiRequest.delete(`/web/item-files/${data.uuid}/`, {})({}, thunkAPI);
  }
);

export const createNewStructure: any = createAsyncThunk(
  `locations/createNewStructure`,
  async (
    data: {
      uuid: string;
      structure: Array<{
        building: string;
        floor: string;
        area: string;
        room: string;
        equipment: string;
        file: Array<string>;
      }>;
    },
    thunkAPI
  ) => {
    const { uuid, structure } = data;
    return ApiRequest.post(`/web/organizations/${uuid}/locations/create-structure/`, {})(
      structure,
      thunkAPI
    );
  }
);

export const grantAccessToItem: any = createAsyncThunk(
  'messaging/grantAccessToItem',
  async (
    params: {
      data: { access_uuid: string; period?: number };
      organization_uuid: string;
      uuid: string;
    },
    thunkAPI
  ) => {
    const { data, organization_uuid, uuid } = params;
    return ApiRequest.post(
      `/web/organizations/${organization_uuid}/items/${uuid}/grant-access/`,
      {}
    )(data, thunkAPI);
  }
);

export const grantAccessToLocation: any = createAsyncThunk(
  'messaging/grantAccessToLocation',
  async (
    params: {
      data: { access_uuid: string; period?: number };
      organization_uuid: string;
      uuid: string;
    },
    thunkAPI
  ) => {
    const { data, organization_uuid, uuid } = params;
    return ApiRequest.post(
      `/web/organizations/${organization_uuid}/locations/${uuid}/grant-access/`,
      {}
    )(data, thunkAPI);
  }
);

const transformResponseToLocationsStructure = (
  location: LocationType,
  ancestors_uuids?: Array<string>,
  ancestors_names?: Array<string>
): any => {
  const _ancestors_uuids = ancestors_uuids ?? [];
  const _ancestors_names = ancestors_names ?? [];
  if (location.subplaces?.length === 0)
    return {
      ...location,
      has_subplaces: false,
      ancestors_uuids: [..._ancestors_uuids, location.uuid],
      ancestors_names: [..._ancestors_names, location.name],
    };
  else {
    return {
      ...location,
      ancestors_uuids: [..._ancestors_uuids, location.uuid],
      ancestors_names: [..._ancestors_names, location.name],
      has_subplaces: (location.subplaces as Array<LocationType>)?.length > 0,
      subplaces: Object.fromEntries(
        (location.subplaces as Array<any>)?.map((subplace) => [
          subplace.uuid,
          transformResponseToLocationsStructure(
            subplace,
            [..._ancestors_uuids, location.uuid],
            [..._ancestors_names, location.name]
          ),
        ])
      ),
    };
  }
};

const slice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    resetLocationsState: (state: LocationsState) => initialState,
    updatePlaceDetails: (state: LocationsState, action: PayloadAction<LocationType>) => {
      state.locationDetails.location = action.payload;
      state.locationDetails.fetchStatus = RequestStatus.status.NULL;
    },
    setCurrentLocation: (state: LocationsState, action: PayloadAction<Array<string>>) => {
      state.locations.currentLocation = action.payload;
    },
    setSelectedLocation: (state: LocationsState, action: PayloadAction<LocationType>) => {
      state.locations.selectedLocation = action.payload;
    },
    setItemsFetchStatus(state: LocationsState, action?: PayloadAction<string | null>): void {
      state.items.fetchStatus = action.payload || RequestStatus.status.NULL;
    },
    resetLocationDetails(state: LocationsState, action?: PayloadAction<any>): void {
      state.locationDetails = initialState.locationDetails;
    },
    resetAddNewLocation(state: LocationsState, action?: PayloadAction<any>): void {
      state.locations.newLocation = initialState.locations.newLocation;
    },
    resetEditLocation(state: LocationsState, action?: PayloadAction<any>): void {
      state.locations.editLocation = initialState.locations.editLocation;
    },
    resetDeleteLocation(state: LocationsState, action?: PayloadAction<any>): void {
      state.locations.deleteLocation = initialState.locations.deleteLocation;
    },
    resetAddNewItem(state: LocationsState, action?: PayloadAction<any>): void {
      state.items.newItem = initialState.items.newItem;
    },
    resetEditItem(state: LocationsState, action?: PayloadAction<any>): void {
      state.items.editItem = initialState.items.editItem;
    },
    resetDeleteItem(state: LocationsState, action?: PayloadAction<any>): void {
      state.items.deleteItem = initialState.items.deleteItem;
    },
    updateLocation(
      state: LocationsState,
      action: PayloadAction<{
        location: LocationType;
        data: any;
      }>
    ): void {
      const { location, data } = action.payload;
      const currentState = current(state);
      const path = location.ancestors_uuids.join('.subplaces.');
      if (
        data.hasOwnProperty('is_active') &&
        data?.is_active !== get(currentState.locations.items, path)?.is_active
      ) {
        let building = cloneDeep(get(currentState.locations.items, path));
        replaceObjValueDeep(building, 'is_active', data.is_active, 'subplaces');
        set(state.locations.items, path, { ...building, ...data });
      } else
        set(state.locations.items, path, { ...get(currentState.locations.items, path), ...data });
    },
    removeLocation(
      state: LocationsState,
      action: PayloadAction<{
        location: LocationType;
      }>
    ): void {
      const { location } = action.payload;
      const currentState = current(state);
      if (location?.ancestors_uuids?.length > 1) {
        const path =
          location.ancestors_uuids
            .slice(0, location.ancestors_uuids.length - 1)
            .join('.subplaces.') + '.subplaces';
        set(
          state.locations.items,
          path,
          Object.fromEntries(
            Object.entries(get(currentState.locations.items, path))?.filter(
              ([uuid, _location]) => uuid !== location.uuid
            )
          )
        );
      } else {
        state.locations.items = Object.fromEntries(
          Object.entries(currentState.locations.items)?.filter(
            ([uuid, _location]) => uuid !== location.uuid
          )
        );
      }
    },
    resetMediaLibraryList(state: LocationsState, action?: any) {
      state.mediaLibraryFiles = initialState.mediaLibraryFiles;
    },
    resetReplaceFile(state: LocationsState, action?: PayloadAction<any>): void {
      state.replaceFile = initialState.replaceFile;
    },
    resetDeleteFile(state: LocationsState, action?: PayloadAction<any>): void {
      state.deleteFile = initialState.deleteFile;
    },
    resetCreateNewStructureErrors(state: LocationsState, action?: PayloadAction<any>): void {
      state.createNewStructure.errors = initialState.createNewStructure.errors;
    },
    resetGrantAccessStatus(state: LocationsState, action?: PayloadAction<any>): void {
      state.grantAccess = initialState.grantAccess;
    },
  },
  extraReducers: {
    [getLocations.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.locations.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getLocations.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      const currentState = current(state);
      if (currentState?.locations?.currentLocation?.length === 0) {
        state.locations.items = Object.fromEntries(
          action.payload.results.map((location) => [location.uuid, { ...location, subplaces: {} }])
        );
      } else {
        set(
          state.locations.items,
          currentState.locations.currentLocation.reduce(
            (acc, cur, idx) =>
              (acc =
                acc +
                cur +
                (idx === currentState.locations.currentLocation?.length - 1
                  ? '.subplaces'
                  : '.subplaces.')),
            ''
          ),
          Object.fromEntries(
            action.payload.results.map((location) => [
              location.uuid,
              { ...location, subplaces: {} },
            ])
          )
        );
      }
      state.locations.count = action.payload.count;
      state.locations.next = action.payload.next;
      state.locations.previous = action.payload.previous;
      state.locations.fetchStatus = RequestStatus.status.DONE;
    },
    [getLocations.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.locations.fetchStatus = RequestStatus.status.ERROR;
    },

    [getLocationsStructure.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.locations.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getLocationsStructure.fulfilled]: (state: LocationsState, action: any) => {
      const currentState = current(state);
      const selectedLocation = currentState.locations.selectedLocation;
      if (selectedLocation !== null) {
        set(
          state.locations.items,
          selectedLocation?.ancestors_uuids.reduce(
            (acc, cur, idx) =>
              (acc =
                acc +
                cur +
                (idx === selectedLocation?.ancestors_uuids.length - 1 ? '' : '.subplaces.')),
            ''
          ),
          transformResponseToLocationsStructure(
            action.payload,
            selectedLocation?.ancestors_uuids.slice(
              0,
              selectedLocation.ancestors_uuids?.length - 1
            ),
            selectedLocation?.ancestors_names.slice(0, selectedLocation.ancestors_names?.length - 1)
          )
        );
        state.locations.selectedLocation = null;
      } else {
        state.locations.items = Object.fromEntries(
          action.payload.results.map((location) => [
            location.uuid,
            transformResponseToLocationsStructure(location),
          ])
        );
      }
      state.locations.fetchStatus = RequestStatus.status.DONE;
    },
    [getLocationsStructure.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.locations.fetchStatus = RequestStatus.status.ERROR;
    },

    [getItems.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.items.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getItems.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      state.items.items = action.payload.results;
      state.items.count = action.payload.count;
      state.items.next = action.payload.next;
      state.items.previous = action.payload.previous;
      state.items.fetchStatus = RequestStatus.status.DONE;
    },
    [getItems.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.items.fetchStatus = RequestStatus.status.ERROR;
    },

    [getLocationDetails.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.locationDetails.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getLocationDetails.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      state.locationDetails.location = action.payload;
      state.locationDetails.fetchStatus = RequestStatus.status.DONE;
    },
    [getLocationDetails.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.locationDetails.fetchStatus = RequestStatus.status.ERROR;
    },

    [getItemDetails.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.itemDetails.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getItemDetails.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      state.itemDetails.item = action.payload;
      state.itemDetails.fetchStatus = RequestStatus.status.DONE;
    },
    [getItemDetails.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.itemDetails.fetchStatus = RequestStatus.status.ERROR;
    },

    [addNewLocation.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.locations.newLocation.postFetchStatus = RequestStatus.status.FETCHING;
    },
    [addNewLocation.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      state.locations.newLocation.postFetchStatus = RequestStatus.status.DONE;
    },
    [addNewLocation.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.locations.newLocation.errors = action.payload;
      state.locations.newLocation.postFetchStatus = RequestStatus.status.ERROR;
    },

    [editLocation.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.locations.editLocation.fetchStatus = RequestStatus.status.FETCHING;
    },
    [editLocation.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      state.locations.editLocation.name = action.payload.name ?? '';
      state.locations.editLocation.is_active = action.payload.is_active ?? false;
      state.locations.editLocation.fetchStatus = RequestStatus.status.DONE;
    },
    [editLocation.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.locations.editLocation.fetchStatus = RequestStatus.status.ERROR;
      state.locations.editLocation.errors = action.payload;
    },

    [deleteLocation.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.locations.deleteLocation.fetchStatus = RequestStatus.status.FETCHING;
    },
    [deleteLocation.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      state.locations.deleteLocation.fetchStatus = RequestStatus.status.DONE;
    },
    [deleteLocation.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.locations.deleteLocation.fetchStatus = RequestStatus.status.ERROR;
      state.locations.deleteLocation.errors = action.payload;
    },

    [addNewItem.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.items.newItem.postFetchStatus = RequestStatus.status.FETCHING;
    },
    [addNewItem.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      state.items.newItem.postFetchStatus = RequestStatus.status.DONE;
    },
    [addNewItem.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.items.newItem.errors = action.payload;
      state.items.newItem.postFetchStatus = RequestStatus.status.ERROR;
    },

    [editItem.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.items.editItem.fetchStatus = RequestStatus.status.FETCHING;
    },
    [editItem.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      state.items.editItem.name = action.payload.name ?? '';
      state.items.editItem.fetchStatus = RequestStatus.status.DONE;
    },
    [editItem.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.items.editItem.fetchStatus = RequestStatus.status.ERROR;
      state.items.editItem.errors = action.payload;
    },

    [deleteItem.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.items.deleteItem.fetchStatus = RequestStatus.status.FETCHING;
    },
    [deleteItem.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      state.items.deleteItem.fetchStatus = RequestStatus.status.DONE;
    },
    [deleteItem.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.items.deleteItem.fetchStatus = RequestStatus.status.ERROR;
      state.items.deleteItem.errors = action.payload;
    },

    [getMediaLibraryFiles.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.mediaLibraryFiles.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getMediaLibraryFiles.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      const currentState = current(state);
      state.mediaLibraryFiles = {
        count: action.payload.count,
        items: action.payload.results,
        allItems: uniqBy(
          [...currentState.mediaLibraryFiles.allItems, ...action.payload.results],
          'uuid'
        ),
        fetchStatus: RequestStatus.status.DONE,
      };
      // state.mediaLibraryFiles.fetchStatus = RequestStatus.status.DONE;
    },
    [getMediaLibraryFiles.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.mediaLibraryFiles.fetchStatus = RequestStatus.status.ERROR;
      // state.items.deleteItem.errors = action.payload;
    },

    [uploadFile.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.uploadFile.fetchStatus = RequestStatus.status.FETCHING;
    },
    [uploadFile.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      state.uploadFile.fetchStatus = RequestStatus.status.DONE;
    },
    [uploadFile.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.uploadFile.fetchStatus = RequestStatus.status.ERROR;
    },

    [replaceFile.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.replaceFile.fetchStatus = RequestStatus.status.FETCHING;
    },
    [replaceFile.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      state.replaceFile.fetchStatus = RequestStatus.status.DONE;
    },
    [replaceFile.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.replaceFile.fetchStatus = RequestStatus.status.ERROR;
    },

    [deleteFile.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.deleteFile.fetchStatus = RequestStatus.status.FETCHING;
    },
    [deleteFile.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      state.deleteFile.fetchStatus = RequestStatus.status.DONE;
    },
    [deleteFile.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.deleteFile.fetchStatus = RequestStatus.status.ERROR;
    },

    [createNewStructure.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.createNewStructure.fetchStatus = RequestStatus.status.FETCHING;
    },
    [createNewStructure.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      const data = action.payload;
      // state.locations[data];
      state.createNewStructure.fetchStatus = RequestStatus.status.DONE;
    },
    [createNewStructure.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.createNewStructure.errors = extractNewLocationErrors(action.payload);
      state.createNewStructure.fetchStatus = RequestStatus.status.ERROR;
    },

    [grantAccessToItem.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.grantAccess.item.fetchStatus = RequestStatus.status.FETCHING;
    },
    [grantAccessToItem.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      state.grantAccess.item.fetchStatus = RequestStatus.status.DONE;
      state.grantAccess.item.message = action.payload.message;
    },
    [grantAccessToItem.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.grantAccess.item.fetchStatus = RequestStatus.status.ERROR;
      state.grantAccess.item.error =
        action.payload.access_uuid ?? Object.values(action.payload)?.[0] ?? null;
    },

    [grantAccessToLocation.pending]: (state: LocationsState, action: PayloadAction<any>) => {
      state.grantAccess.location.fetchStatus = RequestStatus.status.FETCHING;
    },
    [grantAccessToLocation.fulfilled]: (state: LocationsState, action: PayloadAction<any>) => {
      state.grantAccess.location.fetchStatus = RequestStatus.status.DONE;
      state.grantAccess.location.message = action.payload.message;
    },
    [grantAccessToLocation.rejected]: (state: LocationsState, action: PayloadAction<any>) => {
      state.grantAccess.location.fetchStatus = RequestStatus.status.ERROR;
      state.grantAccess.location.error =
        action.payload.access_uuid ?? Object.values(action.payload)?.[0] ?? null;
    },
  },
});

export const {
  resetLocationsState,
  updatePlaceDetails,
  setCurrentLocation,
  setSelectedLocation,
  setItemsFetchStatus,
  resetLocationDetails,
  resetAddNewLocation,
  resetEditLocation,
  resetDeleteLocation,
  resetAddNewItem,
  resetEditItem,
  resetDeleteItem,
  updateLocation,
  removeLocation,
  resetMediaLibraryList,
  resetReplaceFile,
  resetDeleteFile,
  resetCreateNewStructureErrors,
  resetGrantAccessStatus,
} = slice.actions;
export const { reducer } = slice;
export { cancelItemsFetching, cancelFileUpload };
