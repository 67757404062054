import { createCanBoundTo } from '@casl/react';
import ability, { permissions, amISuperAdmin } from './ability';
import * as subjects from './permissions.constants';

const haveButtonRenderPermission = ({ permittedSubject, permittedAction }) => {
  if (!permittedSubject || !permittedAction) return true;
  return ability.can(permittedAction, permittedSubject);
};

export { permissions, subjects, ability, haveButtonRenderPermission, amISuperAdmin };
export default createCanBoundTo(ability);
