import { useState, useEffect } from 'react';
import type { ChangeEvent, FC } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  InputAdornment,
  Container,
  Grid,
  Breadcrumbs,
  Link,
  SelectChangeEvent,
} from '@mui/material';
import ChevronRightIcon from '../../../icons/ChevronRight';
import UploadIcon from '../../../icons/Upload';
import DownloadIcon from '../../../icons/Download';
import { Link as RouterLink } from 'react-router-dom';
import { GenericInput } from 'src/components/widgets/inputs/GenericInput';
import { RootState, useDispatch, useSelector } from '../../../store';
import useQuery from 'src/hooks/useQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchIcon from '../../../icons/Search';
import OrganizationAddAdministratorUsersListTable from './OrganizationAddAdministratorUsersListTable';
import {
  postCreateAdminFromExistingUser,
  clearPostFetchStatuses,
  postCreateAdminAndInvite,
  setPostCreateNewAdminAndInviteFetchStatus,
  setPostCreateNewAdminAndInviteValidationErrors,
} from '../../../slices/organizations';
import { useParams } from 'react-router';
import toast from 'react-hot-toast';
import { RequestStatus } from 'src/utils/RequestStatus';
import { getOrganizationsAdministratorsPath } from '../../../routesPaths';
import { Helmet } from 'react-helmet-async';
import useSettings from '../../../hooks/useSettings';
import { useForm } from 'react-hook-form';
import AddNewAdministratorForm from './AddNewAdministratorForm';
import ScrollBar from '../../../components/Scrollbar';
import {
  convertPhoneToSend,
  getErrorMessageFor,
  isObjectAsString,
} from '../../../utils/formsHelper';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import SaveAndInvite from './SaveAndInvite';

enum UserType {
  NewUser,
  ExistingUser,
}

const OrganizationAddAdministrator: FC<any> = (props) => {
  const { ...other } = props;
  const { settings } = useSettings();
  const [query, setQuery] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedUserType, setSelectedUserType] = useState<UserType>(UserType.NewUser);

  const { uuid: organizationUuid } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    postExistingFetchStatus,
    postCreateNewAdminAndInviteFetchStatus,
    postCreateNewAdminAndInviteValidationErrors,
  } = useSelector((state: RootState) => state.organizations.details.administrators);
  const organizationName = useSelector(
    (state: RootState) => state.organizations.details.organization.name
  );

  const { handleSubmit, control, setValue } = useForm();

  const onFormSubmit = handleSubmit((data) => {
    for (let [key, value] of Object.entries(data)) {
      if (value === 'null') delete data[key];
    }

    if (data.phone_number) data.phone_number = convertPhoneToSend(data.phone_number);
    dispatch(postCreateAdminAndInvite({ admin: data, organization_uuid: organizationUuid }));
  });

  useEffect(() => {
    setQuery('');
  }, [selectedUserType]);

  useEffect(() => {
    if (RequestStatus.isDone(postExistingFetchStatus)) {
      dispatch(setPostCreateNewAdminAndInviteFetchStatus(RequestStatus.status.NULL));
      dispatch(setPostCreateNewAdminAndInviteValidationErrors(null));
      toast.success('Administrator added!');
      setTimeout(() => {
        navigate(getOrganizationsAdministratorsPath({ id: organizationUuid }));
      }, 1000);
    }

    if (RequestStatus.isDone(postCreateNewAdminAndInviteFetchStatus)) {
      dispatch(setPostCreateNewAdminAndInviteFetchStatus(RequestStatus.status.NULL));
      dispatch(setPostCreateNewAdminAndInviteValidationErrors(null));
      toast.success('Administrator added, invitation has been sent!');
      setTimeout(() => {
        navigate(getOrganizationsAdministratorsPath({ id: organizationUuid }));
      }, 1000);
    }
  }, [postExistingFetchStatus, postCreateNewAdminAndInviteFetchStatus]);

  useEffect(() => {
    return () => {
      dispatch(clearPostFetchStatuses());
    };
  }, []);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };

  const handleSelectUser = (uuid: string) => {
    setSelectedUser(uuid);
  };

  const handleAddNewAdminFromExistingUserClick = () => {
    if (selectedUser) {
      dispatch(postCreateAdminFromExistingUser({ userUuid: selectedUser, organizationUuid }));
    }
  };

  const handleUserTypeChange = (event: SelectChangeEvent<UserType>) => {
    setSelectedUserType(event.target.value as UserType.NewUser | UserType.ExistingUser);
  };

  const isPostFetching = RequestStatus.isFetching(
    postExistingFetchStatus || postCreateNewAdminAndInviteFetchStatus
  );

  return (
    <>
      <Card {...other} sx={{ borderRadius: 0, overflow: 'visible' }}>
        <Box sx={{ minWidth: 700, borderRadius: 0 }}>
          <Table>
            <TableBody>
              {/*<TableRow>*/}
              {/*  <TableCell>*/}
              {/*    <Typography color="textPrimary" variant="subtitle2">*/}
              {/*      Organization*/}
              {/*    </Typography>*/}
              {/*  </TableCell>*/}
              {/*  <TableCell>*/}
              {/*    <Typography color="textPrimary" variant="subtitle2">*/}
              {/*      {organizationName}*/}
              {/*    </Typography>*/}
              {/*  </TableCell>*/}
              {/*</TableRow>*/}
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    User Type
                  </Typography>
                </TableCell>
                <TableCell>
                  <FormControl>
                    <Select
                      labelId="selectedUserType"
                      id="selectedUserType"
                      sx={{ width: 150 }}
                      defaultValue={selectedUserType}
                      onChange={handleUserTypeChange}
                      disabled={isPostFetching}
                    >
                      <MenuItem value={UserType.NewUser}>New User</MenuItem>
                      <MenuItem value={UserType.ExistingUser}>Existing User</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
              {selectedUserType === UserType.ExistingUser && (
                <TableRow>
                  <TableCell>
                    <Typography color="textPrimary" variant="subtitle2">
                      User (Search by email)
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      onChange={handleQueryChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search users"
                      variant="outlined"
                      disabled={isPostFetching}
                    />
                    {selectedUser && (
                      <Box
                        sx={{
                          display: 'inline-block',
                        }}
                      >
                        <SaveAndInvite
                          onClick={handleAddNewAdminFromExistingUserClick}
                          sx={{ m: 1 }}
                          isFetching={isPostFetching}
                        />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {selectedUserType === UserType.ExistingUser && (
            <OrganizationAddAdministratorUsersListTable
              query={query}
              handleSelectUser={handleSelectUser}
              selectedUser={selectedUser}
            />
          )}
          {selectedUserType === UserType.NewUser && (
            <AddNewAdministratorForm
              control={control}
              setValue={setValue}
              onFormSubmit={onFormSubmit}
              isEditMode={true}
              validationMessages={postCreateNewAdminAndInviteValidationErrors}
              isPostFetching={isPostFetching}
            />
          )}
        </Box>
      </Card>
    </>
  );
};

export default OrganizationAddAdministrator;
