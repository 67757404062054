// response err messages in array
export const getErrorMessageFor = ({ key, errorMessages, inArray = true }) => {
  if (!key || !errorMessages) console.error('Not enough data to show validations messages');
  const errorMessage =
    errorMessages && errorMessages[key] && (inArray ? errorMessages[key][0] : errorMessages[key]);
  return errorMessage != 0 ? errorMessage : null;
};

export const isObjectAsString = (value) => {
  return value === '[object Object]';
};

export const convertPhoneToSend = (value) => {
  return `${value && String(value).includes('+1') ? '' : '+1'}${value
    .replace(new RegExp('-', 'g'), '')
    .replace(new RegExp('_', 'g'), '')}`;
};

export const convertPhoneToInputReadOnly = (value) => {
  if (!value) return null;
  else if (!value.includes('+1') && value.includes('-')) return value;
  let phone = value.replace('+1', '');
  phone = phone.slice(0, 3) + '-' + phone.slice(3);
  return phone.slice(0, 7) + '-' + phone.slice(7);
};

export const convertPhoneToInput = (value) => {
  if (!value) return null;
  else if (!value.includes('+') && !value.includes('-')) return value;
  return value.replace('+1', '').replaceAll('-', '');
};

export const setFormValuesFromDetailsObject = ({
  detailsObject,
  setValue,
  excludeKeys,
  acceptedKeys,
}: {
  detailsObject: any;
  setValue: any;
  excludeKeys?: Array<string>;
  acceptedKeys?: Array<string>;
}) => {
  for (let [key, value] of Object.entries(detailsObject)) {
    if (excludeKeys?.includes(key)) continue;
    if (acceptedKeys?.length && !acceptedKeys.includes(key)) continue;
    // @ts-ignore
    if (typeof value === 'object' && value?.uuid) setValue(key, String(value.uuid));
    else setValue(key, String(value));
  }
};
