import React, { FC, useCallback, useEffect, useState } from 'react';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import AddIcon from '@mui/icons-material/Add';
import DescriptionIcon from '@mui/icons-material/Description';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import useModal from '../../../hooks/useModal';
import { Organization } from '../../../types/organization';
import { alpha, useTheme } from '@mui/material/styles';
import Scrollbar from '../../../components/Scrollbar';
import { RootState, useDispatch, useSelector } from '../../../store';
import { debounce } from 'lodash';
import {
  deleteFile,
  getMediaLibraryFiles,
  replaceFile,
  resetDeleteFile,
  resetMediaLibraryList,
  resetReplaceFile,
} from '../../../slices/locations';
import { RequestStatus } from '../../../utils/RequestStatus';
import { formatBytes } from '../../../utils/formatBytes';
import ClearIcon from '@mui/icons-material/Clear';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';

interface LocationsMediaLibraryModalProps {
  organization: Organization;
  onUploadFilesClick: any;
  disabled?: boolean;
  onClick?: any;
}

const LocationsMediaLibraryModal: FC<LocationsMediaLibraryModalProps> = (props) => {
  const { organization, onUploadFilesClick, disabled = false, onClick = null } = props;

  const dispatch = useDispatch();
  const theme = useTheme();

  const { count, fetchStatus, items } = useSelector(
    (state: RootState) => state.locations.mediaLibraryFiles
  );
  const { fetchStatus: replaceFileFetchStatus } = useSelector(
    (state: RootState) => state.locations.replaceFile
  );

  const { fetchStatus: deleteFileFetchStatus } = useSelector(
    (state: RootState) => state.locations.deleteFile
  );

  const { Component: MediaLibraryModal, ...mediaLibraryModal } = useModal();

  const rowsPerPageOptions = [5, 10, 25];
  const [pagination, setPagination] = useState<{
    page: number;
    rowsPerPage: number;
    query: string;
  }>({
    page: 0,
    rowsPerPage: rowsPerPageOptions[1],
    query: '',
  });
  const [showEditFile, setShowEditFile] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [newFile, setNewFile] = useState<File>(null);

  const [showDeleteFile, setShowDeleteFile] = useState<string | null>(null);

  const onDrop = useCallback((acceptedFiles) => {
    // handleAddToQueue(acceptedFiles);
    setNewFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected: () => toast.error('Select or drop only 1 file'),
    maxFiles: 1,
    disabled: isUploading,
  });

  const callback = (forceFetchData: boolean = false): void => {
    if (!mediaLibraryModal.isOpen && !forceFetchData) return;
    dispatch(
      getMediaLibraryFiles({
        //@ts-ignore
        organization_uuid: organization.uuid,
        page: pagination.page + 1,
        page_size: pagination.rowsPerPage,
        search: pagination.query,
      })
    );
  };

  const handleOnMediaLibraryModalTrigger = (): void => {
    onClick?.();
    callback(true);
    mediaLibraryModal.open();
  };

  const queryChangeHandler = (e): void => {
    setPagination((prev) => ({ ...prev, query: e.target.value, page: 0 }));
  };

  const handleQueryChange = useCallback(debounce(queryChangeHandler, 750), []);

  const resetPaginationAndData = (): void => {
    setPagination({
      page: 0,
      rowsPerPage: rowsPerPageOptions[1],
      query: '',
    });
    dispatch(resetMediaLibraryList(null));
  };

  const handleReplaceFile = (uuid: string): void => {
    if (!newFile) return;
    setIsUploading(true);
    dispatch(
      replaceFile({
        uuid,
        file: newFile,
      })
    );
  };

  const handleDeleteFile = (uuid: string): void => {
    dispatch(deleteFile({ uuid }));
  };

  useEffect(() => {
    if (!mediaLibraryModal.isOpen) {
      setTimeout(() => {
        resetPaginationAndData();
        setShowEditFile(null);
        setShowDeleteFile(null);
      }, 150);
    }
  }, [mediaLibraryModal.isOpen]);

  useEffect(() => {
    callback();
  }, [pagination]);

  useEffect(() => {
    if (RequestStatus.isDone(replaceFileFetchStatus)) {
      toast.success('File replaced');
      callback();
      // setShowEditFile(null);
    } else if (RequestStatus.isError(replaceFileFetchStatus)) {
      toast.error(`File couldn't be replaced`);
    }

    if (!RequestStatus.isFetching(replaceFileFetchStatus)) {
      setNewFile(null);
      setIsUploading(false);
      dispatch(resetReplaceFile(null));
    }
  }, [replaceFileFetchStatus]);

  useEffect(() => {
    if (RequestStatus.isDone(deleteFileFetchStatus)) {
      toast.success('File deleted');
      setShowDeleteFile(null);
      if (items.length === 1 && pagination.page > 0) {
        setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
      } else callback();
    } else if (RequestStatus.isError(deleteFileFetchStatus)) {
      toast.error(`File couldn't be deleted`);
    }

    if (!RequestStatus.isFetching(deleteFileFetchStatus)) {
      dispatch(resetDeleteFile(null));
    }
  }, [deleteFileFetchStatus]);

  return (
    <>
      <MediaLibraryModal
        {...mediaLibraryModal.props}
        sx={{
          minWidth: '650px',
          width: 'fit-content',
          height: 'fit-content',
        }}
      >
        <FileOpenIcon color={'primary'} sx={{ height: '64px', width: '64px' }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '64px',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant={'h5'}>{organization?.name + ' media'}</Typography>
          <Button
            sx={{
              minWidth: '160px',
            }}
            variant={'outlined'}
            startIcon={<AddIcon />}
            onClick={() => {
              mediaLibraryModal.close();
              onUploadFilesClick();
            }}
          >
            Upload files
          </Button>
        </Box>
        <List>
          <TextField
            inputProps={{
              id: 'media-library-files-query-input',
            }}
            InputProps={{
              startAdornment: (
                <SearchIcon
                  sx={{
                    color: 'action.disabled',
                    mr: 1,
                  }}
                />
              ),
              endAdornment: (
                <IconButton
                  color={'primary'}
                  onClick={() => {
                    (
                      document.getElementById('media-library-files-query-input') as HTMLInputElement
                    ).value = '';
                    // setPagination((prev) => ({ ...prev, query: '' }));
                    handleQueryChange({ target: { value: '' } });
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
            onChange={handleQueryChange}
            fullWidth
            placeholder={'Search'}
            sx={{
              mb: 1,
            }}
          />
          {items.length > 0 ? (
            <Scrollbar
              style={{
                height: 'calc(50vh - 200px)',
                padding: '0 16px',
              }}
            >
              {items.map((item) => {
                return (
                  <Box
                    key={item.uuid}
                    sx={{
                      border: `solid 1px ${theme.palette.divider}`,
                      borderRadius: '8px',
                      my: 1,
                      maxWidth: '552px',
                    }}
                  >
                    <ListItem
                      dense
                      secondaryAction={
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
                          <IconButton
                            disabled={!item?.file?.url}
                            color={'primary'}
                            sx={{
                              border: `solid 1px ${theme.palette.divider}`,
                              padding: 0.5,
                              borderRadius: '8px',
                            }}
                            component={'a'}
                            href={item.file.url}
                            rel={'noreferrer noopener'}
                            target={'_blank'}
                          >
                            <VisibilityIcon fontSize={'small'} />
                          </IconButton>
                          <IconButton
                            color={showEditFile === item.uuid ? 'success' : 'primary'}
                            sx={{
                              border: `solid 1px ${theme.palette.divider}`,
                              padding: 0.5,
                              borderRadius: '8px',
                              ...(showEditFile === item.uuid && {
                                backgroundColor: 'divider',
                              }),
                            }}
                            onClick={() => {
                              setShowEditFile((prev) => (prev === item.uuid ? null : item.uuid));
                              setShowDeleteFile(null);
                              setTimeout(() => {
                                setNewFile(null);
                              }, 150);
                            }}
                            disabled={isUploading}
                          >
                            <EditIcon fontSize={'small'} />
                          </IconButton>
                          <IconButton
                            color={'error'}
                            sx={{
                              border: `solid 1px ${theme.palette.divider}`,
                              padding: 0.5,
                              borderRadius: '8px',
                              ...(showDeleteFile === item.uuid && {
                                backgroundColor: 'divider',
                              }),
                            }}
                            onClick={() => {
                              setShowDeleteFile((prev) => (prev === item.uuid ? null : item.uuid));
                              setShowEditFile(null);
                              setTimeout(() => {
                                setNewFile(null);
                              }, 150);
                            }}
                          >
                            <DeleteIcon fontSize={'small'} />
                          </IconButton>
                        </Box>
                      }
                    >
                      <ListItemIcon
                        sx={{
                          color: 'primary.main',
                        }}
                      >
                        <DescriptionIcon sx={{ height: '40px', width: '40px' }} />
                      </ListItemIcon>

                      <ListItemText secondary={formatBytes(item.file.size)}>
                        <Tooltip
                          slotProps={{
                            tooltip: {
                              sx: {
                                wordBreak: 'break-all',
                              },
                            },
                          }}
                          title={item.file_label}
                          disableInteractive
                          placement={'top'}
                          arrow
                        >
                          <Typography
                            variant={'body2'}
                            sx={{
                              display: '-webkit-box',
                              overflow: 'hidden',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                              maxWidth: '335px',
                              wordBreak: 'break-all',
                              width: 'fit-content',
                            }}
                          >
                            {item.file_label}
                          </Typography>
                        </Tooltip>
                      </ListItemText>
                    </ListItem>
                    <Collapse in={showEditFile === item.uuid}>
                      <Box sx={{ px: 2, pb: 1 }}>
                        <Divider />
                        <Box sx={{ height: '4px' }}>
                          {RequestStatus.isFetching(replaceFileFetchStatus) && <LinearProgress />}
                        </Box>
                        <Box
                          {...getRootProps()}
                          sx={{
                            outline: `dashed 2px ${theme.palette.text.secondary}`,
                            mt: 1,
                            borderRadius: '4px',
                            display: 'flex',
                            justifyContent: 'center',
                            cursor: !isUploading && 'pointer',
                            ...(isUploading && {
                              backgroundColor: theme.palette.action.disabled,
                            }),
                            transition: 'filter 150ms, background-color 150ms',
                            ...(isDragActive &&
                              !isUploading && {
                                backgroundColor: 'background.paper',
                                filter: 'brightness(75%)',
                              }),
                          }}
                        >
                          <Typography
                            variant={'subtitle2'}
                            sx={{
                              color: theme.palette.text.secondary,
                              my: 2,
                            }}
                          >
                            Drag & drop or select file from disk
                          </Typography>
                          <input {...getInputProps()} />
                        </Box>
                        {newFile && (
                          <Typography sx={{ mt: 1 }} variant={'h6'}>
                            New file:
                          </Typography>
                        )}
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '24px',
                            mt: !newFile && 1,
                          }}
                        >
                          {newFile ? (
                            <ListItem
                              sx={{
                                p: 0,
                                // border: `solid 1px ${theme.palette.divider}`,
                                borderRadius: '8px',
                                backgroundColor: alpha(theme.palette.divider, 0.05),
                              }}
                              secondaryAction={
                                <IconButton color={'error'} onClick={() => setNewFile(null)}>
                                  <DeleteIcon />
                                </IconButton>
                              }
                            >
                              <ListItemIcon
                                sx={{
                                  color: 'success.main',
                                }}
                              >
                                <DescriptionIcon sx={{ height: '40px', width: '40px' }} />
                              </ListItemIcon>
                              <ListItemText secondary={formatBytes(item.file.size)}>
                                <Tooltip
                                  slotProps={{
                                    tooltip: {
                                      sx: {
                                        wordBreak: 'break-all',
                                      },
                                    },
                                  }}
                                  title={item.file_label}
                                  disableInteractive
                                  placement={'top'}
                                  arrow
                                >
                                  <Typography
                                    variant={'body2'}
                                    sx={{
                                      display: '-webkit-box',
                                      overflow: 'hidden',
                                      WebkitBoxOrient: 'vertical',
                                      WebkitLineClamp: 1,
                                      maxWidth: '300px',
                                      wordBreak: 'break-all',
                                      width: 'fit-content',
                                    }}
                                  >
                                    {newFile.name}
                                  </Typography>
                                </Tooltip>
                              </ListItemText>
                            </ListItem>
                          ) : (
                            <Typography
                              noWrap={true}
                              color={!newFile && 'textSecondary'}
                              variant={'h6'}
                              align={'center'}
                            >
                              No file selected
                            </Typography>
                          )}
                          <Button
                            variant={'contained'}
                            sx={{ height: 'fit-content' }}
                            onClick={() => handleReplaceFile(item.uuid)}
                            disabled={!newFile || isUploading}
                          >
                            Replace
                          </Button>
                        </Box>
                      </Box>
                    </Collapse>
                    <Collapse in={showDeleteFile === item.uuid}>
                      <Box
                        sx={{
                          px: 2,
                          pb: 1,
                        }}
                      >
                        <Divider />
                        <Box sx={{ height: '4px' }}>
                          {RequestStatus.isFetching(deleteFileFetchStatus) && <LinearProgress />}
                        </Box>
                        <Typography
                          noWrap={true}
                          color={'textSecondary'}
                          variant={'h6'}
                          align={'center'}
                        >
                          Are you sure you want to delete {item.file_label}?
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            gap: '32px',
                            mt: 1,
                          }}
                        >
                          <Button
                            variant={'outlined'}
                            sx={{ minWidth: '150px' }}
                            onClick={() => setShowDeleteFile(null)}
                          >
                            No
                          </Button>
                          <Button
                            variant={'contained'}
                            color={'error'}
                            sx={{ minWidth: '150px' }}
                            onClick={() => handleDeleteFile(item.uuid)}
                            disabled={RequestStatus.isFetching(deleteFileFetchStatus)}
                          >
                            Yes
                          </Button>
                        </Box>
                      </Box>
                    </Collapse>
                  </Box>
                );
              })}
            </Scrollbar>
          ) : RequestStatus.isDone(fetchStatus) ? (
            <ListItemText
              primary={'No media found'}
              primaryTypographyProps={{
                noWrap: true,
                color: 'textSecondary',
                variant: 'h6',
                align: 'center',
              }}
            />
          ) : (
            RequestStatus.isError(fetchStatus) && (
              <ListItemText
                primary={'Error while getting files list. Try again later.'}
                primaryTypographyProps={{
                  noWrap: true,
                  color: 'error',
                  variant: 'h6',
                  align: 'center',
                }}
              />
            )
          )}
          <Box sx={{ height: '4px' }}>
            {RequestStatus.isFetching(fetchStatus) && <LinearProgress />}
          </Box>
        </List>
        <TablePagination
          component={'div'}
          count={count}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={(e, page) => {
            setPagination((prev) => ({ ...prev, page: page }));
          }}
          onRowsPerPageChange={(e) => {
            setPagination((prev) => ({ ...prev, page: 0, rowsPerPage: +e.target.value }));
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Button
            sx={{
              minWidth: '160px',
            }}
            variant={'outlined'}
            onClick={() => {
              mediaLibraryModal.close();
            }}
          >
            Close
          </Button>
        </Box>
      </MediaLibraryModal>
      <Button
        fullWidth
        variant={'outlined'}
        startIcon={<FileOpenIcon />}
        disabled={disabled}
        onClick={handleOnMediaLibraryModalTrigger}
      >
        Media library
      </Button>
    </>
  );
};

export default LocationsMediaLibraryModal;
