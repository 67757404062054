import { RequestStatus } from './../utils/RequestStatus';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { Customer, CustomerUser } from '../types/customer_new';
import type { Call } from '../types/call';
import ApiRequests from '../utils/ApiRequest';
import ApiRequest from '../utils/ApiRequest';
import { Organization } from 'src/types/organization';
import { startIdleTransaction } from '@sentry/tracing';

export interface CustomersState {
  customersList: Array<Customer> | [];
  customersListLength: number;
  customersListfetchStatus: any;
  customerDetails: Customer | null;
  customerDetailsFetchStatus: any;
  callHistoryList: Call[] | [];
  callHistoryListLength: number;
  callHistoryListFetchStatus: any;

  connectedOrganizationsList: Array<Organization> | [];
  connectedOrganizationsListLength: number;
  connectedOrganizationsFetchStatus: any;
  customerPatchStatus: any;
  patchValidationErrors: any;
}

const initialState: CustomersState = {
  customersList: [],
  customersListLength: 0,
  customersListfetchStatus: null,

  customerDetails: null,
  customerDetailsFetchStatus: null,

  callHistoryList: [],
  callHistoryListLength: 0,
  callHistoryListFetchStatus: null,

  connectedOrganizationsList: [],
  connectedOrganizationsFetchStatus: null,
  connectedOrganizationsListLength: 0,

  customerPatchStatus: null,
  patchValidationErrors: null,
};

export const getCustomers: any = createAsyncThunk(
  'customers/getCustomers',
  async (data: { limit: string; offset: string; search: string }, thunkApi) => {
    const { offset, limit, search } = data;
    return ApiRequests.get('/web/customers/', {})(data, thunkApi);
  }
);

export const getCustomerDetails: any = createAsyncThunk(
  'customers/getCustomerDetails',
  async (data: { uuid: string }, thunkApi) => {
    const { uuid } = data;
    return ApiRequest.get(`/web/customers/${uuid}/`, {})({}, thunkApi);
  }
);

export const patchCustomer: any = createAsyncThunk(
  'customers/patchCustomer',
  async (
    data: {
      uuid: string;
      first_name: string;
      last_name: string;
      phone_number: string;
      profile_image: string;
      email: string;
    },
    thunkApi
  ) => {
    const { uuid, ...otherData } = data;

    return ApiRequest.patch(`/web/customers/${uuid}/user/`, {})(otherData, thunkApi);
  }
);

export const getCallHistory: any = createAsyncThunk(
  'customers/callHistory',
  async (data: { limit: number; offset: number; uuid: string }, thunkApi) => {
    const { uuid, ...otherData } = data;
    return ApiRequest.get(`/web/customers/${uuid}/call_history/`, {})(otherData, thunkApi);
  }
);

export const getConnectedOrganizations: any = createAsyncThunk(
  'customers/getConnectedOrganizations',
  async (data: { search: string; offset: string; limit: string; uuid: string }, thunkAPI) => {
    const { uuid, ...otherData } = data;
    return ApiRequest.get(`/web/customers/${uuid}/connected_organizations/`, {})(
      otherData,
      thunkAPI
    );
  }
);

const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    // getCustomerDetails: (state, action) => {
    //   state.customerDetails = state.customersList.find((customer: Customer) => {
    //     const uuid = customer?.device?.uuid || customer?.owner?.uuid;
    //     return action.payload.uuid === uuid;
    //   });
    // },
    resetState: (state: CustomersState) => initialState,
    resetCustomerPatchStatus: (state: CustomersState) => {
      state.customerPatchStatus = null;
    },
  },
  extraReducers: {
    [getCustomers.pending]: (state, action) => {
      state.customersListfetchStatus = RequestStatus.status.FETCHING;
    },
    [getCustomers.fulfilled]: (state, action) => {
      state.customersList = action.payload.results;
      state.customersListLength = action.payload.count;
      state.customersListfetchStatus = RequestStatus.status.DONE;
    },
    [getCustomers.rejected]: (state, action) => {
      state.customersListfetchStatus = RequestStatus.status.ERROR;
    },
    [getCustomerDetails.pending]: (state, action) => {
      state.customerDetailsFetchStatus = RequestStatus.status.FETCHING;
    },
    [getCustomerDetails.fulfilled]: (state, action) => {
      state.customerDetails = action.payload;
      state.customerDetailsFetchStatus = RequestStatus.status.DONE;
    },
    [getCustomerDetails.rejected]: (state, action) => {
      state.customerDetailsFetchStatus = RequestStatus.status.ERROR;
    },
    [patchCustomer.pending]: (state, action) => {
      state.customerPatchStatus = RequestStatus.status.FETCHING;
    },
    [patchCustomer.fulfilled]: (state, action) => {
      state.customerPatchStatus = RequestStatus.status.DONE;
      const { profile_image, ...user } = action.payload;
      state.customerDetails.user = user;
      state.customerDetails.avatar = profile_image;
    },
    [patchCustomer.rejected]: (state, action) => {
      state.customerPatchStatus = RequestStatus.status.ERROR;
      state.patchValidationErrors = action.payload;
    },
    [getCallHistory.pending]: (state, action) => {
      state.callHistoryListFetchStatus = RequestStatus.status.FETCHING;
    },
    [getCallHistory.fulfilled]: (state, action) => {
      state.callHistoryList = action.payload.results;
      state.callHistoryListLength = action.payload.count;
      state.callHistoryListFetchStatus = RequestStatus.status.DONE;
    },
    [getCallHistory.rejected]: (state, action) => {
      state.callHistoryListFetchStatus = RequestStatus.status.ERROR;
    },
    [getConnectedOrganizations.pending]: (state, action) => {
      state.connectedOrganizationsFetchStatus = RequestStatus.status.FETCHING;
    },
    [getConnectedOrganizations.fulfilled]: (state, action) => {
      state.connectedOrganizationsList = action.payload.results;
      state.connectedOrganizationsListLength = action.payload.count;
      state.connectedOrganizationsFetchStatus = RequestStatus.status.DONE;
    },
    [getConnectedOrganizations.rejected]: (state, action) => {
      state.connectedOrganizationsFetchStatus = RequestStatus.status.ERROR;
    },
  },
});

export const { resetCustomerPatchStatus, resetState, resetState: clearCustomers } = slice.actions;
export const { reducer } = slice;
