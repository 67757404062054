import { compact } from 'lodash';

export const toastMessageKeyName = 'new-location-toast-error';

const errorParameterToIndexMap = {
  building: 0,
  floor: 1,
  area: 2,
  room: 3,
  equipment: 4,
  file: 5,
};

export const extractNewLocationErrors = (
  errors: {
    buildings: Array<
      | {
          building: Array<string>;
          floor: Array<string>;
          area: Array<string>;
          room: Array<string>;
          equipment: Array<string>;
          file: Array<string>;
        }
      | {}
    >;
  } & { message: string }
): any => {
  if (!errors) return [];
  if (errors?.hasOwnProperty('buildings'))
    return compact(
      errors?.buildings
        ?.map((error, idx) => {
          if (Object.keys(error).length === 0) return;
          else
            return Object.entries(error).map(([key, value]) => ({
              row: idx,
              cell: errorParameterToIndexMap[key],
              message: value?.[0],
            }));
        })
        .flat()
    );
  else if (errors?.hasOwnProperty('message')) return [{ [toastMessageKeyName]: errors.message }];
  else return [];
};
