import type { FC } from 'react';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import MessagingChatMessages from './MessagingChatMessages';
import { MessagingMessage } from '../../../types/messaging_message';
import { MessagingParticipant } from '../../../types/messaging_participant';
import MessagingMessageAdd from './MessagingMessageAdd';
import { experimentalStyled } from '@mui/material/styles';
import MessagingThreadToolbar from './MessagingThreadToolbar';
import { LoadMoreButtonsConfig } from '../../../types/messaging_load_more_buttons_config';
import useModal, { SimpleActions } from '../../../hooks/useModal';
import QRCodeImage from '../../../components/QRCodeImage';
import { sizes } from '../../../theme';
import { useDropzone } from 'react-dropzone';

interface Props {
  participants: Array<MessagingParticipant>;
  threadAciveParticipants: Array<MessagingParticipant>;
  detailsFetchStatus: string | null;
  messages: Array<MessagingMessage>;
  pinnedMessages: Array<MessagingMessage>;
  pinnedMessagesFetchStatus: string | null;
  owner?: any;
  handleSendMessage: any;
  handleGetThreadPinnedMessages: any;
  handleFileChange: any;
  isLoading: boolean;
  thread: any;
  onLoadMoreMessagesClick: any;
  isLoadMoreMessagesVisible: boolean;
  onLoadMorePinnedMessagesClick: any;
  isLoadMorePinnedMessagesVisible: boolean;
  shouldChatScroll: any;
  onScrollUp: (event) => void;
  onPostInitPayment: any;
  onPostEndChat: any;
  onPostRequestLogin: any;
  isUserTechnician?: boolean;
  handleChangeMesssagePinnedStatus: (data: {
    is_pinned: boolean;
    thread_uuid: string;
    uuid: string;
  }) => void;
  messagesFetchStatus: string | null;
  changeMessagePinnedPostFetchStatus: string | null;
  getThreadMessagesWithInterval: (params: {
    id?: string | number;
    cursor?: string;
    page_size?: string | number;
    options?: {
      dispatchedFromPinnedMessages?: boolean;
      omitGetNewThreadMessages?: boolean;
      clearCurrentMessages?: boolean;
    };
  }) => void;
  checkIfMessageLoaded: (params: { uuid: string }) => boolean;
  newMessagesButtonConfig: {
    visible?: boolean;
    title?: string;
    onClick?: () => void;
  };
  loadMoreButtonsConfig: LoadMoreButtonsConfig;
  handleExportMessages: (
    data: {
      name: string;
      start_date: string;
      end_date: string;
      start_message_uuid: string;
      end_message_uuid: string;
    },
    exportType: 'all' | 'dateRange' | 'selection'
  ) => void;
  resetFileInput: boolean;
  setResetFileInput: Dispatch<SetStateAction<boolean>>;
  pinNewMessages: boolean;
  setPinNewMessages: Dispatch<SetStateAction<boolean>>;
  threadType: string;
  isTyping: boolean;
  sendIsTyping: () => void;
}

const NoThread = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  paddingTop: 64,
  width: '100%',
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
}));

const ChatThread: FC<Props> = ({
  participants,
  threadAciveParticipants,
  detailsFetchStatus,
  messages,
  pinnedMessages,
  pinnedMessagesFetchStatus,
  owner,
  handleSendMessage,
  handleGetThreadPinnedMessages,
  handleFileChange,
  isLoading,
  thread,
  onLoadMoreMessagesClick,
  isLoadMoreMessagesVisible,
  onLoadMorePinnedMessagesClick,
  isLoadMorePinnedMessagesVisible,
  shouldChatScroll,
  onScrollUp,
  onPostInitPayment,
  onPostEndChat,
  onPostRequestLogin,
  isUserTechnician,
  handleChangeMesssagePinnedStatus,
  changeMessagePinnedPostFetchStatus,
  getThreadMessagesWithInterval,
  messagesFetchStatus,
  checkIfMessageLoaded,
  newMessagesButtonConfig,
  loadMoreButtonsConfig,
  handleExportMessages,
  resetFileInput,
  setResetFileInput,
  pinNewMessages,
  setPinNewMessages,
  threadType,
  isTyping: _isTyping,
  sendIsTyping,
}) => {
  const [messageToScrollTo, setMessageToScrollTo] = useState<string>(null);

  const { uuid } = useParams();

  const { Component: ConfirmAddAttachmentModal, ...confirmAddAttachmentModal } = useModal();

  const isTyping = useMemo(() => _isTyping, [_isTyping]);

  const onDrop = useCallback((acceptedFiles) => {
    handleConfirmAttachmentAdd({
      target: {
        files: acceptedFiles,
      },
    });
  }, []);
  const { getRootProps, isDragActive } = useDropzone({ onDrop, noClick: true });

  const [attachmentToSend, setAttachmentToSend] = useState<{
    fileList: FileList;
    confirmAttach: boolean;
  }>({
    fileList: null,
    confirmAttach: false,
  });

  const handleConfirmAttachmentAdd = (
    event: ChangeEvent<HTMLInputElement> | { target: { files } }
  ): void => {
    setAttachmentToSend({
      fileList: event.target.files,
      confirmAttach: false,
    });
  };

  const onFileChange = (_file: FileList): void => {
    if (typeof _file == 'object' && _file?.length) {
      const raw = _file[0];
      const url = URL.createObjectURL(raw);
      const reader = new FileReader();
      let attachment = { file: _file, url };
      reader.readAsDataURL(raw);
      reader.onload = function (e) {
        // @ts-ignore
        attachment = { ...attachment, base64: e.target.result };
        if (attachment.file && attachment.url) handleFileChange(attachment);
        setAttachmentToSend({ fileList: null, confirmAttach: false });
      };
    }
  };

  const getFilesNames = (fileList: FileList): string => {
    let fileNamesArray = [];
    for (let i = 0; i < fileList?.length; i++) {
      if (fileList[i]?.name) fileNamesArray.push(fileList[i]?.name);
    }
    return fileNamesArray?.join(', ');
  };

  useEffect(() => {
    if (!attachmentToSend.confirmAttach && attachmentToSend?.fileList?.length > 0) {
      confirmAddAttachmentModal.open();
    }
    if (attachmentToSend.confirmAttach) {
      onFileChange(attachmentToSend.fileList);
    }
  }, [attachmentToSend]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <ConfirmAddAttachmentModal
        {...confirmAddAttachmentModal.props}
        handleClose={() => {
          setTimeout(() => {
            setAttachmentToSend({
              fileList: null,
              confirmAttach: false,
            });
            setResetFileInput(true);
            setPinNewMessages(false);
          }, 150);
          confirmAddAttachmentModal.props.handleClose();
        }}
        sx={{
          width: 'fit-content',
          height: 'fit-content',
        }}
      >
        <Typography color="textPrimary" variant="h5" sx={{ marginBottom: '20px' }}>
          Send attachment{attachmentToSend?.fileList?.length > 1 ? 's' : ''}
        </Typography>
        <Typography variant={'subtitle2'}>
          {`Are you sure you want to send ${getFilesNames(attachmentToSend?.fileList)}?`}
        </Typography>
        <FormControlLabel
          checked={pinNewMessages}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setPinNewMessages(event.target.checked);
          }}
          control={<Checkbox />}
          label={`Pin message${attachmentToSend?.fileList?.length > 1 ? 's' : ''}?`}
          componentsProps={{
            typography: {
              variant: 'subtitle2',
            },
          }}
          sx={{
            mt: 1,
          }}
        />
        <SimpleActions
          onConfirm={() => {
            //setTimeout used to prevent content disappearing before modal fully closes
            setTimeout(() => {
              setAttachmentToSend((prev) => ({
                fileList: prev.fileList,
                confirmAttach: true,
              }));
              setPinNewMessages(false);
            }, 150);
            confirmAddAttachmentModal.close();
          }}
          onCancel={() => {
            //setTimeout used to prevent content disappearing before modal fully closes
            setTimeout(() => {
              setAttachmentToSend({
                fileList: null,
                confirmAttach: false,
              });
              setResetFileInput(true);
              setPinNewMessages(false);
            }, 150);
            confirmAddAttachmentModal.close();
          }}
          sx={{ mt: 2 }}
          confirmLabel={'Yes'}
          cancelLabel={'No'}
        />
      </ConfirmAddAttachmentModal>
      {thread?.object !== null && (
        <ListItem
          sx={{
            px: 0,
            py: 0,
            height: sizes.messaging.locationHeight(true),
            borderBottom: 'solid 1px',
            borderBottomColor: 'divider',
          }}
        >
          <ListItemIcon>
            <QRCodeImage
              qrCode={thread.object.qr_code}
              QRCodePlaceholderStyle={{
                // -1px to account for bottom border
                width: `calc(${sizes.messaging.locationHeight(true)} - 1px)`,
                height: `calc(${sizes.messaging.locationHeight(true)} - 1px)`,
                top: '16px',
              }}
              qrCodeImageStyle={{
                width: `calc(${sizes.messaging.locationHeight(true)} - 1px)`,
                height: `calc(${sizes.messaging.locationHeight(true)} - 1px)`,
              }}
            />
          </ListItemIcon>
          <ListItemText primary={thread.object.name} secondary={thread.object.full_path} />
        </ListItem>
      )}
      {uuid && (
        <MessagingThreadToolbar
          participants={participants}
          threadAciveParticipants={threadAciveParticipants}
          detailsFetchStatus={detailsFetchStatus}
          thread={thread}
          messages={messages}
          pinnedMessages={pinnedMessages}
          pinnedMessagesFetchStatus={pinnedMessagesFetchStatus}
          handleGetThreadPinnedMessages={handleGetThreadPinnedMessages}
          handleChangeMesssagePinnedStatus={handleChangeMesssagePinnedStatus}
          setMessageToScrollTo={setMessageToScrollTo}
          isLoadMorePinnedMessagesVisible={isLoadMorePinnedMessagesVisible}
          onLoadMorePinnedMessagesClick={onLoadMorePinnedMessagesClick}
          changeMessagePinnedPostFetchStatus={changeMessagePinnedPostFetchStatus}
          getThreadMessagesWithInterval={getThreadMessagesWithInterval}
          messagesFetchStatus={messagesFetchStatus}
          checkIfMessageLoaded={checkIfMessageLoaded}
          loadMoreButtonsConfig={loadMoreButtonsConfig}
          handleExportMessages={handleExportMessages}
        />
      )}
      {isDragActive && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            position: 'sticky',
            top: '16px',
            zIndex: 100,
          }}
        >
          <Card
            sx={{
              position: 'absolute',
              top: '16px',
              px: 2,
              py: 1,
            }}
          >
            <Typography variant={'subtitle2'}>
              Drop your files here to send them as messages
            </Typography>
          </Card>
        </Box>
      )}
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          transition: 'filter 150ms',
          ...(isDragActive && {
            backgroundColor: 'background.paper',
            filter: 'brightness(75%)',
          }),
        }}
        {...getRootProps()}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
          }}
        >
          {!uuid && !isLoading ? (
            <NoThread>Select thread</NoThread>
          ) : (
            <>
              <MessagingChatMessages
                messages={messages}
                participants={participants}
                onLoadMoreMessagesClick={onLoadMoreMessagesClick}
                isLoadMoreMessagesVisible={isLoadMoreMessagesVisible}
                shouldChatScroll={shouldChatScroll}
                onScrollUp={onScrollUp}
                isLoading={isLoading}
                onPostInitPayment={onPostInitPayment}
                onPostEndChat={onPostEndChat}
                onPostRequestLogin={onPostRequestLogin}
                handleChangeMesssagePinnedStatus={handleChangeMesssagePinnedStatus}
                setMessageToScrollTo={setMessageToScrollTo}
                messageToScrollTo={messageToScrollTo}
                changeMessagePinnedPostFetchStatus={changeMessagePinnedPostFetchStatus}
                newMessagesButtonConfig={newMessagesButtonConfig}
                loadMoreButtonsConfig={loadMoreButtonsConfig}
                handleConfirmAttachmentAdd={handleConfirmAttachmentAdd}
                thread={thread}
              />
            </>
          )}
        </Box>
        {isTyping && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: '18px',
              px: 2,
              pb: 1,
            }}
          >
            {/*<Typography variant={'subtitle2'}>Someone is typing</Typography>*/}
            <Box
              sx={{
                display: 'flex',
                backgroundColor: '#cccccc',
                borderRadius: '16px',
                // width: '50px',
                height: '18px',
                letterSpacing: '-4px',
                pr: 1,
                pl: 0.5,
                '& > *': {
                  mt: '-14px !important',
                  animation: 'wave 0.9s linear infinite',
                  '&:nth-child(2)': {
                    'animation-delay': '-0.7s',
                  },
                  '&:nth-child(3)': {
                    'animation-delay': '-0.5s',
                  },
                  '@keyframes wave': {
                    '0%, 60%, 100%': {
                      // transform: 'initial',
                      opacity: 0,
                    },
                    '30%': {
                      // transform: 'translateY(-4px)',
                      opacity: 1,
                    },
                  },
                },
              }}
            >
              <Typography variant={'h4'}>&#x2022;</Typography>
              <Typography variant={'h4'}>&#x2022;</Typography>
              <Typography variant={'h4'}>&#x2022;</Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            // mb: 2,
            background: 'none',
            position: 'relative',
          }}
        >
          {isLoading && (
            <LinearProgress
              variant={'indeterminate'}
              sx={{ position: 'absolute', height: '4px', width: '100%' }}
            />
          )}
        </Box>
        <Divider />
        <MessagingMessageAdd
          disabled={isLoading || !thread.uuid}
          onSend={(message) => handleSendMessage(message, 'text', thread?.uuid)}
          onPostInitPayment={onPostInitPayment}
          onPostEndChat={onPostEndChat}
          onPostRequestLogin={onPostRequestLogin}
          hasActivePayment={!!thread?.has_active_payment}
          isUserTechnician={isUserTechnician}
          handleConfirmAttachmentAdd={handleConfirmAttachmentAdd}
          resetFileInput={resetFileInput}
          setResetFileInput={setResetFileInput}
          threadType={threadType}
          paymentAvailable={thread?.payment_available}
          sendIsTyping={sendIsTyping}
        />
      </Box>
    </Box>
  );
};

export default ChatThread;
