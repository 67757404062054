import { useState, useEffect } from 'react';
import type { FC } from 'react';
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
} from '@mui/material';
import useQuery from 'src/hooks/useQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  getCustomerCallHistoryPath,
  getCustomerConnectedOrganizationsPath,
  getCustomerBillingInfoPath,
} from 'src/routesPaths';
import { Input } from '../../components/widgets/inputs/Input';
import { useParams } from 'react-router-dom';
import FileUploadInput from '../FileUpload/FileUploadInput';
import { getErrorMessageFor } from '../../utils/formsHelper';
import AvatarUploadInput from '../AvatarUploadInput/AvatarUploadInput';

const statusOptions = ['Canceled', 'Completed', 'Rejected'];

const CustomerDetailsSummary: FC<any> = (props) => {
  const {
    handleFormSubmit,
    control,
    isEditMode,
    setValue,
    isCustomerRegistered,
    customerDetails,
    patchValidationErrors,
    isPatchFetching,
    ...other
  } = props;

  const { uuid } = useParams();

  const navigate = useNavigate();

  const areValidationMessages =
    patchValidationErrors && Object.keys(patchValidationErrors).length > 0;

  const handleCallHistoryClick = () => {
    navigate(getCustomerCallHistoryPath({ uuid }));
  };

  const handleListOfConnectedOrganizationsClick = () => {
    navigate(getCustomerConnectedOrganizationsPath({ uuid }));
  };

  const handleOnBillingInfoClick = () => {
    navigate(getCustomerBillingInfoPath({ uuid }));
  };

  return (
    <Card {...other} sx={{ overflow: 'visible' }}>
      <CardHeader title="Customer info" />
      <form onSubmit={handleFormSubmit} id="edit-customer-form">
        {isCustomerRegistered ? (
          <>
            <Box sx={{ margin: '30px' }}>
              {/* <FileUploadInput
                control={control}
                isEditMode={isEditMode}
                setValue={setValue}
                name="profile_image"
                defaultValue={customerDetails?.avatar}
                storeKey={'customerAvatar'}
                validationMessage={
                  areValidationMessages &&
                  getErrorMessageFor({
                    key: 'profile_image',
                    errorMessages: patchValidationErrors,
                  })
                }
                disabled={isPatchFetching}
              /> */}

              <AvatarUploadInput
                control={control}
                isEditMode={isEditMode}
                setValue={setValue}
                name="profile_image"
                defaultValue={customerDetails?.avatar}
                storeKey={'customerAvatar'}
                validationMessage={
                  areValidationMessages &&
                  getErrorMessageFor({
                    key: 'profile_image',
                    errorMessages: patchValidationErrors,
                  })
                }
                disabled={isPatchFetching}
              />
            </Box>
            <Divider />
            <Table>
              <TableBody>
                {/*{!isEditMode && (*/}
                {/*  <TableRow>*/}
                {/*    <TableCell>*/}
                {/*      <Avatar*/}
                {/*        src={customerDetails?.avatar?.url}*/}
                {/*        sx={{*/}
                {/*          height: 42,*/}
                {/*          width: 42,*/}
                {/*        }}*/}
                {/*      />*/}
                {/*    </TableCell>*/}

                {/*    <TableCell></TableCell>*/}
                {/*  </TableRow>*/}
                {/*)}*/}

                <TableRow>
                  <TableCell>
                    <Typography color="textPrimary" variant="subtitle2">
                      First Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Input
                      control={control}
                      isEditMode={isEditMode}
                      defaultValue={customerDetails?.user?.first_name}
                      type="text"
                      name="first_name"
                      validationMessage={
                        areValidationMessages &&
                        getErrorMessageFor({
                          key: 'first_name',
                          errorMessages: patchValidationErrors,
                        })
                      }
                      disabled={isPatchFetching}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography color="textPrimary" variant="subtitle2">
                      Last Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Input
                      control={control}
                      isEditMode={isEditMode}
                      defaultValue={customerDetails?.user?.last_name}
                      type="text"
                      name="last_name"
                      validationMessage={
                        areValidationMessages &&
                        getErrorMessageFor({
                          key: 'last_name',
                          errorMessages: patchValidationErrors,
                        })
                      }
                      disabled={isPatchFetching}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography color="textPrimary" variant="subtitle2">
                      Email
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Input
                      control={control}
                      isEditMode={isEditMode}
                      defaultValue={customerDetails?.user?.email}
                      type="email"
                      name="email"
                      disabled={isPatchFetching}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography color="textPrimary" variant="subtitle2">
                      Phone number
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Input
                      control={control}
                      isEditMode={isEditMode}
                      defaultValue={customerDetails?.user?.phone_number}
                      type="phone"
                      name="phone_number"
                      validationMessage={
                        areValidationMessages &&
                        getErrorMessageFor({
                          key: 'phone_number',
                          errorMessages: patchValidationErrors,
                        })
                      }
                      disabled={isPatchFetching}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography color="textPrimary" variant="subtitle2">
                      Call history
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleCallHistoryClick}
                      disabled={isPatchFetching}
                    >
                      See call history
                    </Button>
                  </TableCell>
                </TableRow>

                {/* <TableRow>
                  <TableCell>
                    <Typography color="textPrimary" variant="subtitle2">
                      Billing information
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button color="primary" variant="contained" onClick={handleOnBillingInfoClick}>
                      See billing information
                    </Button>
                  </TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell>
                    <Typography color="textPrimary" variant="subtitle2">
                      Connected organizations
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleListOfConnectedOrganizationsClick}
                      disabled={isPatchFetching}
                    >
                      See list of connected organizations
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        ) : (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    {`${customerDetails?.customer_label}`}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    Device Id
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    {customerDetails?.device?.id}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    Call history
                  </Typography>
                </TableCell>
                <TableCell>
                  <Button color="primary" variant="contained" onClick={handleCallHistoryClick}>
                    See call history
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </form>
    </Card>
  );
};

export default CustomerDetailsSummary;
