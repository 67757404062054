import SaveAltIcon from '@mui/icons-material/SaveAlt';
import PencilAltIcon from '../../../icons/PencilAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, CircularProgress } from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface EditButtonProps {
  isEditMode?: boolean;
  form?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  onClick?: (event, type) => void;
  onCancelClick?: (event) => void;
  isLoading?: boolean;
}

export const clickEventTypes = { edit: 'edit', save: 'save', cancel: 'cancel' };

const EditButton: FC<EditButtonProps> = (props) => {
  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  const {
    isEditMode = false,
    form = null,
    type = 'submit',
    isLoading,
    onClick = (event, type) => {},
    onCancelClick = (event) => {},
  } = props;

  // because submit was triggered before button was visible
  useEffect(() => {
    setTimeout(() => {
      setIsSubmitAvailable(isEditMode);
    }, 100);
  }, [isEditMode]);

  return (
    <>
      {type === 'submit' && isEditMode ? (
        <Button
          form={form}
          type={isSubmitAvailable ? 'submit' : 'button'}
          color="primary"
          startIcon={
            isLoading ? null : isEditMode ? (
              <SaveAltIcon fontSize="small" />
            ) : (
              <PencilAltIcon fontSize="small" />
            )
          }
          sx={{ m: 1 }}
          variant="contained"
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress
              sx={{
                display: 'block',
                width: '20px !important ',
                height: '20px !important',
                margin: '0px 20px 0px 20px',
              }}
            />
          ) : (
            'Save'
          )}
        </Button>
      ) : (
        <Button
          form={form}
          onClick={(event) =>
            onClick(event, isEditMode ? clickEventTypes.save : clickEventTypes.edit)
          }
          type="submit"
          color="primary"
          startIcon={
            isEditMode ? <SaveAltIcon fontSize="small" /> : <PencilAltIcon fontSize="small" />
          }
          sx={{ m: 1 }}
          variant="contained"
          disabled={isLoading}
        >
          {isEditMode ? 'Save' : 'Edit'}
        </Button>
      )}
      {isEditMode && (
        <Button
          type="button"
          onClick={(event) => onCancelClick(event)}
          color="primary"
          startIcon={<CancelIcon fontSize="small" />}
          sx={{ m: 1 }}
          variant="contained"
          disabled={isLoading}
        >
          Cancel
        </Button>
      )}
    </>
  );
};

export default EditButton;
