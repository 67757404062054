import type { FC, ChangeEvent } from 'react';
import { RootState, useSelector } from '../../store';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import useSettings from '../../hooks/useSettings';
import { Helmet } from 'react-helmet-async';

const Welcome: FC<any> = (props) => {
  const { settings } = useSettings();
  const { user } = useSelector((state: RootState) => state.user);

  return (
    <>
      <Helmet>
        <title>Plunjr</title>
      </Helmet>
      <Container maxWidth={settings.compact ? 'xl' : false}>
        <Grid container spacing={3}>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={3}
            item
            xs={12}
            mt={1}
          >
            <Grid item>
              <Typography color="textSecondary" variant="overline">
                Welcome
              </Typography>
              <Typography color="textPrimary" variant="h5">
                {user?.first_name} {user?.last_name}
              </Typography>
              <Typography color="textSecondary" variant="subtitle2" mt={1}>
                Select tab from your dashboard to see details
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Welcome;
