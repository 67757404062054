import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid, Link, TextField, Typography } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import gtm from '../../lib/gtm';
import { useParams } from 'react-router-dom';
import ProfileDetailsSummary from './ProfileSummary';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { RootState, useDispatch, useSelector } from '../../store';
import {
  getUserDetails,
  patchUserDetails,
  clearPatchValidationErrorsAndFetchStatuses,
} from '../../slices/user';
import { RequestStatus } from '../../utils/RequestStatus';
import EditButton from 'src/components/widgets/buttons/EditButton';
import useEditMode from '../../hooks/useEditMode';
import { isObjectAsString, setFormValuesFromDetailsObject } from '../../utils/formsHelper';
import GenericBreadcrumb from '../../components/GenericBreadcrumb';

const acceptedKeys = ['profile_image', 'first_name', 'last_name', 'phone_number'];
const formId = 'edit-user-form';

const Profile: FC<any> = () => {
  const { settings } = useSettings();
  let { uuid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state: RootState) => state.user.user);
  const { patchFetchStatus, patchValidationErrors } = useSelector((state: RootState) => state.user);

  const { handleSubmit, control, setValue } = useForm();
  const { triggerEditMode, isEditMode } = useEditMode();

  useEffect(() => {
    if (RequestStatus.isDone(patchFetchStatus)) {
      dispatch(clearPatchValidationErrorsAndFetchStatuses({}));
      triggerEditMode();
    }
  }, [patchFetchStatus]);

  useEffect(() => {
    dispatch(getUserDetails({}));
  }, []);

  useEffect(() => {
    userDetails &&
      setFormValuesFromDetailsObject({ detailsObject: userDetails, setValue, acceptedKeys });
  }, [userDetails]);

  if (!userDetails) {
    return null;
  }

  const handleFormSubmit = handleSubmit((data) => {
    if (isEditMode) {
      if ((data.logo && isObjectAsString(data.logo)) || data.logo === 'null') delete data.logo;
      dispatch(patchUserDetails(data));
    }
  });

  const handleCancelClick = () => {
    setFormValuesFromDetailsObject({ detailsObject: userDetails, setValue, acceptedKeys });
    triggerEditMode();
  };

  const isPatchFetching = RequestStatus.isFetching(patchFetchStatus);

  return (
    <>
      <Helmet>
        <title>My Profile</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                My Profile
              </Typography>
              <GenericBreadcrumb />
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <EditButton
                  form={formId}
                  type="submit"
                  isEditMode={isEditMode}
                  onCancelClick={handleCancelClick}
                  onClick={triggerEditMode}
                  isLoading={isPatchFetching}
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ProfileDetailsSummary
                  onSubmit={handleFormSubmit}
                  control={control}
                  isEditMode={isEditMode}
                  setValue={setValue}
                  user={userDetails}
                  patchValidationErrors={patchValidationErrors}
                  isPatchFetching={isPatchFetching}
                  formId={formId}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Profile;
