import type { FC } from 'react';
import { TextField } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';

interface GenericInputProps {
  type: string;
  name: string;
  validationMessage: string;
  isEditMode: boolean;
  defaultValue: string;
  control: any;
  label?: string;
  onChange?: any;
  value?: any;
  helperText?: any;
  error?: any;
  inputRef?: any;
  variant?: any;
}

export const StyledTextField: any = experimentalStyled(TextField)(({ theme }) => ({
  width: '100%',
  maxWidth: '500px',
  maxHeight: '80px',
  // minHeight: '75px',
  '> div': {
    height: '50px',
  },
  '.MuiFormHelperText-root': {
    marginLeft: '0px',
    marginRight: '0px',
    fontSize: '0.68rem',
    lineHeight: '1.2',
  },
}));

// use component Input instead
export const GenericInput: FC<any> = (props) => {
  return <StyledTextField {...props} variant="outlined" />;
};
