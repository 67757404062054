import { experimentalStyled } from '@mui/material/styles';

const LoginLayout = experimentalStyled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  // @ts-ignore
  backgroundColor: theme.loginBGColor,
}));

export default LoginLayout;
