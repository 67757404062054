import { useState, useEffect, useCallback } from 'react';
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import gtm from '../../../lib/gtm';
import { Helmet } from 'react-helmet-async';
import ChevronRightIcon from '../../../icons/ChevronRight';
import { Link as RouterLink, useParams } from 'react-router-dom';
import CustomersListTable from './CustomerCallHistoryTable';
import { RootState, useDispatch, useSelector } from '../../../store';
import { getCustomerDetails } from '../../../slices/customers';
import GenericBreadcrumb, {
  getBreadcrumbLabelFromUserDetails,
} from '../../../components/GenericBreadcrumb';

const CustomerCallHistory = () => {
  const { settings } = useSettings();
  const dispatch = useDispatch();
  const { uuid } = useParams();

  const { customerDetails } = useSelector((state: RootState) => state.customers);

  useEffect(() => {
    if (!customerDetails?.user) {
      dispatch(getCustomerDetails({ uuid }));
    }
  }, [customerDetails]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Customer List </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Customer call history
              </Typography>
              <GenericBreadcrumb
                replaceLastUUIDWith={getBreadcrumbLabelFromUserDetails(customerDetails)}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <CustomersListTable />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CustomerCallHistory;
