import { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material';
import { ThemeProvider } from 'styled-components';
import './i18n';
import RTL from './components/RTL';
import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import { routes } from './routes';
import { createCustomTheme } from './theme';
import { RootState, useDispatch, useSelector } from './store';
import { useFCMBGMessageOrSocketChannel } from './hooks/useFCMBGMessageOrSocketChannel';
import { useFirebaseServices } from './hooks/customHooks';
import {
  getNotificationsPreview,
  getNotificationsSummary,
  postRegisterDevice,
} from './slices/notifications';
import SWSnackbar from './components/SWSnackbar';
import { triggerBlinkingOnSWBroadcast } from './utils/blinkingDocumentTitle';
import { getConstants } from './slices/config';
import { RequestStatus } from './utils/RequestStatus';
import * as Sentry from '@sentry/react';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  const dispatch = useDispatch();

  const { isAuthenticated, user, token } = useSelector((state: RootState) => state.user);
  const { lastUpdateTimestamp } = useSelector((state: RootState) => state.notifications);
  const { fetchStatus: constantsFetchStatus } = useSelector(
    (state: RootState) => state.config.dropdownValues
  );
  const markAllAsReadFetchStatus = useSelector(
    (state: RootState) => state.messaging.thread.actions.markAllAsReadFetchStatus
  );

  const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
  const SENTRY_SAMPLE_RATE = +process.env.REACT_APP_SENTRY_SAMPLE_RATE;
  const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;

  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: SENTRY_SAMPLE_RATE,
    environment: SENTRY_ENVIRONMENT,
  });

  useFirebaseServices(
    {
      registerDeviceRequest: ({ token }) => {
        dispatch(postRegisterDevice({ instance_id: token }));
      },
      onMessage: (response) => {
        // broken lib channel
      },
    },
    [isAuthenticated],
    [!isAuthenticated]
  );

  const notificationEvent = useFCMBGMessageOrSocketChannel(
    {
      uuid: user?.uuid,
      token,
    },
    [user?.uuid, token]
  );

  const refreshNotifications = () => {
    dispatch(getNotificationsSummary());
    dispatch(getNotificationsPreview({ is_read: false, limit: 8 }));
  };

  useEffect(() => {
    if (RequestStatus.isDone(markAllAsReadFetchStatus)) refreshNotifications();
  }, [markAllAsReadFetchStatus]);

  useEffect(() => {
    if (!notificationEvent) return;
    refreshNotifications();
  }, [notificationEvent]);

  useEffect(() => {
    if (isAuthenticated && user?.uuid) {
      refreshNotifications();
      triggerBlinkingOnSWBroadcast();
    }
  }, [isAuthenticated, user, lastUpdateTimestamp]);

  useEffect(() => {
    if (isAuthenticated && user?.uuid && RequestStatus.isNull(constantsFetchStatus)) {
      dispatch(getConstants());
    }
  }, [isAuthenticated, user]);

  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  const areConstantsLoadedIfNeeded = () => {
    return isAuthenticated ? RequestStatus.isDone(constantsFetchStatus) : true;
  };

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <RTL direction={settings.direction}>
            <CssBaseline />
            <Toaster position="top-center" />
            <SettingsDrawer />
            <SWSnackbar />
            {auth.isInitialized && areConstantsLoadedIfNeeded() ? content : <SplashScreen />}
          </RTL>
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
