import { Dispatch, forwardRef, SetStateAction, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { getFileNameFromLink } from '../Messaging/messagingHelper';
import { qrCodesDragDropLayout } from './locationsConfig';

interface QRCodesPrintedComponentProps {
  layout: Array<Array<Array<string>>>;
  setAreImagesToPrintLoaded: Dispatch<SetStateAction<boolean>>;
}

const QRCodesPrintedComponent = forwardRef((props: QRCodesPrintedComponentProps, ref) => {
  const { layout, setAreImagesToPrintLoaded } = props;

  let loaded = useRef<number>(0);

  useEffect(() => {
    if (
      loaded?.current ===
      layout.map((page) => page.map((row) => row.map((cell) => cell))).flat().length
    ) {
      setAreImagesToPrintLoaded(true);
    }
  }, [loaded?.current, layout]);

  useEffect(() => {
    if (layout.length === 0) loaded.current = 0;
  }, [layout]);

  const Page = (props) => {
    const { page } = props;

    const isEmpty = page
      .map((row) => row.map((cell) => cell))
      .flat()
      .every((cell) => !cell);

    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: isEmpty ? 'none' : 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          pageBreakAfter: 'always',
        }}
      >
        {page.map((row) => (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {row.map((cell) =>
              cell ? (
                <img
                  style={{
                    height: `calc(${qrCodesDragDropLayout.qrCodeSize.print.pixels} - 20px)`,
                    width: `calc(${qrCodesDragDropLayout.qrCodeSize.print.pixels} - 24px)`,
                    margin: '16px 12px', //current margins should work for US letter page format; 10px 6px for A4
                  }}
                  src={cell}
                  alt={getFileNameFromLink(cell)}
                  onLoad={(e) => {
                    loaded.current = loaded?.current + 1;
                  }}
                />
              ) : (
                <Box
                  sx={{
                    height: `calc(${qrCodesDragDropLayout.qrCodeSize.print.pixels} - 20px)`,
                    width: `calc(${qrCodesDragDropLayout.qrCodeSize.print.pixels} - 24px) !important`,
                    margin: '16px 12px', //current margins should work for US letter page format; 10px 6px for A4
                  }}
                />
              )
            )}
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box
      ref={ref}
      sx={{
        display: 'block',
        height: '100%',
        width: '100%',
        overflow: 'visible',
      }}
    >
      {layout.map((page) => (
        <Page page={page} />
      ))}
    </Box>
  );
});

export default QRCodesPrintedComponent;
