import React, { useRef, useState, useEffect } from 'react';
import type { FC } from 'react';
import { Badge, IconButton, Tooltip, Typography } from '@mui/material';
import BellIcon from '../../icons/InformationCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { experimentalStyled } from '@mui/material/styles';

const IconContainer = experimentalStyled('div')({
  height: 56,
  width: 56,
  backgroundColor: 'white',
});

const NavbarCheckPermissions: FC<any> = (props) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [areNotificationsEnabled, setAreNotificationsEnabled] = useState<boolean>(true);

  const checkIfNotificationsEnabled = () => {
    if (
      typeof Notification === 'undefined' ||
      typeof Notification.requestPermission === 'undefined'
    )
      return;
    Notification.requestPermission()?.then(function (permission) {
      if (permission === 'granted') setAreNotificationsEnabled(true);
      else if (permission === 'denied') setAreNotificationsEnabled(false);
    });
  };

  useEffect(() => {
    checkIfNotificationsEnabled();
  }, []);

  return <>
    {!areNotificationsEnabled && (
      <Tooltip
        title={
          'Enable notifications in your browser settings to see all updates in real time.'
        }
      >
        <IconButton
          color="secondary"
          sx={{
            backgroundColor: 'white',
            borderRadius: '30px',
            py: 1,
            '&:hover': {
              backgroundColor: 'white',
              opacity: 0.9,
            },
          }}
          ref={anchorRef}
          onClick={() => setOpen(true)}
          size="large">
          <ErrorIcon fontSize="small" />
          <Typography variant="subtitle1"> Enable notifications! </Typography>
        </IconButton>
      </Tooltip>
    )}
  </>;
};

export default NavbarCheckPermissions;
