import { useEffect, useRef, useState } from 'react';
import { usePrevious } from './customHooks';

// assumes cursor usage
// scroll from default position checked before requesting
/**
 * scroll when
 * - chat changed and messages loaded
 * - received notification and scrolled position max 100px from bottom
 */
const useShouldChatScroll = (
  // @ts-ignore
  { id, messages, limit, wasScrolledUnlocked, wasMessagesSendRef } = {},
  watchExistingVariables
): any => {
  const [shouldChatScroll, setShouldChatScroll] = useState<any>();
  const prevMessages: any = usePrevious(messages);
  const wasIdChangedRef = useRef(false);

  useEffect(() => {
    wasIdChangedRef.current = true;
  }, [id]);

  useEffect(() => {
    // when user sent message
    if (
      wasMessagesSendRef.current &&
      ((messages && messages.length > 0 && prevMessages && !prevMessages.length) ||
        (prevMessages?.length &&
          messages?.length &&
          prevMessages[prevMessages?.length - 1]?.uuid !== messages[messages.length - 1]?.uuid))
    ) {
      wasMessagesSendRef.current = false;
      setShouldChatScroll(Date.now());
    }
    // when chat changed and previous chat didn't clean messages
    else if (
      wasIdChangedRef.current &&
      ((messages && messages.length > 0 && prevMessages && !prevMessages.length) ||
        (prevMessages?.length &&
          messages?.length &&
          prevMessages[prevMessages?.length - 1]?.uuid !== messages[messages.length - 1]?.uuid))
    ) {
      wasIdChangedRef.current = false;
      setShouldChatScroll(Date.now());
    }

    // when was scrolled and new notification
    else if (
      messages &&
      !wasScrolledUnlocked &&
      prevMessages?.length &&
      messages?.length &&
      prevMessages[prevMessages?.length - 1]?.uuid !== messages[messages.length - 1]?.uuid
    ) {
      setShouldChatScroll(Date.now());
    }
  }, [watchExistingVariables]);

  return shouldChatScroll;
};

export default useShouldChatScroll;
