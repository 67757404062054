import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { User } from '../types/user';
import { initialize, login, logout } from '../slices/user';
import { useDispatch, useSelector } from '../store';
import { useLocation, useNavigate } from 'react-router-dom';
import { deleteFCMToken } from '../lib/firebase';
import { getLoginPath } from '../routesPaths';
import { isLocationInPublicRoutes } from '../routes';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends State {
  platform: string;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, name: string, password: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextValue>({
  platform: 'token',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  isInitialized: false,
  isAuthenticated: true,
  user: {},
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isInitialized = useSelector((state) => state.user.isInitialized);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    dispatch(initialize({ navigate }));
  }, []);

  const _logout = async (): Promise<void> => {
    deleteFCMToken();
    await dispatch(logout());
  };

  const _login = async (email, password): Promise<void> => {
    await dispatch(login(email, password, navigate));
  };

  const register = async (): Promise<void> => {};

  useEffect(() => {
    if (
      isInitialized &&
      !isAuthenticated &&
      !user &&
      location.pathname &&
      !isLocationInPublicRoutes(location)
    ) {
      navigate(getLoginPath({}));
    }
  }, [isInitialized, isAuthenticated, user, location]);

  return (
    <AuthContext.Provider
      value={{
        platform: 'custom',
        login: _login,
        logout: _logout,
        register,
        isInitialized,
        isAuthenticated,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
