import { useState, useEffect, ChangeEvent, FC } from 'react';
import { Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import type { Technician } from '../../../types/technician';
import { Input } from '../../../components/widgets/inputs/Input';
import { getErrorMessageFor } from '../../../utils/formsHelper';
import QRCodeImage from '../../../components/QRCodeImage';

interface OrganizationTechniciansDetailsSummaryProps {
  technician?: Technician;
  onSubmit: any;
  control: any;
  isEditMode: boolean;
  formId: string;
  validationErrors?: any;
  isPatchFetching?: boolean;
}

const OrganizationTechniciansSummary: FC<OrganizationTechniciansDetailsSummaryProps> = (props) => {
  const {
    technician = {},
    onSubmit,
    control,
    isEditMode,
    formId,
    validationErrors,
    isPatchFetching,
    ...other
  } = props;

  const areValidationMessages = validationErrors && Object.keys(validationErrors).length > 0;

  return (
    <form onSubmit={onSubmit} id={formId}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                First Name
              </Typography>
            </TableCell>
            <TableCell>
              <Input
                control={control}
                isEditMode={isEditMode}
                defaultValue={technician?.first_name}
                type="text"
                name="first_name"
                validationMessage={
                  areValidationMessages &&
                  getErrorMessageFor({
                    key: 'first_name',
                    errorMessages: validationErrors,
                  })
                }
                disabled={isPatchFetching}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Last Name
              </Typography>
            </TableCell>
            <TableCell>
              <Input
                control={control}
                isEditMode={isEditMode}
                defaultValue={technician?.last_name}
                type="text"
                name="last_name"
                validationMessage={
                  areValidationMessages &&
                  getErrorMessageFor({
                    key: 'last_name',
                    errorMessages: validationErrors,
                  })
                }
                disabled={isPatchFetching}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Email
              </Typography>
            </TableCell>
            <TableCell>
              <Input
                control={control}
                isEditMode={isEditMode}
                defaultValue={technician?.email}
                type="text"
                name="email"
                validationMessage={
                  areValidationMessages &&
                  getErrorMessageFor({
                    key: 'email',
                    errorMessages: validationErrors,
                  })
                }
                disabled={isPatchFetching}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Phone number
              </Typography>
            </TableCell>
            <TableCell>
              <Input
                control={control}
                isEditMode={isEditMode}
                defaultValue={technician?.phone_number}
                type="phone"
                name="phone_number"
                validationMessage={
                  areValidationMessages &&
                  getErrorMessageFor({
                    key: 'phone_number',
                    errorMessages: validationErrors,
                  })
                }
                disabled={isPatchFetching}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                QR Code
              </Typography>
            </TableCell>
            <TableCell sx={{ position: 'relative' }}>
              <QRCodeImage qrCode={technician?.qr_code} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </form>
  );
};

export default OrganizationTechniciansSummary;
