import { useEffect, useRef, useState } from 'react';

export const useRefState = <T extends Partial<T>>(initialValue: T): [T, any, any] => {
  const [state, setState] = useState<T>(initialValue);
  const stateRef = useRef<T>(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  return [state, stateRef, setState];
};
