import type { ChangeEvent, FC, KeyboardEvent } from 'react';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, IconButton, TextField, Tooltip } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import useAuth from '../../../hooks/useAuth';
import MessagingActionMessageAdd from './MessagingActionMessageAdd';

interface ChatMessageAddProps {
  disabled?: boolean;
  onSend?: (value: string) => void;
  onPostInitPayment?: any;
  onPostEndChat?: any;
  onPostRequestLogin?: any;
  hasActivePayment?: boolean;
  isUserTechnician?: boolean;
  handleConfirmAttachmentAdd?: any;
  resetFileInput: boolean;
  setResetFileInput: Dispatch<SetStateAction<boolean>>;
  threadType: string;
  paymentAvailable: boolean;
  sendIsTyping: () => void;
}

const ChatMessageAdd: FC<ChatMessageAddProps> = (props) => {
  const {
    disabled,
    onSend,
    onPostInitPayment,
    onPostEndChat,
    onPostRequestLogin,
    hasActivePayment,
    isUserTechnician,
    handleConfirmAttachmentAdd,
    resetFileInput,
    setResetFileInput,
    threadType,
    paymentAvailable,
    sendIsTyping,
    ...other
  } = props;
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { user } = useAuth();
  const [body, setBody] = useState<string>('');
  const isThreadInternal = threadType === 'INTERNAL';

  const handleAttach = (): void => {
    fileInputRef.current.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setBody(event.target.value);
  };

  const handleSend = (): void => {
    if (!body) {
      return;
    }

    onSend?.(body);
    setBody('');
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    sendIsTyping();
    if (event.code?.toUpperCase() === 'ENTER' && !disabled) {
      handleSend();
    }
  };

  useEffect(() => {
    if (resetFileInput && fileInputRef?.current !== null) {
      fileInputRef.current.value = '';
      setResetFileInput(false);
    }
  }, [resetFileInput, fileInputRef]);

  return (
    <Box>
      {isUserTechnician && !isThreadInternal && (
        <MessagingActionMessageAdd
          disabled={disabled}
          onPostInitPayment={onPostInitPayment}
          onPostEndChat={onPostEndChat}
          onPostRequestLogin={onPostRequestLogin}
          hasActivePayment={hasActivePayment}
          paymentAvailable={paymentAvailable}
        />
      )}
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          flexShrink: 0,
          px: 2,
          // pb: isUserTechnician && !isThreadInternal ? 1 : 0,
          py: !isUserTechnician || isThreadInternal ? 1 : 0,
          // paddingBottom: 0.5,
          pb: 1,
        }}
        {...other}
      >
        <Avatar
          sx={{
            display: {
              xs: 'none',
              sm: 'flex',
            },
            mr: 2,
          }}
          src={user?.profile_image?.thumbnails?.avatar}
        />
        <TextField
          fullWidth
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          placeholder="Leave a message"
          value={body}
          size="small"
          variant="outlined"
          multiline
          maxRows={5}
        />
        <Tooltip title="Send">
          <span>
            <IconButton
              color="primary"
              disabled={!body || disabled}
              onClick={handleSend}
              size="large"
            >
              <SendIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        <Box
          sx={{
            alignItems: 'center',
            display: {
              xs: 'none',
              sm: 'flex',
            },
          }}
        >
          <Divider orientation="vertical" sx={{ height: 24 }} />
          <Tooltip title="Attach file">
            <span>
              <IconButton
                disabled={disabled}
                edge="end"
                onClick={handleAttach}
                sx={{
                  color: 'primary.main',
                }}
                size="large"
              >
                {/* <AddPhotoIcon fontSize="small" /> */}
                <AttachFileIcon
                  sx={{
                    mr: 0.5,
                  }}
                  fontSize={'small'}
                />
              </IconButton>
            </span>
          </Tooltip>
          {/*<Tooltip title="Attach file">*/}
          {/*  <span>*/}
          {/*    <IconButton disabled={disabled} edge="end" onClick={handleAttach}>*/}
          {/*      <AttachFileIcon fontSize="small" />*/}
          {/*    </IconButton>*/}
          {/*  </span>*/}
          {/*</Tooltip>*/}
        </Box>
        <input
          hidden
          ref={fileInputRef}
          type="file"
          onChange={handleConfirmAttachmentAdd}
          multiple
        />
      </Box>
    </Box>
  );
};

ChatMessageAdd.propTypes = {
  disabled: PropTypes.bool,
  onSend: PropTypes.func,
};

ChatMessageAdd.defaultProps = {
  disabled: false,
};

export default ChatMessageAdd;
