export const formatBytes = (bytes: number, base: 2 | 10 = 10, decimals: number = 2) => {
  if (!+bytes) return '0B';

  const k = base === 2 ? 1024 : 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes =
    base === 2
      ? ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
      : ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
