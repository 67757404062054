import React, { useEffect, useRef, useState } from 'react';
import type { ChangeEvent, FC, KeyboardEvent } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ForumIcon from '@mui/icons-material/Forum';
import useAuth from '../../../hooks/useAuth';
import { experimentalStyled } from '@mui/material/styles';
import useModal from '../../../hooks/useModal';
import { useForm, useWatch } from 'react-hook-form';
import { getErrorMessageFor } from '../../../utils/formsHelper';
import { Input } from '../../../components/widgets/inputs/Input';

const IconButtonContainer = experimentalStyled('span')`
  cursor: pointer
`;

const StyledForm = experimentalStyled('form')({
  marginTop: '0px',
  marginBottom: '30px',
});

const formId = 'payment-form';

const MessagingActionMessageAdd: FC<any> = (props) => {
  const {
    disabled,
    onPostInitPayment = () => {},
    onPostEndChat = () => {},
    onPostRequestLogin = () => {},
    hasActivePayment,
    paymentAvailable,
    ...other
  } = props;

  const { Component: LoginRequestModal, ...loginRequestModal } = useModal();
  const { Component: EndChatModal, ...endChatModal } = useModal();
  const {
    Component: PaymentModal,
    SimpleActions: PaymentSimpleActions,
    ContentContainer: PaymentContentContainer,
    ...paymentModal
  } = useModal();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: { amount: '10' },
  });

  const amount = useWatch({
    control,
    defaultValue: null,
    name: 'amount',
  });

  const handlePaymentClick = () => {
    paymentModal.open();
  };

  const handlePaymentRequest = (data) => {
    onPostInitPayment(parseFloat(data.amount));
    paymentModal.close();
  };

  const handleLoginRequestClick = () => {
    loginRequestModal.open();
  };

  const handleEndChatClick = () => {
    endChatModal.open();
  };

  const handleEndChatRequest = () => {
    onPostEndChat();
    endChatModal.close();
  };

  const handleLoginReqRequest = () => {
    onPostRequestLogin();
    loginRequestModal.close();
  };

  return <>
    <Box
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'background.paper',
        display: 'flex',
        flexShrink: 0,
        px: 2,
        py: 0,
      }}
      {...other}
    >
      <LoginRequestModal
        {...loginRequestModal.props}
        isConfirmation={true}
        title={'Send Registration to Customer?'}
        onConfirm={handleLoginReqRequest}
        onCancel={loginRequestModal.close}
        confirmLabel={'Yes'}
        cancelLabel={'No'}
      />
      <EndChatModal
        {...endChatModal.props}
        isConfirmation={true}
        title={'Do you want to end the chat?'}
        onConfirm={handleEndChatRequest}
        onCancel={endChatModal.close}
      />
      <PaymentModal
        {...paymentModal.props}
        sx={{ display: 'flex', flexDirection: 'column', height: 'auto', width: 'auto' }}
      >
        <Typography color="textPrimary" variant="h5" sx={{ marginBottom: '20px' }}>
          Charge the Customer
        </Typography>
        <Typography
          color="textPrimary"
          variant="subtitle2"
          sx={{ marginTop: '20px', marginBottom: '20px' }}
        >
          Enter the amount
        </Typography>
        <StyledForm id={formId} onSubmit={handleSubmit(handlePaymentRequest)}>
          <Input
            control={control}
            isEditMode={true}
            defaultValue={'10'}
            type="amount"
            name="amount"
            validationMessage={null}
            disabled={false}
          />
        </StyledForm>
        <PaymentSimpleActions
          form={formId}
          onConfirm={handlePaymentRequest}
          onCancel={paymentModal.close}
          confirmLabel={'Confirm'}
          sx={{ marginTop: 'auto' }}
        />
      </PaymentModal>
      <Tooltip title="Send login action" placement="top">
        <IconButtonContainer>
          <IconButton
            color="primary"
            disabled={disabled}
            onClick={handleLoginRequestClick}
            size="large">
            <AccountCircleIcon fontSize="medium" />
          </IconButton>
        </IconButtonContainer>
      </Tooltip>
      <Tooltip
        title={hasActivePayment ? 'Thread has active payment' : 'Send payment request action'}
        placement="top"
      >
        <IconButtonContainer>
          <IconButton
            color="primary"
            disabled={disabled || hasActivePayment || !paymentAvailable}
            onClick={handlePaymentClick}
            size="large">
            <CreditScoreIcon fontSize="medium" />
          </IconButton>
        </IconButtonContainer>
      </Tooltip>
      <Tooltip title="Send end chat action" placement="top">
        <IconButtonContainer>
          <IconButton
            color="primary"
            disabled={disabled}
            onClick={handleEndChatClick}
            size="large">
            <ForumIcon fontSize="medium" />
          </IconButton>
        </IconButtonContainer>
      </Tooltip>
    </Box>
  </>;
};

export default MessagingActionMessageAdd;
