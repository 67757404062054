import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { dispatch } from 'react-hot-toast';
import ApiRequest from '../utils/ApiRequest';
import { RequestStatus } from '../utils/RequestStatus';
interface FilesDictionary {
  [key: string]: {
    fetchStatus: string | null;
    uploadResponse: Array<any> | null;
  };
}

interface FileUploadState {
  files: FilesDictionary | {};
}

const initialState: FileUploadState = {
  files: {},
};

export const postFile: any = createAsyncThunk(
  'fileUpload/postFile',
  async (data: { file?: any; fileGroup?: any; storeKey: string; type?: string }, thunkAPI) => {
    const { file, fileGroup, storeKey, type = 'image' } = data;

    const formData = new FormData();

    if (file) {
      formData.append(type, new File([file], file.name, { type: file.type }));
    } else if (fileGroup) {
      formData.append(type, fileGroup?.file[0]);
    }

    return ApiRequest.post(`/web/files/`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })(formData, thunkAPI);
  }
);

const slice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setFileStoreKey(state: FileUploadState, action: PayloadAction<any>): void {},
    clearUploadDetails(state: FileUploadState, action: PayloadAction<any>): void {
      state.files = {};
    },
    clearFilesForGivenKey(state: FileUploadState, action: PayloadAction<any>): void {
      const { storeKey } = action.payload;

      state.files[storeKey] = {
        uploadResponse: null,
        fetchStatus: RequestStatus.status.NULL,
      };
    },
  },
  extraReducers: {
    [postFile.pending]: (state, action) => {
      const storeKey = action.meta.arg.storeKey;
      state.files[storeKey] = {
        fetchStatus: RequestStatus.status.FETCHING,
        uploadResponse: null,
      };
    },
    [postFile.fulfilled]: (state, action) => {
      const storeKey = action.meta.arg.storeKey;
      state.files[storeKey] = {
        fetchStatus: RequestStatus.status.DONE,
        uploadResponse: action.payload.results,
      };
    },
    [postFile.rejected]: (state, action) => {
      const storeKey = action.meta.arg.storeKey;
      state.files[storeKey] = {
        fetchStatus: RequestStatus.status.ERROR,
        uploadResponse: null,
      };
    },
  },
});

export const { reducer } = slice;
export const { clearUploadDetails, clearFilesForGivenKey } = slice.actions;

export default slice;
