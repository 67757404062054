import { useEffect, useState } from 'react';
import type { ChangeEvent, FC } from 'react';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import Scrollbar from '../../../components/Scrollbar';
import ChatContactSearch from '../../../components/dashboard/chat/ChatContactSearch';
import MessagingSidebarList from './MessagingSidebarList';
import { MessagingThread } from '../../../types/messaging_thread';
import { RequestStatus } from '../../../utils/RequestStatus';
import LinearProgress from '../../../components/LinearProgress';
import MarkAsRead from './MarkAsRead';

interface Props {
  threadsList: any;
  activeThreadId: string | number | null;
  searchQuery: string;
  setSearchQuery(query: String): void;
  fetchStatus: string | null;
  handleSelect: (thread: MessagingThread) => void;
  onLoadMoreThreadsClick: any;
  isLoadMoreThreadVisible: boolean;
  wasThreadsFetchingCancelled: boolean;
  isLoadingMore: boolean;
  onPostMarkAllAsRead: () => any;
  isAnyThreadLoading?: boolean;
}

const MessagingSidebar: FC<Props> = (props) => {
  const {
    threadsList,
    activeThreadId,
    searchQuery,
    setSearchQuery,
    fetchStatus,
    handleSelect,
    onLoadMoreThreadsClick,
    isLoadMoreThreadVisible,
    wasThreadsFetchingCancelled,
    isLoadingMore,
    onPostMarkAllAsRead,
    isAnyThreadLoading,
  } = props;

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        borderRight: 1,
        borderColor: 'divider',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        width: 300,
        minWidth: 300,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexShrink: 0,
          height: 10,
          px: 2,
        }}
      />
      <Box
        sx={{
          display: {
            sm: 'flex',
            xs: 'none',
          },
        }}
      >
        <ChatContactSearch
          isFocused={false}
          onChange={(event) => setSearchQuery(event.target?.value)}
          onClickAway={() => {}}
          onFocus={() => {}}
          onSelect={() => {}}
          query={searchQuery}
          results={[]}
          hideResultsPreview={true}
        />
        <MarkAsRead onClick={onPostMarkAllAsRead} />
      </Box>
      <LinearProgress show={isAnyThreadLoading || wasThreadsFetchingCancelled} color="primary" />
      <Scrollbar options={{ suppressScrollX: true }} className={'all-results'}>
        <Box>
          {(RequestStatus.isFetching(fetchStatus) || wasThreadsFetchingCancelled) &&
            !threadsList?.length && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  background: 'none',
                  position: 'relative',
                  height: '50px',
                }}
              >
                <CircularProgress
                  sx={{
                    position: 'absolute',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    bottom: 0,
                  }}
                />
              </Box>
            )}
          <MessagingSidebarList
            threadsList={threadsList}
            activeThreadId={activeThreadId}
            handleSelect={handleSelect}
            fetchStatus={fetchStatus}
            onLoadMoreThreadsClick={onLoadMoreThreadsClick}
            isLoadMoreThreadVisible={isLoadMoreThreadVisible}
            wasThreadsFetchingCancelled={wasThreadsFetchingCancelled}
            isLoadingMore={isLoadingMore}
          />
        </Box>
      </Scrollbar>
    </Box>
  );
};

export default MessagingSidebar;
