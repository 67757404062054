import { experimentalStyled } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';

const CircularProgressRoot = experimentalStyled('div')({
  height: 5,
});

const LinearProgressComponent = (props) => {
  const { show, ...otherProps } = props;

  return <CircularProgressRoot>{show && <LinearProgress {...otherProps} />}</CircularProgressRoot>;
};

export default LinearProgressComponent;
