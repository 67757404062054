import * as React from 'react';
import { useContext, useEffect, useRef } from 'react';
import Select from 'react-select';
import { CellBase, DataEditorComponent, DataViewerComponent } from 'react-spreadsheet';
import { RootState, useDispatch, useSelector } from '../../../../store';
import { getMediaLibraryFiles, MediaLibraryFile } from '../../../../slices/locations';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { MediaFilesPaginationContext } from '../../../../contexts/MediaFilesPaginationContext';
import CustomOption from './LocationsSpreadsheetCustomSelectOption';
import { CircularProgress, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { RequestStatus } from '../../../../utils/RequestStatus';

type Value = string | undefined;
type Cell = CellBase<Value> & {
  value: Value;
};

const getOptions = (items: Array<MediaLibraryFile>) => {
  return items.map((file) => ({ value: file.uuid, label: file.file_label }));
};

export const SelectView: DataViewerComponent<Cell> = ({ cell }) => {
  const { allItems, fetchStatus, count } = useSelector(
    (state: RootState) => state.locations.mediaLibraryFiles
  );

  const option = React.useMemo(
    () => (cell && getOptions(allItems).find((option) => option.value === cell.value)) ?? null,
    [cell]
  );

  const loadOptions = (inputValue: string, callback: any) => {
    callback(getOptions(allItems));
  };

  return (
    <AsyncSelect
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          border: 'unset',
        }),
      }}
      value={option}
      loadOptions={loadOptions}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Menu: () => null,
      }}
    />
  );
};

export const SelectEdit: DataEditorComponent<Cell> = ({ cell, onChange, exitEditMode }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectRef = useRef();

  const { pageSize, shouldFetchOnSelectOpen, setQuery, setShouldFetchOnSelectOpen } = useContext(
    MediaFilesPaginationContext
  );

  const { items, fetchStatus, count } = useSelector(
    (state: RootState) => state.locations.mediaLibraryFiles
  );

  const handleChange = React.useCallback(
    (selection) => {
      onChange({ ...cell, value: selection ? selection.value : null });
    },
    [cell, onChange]
  );
  const option = React.useMemo(
    () => cell && getOptions(items).find((option) => option.value === cell.value),
    [cell]
  );

  const _loadOptions = (inputValue: string, callback: any) => {
    if (inputValue !== '') setShouldFetchOnSelectOpen(true);
    setQuery(inputValue);
    if (selectRef.current !== null) {
      dispatch(
        getMediaLibraryFiles({
          organization_uuid: searchParams.get('organization_uuid'),
          page: 1,
          page_size: pageSize,
          search: inputValue,
        })
      );
    }
  };

  const loadOptions = debounce(_loadOptions, 1000);

  useEffect(() => {
    (!count || shouldFetchOnSelectOpen) &&
      dispatch(
        getMediaLibraryFiles({
          organization_uuid: searchParams.get('organization_uuid'),
          page: 1,
          page_size: pageSize,
          search: '',
        })
      ).then(() => shouldFetchOnSelectOpen && setShouldFetchOnSelectOpen(false));
  }, []);

  return (
    <Select
      filterOption={null}
      ref={selectRef}
      value={option}
      onChange={handleChange}
      autoFocus
      options={getOptions(
        count > items.length
          ? [...items, { uuid: 'load-more', file_label: 'Load more', file: null }]
          : items
      )}
      onInputChange={loadOptions}
      defaultMenuIsOpen
      onMenuClose={() => exitEditMode()}
      isClearable
      classNamePrefix={'locations-spreadsheet'}
      menuPortalTarget={document.body}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Option: CustomOption,
        NoOptionsMessage: (props) => (
          <ListItem sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {RequestStatus.isFetching(fetchStatus) ? (
              <>
                <ListItemIcon>
                  <CircularProgress size={16} />
                </ListItemIcon>
                <ListItemText
                  primary={'Loading...'}
                  primaryTypographyProps={{
                    color: 'textPrimary',
                  }}
                />
              </>
            ) : (
              <ListItemText primary={'No files'} />
            )}
          </ListItem>
        ),
      }}
      styles={{
        menuPortal: (baseStyles) => ({
          ...baseStyles,
          zIndex: 9999,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          width: '350px',
          left: '-125px',
        }),
      }}
    />
  );
};
