import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, Button, Card, Grid, ListItemText, Typography } from '@mui/material';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { getFileNameFromLink } from '../../../Messaging/messagingHelper';
import { qrCodesDragDropLayout } from '../../locationsConfig';
import { alpha, useTheme } from '@mui/material/styles';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ClearIcon from '@mui/icons-material/Clear';
import { compact } from 'lodash';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

interface LocationsPrintLayoutSetupModalProps {
  qrCodesToPrint: Array<{
    qr_code: string;
    name: string;
    ancestors_names: Array<string>;
    printAmount: number;
  }>;
  setPagesToPrintRef: Dispatch<SetStateAction<MutableRefObject<HTMLDivElement>>>;
  setQrCodesToPrintLayout: Dispatch<SetStateAction<Array<Array<Array<string>>>>>;
}

const LocationsPrintLayoutSetupModal: FC<LocationsPrintLayoutSetupModalProps> = (props) => {
  const { qrCodesToPrint, setPagesToPrintRef, setQrCodesToPrintLayout } = props;

  const theme = useTheme();

  const pagesRef = useRef<HTMLDivElement>(null);

  const [qrCodesConfig, setQrCodesConfig] = useState<{
    sidebar: Array<{
      qr_code: string;
      name: string;
      ancestors_names: Array<string>;
      printAmount: number;
    }>;
    pages: {
      [page: string]: Array<{ [slot: string]: string }>;
    };
  }>({
    sidebar: [],
    pages: {},
  });

  const [qrCodesAmount, setQrCodesAmount] = useState<number>(0);
  const [initialPagesCount, setInitialPagesCount] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(null);
  const [draggingSourceId, setDraggingSourceId] = useState<string>(null);

  const setInitialQrCodesConfig = (): void => {
    setQrCodesConfig((prev) => {
      let pages = prev.pages;
      const pagesCount =
        Object.keys(pages)?.length > 0
          ? Object.keys(pages)?.length
          : Math.ceil(qrCodesToPrint.reduce((acc, cur) => (acc = acc + cur.printAmount), 0) / 20);
      if (Object.keys(pages)?.length === 0) setInitialPagesCount(pagesCount);

      for (let i = 0; i < pagesCount; i++) {
        pages[`${i}`] = qrCodesDragDropLayout
          .pageLayout(i)
          .map((row, idx) => Object.fromEntries(row.map((slot) => [slot, null])));
      }

      return {
        pages,
        sidebar: qrCodesToPrint
          .map((qrCode) => {
            if (qrCode?.printAmount === 1) return qrCode;
            else {
              let qrCodeCopies = [];
              for (let i = 0; i < qrCode.printAmount; i++) {
                qrCodeCopies.push({ ...qrCode, printAmount: 1 });
              }
              return qrCodeCopies;
            }
          })
          .flat(),
      };
    });
  };

  const selectAllQrCodes = (): void => {
    const pagesCount = Math.ceil(
      qrCodesToPrint.reduce((acc, cur) => (acc = acc + cur.printAmount), 0) / 20
    );
    setQrCodesConfig((prev) => {
      let pages = prev.pages;
      //to correctly fill all of the necessary spots while omitting slots containing qr codes
      let sourceQrCodeIndexOffset = 0;

      for (let i = 0; i < pagesCount; i++) {
        pages[`${i}`] = qrCodesDragDropLayout.pageLayout(i).map((row, rowIdx) =>
          Object.fromEntries(
            row.map((slot, slotIdx) => {
              if (prev.pages[i][rowIdx][`${i}-${rowIdx}-${slotIdx}`]) {
                sourceQrCodeIndexOffset += 1;
                return [slot, prev.pages[i][rowIdx][`${i}-${rowIdx}-${slotIdx}`]];
              }

              return [
                slot,
                prev.sidebar[
                  slotIdx +
                    row.length * rowIdx +
                    qrCodesDragDropLayout.pageLayout(i).length * row.length * i -
                    sourceQrCodeIndexOffset
                ]?.qr_code ?? null,
              ];
            })
          )
        );
      }

      return {
        pages,
        sidebar: [],
      };
    });
  };

  const handleAddEmptyPage = (): void => {
    setQrCodesConfig((prev) => ({
      ...prev,
      pages: {
        ...prev.pages,
        [`${Object.keys(prev.pages).length.toString()}`]: qrCodesDragDropLayout
          .pageLayout(Object.keys(prev.pages).length)
          .map((row, idx) => Object.fromEntries(row.map((slot) => [slot, null]))),
      },
    }));
  };

  const handleRemovePage = (_key: string): void => {
    setQrCodesConfig((prev) => {
      const pageToRemove = qrCodesConfig.pages[_key];

      const qrCodesToMove = pageToRemove
        .map((row, idx) => Object.values(row).filter((cell, idx) => cell !== null))
        .flat()
        .map((qrCodeUrl) => qrCodesToPrint.find((qrCode) => qrCode.qr_code === qrCodeUrl));

      let newPages = prev.pages;

      delete newPages[_key];

      return {
        ...prev,
        sidebar: [...prev.sidebar, ...qrCodesToMove],
        //Renaming pages object so the indexes and keys match the page number
        pages: Object.fromEntries(
          Object.entries(newPages)
            .map(([key, value], idx) => {
              const pageNumber = parseInt(key, 10);
              const removedPageNumber = parseInt(_key, 10);
              if (pageNumber < removedPageNumber) return [key, value];
              else
                return [
                  (pageNumber - 1).toString(),
                  value.map((row, rowIdx) =>
                    Object.fromEntries(
                      Object.entries(row).map(([cellKey, cellValue], cellIdx) => [
                        `${pageNumber - 1}-${rowIdx}-${cellIdx}`,
                        cellValue,
                      ])
                    )
                  ),
                ];
            })
            .filter((item) => item !== null)
        ),
      };
    });
  };

  const insertIntoArray = (array: Array<any>, idx, itemToInsert): Array<any> => {
    if (!!itemToInsert) array.splice(idx, 0, itemToInsert);
    return array;
  };

  const swapElements = (array: Array<any>, index1: number, index2: number): Array<any> => {
    const temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
    return array;
  };

  const removeIndexesFromUrl = (url: string): string => {
    const parts = url.split('http');
    return `http${parts[1]}`;
    // return '';
  };

  const getPagesLocation = (
    path: string
  ): {
    page: number;
    row: number;
    cell: number;
  } => {
    const pageRowCell = path.split('-').map((item) => parseInt(item, 10));
    return {
      page: pageRowCell[0],
      row: pageRowCell[1],
      cell: pageRowCell[2],
    };
  };

  const checkIfCellNotEmpty = (object: any, path: string): boolean => {
    const location = getPagesLocation(path);
    return (
      object[location.page][location.row][
        `${location.page.toString()}-${location.row.toString()}-${location.cell.toString()}`
      ] !== null
    );
  };

  const modifyCellValue = (object: any, path: string, value: any): any => {
    const location = getPagesLocation(path);
    let pages = object;
    pages[location.page][location.row][
      `${location.page.toString()}-${location.row.toString()}-${location.cell.toString()}`
    ] = value;
    return pages;
  };

  const swapCellsValues = (object: any, source: string, destination: string): any => {
    let pages = object;
    const locationSource = getPagesLocation(source);
    const locationDestination = getPagesLocation(destination);
    const sourceTargetValue =
      object[locationDestination.page][locationDestination.row][
        `${locationDestination.page.toString()}-${locationDestination.row.toString()}-${locationDestination.cell.toString()}`
      ];
    const destinationTargetValue =
      object[locationSource.page][locationSource.row][
        `${locationSource.page.toString()}-${locationSource.row.toString()}-${locationSource.cell.toString()}`
      ];

    pages = modifyCellValue(object, source, sourceTargetValue);
    pages = modifyCellValue(pages, destination, destinationTargetValue);
    return pages;
  };

  const onDragEnd = (result: DropResult, provided: ResponderProvided): any => {
    setQrCodesConfig((prev) => {
      if (!result.destination?.droppableId || !result.source?.droppableId) return prev;
      setIsDragging(false);
      setDraggingSourceId(null);

      let wasFound = false;

      return {
        sidebar:
          //drag qr code from sidebar to page preview
          result.source.droppableId === 'sidebar-list' &&
          result.destination.droppableId !== 'sidebar-list'
            ? checkIfCellNotEmpty(prev.pages, result.destination.droppableId)
              ? compact(
                  insertIntoArray(
                    prev.sidebar,
                    result.source.index,
                    qrCodesToPrint.find((qrCode) => {
                      const location = getPagesLocation(result.destination.droppableId);
                      return (
                        qrCode.qr_code ===
                        prev.pages[location.page][location.row][
                          `${location.page.toString()}-${location.row.toString()}-${location.cell.toString()}`
                        ]
                      );
                    }) ?? null
                  ).filter((qrCode) => {
                    //qrCode.qr_code !== removeIndexesFromUrl(result.draggableId)
                    if (qrCode.qr_code === removeIndexesFromUrl(result.draggableId) && !wasFound) {
                      wasFound = true;
                      return null;
                    } else return qrCode;
                  })
                )
              : compact(
                  prev.sidebar.map((qrCode) => {
                    //qrCode.qr_code !== removeIndexesFromUrl(result.draggableId)
                    if (qrCode.qr_code === removeIndexesFromUrl(result.draggableId) && !wasFound) {
                      wasFound = true;
                      return null;
                    } else return qrCode;
                  })
                )
            : //drag qr code to sidebar from page preview
            result.destination.droppableId === 'sidebar-list' &&
              result.source.droppableId !== 'sidebar-list'
            ? insertIntoArray(
                prev.sidebar,
                result.destination.index,

                {
                  ...qrCodesToPrint.find(
                    (qrCode) => qrCode.qr_code === removeIndexesFromUrl(result.draggableId)
                  ),
                  printAmount: 1,
                }
              )
            : //move qr code in sidebar
            result.source.droppableId === 'sidebar-list' &&
              result.destination.droppableId === 'sidebar-list'
            ? swapElements(prev.sidebar, result.source.index, result.destination.index)
            : prev.sidebar,
        pages:
          //drag qr code from sidebar to page preview
          result.source.droppableId === 'sidebar-list' &&
          result.destination.droppableId !== 'sidebar-list'
            ? modifyCellValue(
                prev.pages,
                result.destination.droppableId,
                removeIndexesFromUrl(result.draggableId)
              )
            : //drag qr code to sidebar to page preview
            result.source.droppableId !== 'sidebar-list' &&
              result.destination.droppableId === 'sidebar-list'
            ? modifyCellValue(prev.pages, result.source.droppableId, null)
            : //move qr code in page preview
            result.source.droppableId !== 'sidebar-list' &&
              result.destination.droppableId !== 'sidebar-list'
            ? swapCellsValues(prev.pages, result.source.droppableId, result.destination.droppableId)
            : prev.pages,
      };
    });
  };

  useEffect(() => {
    setInitialQrCodesConfig();
    setQrCodesAmount(
      qrCodesToPrint
        .map((qrCode) => {
          if (qrCode?.printAmount === 1) return qrCode;
          else {
            let qrCodeCopies = [];
            for (let i = 0; i < qrCode.printAmount; i++) {
              qrCodeCopies.push(qrCode);
            }
            return qrCodeCopies;
          }
        })
        .flat()?.length
    );
  }, [qrCodesToPrint]);

  useEffect(() => {
    if (pagesRef?.current) setPagesToPrintRef(pagesRef);
  }, [pagesRef]);

  const sidebarItem = useCallback(
    (qrCode, i, idx) => {
      return (
        <Draggable
          draggableId={`${idx}-${i}-${qrCode.qr_code}`}
          index={idx}
          key={`${idx}-${i}-${qrCode.qr_code}`}
        >
          {(provided, snapshot) => (
            <Box
              {...provided.draggableProps}
              ref={provided.innerRef}
              key={`${idx}-${i}-${qrCode.qr_code}`}
              sx={{
                dispay: 'flex',
              }}
            >
              <img
                {...provided.dragHandleProps}
                src={qrCode.qr_code}
                alt={getFileNameFromLink(qrCode.qr_code)}
                style={{
                  width: qrCodesDragDropLayout.qrCodeSize.preview.pixels,
                }}
              />
            </Box>
          )}
        </Draggable>
      );
    },
    [qrCodesConfig.sidebar]
  );

  useEffect(() => {
    if (qrCodesToPrint.length > 500)
      setTimeout(() => {
        selectAllQrCodes();
      }, 100);
  }, [qrCodesToPrint]);

  const pageCell = useCallback(
    (key, value, idx) => {
      return (
        <Droppable
          droppableId={key}
          renderClone={(provided, snapshot, rubric) => (
            <Box {...provided.draggableProps} ref={provided.innerRef} key={value}>
              {value !== null && (
                <img
                  {...provided.dragHandleProps}
                  src={value}
                  alt={getFileNameFromLink(value)}
                  style={{
                    width: qrCodesDragDropLayout.qrCodeSize.preview.pixels,
                    borderRadius: '16px',
                  }}
                />
              )}
            </Box>
          )}
        >
          {(provided, snapshot) => (
            <Card
              {...provided.droppableProps}
              ref={provided.innerRef}
              key={key}
              variant={'outlined'}
              sx={{
                width: qrCodesDragDropLayout.qrCodeSize.preview.pixels,
                height: qrCodesDragDropLayout.qrCodeSize.preview.pixels,
                m: 0.5,
                ...(snapshot.isDraggingOver && {
                  backgroundColor: alpha(theme.palette.primary.main, 0.2),
                  outline: `solid 1px ${alpha(theme.palette.primary.main, 0.2)}`,
                }),
              }}
            >
              {(isDragging && draggingSourceId === key) || !isDragging ? (
                <Draggable
                  draggableId={`${idx}-${key}-${value}`}
                  index={idx}
                  key={`${idx}-${key}-${value}`}
                >
                  {(provided, snapshot) => (
                    <Box
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                    >
                      {value !== null && (
                        <img
                          src={value}
                          alt={getFileNameFromLink(value)}
                          style={{
                            width: qrCodesDragDropLayout.qrCodeSize.preview.pixels,
                          }}
                        />
                      )}
                    </Box>
                  )}
                </Draggable>
              ) : (
                <img
                  src={value}
                  alt={getFileNameFromLink(value)}
                  style={{
                    width: qrCodesDragDropLayout.qrCodeSize.preview.pixels,
                  }}
                />
              )}
            </Card>
          )}
        </Droppable>
      );
    },
    [qrCodesConfig.pages]
  );

  useEffect(() => {
    setQrCodesToPrintLayout(
      Object.values(qrCodesConfig.pages).map((page) =>
        page.map((row) => Object.values(row).map((cell) => cell))
      )
    );
  }, [qrCodesConfig]);

  return (
    <>
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} md={4}>
          <Card sx={{ px: 2 }}>
            <ListItemText
              primary={'QR codes count'}
              secondary={qrCodesAmount}
              sx={{
                textAlign: 'center',
              }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ px: 2 }}>
            <ListItemText
              primary={'QR codes left'}
              secondary={qrCodesConfig.sidebar.length.toString()}
              sx={{
                textAlign: 'center',
              }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ px: 2 }}>
            <ListItemText
              primary={'Pages count'}
              secondary={Object.keys(qrCodesConfig.pages)?.length}
              sx={{
                textAlign: 'center',
              }}
            />
          </Card>
        </Grid>
      </Grid>
      <Grid container marginBottom={2} spacing={2}>
        <Grid item xs={12} md={4}>
          <Button
            fullWidth
            variant={'outlined'}
            sx={{ mr: 1 }}
            onClick={selectAllQrCodes}
            endIcon={<PostAddIcon sx={{ mb: 0.5 }} />}
          >
            Select all
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            fullWidth
            variant={'outlined'}
            sx={{ mr: 1 }}
            onClick={handleAddEmptyPage}
            endIcon={<NoteAddIcon sx={{ mb: 0.5 }} />}
          >
            Add page
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            fullWidth
            variant={'outlined'}
            color={'error'}
            sx={{ mr: 1 }}
            onClick={setInitialQrCodesConfig}
            endIcon={<ClearIcon sx={{ mb: 0.5 }} />}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <DragDropContext
        onDragEnd={onDragEnd}
        onBeforeCapture={(before) => {
          //a bit hacky way to make sure that on page preview there is only one or no draggable elements when user starts dragging,
          //it's a workaround for the fact that droppable from react-beautiful-dnd will always try to make space for currently dragged item,
          //and that in turn causes issues with placing qr codes into cells, this space created by dnd will overlap the space beneath
          //and user won't be able to place the qr code until he wiggles the dragged qr code around so the invisible space disappears
          //
          //length of extracted id defines whether the qr code was dragged from page preview or from sidebar,
          //there is no other way currently to define the source of dragged qr code,
          //and it has to be defined in onBeforeCapture;
          //onBeforeDragStart may contain source id, but then no draggable element will be rerendered into just an image to prevent this placement issue
          const id = before.draggableId.split('http')[0];
          setIsDragging(true);
          if (id.length > 4) setDraggingSourceId(id.slice(2, 7));
          else setDraggingSourceId('sidebar-list');
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Droppable
            droppableId={'sidebar-list'}
            renderClone={(provided, snapshot, rubric) => (
              <Box
                {...provided.dragHandleProps}
                {...provided.draggableProps}
                ref={provided.innerRef}
                key={`${qrCodesConfig.sidebar[rubric.source.index].qr_code}-${rubric.source.index}`}
              >
                <img
                  src={qrCodesConfig.sidebar[rubric.source.index].qr_code}
                  alt={getFileNameFromLink(qrCodesConfig.sidebar[rubric.source.index].qr_code)}
                  style={{
                    width: qrCodesDragDropLayout.qrCodeSize.preview.pixels,
                    borderRadius: '16px',
                  }}
                />
              </Box>
            )}
          >
            {(provided, snapshot) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{
                  overflow: 'scroll',
                  height: 'calc(90vh - 320px)',
                  minWidth: `calc(${qrCodesDragDropLayout.qrCodeSize.preview.pixels} + 16px)`,
                  px: 1,
                  py: 0.75,
                  ...(snapshot.isDraggingOver && {
                    backgroundColor: alpha(theme.palette.primary.main, 0.2),
                  }),
                }}
              >
                {qrCodesConfig.sidebar.map((qrCode, idx) => {
                  let qrCodes = [];
                  for (let i = 0; i < qrCode.printAmount; i++) {
                    qrCodes.push(
                      // <Draggable
                      //   draggableId={`${idx}-${i}-${qrCode.qr_code}`}
                      //   index={idx}
                      //   key={`${idx}-${i}-${qrCode.qr_code}`}
                      // >
                      //   {(provided, snapshot) => (
                      //     <Box
                      //       {...provided.draggableProps}
                      //       ref={provided.innerRef}
                      //       key={`${idx}-${i}-${qrCode.qr_code}`}
                      //       sx={{
                      //         dispay: 'flex',
                      //       }}
                      //     >
                      //       <img
                      //         {...provided.dragHandleProps}
                      //         src={qrCode.qr_code}
                      //         alt={getFileNameFromLink(qrCode.qr_code)}
                      //         style={{
                      //           width: qrCodesDragDropLayout.qrCodeSize.preview.pixels,
                      //         }}
                      //       />
                      //     </Box>
                      //   )}
                      // </Draggable>
                      sidebarItem(qrCode, i, idx)
                    );
                  }
                  return <>{qrCodes.map((qrCode) => qrCode)}</>;
                })}
              </Box>
            )}
          </Droppable>
          <Box
            ref={pagesRef}
            sx={{
              minWidth: '428px',
              height: 'calc(90vh - 320px)',
              overflow: 'scroll',
              pr: 1,
            }}
          >
            {Object.entries(qrCodesConfig.pages)?.map(([key, value], idx) => (
              <>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant={'subtitle2'} color={'textSecondary'}>{`Page ${
                    idx + 1
                  }`}</Typography>
                  <Button
                    disabled={idx < initialPagesCount}
                    color={'error'}
                    endIcon={<ClearIcon sx={{ mb: 0.5 }} />}
                    onClick={() => {
                      handleRemovePage(key);
                    }}
                  >
                    Remove page
                  </Button>
                </Box>
                <Box
                  className={'page'}
                  sx={{
                    border: 'solid 1px',
                    borderColor: theme.palette.divider,
                    width: '416px',
                    height: '528px',
                    marginBottom: '24px',
                  }}
                >
                  {value.map((row) => (
                    <Box sx={{ display: 'flex' }}>
                      {Object.entries(row).map(([key, value], idx) =>
                        // <Droppable
                        //   droppableId={key}
                        //   renderClone={(provided, snapshot, rubric) => (
                        //     <Box {...provided.draggableProps} ref={provided.innerRef} key={value}>
                        //       {value !== null && (
                        //         <img
                        //           {...provided.dragHandleProps}
                        //           src={value}
                        //           alt={getFileNameFromLink(value)}
                        //           style={{
                        //             width: qrCodesDragDropLayout.qrCodeSize.preview.pixels,
                        //             borderRadius: '16px',
                        //           }}
                        //         />
                        //       )}
                        //     </Box>
                        //   )}
                        // >
                        //   {(provided, snapshot) => (
                        //     <Card
                        //       {...provided.droppableProps}
                        //       ref={provided.innerRef}
                        //       key={key}
                        //       variant={'outlined'}
                        //       sx={{
                        //         width: qrCodesDragDropLayout.qrCodeSize.preview.pixels,
                        //         height: qrCodesDragDropLayout.qrCodeSize.preview.pixels,
                        //         m: 0.5,
                        //         ...(snapshot.isDraggingOver && {
                        //           backgroundColor: alpha(theme.palette.primary.main, 0.2),
                        //           outline: `solid 1px ${alpha(theme.palette.primary.main, 0.2)}`,
                        //         }),
                        //       }}
                        //     >
                        //       {(isDragging && draggingSourceId === key) || !isDragging ? (
                        //         <Draggable
                        //           draggableId={`${idx}-${key}-${value}`}
                        //           index={idx}
                        //           key={`${idx}-${key}-${value}`}
                        //         >
                        //           {(provided, snapshot) => (
                        //             <Box
                        //               {...provided.dragHandleProps}
                        //               {...provided.draggableProps}
                        //               ref={provided.innerRef}
                        //             >
                        //               {value !== null && (
                        //                 <img
                        //                   src={value}
                        //                   alt={getFileNameFromLink(value)}
                        //                   style={{
                        //                     width: qrCodesDragDropLayout.qrCodeSize.preview.pixels,
                        //                   }}
                        //                 />
                        //               )}
                        //             </Box>
                        //           )}
                        //         </Draggable>
                        //       ) : (
                        //         <img
                        //           src={value}
                        //           alt={getFileNameFromLink(value)}
                        //           style={{
                        //             width: qrCodesDragDropLayout.qrCodeSize.preview.pixels,
                        //           }}
                        //         />
                        //       )}
                        //     </Card>
                        //   )}
                        // </Droppable>
                        pageCell(key, value, idx)
                      )}
                    </Box>
                  ))}
                </Box>
              </>
            ))}
          </Box>
        </Box>
      </DragDropContext>
    </>
  );
};

export default LocationsPrintLayoutSetupModal;
