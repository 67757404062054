import { FC, useEffect, useState } from 'react';
import {
  Button,
  Chip,
  CircularProgress,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { RequestStatus } from '../../utils/RequestStatus';
import { getFileNameFromLink } from '../Messaging/messagingHelper';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DownloadIcon from '@mui/icons-material/Download';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Trash from '../../icons/Trash';
import { getMessagingDetailsPath } from '../../routesPaths';
import toast from 'react-hot-toast';
import useModal from '../../hooks/useModal';

interface CaseManagementThreadExportedMessagesListProps {
  exportedMessages: Array<any>;
  fetchStatus: string | null;
  handleRefreshData: (options: { preventPageChange?: boolean }) => void;
  deleteExportedMessagesFileFetchStatus: string | null;
  handleDeleteExportedMessagesFile: (uuid: string) => void;
}

const CaseManagementExportedMessagesList: FC<CaseManagementThreadExportedMessagesListProps> = (
  props
) => {
  const {
    exportedMessages,
    fetchStatus,
    handleRefreshData,
    deleteExportedMessagesFileFetchStatus,
    handleDeleteExportedMessagesFile,
  } = props;

  const { Component: ConfirmDeleteFileModal, ...confirmDeleteFileModal } = useModal();

  const [downloading, setDownloading] = useState<string>(null);
  const [deleting, setDeleting] = useState<string>(null);
  const [fileToDelete, setFileToDelete] = useState<any>(null);

  const downloadPdf = (url: string, uuid: string): void => {
    setDownloading(uuid);
    fetch(url, {
      method: 'get',
      // mode: 'no-cors',
    })
      .then((r) => r.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = getFileNameFromLink(url);
        document.body.append(link);
        link.click();
        link.remove();
        setDownloading(null);
      })
      .catch((err) => {
        console.error(err);
        setDownloading(null);
      });
  };

  const deletePdf = (uuid: string): void => {
    setDeleting(uuid);
    handleDeleteExportedMessagesFile(uuid);
  };

  useEffect(() => {
    if (RequestStatus.isDone(deleteExportedMessagesFileFetchStatus)) {
      toast.success(`${fileToDelete.name} successfully deleted.`);
      setDeleting(null);
      handleRefreshData({ preventPageChange: true });
    }
    if (RequestStatus.isError(deleteExportedMessagesFileFetchStatus)) {
      toast.error('There was an error deleting this file');
      setDeleting(null);
    }
  }, [deleteExportedMessagesFileFetchStatus]);

  return (
    <>
      <ConfirmDeleteFileModal
        {...confirmDeleteFileModal.props}
        title={'Delete file'}
        isConfirmation={true}
        onConfirm={() => {
          deletePdf(fileToDelete.uuid);
          confirmDeleteFileModal.close();
        }}
        onCancel={() => {
          setTimeout(() => {
            setFileToDelete(null);
          }, 150);
          confirmDeleteFileModal.close();
        }}
        confirmLabel={'Yes'}
        cancelLabel={'No'}
        description={`Are you sure you want to delete ${fileToDelete?.name}?`}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>File name</TableCell>
            <TableCell align={'center'} width={'175px'}>
              Export Type
            </TableCell>
            <TableCell align={'center'} width={'175px'}>
              Export status
            </TableCell>
            <TableCell align={'center'} width={'175px'}></TableCell>
            {/*<TableCell align={'center'} width={'175px'}></TableCell>*/}
            {/*<TableCell align={'center'} width={'175px'}></TableCell>*/}
            <TableCell align={'center'} width={'175px'}>
              <Button
                onClick={() => {
                  handleRefreshData({});
                }}
                endIcon={<RefreshIcon />}
              >
                Refresh
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ p: 0, height: '4px', borderStyle: 'none' }} colSpan={5}>
              {RequestStatus.isFetching(fetchStatus) && <LinearProgress />}
            </TableCell>
          </TableRow>
          {exportedMessages.length > 0
            ? exportedMessages.map((file, idx) => {
                const exportedBy =
                  file.start_date && file.end_date
                    ? 'Date Range'
                    : file.start_message_uuid && file.end_message_uuid
                    ? 'Selection'
                    : 'All';

                return (
                  <TableRow
                    key={file.uuid}
                    hover
                    sx={{
                      ...(idx === exportedMessages.length - 1 && {
                        '& > td': {
                          borderBottomStyle: 'none',
                        },
                      }),
                    }}
                  >
                    <TableCell>
                      <Typography variant={'subtitle2'}>{file.name}</Typography>
                    </TableCell>
                    <TableCell align={'center'}>{exportedBy}</TableCell>
                    <TableCell align={'center'}>
                      {file.export_status === 'failed' ? (
                        <Chip label={'Failed'} color={'error'} />
                      ) : file.export_status === 'completed' ? (
                        <Chip label={'Completed'} color={'success'} />
                      ) : (
                        <Chip label={'Pending'} color={'warning'} />
                      )}
                    </TableCell>
                    <TableCell align={'center'}>
                      {/*</TableCell>*/}
                      {/*<TableCell align={'center'}>*/}
                      <Button
                        endIcon={<OpenInNewIcon />}
                        fullWidth
                        component={'a'}
                        href={file.pdf}
                        disabled={!file.pdf}
                        target={'_blank'}
                      >
                        Open file
                      </Button>
                      <Button
                        endIcon={<DownloadIcon />}
                        fullWidth
                        disabled={!file.pdf}
                        onClick={() => {
                          downloadPdf(file.pdf, file.uuid);
                        }}
                      >
                        {downloading === file.uuid && <CircularProgress size={12} sx={{ mr: 1 }} />}
                        Download
                      </Button>
                    </TableCell>
                    <TableCell>
                      {/*</TableCell>*/}
                      {/*<TableCell>*/}
                      <Button
                        endIcon={<ArrowForwardIcon />}
                        fullWidth
                        component={'a'}
                        href={getMessagingDetailsPath({ id: file?.thread_uuid })}
                        disabled={!file?.thread_uuid}
                      >
                        Go to chat
                      </Button>
                      <Button
                        color={'error'}
                        endIcon={<Trash />}
                        fullWidth
                        // disabled={!file.pdf}
                        onClick={() => {
                          setFileToDelete(file);
                          confirmDeleteFileModal.open();
                        }}
                      >
                        {deleting === file.uuid && (
                          <CircularProgress size={12} sx={{ mr: 1 }} color={'error'} />
                        )}
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            : RequestStatus.isDone(fetchStatus) && (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{
                      borderBottomStyle: 'none',
                    }}
                  >
                    <Typography variant={'h6'}>No items</Typography>
                  </TableCell>
                </TableRow>
              )}
        </TableBody>
      </Table>
    </>
  );
};

export default CaseManagementExportedMessagesList;
