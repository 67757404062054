import { useEffect } from 'react';

export const useSetFormErrors = ({ errors, setError }: { errors: any; setError: any }) => {
  useEffect(() => {
    setFormErrors(errors, setError);
  }, [errors]);
};

const getErrorValue = (error) => {
  if (Array.isArray(error)) return error[0];
  else return error;
};

const setFormErrors = (
  errors: any,
  setError: any,
  isFieldArray: boolean = false,
  errorKey: string | null = null
) => {
  if (!Object.keys(errors).length) return;
  if (isFieldArray) {
    errors.map((error, idx) => {
      Object.entries(error).map(([key, values]) => {
        setError(`${errorKey}.${idx}.${key}`, { type: 'custom', message: getErrorValue(values) });
      });
    });
  } else
    Object.entries(errors).map(([key, values]) =>
      setError(key, { type: 'custom', message: getErrorValue(values) })
    );
};
