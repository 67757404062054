import type { FC } from 'react';
import { useCallback } from 'react';
import { RootState, useDispatch, useSelector } from '../../store';
import { clearFilesForGivenKey, clearUploadDetails, postFile } from '../../slices/fileUpload';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { RequestStatus } from '../../utils/RequestStatus';
import FileDropzone from '../../components/FileDropzone';
import { experimentalStyled } from '@mui/material/styles';
import useModal from '../../hooks/useModal';
import Cropper from 'react-easy-crop';
import { Slider, Typography, Button, IconButton } from '@mui/material';
import { ArrowBack, ArrowForward, ArrowDownward, ArrowUpward } from '@mui/icons-material/';
import CancelIcon from '@mui/icons-material/Cancel';
import { CropperWrapper, CropPhotoModalContent } from './AvatarUploadInput.styles';

const CustomSlider = experimentalStyled(Slider)(({ theme }) => ({
  root: {
    padding: '10px',
  },
  '& .MuiSlider-thumb': {
    width: '20px',
    height: '20px',
    marginTop: '-7px',
  },
  '& .MuiSlider-rail': {
    height: '5px',
    borderRadius: '5px',
  },
  '& .MuiSlider-track': {
    height: '5px',
    borderRadius: '5px',
  },
}));

const FileUploadInputWrapper = experimentalStyled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    maxWidth: 'unset',
  },
}));

const createImage = (url): any =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    //image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  return canvas.toDataURL('image/jpeg');

  console.log('canvas width, height', canvas.width, canvas.height);
  // As a blob
  // return new Promise((resolve) => {
  //   canvas.toBlob((file) => {
  //     resolve(URL.createObjectURL(file));
  //   }, 'image/jpeg');
  // });
}

const DropzoneContainer = experimentalStyled('div')``;

interface AvatarUploadInputProps {
  setValue: any;
  name: string;
  defaultValue: any;
  control: any;
  isEditMode: boolean;
  isAvatar?: boolean;
  isPdf?: boolean;
  storeKey: string;
  validationMessage?: string;
  disabled?: boolean;
}

const AvatarUploadInput: FC<AvatarUploadInputProps> = (props) => {
  const {
    setValue,
    name,
    defaultValue,
    isEditMode,
    validationMessage,
    storeKey,
    isAvatar = true,
    isPdf = false,
    disabled = false,
  } = props;
  const dispatch = useDispatch();

  const { fetchStatus, uploadResponse } = useSelector(
    (state: RootState) => state.fileUpload.files[storeKey] || {}
  );

  const [avatarPreviewUrl, setAvatarPreviewUrl] = useState<string | null>(null);

  const [fullImagePreviewUrl, setFullImagePreviewUrl] = useState<{
    src: ArrayBuffer | null | string;
    isUploading: boolean;
  } | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const { Component: CropModal, SimpleActions: CropSimpleActions, ...cropModal } = useModal();

  useEffect(() => {
    if (!cropModal.isOpen) {
      setTimeout(() => {
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setCroppedAreaPixels(null);
      }, 300);
    }
  }, [cropModal.isOpen]);

  const handleDrop = (newFiles: any): void => {
    const [file] = newFiles;
    setFullImagePreviewUrl({ src: null, isUploading: true });

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const src = reader.result;
      setFullImagePreviewUrl({ src, isUploading: false });
    });
    reader.readAsDataURL(file);

    cropModal.open();
  };

  const cropAndSave = async () => {
    try {
      const croppedImageBase64 = await getCroppedImg(
        fullImagePreviewUrl?.src.toString(),
        croppedAreaPixels,
        0
      );

      const response = await fetch(croppedImageBase64);
      const blob = await response.blob();
      const file = new File([blob], 'avatar.jpg', { type: 'image/jpeg' });

      dispatch(postFile({ type: 'image', storeKey, file }));
      cropModal.close();
    } catch (err) {
      console.log(err);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleRemoveAll = (): void => {
    setAvatarPreviewUrl(null);
  };

  const handleRemoveAvatar = (event) => {
    event.stopPropagation();
    handleRemoveAll();
    dispatch(clearUploadDetails(null));
    setValue(name, null);
    setAvatarPreviewUrl(null);
  };

  useEffect(() => {
    setValue(name, defaultValue?.uuid || null);
    setAvatarPreviewUrl(defaultValue?.thumbnails?.avatar || null);
  }, [defaultValue]);

  useEffect(() => {
    setAvatarPreviewUrl(defaultValue?.thumbnails?.avatar || null);
  }, [isEditMode]);

  useEffect(() => {
    if (
      RequestStatus.isDone(fetchStatus) &&
      uploadResponse &&
      uploadResponse[0]?.payload &&
      setValue &&
      name
    ) {
      setValue(name, uploadResponse[0].payload.uuid);
      setAvatarPreviewUrl(uploadResponse[0]?.payload?.url || null);
    }
  }, [fetchStatus]);

  useEffect(() => {
    return () => {
      if (storeKey) dispatch(clearFilesForGivenKey({ storeKey }));
    };
  }, []);

  return (
    <>
      <CropModal {...cropModal.props}>
        <CropPhotoModalContent>
          <Typography color="textPrimary" variant="h5" sx={{ marginBottom: '20px' }}>
            Crop photo
          </Typography>

          <CropperWrapper>
            <Cropper
              image={fullImagePreviewUrl?.src?.toString()}
              crop={crop}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              zoom={zoom}
              onZoomChange={setZoom}
            />
          </CropperWrapper>
          <Typography
            sx={{
              marginTop: '15px',
            }}
            color="textPrimary"
            variant="subtitle2"
          >
            Zoom
          </Typography>
          <div className="zoomSlider">
            <CustomSlider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => {
                if (Array.isArray(zoom)) {
                  setZoom(zoom[0]);
                } else {
                  setZoom(zoom);
                }
              }}
              classes={{ root: 'slider' }}
            />
          </div>

          <CropSimpleActions
            onConfirm={cropAndSave}
            onCancel={cropModal.close}
            confirmLabel={'Crop'}
          />
        </CropPhotoModalContent>
      </CropModal>
      <FileUploadInputWrapper>
        <DropzoneContainer>
          <input {...props} type={'text'} style={{ display: 'none' }} />
          <FileDropzone
            accept={'image/png, image/gif, image/jpeg'}
            maxFiles={1}
            isAvatar={isAvatar}
            onDrop={handleDrop}
            avatarUrl={avatarPreviewUrl}
            isLoading={RequestStatus.isFetching(fetchStatus)}
            disabled={disabled || !isEditMode}
            onRemoveAvatar={handleRemoveAvatar}
            validationMessage={validationMessage}
          />
        </DropzoneContainer>
      </FileUploadInputWrapper>
    </>
  );
};
export default AvatarUploadInput;
