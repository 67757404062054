import ChartSquareBarIcon from './icons/ChartSquareBar';
import ChartPieIcon from './icons/ChartPie';
import ShoppingBagIcon from './icons/ShoppingBag';
import UserIcon from './icons/User';
import UsersIcon from './icons/Users';
import ShoppingCartIcon from './icons/ShoppingCart';
import FolderOpenIcon from './icons/FolderOpen';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ChatIcon from '@mui/icons-material/Chat';
import HomeIcon from '@mui/icons-material/Home';
import FolderIcon from '@mui/icons-material/Folder';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import {
  getAnalyticsPath,
  getCaseManagementPath,
  getCustomersPath,
  getLocationsPath,
  getMessagingPath,
  getOrganizationsPath,
  getVendorsPath,
} from './routesPaths';
import {
  ANALYTICS,
  CUSTOMERS,
  MESSAGES,
  ORGANIZATION,
  VENDOR,
} from './utils/permissions.constants';

export const getMenuRoutes = () => {
  return [
    {
      title: 'Menu',
      items: [
        {
          title: 'Organizations',
          path: getOrganizationsPath(),
          permissionSubject: ORGANIZATION,
          icon: <FolderOpenIcon />,
        },
        {
          title: 'Vendors',
          path: getVendorsPath(),
          permissionSubject: VENDOR,
          icon: <ShoppingCartIcon />,
        },
        {
          title: 'Customers',
          path: getCustomersPath(),
          permissionSubject: CUSTOMERS,
          icon: <UsersIcon />,
        },
        {
          title: 'Analytics',
          path: getAnalyticsPath(),
          permissionSubject: ANALYTICS,
          icon: <ChartPieIcon />,
        },
        {
          title: 'Messaging',
          path: getMessagingPath(),
          permissionSubject: MESSAGES,
          icon: <ChatIcon />,
        },
        {
          title: 'Case Management',
          path: getCaseManagementPath(),
          // permissionSubject: MESSAGES,
          icon: <FolderIcon />,
        },
        {
          title: 'Buildings',
          path: getLocationsPath(),
          // permissionSubject: MESSAGES,
          icon: <HomeWorkIcon />,
        },
      ],
    },
  ];
};
