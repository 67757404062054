import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ApiRequests from '../utils/ApiRequest';
import { RequestStatus } from '../utils/RequestStatus';

export interface Timeframe {
  label: string;
  period: number | null;
}

export interface ConfigState {
  breadcrumb: {
    replaceLastUUIDWith: string | null;
  };
  dropdownValues: {
    technicianTypesChoices: Array<{ value: string; label: string }>;
    fetchStatus: string | null;
  };
  dialogs: {
    access_grant: string;
    access_periods: Array<Timeframe>;
    access_request: string;
    connected_with_technician_message: string;
  };
  configFetchStatus: string | null;
}

const initialState: ConfigState = {
  breadcrumb: {
    replaceLastUUIDWith: null,
  },
  dropdownValues: {
    technicianTypesChoices: [],
    fetchStatus: RequestStatus.status.NULL,
  },
  dialogs: {
    access_grant: null,
    access_periods: [],
    access_request: null,
    connected_with_technician_message: null,
  },
  configFetchStatus: RequestStatus.status.NULL,
};

export const getConstants: any = createAsyncThunk(
  'config/getConstants',
  async (data: any, thunkApi) => {
    return ApiRequests.get('/web/constants/', {
      headers: {
        // HTTP_X_APP_CODE: 'eyenstein',
      },
    })(data, thunkApi);
  }
);

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    resetState: (state: ConfigState) => initialState,
    replaceLastUUIDInOrganizationBreadcrumbWith: (state: ConfigState, action) => {
      state.breadcrumb.replaceLastUUIDWith = action.payload;
    },
  },
  extraReducers: {
    [getConstants.pending]: (state, action) => {
      state.dropdownValues.fetchStatus = RequestStatus.status.FETCHING;
      state.configFetchStatus = RequestStatus.status.FETCHING;
    },
    [getConstants.fulfilled]: (state, action) => {
      const { technician_label_types, dialogs } = action.payload;
      state.dropdownValues.technicianTypesChoices = technician_label_types ?? [];
      state.dialogs = {
        ...dialogs,
        access_periods:
          dialogs?.access_periods?.map((period) =>
            period?.period === null ? { ...period, period: -1 } : period
          ) ?? [],
      };
      state.dropdownValues.fetchStatus = RequestStatus.status.DONE;
      state.configFetchStatus = RequestStatus.status.DONE;
    },
    [getConstants.rejected]: (state, action) => {
      state.dropdownValues.fetchStatus = RequestStatus.status.ERROR;
      state.configFetchStatus = RequestStatus.status.ERROR;
    },
  },
});

export const { resetState, replaceLastUUIDInOrganizationBreadcrumbWith } = slice.actions;
export const { reducer } = slice;
