import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import ApiRequest from '../utils/ApiRequest';
import { RequestStatus } from '../utils/RequestStatus';
import { getTime, subDays, subHours } from 'date-fns';

export const postRegisterDevice: any = createAsyncThunk(
  'notifications/postRegisterDevice',
  async (
    data: { instance_id: string; app_version?: string; voip_token?: string; device_id?: string },
    thunkAPI
  ) => {
    return ApiRequest.post(`/web/devices/`, {})(data, thunkAPI);
  }
);

export const getNotifications: any = createAsyncThunk(
  'notifications/getNotifications',
  async (data: any, thunkAPI) => {
    return await ApiRequest.get(`/web/notifications/`)(data, thunkAPI);
  }
);

export const getNotificationsSummary: any = createAsyncThunk(
  'notifications/getNotificationsSummary',
  async (data: any, thunkAPI) => {
    return await ApiRequest.get(`/web/notifications/summary/`)(data, thunkAPI);
  }
);

export const getNotificationsPreview: any = createAsyncThunk(
  'notifications/getNotificationsPreview',
  async (data: any, thunkAPI) => {
    return await ApiRequest.get(`/web/notifications/`)(data, thunkAPI);
  }
);

export const postMarkNotificationsAsRead: any = createAsyncThunk(
  'notifications/postMarkNotificationsAsRead',
  async (data: any = {}, thunkAPI) => {
    return await ApiRequest.post(`/web/notifications/clear/`)(data, thunkAPI);
  }
);

export const patchMarkNotificationAsRead: any = createAsyncThunk(
  'notifications/patchMarkNotificationAsRead',
  async (data: { is_read: boolean; id: string }, thunkAPI) => {
    const { id, ...otherData } = data;
    return await ApiRequest.patch(`/web/notifications/${id}/`)(otherData, thunkAPI);
  }
);

export const postClearThreadNotifications: any = createAsyncThunk(
  'notifications/postClearThreadNotifications',
  async (data: { uuid: string }, thunkAPI) => {
    const { uuid, ...otherData } = data;
    return ApiRequest.post(`/web/messaging/threads/${uuid}/clear_notifications/ `, {})(
      otherData,
      thunkAPI
    );
  }
);

interface NotificationsState {
  register: {
    fetchStatus: string | null;
    firebaseRegistered: boolean;
  };
  badges: {
    messagesCount: number;
    notificationsCount: number;
    fetchStatus: string | null;
  };
  notificationsPreview: {
    fetchStatus: string | null;
    postFetchStatus: string | null;
    patchFetchStatus: string | null;
    items: Array<any>;
  };
  lastUpdateTimestamp: number;
  fetchStatus: string | null;
  errorMessage: any;
  list: {
    count: number | null;
    fetchStatus: string | null;
    items: Array<any>;
    next: string | null;
    previous: string | null;
  };
}

const initialState: NotificationsState = {
  register: {
    fetchStatus: RequestStatus.status.NULL,
    firebaseRegistered: false,
  },
  badges: {
    messagesCount: 0,
    notificationsCount: 0,
    fetchStatus: RequestStatus.status.NULL,
  },
  notificationsPreview: {
    items: [],
    fetchStatus: RequestStatus.status.NULL,
    postFetchStatus: RequestStatus.status.NULL,
    patchFetchStatus: RequestStatus.status.NULL,
  },
  lastUpdateTimestamp: getTime(new Date()),
  fetchStatus: RequestStatus.status.NULL,
  errorMessage: null,
  list: {
    count: 0,
    fetchStatus: RequestStatus.status.NULL,
    items: [],
    next: null,
    previous: null,
  },
};

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateFetchStatus(state, action) {
      state.fetchStatus = action.payload;
    },
    refreshLastUpdateTimestamp(state, action) {
      state.lastUpdateTimestamp = getTime(new Date());
    },
    setPatchFetchStatus(state, action) {
      state.notificationsPreview.patchFetchStatus = action.payload || RequestStatus.status.NULL;
    },
    clearNotificationsData(state, action) {
      state.badges = initialState.badges;
      state.notificationsPreview = initialState.notificationsPreview;
      state.list = initialState.list;
    },
  },
  extraReducers: {
    [getNotifications.pending]: (state, action) => {
      state.list.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getNotifications.fulfilled]: (state, action) => {
      let notifications = action.payload.results;
      const appUrl = process.env.REACT_APP_URL;
      for (let notification of notifications) {
        if (notification?.link?.includes(appUrl))
          notification.relativeLink = notification.link.replace(appUrl, '/');
      }
      state.list.items = notifications;
      state.list.fetchStatus = RequestStatus.status.DONE;
      state.list.count = action.payload.count;
      state.list.next = action.payload.next;
      state.list.previous = action.payload.previous;
    },
    [getNotifications.rejected]: (state, action) => {
      state.list.fetchStatus = RequestStatus.status.ERROR;
    },
    [getNotificationsSummary.pending]: (state, action) => {
      state.badges.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getNotificationsSummary.fulfilled]: (state, action) => {
      state.badges.messagesCount = action.payload.unread_messages_count;
      state.badges.notificationsCount = action.payload.notifications_count;
      state.badges.fetchStatus = RequestStatus.status.DONE;
    },
    [getNotificationsSummary.rejected]: (state, action) => {
      state.badges.fetchStatus = RequestStatus.status.ERROR;
    },
    [getNotificationsPreview.pending]: (state, action) => {
      state.notificationsPreview.fetchStatus = RequestStatus.status.FETCHING;
    },
    [getNotificationsPreview.fulfilled]: (state, action) => {
      state.notificationsPreview.items = action.payload.results;
      state.notificationsPreview.fetchStatus = RequestStatus.status.DONE;
    },
    [getNotificationsPreview.rejected]: (state, action) => {
      state.notificationsPreview.fetchStatus = RequestStatus.status.ERROR;
    },
    [postRegisterDevice.pending]: (state, action) => {
      state.register.fetchStatus = RequestStatus.status.FETCHING;
    },
    [postRegisterDevice.fulfilled]: (state, action) => {
      state.register.firebaseRegistered = true;
      state.register.fetchStatus = RequestStatus.status.DONE;
    },
    [postRegisterDevice.rejected]: (state, action) => {
      state.register.fetchStatus = RequestStatus.status.ERROR;
    },
    [postMarkNotificationsAsRead.pending]: (state, action) => {
      state.notificationsPreview.postFetchStatus = RequestStatus.status.FETCHING;
    },
    [postMarkNotificationsAsRead.fulfilled]: (state, action) => {
      state.lastUpdateTimestamp = getTime(new Date());
      state.notificationsPreview.postFetchStatus = RequestStatus.status.DONE;
    },
    [postMarkNotificationsAsRead.rejected]: (state, action) => {
      state.notificationsPreview.postFetchStatus = RequestStatus.status.ERROR;
    },
    [patchMarkNotificationAsRead.pending]: (state, action) => {
      state.notificationsPreview.patchFetchStatus = RequestStatus.status.FETCHING;
    },
    [patchMarkNotificationAsRead.fulfilled]: (state, action) => {
      state.lastUpdateTimestamp = getTime(new Date());
      state.notificationsPreview.patchFetchStatus = RequestStatus.status.DONE;
    },
    [patchMarkNotificationAsRead.rejected]: (state, action) => {
      state.notificationsPreview.patchFetchStatus = RequestStatus.status.ERROR;
    },
    [postClearThreadNotifications.pending]: (state, action) => {
      state.badges.fetchStatus = RequestStatus.status.FETCHING;
    },
    [postClearThreadNotifications.fulfilled]: (state, action) => {
      state.badges.fetchStatus = RequestStatus.status.DONE;
      state.lastUpdateTimestamp = getTime(new Date());
    },
    [postClearThreadNotifications.rejected]: (state, action) => {
      state.badges.fetchStatus = RequestStatus.status.ERROR;
    },
  },
});

export const { reducer } = slice;
export const {
  updateFetchStatus,
  refreshLastUpdateTimestamp,
  setPatchFetchStatus,
  clearNotificationsData,
} = slice.actions;

export default slice;
