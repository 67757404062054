import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { RootState, useDispatch, useSelector } from '../../store';
import {
  patchMarkNotificationAsRead,
  postMarkNotificationsAsRead,
} from '../../slices/notifications';
import { Notification } from '../../types/notification';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px',
  [theme.breakpoints.up('xs')]: {
    paddingLeft: '280px',
  },
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
});

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { badges, notificationsPreview, fetchStatus } = useSelector(
    (state: RootState) => state.notifications
  );
  const { notificationsCount, messagesCount } = badges;

  const onMarkNotificationsAsRead = () => {
    dispatch(postMarkNotificationsAsRead());
  };

  const onMarkNotificationAsRead = (notification: Notification) => {
    dispatch(patchMarkNotificationAsRead({ id: notification.uuid, is_read: true }));
  };

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
        notificationsCount={notificationsCount}
        notifications={notificationsPreview.items}
        onMarkNotificationsAsRead={onMarkNotificationsAsRead}
        onMarkNotificationAsRead={onMarkNotificationAsRead}
      />
      <DashboardSidebar
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
        messagesCount={messagesCount}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
